import EditTeamMember from "../../../components/TeamMembers/EditTeamMember";

const TeamMembersEdit = () => {
  return (
    <>
      <EditTeamMember />
    </>
  );
};

export default TeamMembersEdit;
