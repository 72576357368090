import React from "react";
import "antd/dist/antd.css";
import { Avatar, Badge } from "antd";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import { getData } from "../../utils/query";
import { UserOutlined } from "@ant-design/icons";

const Organisations = () => {
  const columns = [
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
    },
    {
      title: "TradingName",
      dataIndex: "TradingName",
      key: "TradingName",
      sorter: (a, b) => a.TradingName.localeCompare(b.TradingName),
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      width: 80,
      render: (image) =>
        image.length > 0 ? (
          <Avatar src={image} />
        ) : (
          <Avatar
            style={{ color: "#5468ff", backgroundColor: "#e6f7ff" }}
            icon={<UserOutlined />}
          ></Avatar>
        ),
    },
    {
      title: "Abn",
      dataIndex: "abn",
      key: "abn",
      sorter: (a, b) => a.abn.localeCompare(b.abn),
    },
    {
      title: "Street",
      dataIndex: "street",
      key: "street",
      sorter: (a, b) => a.street.localeCompare(b.street),
    },
    {
      title: "Suburb",
      dataIndex: "suburb",
      key: "suburb",
      sorter: (a, b) => a.suburb.localeCompare(b.suburb),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: "Postcode",
      dataIndex: "postcode",
      key: "postcode",
      sorter: (a, b) => a.postcode.localeCompare(b.postcode),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: "Departments",
      dataIndex: "departments",
      key: "departments",
      sorter: (a, b) => a.departments.localeCompare(b.departments),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let type = status === "Active" ? "success" : "error";
        return <Badge status={type} text={status} />;
      },
      sorter: (a, b) => b.status.length - a.status.length,
    },
  ];

  return (
    <div className="table-organisations">
      <StandardTableLayout
        hierachy={[{ key: 1, label: "Organisations" }]}
        dataSource={getData}
        columns={columns}
        table="team/organisations"
      />
    </div>
  );
};

export default Organisations;
