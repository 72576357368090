import VehicleMakes from "../../../components/VehicleMakes";

const VehicleMakesPage = () => {
  return (
    <>
      <VehicleMakes />
    </>
  );
};

export default VehicleMakesPage;
