import TeamMember from "./index";
import TeamMembersEdit from "./detail";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "/team/members/:memberId",
      component: TeamMembersEdit,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/team/members",
      component: TeamMember,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/team",
      component: TeamMember,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
  ],
};

export default router;
