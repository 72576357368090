import Document from "../../../components/Document";

const SafeWorkMethodStatements = () => {
  return (
    <>
      <Document description="Safe Work Method Statement"/>
    </>
  );
};

export default SafeWorkMethodStatements;
