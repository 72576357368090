import Vehicle from "./Vehicle/index";
import VehicleEdit from "./Vehicle/detail";
import FleetCategory from "./Categories";
import DetailFleetCategory from "./Categories/detail";
import VehicleModel from "./Models";
import DetailVehicleModel from "./Models/detail";
import VehicleMakesPage from "./Makes";
import VehicleMakeEditPage from "./Makes/detail";
import FleetIssuesPage from "./Issues";
import FleetIssuesEditPage from "./Issues/detail";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "/fleet/vehicle/:vehicleId",
      component: VehicleEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/vehicle",
      component: Vehicle,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/categories/:id",
      component: DetailFleetCategory,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/categories",
      component: FleetCategory,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/vehicle/models/:id",
      component: DetailVehicleModel,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/vehicle/models",
      component: VehicleModel,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/vehicle/makes/:id",
      component: VehicleMakeEditPage,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/vehicle/makes",
      component: VehicleMakesPage,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet",
      component: Vehicle,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/issues/:id",
      component: FleetIssuesEditPage,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/issues",
      component: FleetIssuesPage,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
  ],
};

export default router;
