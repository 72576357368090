import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
//state
const timeSheetSlice = createSlice({
  name: "userStore",
  initialState: {
    searchDateTime: moment(),
    detailTimeSheet: null,
    detailTimeSheetAdmin: null,
    personalTimeSheets: [],
    employTimeSheet: {},
    totalOrdinaryMember: {},
    totalAdminMember: {},
    allTimeSheets: [],
    detailTimeSheetById: null,
  },
  reducers: {
    setDetailTimeSheet: (state, action) => {
      state.detailTimeSheet = action.payload;
    },
    setDetailTimeSheetAdmin: (state, action) => {
      state.detailTimeSheetAdmin = action.payload;
    },
    setEmployTimeSheet: (state, action) => {
      state.employTimeSheet = action.payload;
      state.detailTimeSheet = null;
    },
    setPersonalTimesheet: (state, action) => {
      state.personalTimeSheets = action.payload;
      state.detailTimeSheet = null;
    },
    setTotalOrdinaryMember: (state, action) => {
      state.totalOrdinaryMember = action.payload;
      state.detailTimeSheet = null;
    },
    setTotalOrdinaryAdmin: (state, action) => {
      state.totalAdminMember = action.payload;
      state.detailTimeSheet = null;
    },
    setAllTimeSheets: (state, action) => {
      state.allTimeSheets = action.payload;
      state.detailTimeSheet = null;
    },
    setDetailTimeSheetById: (state, action) => {
      state.detailTimeSheetById = action.payload;
      state.detailTimeSheet = null;
    },
    setSearchDateTime: (state, action) => {
      state.searchDateTime = action.payload
    }
  },
});

export default timeSheetSlice.reducer;

//action
export const {
  setDetailTimeSheet,
  setDetailTimeSheetAdmin,
  setPersonalTimesheet,
  setEmployTimeSheet,
  setTotalOrdinaryMember,
  setTotalOrdinaryAdmin,
  setDateData,
  setAllTimeSheets,
  setDetailTimeSheetById,
  setSearchDateTime
} = timeSheetSlice.actions;

//category
export const FETCH_ALL_TIME_SHEET = async (organisationId) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/time-sheet/all?organisationId=${organisationId}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_ALL_TIME_SHEET_ADMIN = async (organisationId, query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/time-sheet/allTimeSheetForCurrentWeek`, 
        {
          params: {
            organisationId,
            query
          }
        }
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_TOTAL_TIME_SHEET = async ({ organisationId, memberId, query }) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/time-sheet/allTimeSheetForWeekByTeamMemberId`, {
          params: {
            teamMemberId: memberId,
            organisationId,
            query
          }
        }
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_ALL_TIME_SHEET_HAVE_BANKED_FOR_TEAMMEMBER = async ({
  organisationId,
  memberId,
}) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/time-sheet/allByMemberId`, {
        params: {
          organisationId,
          memberId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_TIME_SHEET = async (id, currentDate) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/time-sheet/detail?id=${id}&currentDate=${currentDate}`
      )
      .then((res) => {
        return res;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_TIME_SHEET_BY_IDDATE = async (id) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/time-sheet/detailById?id=${id}`
      )
      .then((res) => {
        return res;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATE_TIME_SHEET = async (data, sign) => {
  try {
    const formData = new FormData();
    if (sign) {
      formData.append("photo", sign);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/time-sheet/create`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATE_TIME_SHEET = async (data, sign) => {
  try {
    const formData = new FormData();
    if (sign) {
      formData.append("photo", sign);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/time-sheet/update`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATE_TIME_SHEET_COMMENTS = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/time-sheet/updateComments`, data)
        .then((res) => {
          return res.data.payload.data;
        });
  } catch (err) {
    throw err;
  }
};

export const DELETE_TIME_SHEET = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/time-sheet/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const NEW_TIME_SHEET =
  ({ dateTime, teamMember }) =>
  (dispatch) => {
    const newModel = {
      _id: "new",
      startTime: moment("00:00", "HH:mm"),
      dateTime,
      startBreak: moment("00:00", "HH:mm"),
      finishBreak: moment("00:00", "HH:mm"),
      finishTime: moment("00:00", "HH:mm"),
      teamMember,
      // payrollProfile: "",
      totalHours: 0,
      day: 0,
      overTimeHours: 0,
      leave: [],
      annualLeaveHours: 0,
      personalLeaveHours: 0,
      leaveWithoutPay: 0,
      bankedHourBalance: 0,
      bankedHours: 0,
      withdraw: 0,
      deposit: 0,
      personCreatedRecord: "",
      lastPersonModifiedRecord: "",
      wasThisDayWorkedAway: false,
      workedAwayHours: 0,
      comment: "",
    };
    return dispatch(setDetailTimeSheet(newModel));
  };

export const NEW_TIME_SHEET_ADMIN =
  ({ dateTime, teamMember }) =>
  (dispatch) => {
    const newModel = {
      _id: "new",
      startTime: moment("00:00", "HH:mm"),
      dateTime,
      startBreak: moment("00:00", "HH:mm"),
      finishBreak: moment("00:00", "HH:mm"),
      finishTime: moment("00:00", "HH:mm"),
      teamMember,
      // payrollProfile: "",
      totalHours: 0,
      day: 0,
      overTimeHours: 0,
      leave: [],
      annualLeaveHours: 0,
      personalLeaveHours: 0,
      leaveWithoutPay: 0,
      bankedHours: 0,
      bankedHourBalance: 0,
      withdraw: 0,
      deposit: 0,
      personCreatedRecord: "",
      lastPersonModifiedRecord: "",
      wasThisDayWorkedAway: false,
      workedAwayHours: 0,
      comment: "",
    };
    return dispatch(setDetailTimeSheetAdmin(newModel));
  };
