import Organisations from "./index";
import OrganisationsEdit from "./detail";

const router = {
  settings: {
    layout: {},
  },

  routes: [
    {
      path: "/team/organisations/:orgId",
      component: OrganisationsEdit,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/team/organisations",
      component: Organisations,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
  ],
};

export default router;
