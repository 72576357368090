import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../styles/StandardTableLayout.module.scss";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import {
  Layout,
  Breadcrumb,
  List,
  Space,
  Typography,
  Spin,
  DatePicker,
} from "antd";
import { Alert } from "antd";
import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Content } = Layout;
const { Text } = Typography;

const StandardListLayout = ({
  canAccess,
  isLoading,
  hierachy,
  dataSource,
  table,
}) => {
  const { totalOrdinaryMember, totalAdminMember } = useSelector(
    ({ timeSheet }) => timeSheet.TimeSheetReducer
  );
  const { firstname, lastname, teamMemberId } = useSelector(
    ({ auth }) => auth.user
  );
  const [isPayrollProfile, setIsPayrollProfile] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [hourTotal, setHourTotal] = useState(0.0);
  const [overTime150, setOverTime150] = useState(0);
  const [overTime200, setOverTime200] = useState(0);
  const [annualHours, setAnnualHours] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [ordinaryHourBonus, setOrdinaryHourBonus] = useState(0);
  const [paidLunch, setPaidLunch] = useState(0);
  const [personalHours, setPersonalHours] = useState(0);
  const [leaveHours, setLeaveHours] = useState(0);
  const [bankedHourBalance, setBankedHourBalance] = useState(0);
  let navigate = useNavigate();
  const { idMember, name } = useParams();
  const [expand, setExpand] = useState([]);

  const handleClickRow = (record) => {
    if (idMember) {
      navigate(`/${table}/${idMember}/${record._id}/${name}`);
    } else {
      navigate(`/${table}/${record._id}`);
    }
  };

  const handleExpand = (record) => {
    const arrExpand = [...expand];
    const index = arrExpand.indexOf(record._id);
    if (index === -1) {
      arrExpand.push(record._id);
    } else {
      arrExpand.splice(index, 1);
    }
    setExpand(arrExpand);
  };

  useEffect(() => {
    const newData = [];
    const expandData = [];
    if (dataSource) {
      dataSource?.forEach((ele) => {
        newData.push({ ...ele, key: ele._id });
        if (
          ele?.ordinaryHours !== 0 ||
          ele?.bankedHours !== 0 ||
          ele?.bonus !== 0 ||
          ele?.paidLunch !== 0 ||
          ele?.overtime150 !== 0 ||
          ele?.leaveWithoutPay !== 0 ||
          ele?.personalLeaveHours !== 0 ||
          ele?.annualLeaveHours !== 0
        ) {
          expandData.push(ele._id);
        }
      });

      setExpand(expandData);
      setTableData(newData);

      // Total data
      if (idMember) {
        setIsPayrollProfile(
          totalAdminMember &&
            totalAdminMember?.payrollProfile &&
            totalAdminMember?.payrollProfile
        );
        setHourTotal(
          totalAdminMember &&
            totalAdminMember?.totalHours &&
            totalAdminMember?.totalHours
        );
        setBonus(
          totalAdminMember && totalAdminMember?.bonus && totalAdminMember?.bonus
        );
        setOrdinaryHourBonus(
          totalAdminMember &&
            totalAdminMember?.ordinaryHourBonus &&
            totalAdminMember?.ordinaryHourBonus
        );
        setPaidLunch(
          totalAdminMember &&
            totalAdminMember?.paidLunch &&
            totalAdminMember?.paidLunch
        );
        setOverTime150(
          totalAdminMember &&
            totalAdminMember?.overtime150 &&
            totalAdminMember?.overtime150
        );
        setOverTime200(
          totalAdminMember &&
            totalAdminMember?.overtime200 &&
            totalAdminMember?.overtime200
        );
        setBankedHourBalance(
          totalAdminMember &&
            totalAdminMember?.bankedHourBalance &&
            totalAdminMember?.bankedHourBalance
        );
        setLeaveHours(
          totalAdminMember &&
            totalAdminMember?.leaveWithoutPay &&
            totalAdminMember?.leaveWithoutPay
        );
        setAnnualHours(
          totalAdminMember &&
            totalAdminMember?.annualleaveHours &&
            totalAdminMember?.annualleaveHours
        );
        setPersonalHours(
          totalAdminMember &&
            totalAdminMember?.personalLeaveHours &&
            totalAdminMember?.personalLeaveHours
        );
      } else {
        setIsPayrollProfile(
          totalOrdinaryMember &&
            totalOrdinaryMember?.payrollProfile &&
            totalOrdinaryMember?.payrollProfile
        );
        setHourTotal(
          totalOrdinaryMember &&
            totalOrdinaryMember?.totalHours &&
            totalOrdinaryMember?.totalHours
        );
        setOrdinaryHourBonus(
          totalOrdinaryMember &&
            totalOrdinaryMember?.ordinaryHourBonus &&
            totalOrdinaryMember?.ordinaryHourBonus
        );
        setBonus(
          totalOrdinaryMember &&
            totalOrdinaryMember?.bonus &&
            totalOrdinaryMember?.bonus
        );
        setPaidLunch(
          totalOrdinaryMember &&
            totalOrdinaryMember?.paidLunch &&
            totalOrdinaryMember?.paidLunch
        );
        setOverTime150(
          totalOrdinaryMember &&
            totalOrdinaryMember?.overtime150 &&
            totalOrdinaryMember?.overtime150
        );
        setOverTime200(
          totalOrdinaryMember &&
            totalOrdinaryMember?.overtime200 &&
            totalOrdinaryMember?.overtime200
        );
        setBankedHourBalance(
          totalOrdinaryMember &&
            totalOrdinaryMember?.bankedHourBalance &&
            totalOrdinaryMember.bankedHourBalance
        );
        setLeaveHours(
          totalOrdinaryMember &&
            totalOrdinaryMember?.leaveWithoutPay &&
            totalOrdinaryMember?.leaveWithoutPay
        );
        setAnnualHours(
          totalOrdinaryMember &&
            totalOrdinaryMember?.annualleaveHours &&
            totalOrdinaryMember?.annualleaveHours
        );
        setPersonalHours(
          totalOrdinaryMember &&
            totalOrdinaryMember?.personalLeaveHours &&
            totalOrdinaryMember?.personalLeaveHours
        );
      }
    }
  }, [dataSource, hierachy, table]);

  return (
    <>
      <Content className={styles.content}>
        <Breadcrumb
          style={{ margin: "16px 0", minHeight: "30px" }}
        ></Breadcrumb>
        {canAccess ? (
          <div className="site-layout-content time-sheet-layout">
            {isLoading ? (
              <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
              />
            ) : (
              <>
                <Typography.Title level={4}>Timesheet</Typography.Title>
                <div className="information-text">
                  <p>
                    <span>Team Member:</span>
                    <span
                      style={{ color: "#1890ff", cursor: "pointer" }}
                      onClick={() =>
                        window.open(`/team/members/${idMember ? idMember : teamMemberId}`, "_blank")
                      }
                    >
                      {name ? name.replace("_", " ") : `${firstname} ${lastname}`}
                    </span>
                  </p>
                  <p>
                    <span>Period:</span>
                    <span>
                      {`${dataSource[0].dateTime}`}{" "}
                      to{" "}
                      {`${
                        dataSource[dataSource?.length - 1].dateTime
                      }`}
                    </span>
                  </p>
                </div>
                <List
                  dataSource={tableData}
                  size="small"
                  renderItem={(record) => (
                    <List.Item
                      onClick={() => handleExpand(record)}
                      style={
                        record.dateText === "Sunday" ||
                        record.dateText === "Saturday"
                          ? {
                              backgroundColor: "#F7F7F7",
                              cursor: "pointer",
                              textAlign: "left",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "inherit",
                              gap: "10px",
                            }
                          : {
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "inherit",
                              gap: "10px",
                            }
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ minWidth: "150px" }}>
                          <Text>
                            {record &&
                              (record?.ordinaryHours !== 0 ||
                                record?.bankedHours !== 0 ||
                                record?.bonus !== 0 ||
                                record?.paidLunch !== 0 ||
                                record?.overtime150 !== 0 ||
                                record?.leaveWithoutPay !== 0 ||
                                record?.personalLeaveHours !== 0 ||
                                record?.annualLeaveHours !== 0) && (
                                <span style={{ marginLeft: "-16px" }}>
                                  {expand.includes(record._id) ? (
                                    <MinusSquareOutlined
                                      onClick={() => handleExpand(record)}
                                      style={{
                                        marginRight: "11px",
                                        opacity: 0.6,
                                      }}
                                    />
                                  ) : (
                                    <PlusSquareOutlined
                                      onClick={() => handleExpand(record)}
                                      style={{
                                        marginRight: "11px",
                                        opacity: 0.6,
                                      }}
                                    />
                                  )}
                                </span>
                              )}
                          </Text>
                          <Text
                            onClick={() => handleClickRow(record)}
                            style={{
                              paddingLeft:
                                !expand.includes(record._id) && "10px",
                            }}
                          >
                            <strong>
                              {record.dateTime} - {record.dateText}
                            </strong>
                          </Text>
                        </div>

                        {/* time  */}
                        <div className="timeRecoder">
                          {record.startTime && (
                            <Text onClick={() => handleClickRow(record)}>
                              <strong>Start</strong> - {record.startTime}
                            </Text>
                          )}

                          {record.startBreak && (
                            <Text onClick={() => handleClickRow(record)}>
                              <strong>Start Break</strong> - {record.startBreak}
                            </Text>
                          )}

                          {record.finishBreak && (
                            <Text onClick={() => handleClickRow(record)}>
                              <strong>Finish break</strong> -{" "}
                              {record.finishBreak}
                            </Text>
                          )}

                          {record.finishTime && (
                            <Text onClick={() => handleClickRow(record)}>
                              <strong>Finish</strong> - {record.finishTime}
                            </Text>
                          )}
                          <Text onClick={() => handleClickRow(record)}></Text>
                        </div>
                        {/* <Text onClick={() => handleClickRow(record)}>
                          {record.totalHours.toFixed(2)}
                        </Text> */}
                      </div>

                      {/* expand  */}
                      {record &&
                        (record?.ordinaryHours !== 0 ||
                          record?.bankedHours !== 0 ||
                          record?.bonus !== 0 ||
                          record?.paidLunch !== 0 ||
                          record?.overtime150 !== 0 ||
                          record?.leaveWithoutPay !== 0 ||
                          record?.personalLeaveHours !== 0 ||
                          record?.annualLeaveHours !== 0) &&
                        expand.includes(record._id) && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                              paddingLeft: "83px",
                            }}
                          >
                            {record?.ordinaryHours !== 0 && (
                              <Text
                                style={{
                                  color: "grey",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Ordinary</span>{" "}
                                <span>{record.ordinaryHours?.toFixed(2)}</span>
                              </Text>
                            )}

                            {record?.bankedHours !== 0 && (
                              <Text
                                style={{
                                  color: "grey",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>
                                  Banked Hour{" "}
                                  {record.bankedHours > 0
                                    ? "(Deposit)"
                                    : "(Withdrawal)"}
                                </span>{" "}
                                <span>{record.bankedHours?.toFixed(2)}</span>
                              </Text>
                            )}

                            {record?.bonus !== 0 && (
                              <Text
                                style={{
                                  color: "grey",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Bonus</span>{" "}
                                <span>{record.bonus?.toFixed(2)}</span>
                              </Text>
                            )}

                            {record?.paidLunch !== 0 && (
                              <Text
                                style={{
                                  color: "grey",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Paid Lunch</span>{" "}
                                <span>{record.paidLunch?.toFixed(2)}</span>
                              </Text>
                            )}

                            {record?.overtime150 !== 0 && (
                              <Text
                                style={{
                                  color: "grey",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Overtime 1.5</span>{" "}
                                <span>{record.overtime150?.toFixed(2)}</span>
                              </Text>
                            )}

                            {record?.leaveWithoutPay &&
                            record?.leaveWithoutPay !== 0 ? (
                              <Text
                                style={{
                                  color: "grey",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span> Leave Without Pay</span>{" "}
                                <span>{record?.leaveWithoutPay}</span>
                              </Text>
                            ) : (
                              <></>
                            )}
                            {record?.personalLeaveHours &&
                            record?.personalLeaveHours !== 0 ? (
                              <Text
                                style={{
                                  color: "grey",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Personal Leave</span>{" "}
                                <span>{record?.personalLeaveHours}</span>
                              </Text>
                            ) : (
                              <></>
                            )}
                            {record?.annualLeaveHours &&
                            record?.annualLeaveHours !== 0 ? (
                              <Text
                                style={{
                                  color: "grey",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Annual Leave:</span>{" "}
                                <span>{record?.annualLeaveHours}</span>
                              </Text>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                    </List.Item>
                  )}
                ></List>

                <Space
                  direction="vertical"
                  style={{ margin: "16px 0", width: "100%" }}
                >
                  {isPayrollProfile?.bankedHoursUsed && (
                    <>
                      <Typography.Title level={5}>
                        Banked Hours
                      </Typography.Title>
                      <Space
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          padding: "0 16px",
                        }}
                      >
                        <Typography.Text>Balance</Typography.Text>
                        <Typography.Text>
                          {bankedHourBalance?.toFixed(2)}
                        </Typography.Text>
                      </Space>
                    </>
                  )}
                  <Typography.Title level={5}>Summary</Typography.Title>
                  <Space
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      padding: "0 16px",
                    }}
                  >
                    <Typography.Text>Ordinary</Typography.Text>
                    <Typography.Text>{hourTotal?.toFixed(2)}</Typography.Text>
                  </Space>
                  {ordinaryHourBonus ? (
                    <Space
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <Typography.Text>Ordinary (Bonus)</Typography.Text>
                      <Typography.Text>
                        {ordinaryHourBonus?.toFixed(2)}
                      </Typography.Text>
                    </Space>
                  ) : (
                    ""
                  )}
                  {bonus ? (
                    <Space
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <Typography.Text>Bonus</Typography.Text>
                      <Typography.Text>{bonus?.toFixed(2)}</Typography.Text>
                    </Space>
                  ) : (
                    ""
                  )}
                  {paidLunch ? (
                    <Space
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <Typography.Text>Paid Lunch</Typography.Text>
                      <Typography.Text>{paidLunch?.toFixed(2)}</Typography.Text>
                    </Space>
                  ) : (
                    ""
                  )}
                  {overTime150 ? (
                    <Space
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <Typography.Text>Overtime 1.5</Typography.Text>
                      <Typography.Text>
                        {overTime150?.toFixed(2)}
                      </Typography.Text>
                    </Space>
                  ) : (
                    ""
                  )}
                  {overTime200 ? (
                    <Space
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <Typography.Text>Overtime 2</Typography.Text>
                      <Typography.Text>
                        {overTime200?.toFixed(2)}
                      </Typography.Text>
                    </Space>
                  ) : (
                    ""
                  )}
                  {annualHours && annualHours > 0 ? (
                    <Space
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <Typography.Text>Annual Leave Hours</Typography.Text>
                      <Typography.Text>
                        {annualHours?.toFixed(2)}
                      </Typography.Text>
                    </Space>
                  ) : (
                    <></>
                  )}
                  {personalHours && personalHours > 0 ? (
                    <Space
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <Typography.Text>Personal Hours</Typography.Text>
                      <Typography.Text>
                        {personalHours?.toFixed(2)}
                      </Typography.Text>
                    </Space>
                  ) : (
                    <></>
                  )}
                  {leaveHours && leaveHours > 0 ? (
                    <Space
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <Typography.Text>Leave Without Pay Hours</Typography.Text>
                      <Typography.Text>
                        {leaveHours?.toFixed(2)}
                      </Typography.Text>
                    </Space>
                  ) : (
                    <></>
                  )}
                </Space>
              </>
            )}
          </div>
        ) : (
          <Alert
            message="Error"
            description="You must be a team member to view TimeSheet"
            type="error"
          />
        )}
      </Content>
    </>
  );
};

export default StandardListLayout;
