import Contact from "./index";
import ContactEdit from "./detail";
import Industry from "./Industry/index";
import IndustryEdit from "./Industry/detail";
import Purchase from "./Purchase/index";
import PurchaseEdit from "./Purchase/detail";
import ContactView from "../../components/Contact/ViewContact";
// import ContactPerson from "../../components/Contact/EditContactPerson";

const router = {
  settings: {
    layout: {},
  },

  routes: [
    {
      path: "/contact/:id",
      component: ContactEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/contact",
      component: Contact,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/contact/view/:id",
      component: ContactView,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    // {
    //   path: "/contact/person/:contactId/:id",
    //   component: ContactPerson,
    //   auth: ["admin", "manager"],
    //   settings: {
    //     layout: {
    //       head: true,
    //       sider: true,
    //     },
    //   },
    // },
    {
      path: "/purchase/:id",
      component: PurchaseEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/purchase",
      component: Purchase,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/contact/industry/:id",
      component: IndustryEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/contact/industry",
      component: Industry,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
  ],
};

export default router;
