import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  Spin,
  DatePicker,
  Space,
  Typography,
  InputNumber,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import "../../styles/teamMember.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_VEHICLE,
  UPDATE_VEHICLE,
  FETCH_ALL_FLEET_CATEGORIES,
  FETCH_ALL_VEHICLE_MAKE,
  FETCH_DETAIL_VEHICLE,
  NEW_VEHICLE,
  setCategories,
  setDetailVehicle,
  setVehicleMakes,
} from "../../store/fleet-store/reducer";
import {
  FETCH_ALL_FLEET_PRESTART,
  setFleetPrestarts,
} from "../../store/fleet-prestart-store/reducer";
import ModalFleetVehicles from "./components/ModalFleetVehicles";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditTeamMember = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const { vehicleId } = useParams();
  const { pathname } = useLocation();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { detailVehicle, categories, makes } = useSelector(
    ({ fleets }) => fleets.fleetReducer
  );
  const { fleetPrestarts } = useSelector(
    ({ fleetPreStart }) => fleetPreStart.fleetPreStartReducer
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState({
    visible: false,
    type: "",
  });

  const onFinish = (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailVehicle._id;
    if (vehicleId === "new") {
      CREATE_VEHICLE(values).then((data) => {
        setLoading(false);
        navigate("/fleet/vehicle");
      });
    } else {
      UPDATE_VEHICLE(values).then((data) => {
        setLoading(false);
        navigate("/fleet/vehicle");
      });
    }
  };

  const fetchSubData = async () => {
    const fleetCategoryData = await FETCH_ALL_FLEET_CATEGORIES(organisationId);
    if (fleetCategoryData) {
      dispatch(setCategories(fleetCategoryData));
    }
    const vehicleMakeData = await FETCH_ALL_VEHICLE_MAKE(organisationId);
    if (vehicleMakeData) {
      dispatch(setVehicleMakes(vehicleMakeData));
    }
    const fleetPreStartData = await FETCH_ALL_FLEET_PRESTART(organisationId);
    if (fleetPreStartData) {
      dispatch(setFleetPrestarts(fleetPreStartData));
    }
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      if (vehicleId === "new") {
        dispatch(NEW_VEHICLE());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_VEHICLE(vehicleId).then((data) => {
          dispatch(setDetailVehicle(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, vehicleId]);

  useEffect(() => {
    if (detailVehicle) {
      form.setFields([
        {
          name: "autoNumber",
          value: detailVehicle.autoNumber,
        },
        {
          name: "nickName",
          value: detailVehicle.nickName,
        },
        {
          name: "registration",
          value: detailVehicle.registration,
        },
        {
          name: "fleetPreStart",
          value: detailVehicle.fleetPreStart?._id,
        },
        {
          name: "make",
          value: detailVehicle.make?._id,
        },
        {
          name: "category",
          value: detailVehicle.category?._id,
        },
        {
          name: "year",
          value: detailVehicle.year,
        },
        {
          name: "seats",
          value: detailVehicle.seats,
        },
        {
          name: "TARE",
          value: detailVehicle.TARE,
        },
        {
          name: "GVM",
          value: detailVehicle.GVM,
        },
        {
          name: "color",
          value: detailVehicle.color,
        },
        {
          name: "tollId",
          value: detailVehicle.tollId,
        },
        {
          name: "lastOdometer",
          value: detailVehicle.lastOdometer,
        },
        {
          name: "lastOdometerDate",
          value: moment(detailVehicle.lastOdometerDate),
        },
        {
          name: "nextServiceOdometer",
          value: detailVehicle.nextServiceOdometer,
        },
        {
          name: "notes",
          value: detailVehicle.notes,
        },
        {
          name: "driveUrl",
          value: detailVehicle.driveUrl,
        },
        {
          name: "hourlyCost",
          value: detailVehicle.hourlyCost,
        },
        {
          name: "vinNumber",
          value: detailVehicle.vinNumber,
        },
        {
          name: "enginNumber",
          value: detailVehicle.enginNumber,
        },
        {
          name: "fuelType",
          value: detailVehicle.fuelType,
        },
        {
          name: "status",
          value: detailVehicle.status,
        },
      ]);
    }
  }, [detailVehicle]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Fleet Vehicle", path: "/fleet/vehicle" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/fleet/vehicle/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            requiredMark={false}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 12 }}
            form={form}
            onFinish={onFinish}
            className="member-form modal-sub"
          >
            <div className="personal">
              {/* firstName */}
              <Form.Item
                label="Auto Number"
                name="autoNumber"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Nick Name"
                name="nickName"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              {/* lastName */}
              <Form.Item
                label="Registration"
                name="registration"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Fleet Prestart" name="fleetPreStart">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input?.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA?.children?.toLowerCase()
                      .localeCompare(optionB?.children?.toLowerCase())
                  }
                >
                  {fleetPrestarts &&
                    fleetPrestarts.map((fleetPreStart) => (
                      <Select.Option
                        key={fleetPreStart?._id}
                        value={fleetPreStart?._id}
                      >
                        {fleetPreStart.title}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item label="Vehicle Makes" name="make">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA?.children?.toLowerCase()?.localeCompare(optionB?.children?.toLowerCase())
                  }
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Space align="right" style={{ padding: "0 8px 4px" }}>
                        <Typography.Link
                          onClick={() =>
                            setIsModalVisible({
                              visible: true,
                              type: "Vehicle Makes",
                            })
                          }
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <PlusOutlined /> Add item
                        </Typography.Link>
                      </Space>
                    </>
                  )}
                >
                  {makes &&
                    makes.map((make) => (
                      <Select.Option key={make?._id} value={make?._id}>
                        {make.description}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <div>
                {isModalVisible.visible && (
                  <ModalFleetVehicles
                    form={form}
                    fetchSubData={fetchSubData}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                  />
                )}
              </div>

              <Form.Item label="Category" name="category">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA?.children?.toLowerCase()?.localeCompare(optionB?.children?.toLowerCase())
                  }
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Space align="center" style={{ padding: "0 8px 4px" }}>
                        <Typography.Link
                          onClick={() =>
                            setIsModalVisible({
                              visible: true,
                              type: "Category",
                            })
                          }
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <PlusOutlined /> Add item
                        </Typography.Link>
                      </Space>
                    </>
                  )}
                >
                  {categories &&
                    categories.map((categorie) => (
                      <Select.Option
                        key={categorie?._id}
                        value={categorie?._id}
                      >
                        {categorie.description}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item label="Fuel Type" name="fuelType">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA?.children?.toLowerCase()?.localeCompare(optionB?.children?.toLowerCase())
                  }
                >
                  <Select.Option value="petrol">Petrol</Select.Option>
                  <Select.Option value="gas">Gas</Select.Option>
                  <Select.Option value="diesel">Diesel</Select.Option>
                </Select>
              </Form.Item>

              {/* Status  */}
              <Form.Item label="Status" name="status" valuePropName="checked">
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </div>
            <div className="personal">
              <Form.Item label="Year" name="year">
                <Input />
              </Form.Item>

              <Form.Item label="Seats" name="seats">
                <Input />
              </Form.Item>

              <Form.Item label="Vin Number" name="vinNumber">
                <Input />
              </Form.Item>

              <Form.Item label="Engin Number" name="enginNumber">
                <Input />
              </Form.Item>

              <Form.Item label="TARE" name="TARE">
                <Input />
              </Form.Item>

              <Form.Item label="GVM" name="GVM">
                <Input />
              </Form.Item>

              <Form.Item label="Color" name="color">
                <Input />
              </Form.Item>

              <Form.Item label="TollId" name="tollId">
                <Input />
              </Form.Item>

              <Form.Item label="Last Odo meter" name="lastOdometer">
                <Input />
              </Form.Item>

              <Form.Item label="Last Odo meter Date" name="lastOdometerDate">
                <DatePicker format="DD-MMM-YYYY" />
              </Form.Item>

              <Form.Item
                label="Next Service Odo meter"
                name="nextServiceOdometer"
              >
                <Input />
              </Form.Item>

              <Form.Item label="Notes" name="notes">
                <Input />
              </Form.Item>

              <Form.Item label="Drive Url" name="driveUrl">
                <Input />
              </Form.Item>

              <Form.Item label="Hourly Cost" name="hourlyCost">
                <Input />
              </Form.Item>
            </div>
            {/* checkbox-person */}

            <div className="personal" style={{ textAlign: "center" }}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        )
      }
    />
  );
};

export default EditTeamMember;
