import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../styles/_sidebar.scss";
import { Layout, Menu, Drawer } from "antd";
import { useSelector, useDispatch } from "react-redux";
import navigationConfig from "../StandardConfigLayout/navigationConfig";
import Utils from "../../utils/Utils";
import _ from "lodash";
import { setVisibleMenu } from "../../store/user-store/reducer";
import { MENU_ITEM_TYPE } from "../../constants";
import { CloseOutlined } from "@ant-design/icons";
const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = () => {
  const { user } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [navigationItems, setNavigationItem] = useState([]);
  const { visibleMenu } = useSelector(({ user }) => user.userReducer);
  const [subMenu, setSubmenu] = useState([]);
  let openSubMenu = ["0"];

  const onClose = () => {
    dispatch(setVisibleMenu(false));
  };

  useEffect(() => {
    let items = [];
    if (!user || (user && (!user.role || user.role.length === 0))) {
      items = navigationConfig.filter((item) => item.auth.length === 0);
    } else {
      navigationConfig.forEach((nav) => {
        const roles = nav.auth;
        let intersection = _.intersection(roles, user.role);
        if (intersection.length > 0) {
          const childrens = [];
          nav.children?.forEach((n) => {
            let intersection = _.intersection(user.role, n.auth);
            if (intersection.length > 0) {
              childrens.push(n);
            }
          });
          if (!user?.teamMemberId) {
            nav.title !== "My Zone" && items.push({ ...nav, children: childrens });
          } else {
            items.push({ ...nav, children: childrens });
          }
        }
      });
    }
    setNavigationItem(items);
  }, [user]);

  useEffect(() => {
    if (pathname) {
      const filterArray = Utils.filterArrayByString(navigationConfig, pathname);
      if (filterArray.length > 0) {
        openSubMenu = [filterArray[0].id];
        setSubmenu(openSubMenu);
      }
    } else {
      setSubmenu([]);
    }
  }, [pathname]);
  return (
    <div className="">
      <Drawer
        placement="left"
        onClose={onClose}
        closable={false}
        visible={visibleMenu}
        className="drawerSider"
      >
        <Sider collapsedWidth="0" className="sider">
          <div className="logo">

            <div>
              <img alt="insight_logo" src={"/images/insight-brand-light.png"} style={{
                maxWidth: "40%"
              }} />
            </div>
            <CloseOutlined onClick={onClose} />
          </div>

          <Menu theme="dark" mode="inline" defaultOpenKeys={subMenu}>
            {navigationItems.map((item) => {
              let menu;
              item.type === MENU_ITEM_TYPE.ITEM
                ? (menu = (
                  <Menu.Item
                    key={item.id}
                    style={{ background: pathname === item.url && "#1890ff" }}
                  >
                    {item.title}
                    <Link to={item.url} onClick={onClose} />
                  </Menu.Item>
                ))
                : (menu = (
                  <SubMenu key={item.id} title={item.title}>
                    {item?.children?.map((subItem) => {
                      return (
                        <Menu.Item
                          key={subItem.id}
                          style={{
                            background: pathname === subItem.url && "#1890ff",
                          }}
                        >
                          <Link to={subItem.url} onClick={onClose}>
                            {subItem.title}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                ));
              return menu;
            })}
          </Menu>
        </Sider>
      </Drawer>
    </div>
  );
};

export default SideBar;
