import EditFleetCompletePreStart from "../../components/FLeetCompletePreStart/EditFleetCompletePreStart";

const FleetCompleteStartDetail = () => {
  return (
    <>
      <EditFleetCompletePreStart />
    </>
  );
};

export default FleetCompleteStartDetail;
