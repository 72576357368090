import axios from "axios";
import { BACK_URL } from "../constants";

export const getData = async (table, action) => {
  try {
    const { data, status } = await axios({
      url: `${BACK_URL}?table=${table}&action=${action}`,
      method: "GET",
    });

    if (status === 200) {
      return data.records;
    }
    return null;
  } catch (err) {
    return null;
  }
};
