import EditDocument from "../../../components/Document/EditDocument";

const DocumentsEdit = () => {
  return (
    <>
      <EditDocument />
    </>
  );
};

export default DocumentsEdit;
