
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import axios from "axios"

const ChangePassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { code } = useParams();
  const decoded = jwtDecode(code);
  const [message, setMessage] = useState({
    isSuccess: false,
    text: ``,
  });

  const onFinish = (values) => {
    axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/auth/password_reset?code=${code}`,
        {
          rePassword: values.rePassword,
        }
      )
      .then(() => {
        setLoading(false);
        setMessage({
          isSuccess: true,
          text: `Change Password Success. Please Signin`,
        });
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          isSuccess: true,
          text: `Cannot change password`,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className='login'>
      <p
        style={{
          color: "red",
          margin: "8px",
        }}></p>
      <Form
        name='basic'
        layout='vertical'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        className='form-login'
        align='center'
        requiredMark={false}>
        <div style={{ width: "45%", margin: "0 auto", marginBottom: 30 }}>
          <img
            alt='insight_logo'
            src={"/images/insight-brand-dark.png"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        {message.isSuccess ? (
          <Alert
            message={<p style={{ textAlign: "left", fontSize: "1.3rem", margin: 0 }}>Success</p>}
            type='success'
            description={
              <div style={{ textAlign: "left", fontSize: "1rem" }}>
                <p style={{ margin: '0 0 12px 0' }}>{message.text}</p>
                <Link to={"/login"}>Back To Sign In</Link>
              </div>
            }
            showIcon
          />
        ) : (
          <>
            <p
              style={{
                textAlign: "center",
                fontSize: "1.1rem",
                fontWeight: 500,
              }}>
              Change Password for
              <br />
              {decoded?.PlayForce?.email}
            </p>

            <Form.Item
              name='password1'
              rules={[
                {
                  required: true,
                  message:
                    "Please input your password that! Greater than 6 characters",
                  min: 6,
                },
              ]}>
              <Input.Password
                placeholder='Password *'
              />
            </Form.Item>

            <Form.Item
              name='rePassword'
              dependencies={['password1']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                  min: 6,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password1") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}>
              <Input.Password
                placeholder='Confirm Password *'
              />
            </Form.Item>

            <Form.Item className='btn-login'>
              <Button type='primary' htmlType='submit' loading={loading}>
                Change Password
              </Button>
            </Form.Item>

            {message.text && !message.isSuccess && (
              <Alert message='Error' description={message.text} type='error' />
            )}
          </>
        )}

      </Form>
    </div>
  );
};

export default ChangePassword;
