import {
  Form,
  Input,
  Button,
  Radio,
  Spin,
  Select,
  Upload,
  Image,
  message,
} from "antd";
import { useEffect, useState } from "react";
import {
  UploadOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import {
  NEW_RECEIPT,
  FETCH_DETAIL_RECEIPT_IN_ORGANISATION,
  setDetailReceipt,
  CREATING_RECEIPT,
  UPDATING_RECEIPT,
} from "../../store/receipt-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { DOWNLOAD_GOOGLE_CLOUD_FILE } from "../../store/bill-store/reducer";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const EditReceipt = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { organisationId, _id } = useSelector(({ auth }) => auth.user);
  const { detailReceipt } = useSelector(
    ({ receipt }) => receipt.receiptReducer
  );
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [deletePhoto, setDeletePhoto] = useState({
    image: "",
    isDeletePhoto: false,
  });

  const onHandleRemoveImage = () => {
    setFileList([]);
    setDeletePhoto({
      image: detailReceipt?.image,
      isDeletePhoto: true,
    });
  };

  const props = {
    name: "file",
    maxCount: 1,
    className: "upload-list-inline",
    listType: "picture",
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        setDeletePhoto({
          image: detailReceipt?.image ? detailReceipt?.image : "",
          isDeletePhoto: detailReceipt?.image ? true : false,
        });
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url,
          },
        ]);
      };
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailReceipt._id;
    values.image = detailReceipt ? deletePhoto.image : deletePhoto.image;
    values.isDeletePhoto = deletePhoto.isDeletePhoto;

    if (id === "new") {
      CREATING_RECEIPT(values, fileList[0])
        .then((data) => {
          setLoading(false);
          navigate("/receipt");
        })
        .catch((err) => {
          const errorMessage = err.data?.payload?.message;
          if (errorMessage) {
            message.error(errorMessage);
          }
          setLoading(false);
        });
    } else {
      UPDATING_RECEIPT(values, fileList[0])
        .then((data) => {
          setLoading(false);
          navigate("/receipt");
        })
        .catch((err) => {
          const errorMessage = err.data?.payload?.message;

          if (errorMessage) {
            message.error(errorMessage);
          }
          setLoading(false);
        });
    }
  };

  const onDownload = async () => {
    try {
      const file = JSON.parse(detailReceipt.file);
      const name = file.name;
      await DOWNLOAD_GOOGLE_CLOUD_FILE(name);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const updateState = async () => {
      if (id === "new") {
        dispatch(NEW_RECEIPT());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_RECEIPT_IN_ORGANISATION(organisationId, id).then(
          (data) => {
            dispatch(setDetailReceipt(data));
            setInitialLoading(false);
          }
        );
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailReceipt) {
      if (detailReceipt.file) {
        const file = JSON.parse(detailReceipt.file);
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url: file.url,
          },
        ]);
      }

      form.setFields([
        {
          name: "jobNumber",
          value: detailReceipt.jobNumber,
        },
        {
          name: "description",
          value: detailReceipt.description,
        },
        {
          name: "status",
          value: detailReceipt.status,
        },
      ]);
    }
  }, [detailReceipt]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Receipt", path: "/receipt" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/receipt/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            name="basic"
            labelAlign="left"
            autoComplete="off"
            labelWrap={true}
            form={form}
            scrollToFirstError
            onFinish={onFinish}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            requiredMark={false}
          >
            <Form.Item
              label="Job Number"
              name="jobNumber"
              rules={[{ required: true, message: "Missing Job Number" }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item label="File Upload">
              <Upload {...props} onRemove={() => setFileList([])}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
              <div>
                {fileList.length > 0 ? (
                  fileList.map((l, index) => (
                    <div
                      key={index}
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        position: "relative",
                        width: "max-content",
                      }}
                    >
                      {id !== "new" && fileList[0]?.url !== "" && (
                        <div
                          onClick={() => onHandleRemoveImage()}
                          style={{
                            right: "-18px",
                            position: "absolute",
                            zIndex: 9,
                            cursor: "pointer",
                          }}
                        >
                          <CloseCircleOutlined />
                        </div>
                      )}
                      <a href={l.url} target="_blank" onClick={() => onDownload()}>
                          {l.name}
                      </a>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Missing Description" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Status" name="status" valuePropName="checked">
              <Radio.Group defaultValue={false}>
                <Radio value={false}>Not Converted</Radio>
                <Radio value={true}>Converted</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditReceipt;
