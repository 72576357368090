import Document from "../../../components/Document";

const SafetyDataSheets = () => {
  return (
    <>
      <Document description="Safety Data Sheet"/>
    </>
  );
};

export default SafetyDataSheets;
