import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Alert,
  TimePicker,
  Typography,
  Spin,
  Modal,
  InputNumber,
  message,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_DETAIL_TIME_SHEET,
  setDetailTimeSheet,
  CREATE_TIME_SHEET,
  UPDATE_TIME_SHEET,
  NEW_TIME_SHEET,
  DELETE_TIME_SHEET,
} from "../../store/time-sheet-store/reducer";
import moment from "moment";
import SignaturePad from "react-signature-canvas";
import { LoadingOutlined } from "@ant-design/icons";
import { dataURLtoFile } from "../../utils/core/UrlToFile";
import {
  FETCH_PAYMENT_PROFILE_BY_TEAMMEMBER,
  setDetailPayment,
} from "../../store/payment-store/reducer";
import {
  FETCH_DETAIL_TRANSACTION_BY_TEAM_MEMBER,
  setDetailTransaction,
} from "../../store/common-store/reducer";
import TimesheetComment from "../TimesheetComments/Comments";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Title, Text } = Typography;
const { Option } = Select;
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const EditEmploymentAwards = () => {
  let sigPad = useRef({});
  const { TextArea } = Input;
  const format = "HH:mm";
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentDate } = useParams();
  const [visible, setVisible] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const { detailPayments } = useSelector(
    ({ payments }) => payments.paymentReducer
  );
  const { detailTimeSheet } = useSelector(
    ({ timeSheet }) => timeSheet.TimeSheetReducer
  );
  const { _id, teamMemberId, role, organisationId, firstname, lastname } =
    useSelector(({ auth }) => auth.user);
  const { detailTransaction } = useSelector(
    ({ common }) => common.commonReducer
  );

  const [sign, setSign] = useState({
    file: null,
    url: "",
  });
  const [dateTime, setDateTime] = useState({
    startTime: 0,
    startBreak: 0,
    finishBreak: 0,
    finishTime: 0,
  });

  const [multiLeave, setMultiLeave] = useState();
  const [bankedHours, setBankedHours] = useState(0);
  const [isPublicHoliday, setIsPublicHoliday] = useState(false);
  const [bankedHoursAction, setBankedHoursAction] = useState("");
  const [children, setChildren] = useState([
    { id: 1, value: "Public Holiday" },
    { id: 2, value: "Leave Without Pay" },
  ]);
  const [validateHours, setValidateHours] = useState(false);

  const fetchSubData = async () => {
    const payProfile = await FETCH_PAYMENT_PROFILE_BY_TEAMMEMBER(
      organisationId,
      teamMemberId
    );
    if (payProfile) {
      dispatch(setDetailPayment(payProfile));
    }

    const transaction = await FETCH_DETAIL_TRANSACTION_BY_TEAM_MEMBER(
      teamMemberId
    );
    if (transaction) {
      dispatch(setDetailTransaction(transaction));
    }
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  // Fetch data
  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      FETCH_DETAIL_TIME_SHEET(teamMemberId, currentDate).then(async (data) => {
        if (data.data.payload.status === 404) {
          dispatch(
            NEW_TIME_SHEET({ dateTime: currentDate, teamMember: teamMemberId })
          );
          setInitialLoading(false);
        } else {
          dispatch(setDetailTimeSheet(data.data.payload.data));
          setInitialLoading(false);
        }
      });
    };

    updateState();
  }, [dispatch, currentDate]);

  useEffect(() => {
    if (detailTimeSheet) {
      setSign({
        file: null,
        url: detailTimeSheet.signature,
      });
      if (detailTimeSheet?.leave) {
        if (detailTimeSheet?.leave[0] !== "Public Holiday") {
          setMultiLeave(detailTimeSheet?.leave);
          handleChangeLeave(detailTimeSheet?.leave);
        } else {
          setIsPublicHoliday(true);
        }
      }
      if (detailTimeSheet?.bankedHoursAction) {
        setBankedHoursAction(detailTimeSheet.bankedHoursAction);
        setBankedHours(detailTimeSheet?.bankedHours);
      }

      setDateTime({
        startTime: moment(detailTimeSheet?.startTime),
        finishBreak: moment(detailTimeSheet?.finishBreak),
        finishTime: moment(detailTimeSheet?.finishTime),
        startBreak: moment(detailTimeSheet?.startBreak),
      });

      form.setFields([
        {
          name: "_id",
          value: detailTimeSheet?._id,
        },
        {
          name: "dateTime",
          value: detailTimeSheet?.dateTime,
        },
        {
          name: "totalHours",
          value: detailTimeSheet?.totalHours.toFixed(2),
        },
        {
          name: "leave",
          value: detailTimeSheet?.leave,
        },
        {
          name: "signature",
          value: detailTimeSheet?.signature,
        },
        {
          name: "payrollProfile",
          value: detailTimeSheet?.payrollProfile,
        },
        {
          name: "day",
          value: detailTimeSheet?.day,
        },
        {
          name: "overTimeHours",
          value: detailTimeSheet?.overTimeHours,
        },
        {
          name: "annualLeaveHours",
          value: detailTimeSheet?.annualLeaveHours,
        },
        {
          name: "personalLeaveHours",
          value: detailTimeSheet?.personalLeaveHours,
        },
        {
          name: "leaveWithoutPay",
          value: detailTimeSheet?.leaveWithoutPay,
        },
        {
          name: "bankedHoursAction",
          value: detailTimeSheet?.bankedHoursAction,
        },
        {
          name: "bankedHours",
          value: detailTimeSheet?.bankedHours,
        },
        {
          name: "wasThisDayWorkedAway",
          value: detailTimeSheet?.wasThisDayWorkedAway,
        },
        {
          name: "workedAwayHours",
          value: detailTimeSheet?.workedAwayHours,
        },
      ]);
    }
  }, [detailTimeSheet]);

  useEffect(() => {
    let secondsStartToFinish =
      moment(dateTime.finishTime).diff(moment().startOf("day"), "seconds") -
      moment(dateTime.startTime).diff(moment().startOf("day"), "seconds");
    let secondsBreakTime =
      moment(dateTime.finishBreak).diff(moment().startOf("day"), "seconds") -
      moment(dateTime.startBreak).diff(moment().startOf("day"), "seconds");

    let total = detailPayments?.includeBreakInTotalHours
      ? secondsStartToFinish / 3600
      : (secondsStartToFinish - secondsBreakTime) / 3600;

    if (isNaN(total)) {
      total = 0;
    }

    if (total > 0) {
      setValidateHours(false);
    } else if (total === 0 && multiLeave && multiLeave.length > 0) {
      setValidateHours(false);
    } else if (bankedHours > 0) {
      setValidateHours(false);
    } else if (isPublicHoliday) {
      setValidateHours(false);
    } else {
      setValidateHours(true);
    }

    form.setFields([
      {
        name: "totalHours",
        value: total ? total.toFixed(2) : 0.0,
      },
    ]);
  }, [dateTime, bankedHours, multiLeave]);

  // check leave of pay profiles
  useEffect(() => {
    const newChild = [
      { id: 1, value: "Public Holiday" },
      { id: 2, value: "Leave Without Pay" },
    ];

    if (detailPayments?.leave?.annualLeave) {
      newChild.push({ id: 3, value: "Annual" });
    }
    if (detailPayments?.leave?.personalLeave) {
      newChild.push({ id: 4, value: "Personal" });
    }

    setChildren(newChild);
    if (detailTimeSheet?._id === "new") {
      setDateTime({
        finishBreak: null,
        finishTime: null,
        startBreak: null,
        startTime: null,
      });
    } else {
      setDateTime({
        finishBreak: moment(detailTimeSheet?.finishBreak),
        finishTime: moment(detailTimeSheet?.finishTime),
        startBreak: moment(detailTimeSheet?.startBreak),
        startTime: moment(detailTimeSheet?.startTime),
      });
    }
  }, [detailPayments, detailTimeSheet]);

  //check is public holiday
  useEffect(() => {
    if (isPublicHoliday) {
      setDateTime({
        startTime: null,
        startBreak: null,
        finishBreak: null,
        finishTime: null,
      });
    }
  }, [isPublicHoliday]);

  // Submit form
  const onFinish = (values) => {
    setLoading(true);
    let formData = {
      _id: detailTimeSheet?._id,
      organisationId,
      dateTime: currentDate,
      updated: detailTimeSheet?.updated,
      created: detailTimeSheet?.created,
      startTime: dateTime?.startTime,
      startBreak: dateTime?.startBreak,
      finishBreak: dateTime?.finishBreak,
      finishTime: dateTime?.finishTime,
      totalHours: values?.totalHours,
      leave: values?.leave,
      payrollProfile: detailPayments?._id,
      lastPersonModifiedRecord: `${firstname} ${lastname}`,
      day: 2,
      overTimeHours: 2,
      annualLeaveHours: values?.annualLeaveHours,
      personalLeaveHours: values?.personalLeaveHours,
      leaveWithoutPay: values?.leaveWithoutPay,
      bankedHours: values?.bankedHours,
      bankedHoursAction: values.bankedHoursAction,
      wasThisDayWorkedAway: true,
      workedAwayHours: 3,
      teamMember: teamMemberId,
    };

    if (!validateHours) {
      if (detailTimeSheet._id === "new") {
        formData.personCreatedRecord = `${firstname} ${lastname}`;
        if (bankedHoursAction) {
          if (bankedHoursAction === "withdraw") {
            formData.bankedHourBalance =
              Number(detailTransaction?.bankedHoursBalance) -
              Number(values.bankedHours);
          } else {
            formData.bankedHourBalance =
              Number(detailTransaction?.bankedHoursBalance) +
              Number(values.bankedHours);
          }
        } else if(detailTransaction?.bankedHoursBalance) {
          formData.bankedHourBalance = detailTransaction?.bankedHoursBalance
        }
        CREATE_TIME_SHEET(formData, sign.file).then(() => {
          setLoading(false);
          navigate("/time-sheets");
        });
      } else {
        if (bankedHoursAction) {
          let changeNumber = 0;
          let currentBankedHourBalance = Number(
            detailTimeSheet?.bankedHourBalance
          );
          if (detailTimeSheet.bankedHoursAction) {
            if (detailTimeSheet.bankedHoursAction === "withdraw") {
              changeNumber += Number(detailTimeSheet?.bankedHours);
            } else {
              changeNumber -= Number(detailTimeSheet?.bankedHours);
            }
          }
          if (bankedHoursAction === "withdraw") {
            changeNumber -= Number(values.bankedHours);
          } else {
            changeNumber += Number(values.bankedHours);
          }
          formData.bankedHourBalance = currentBankedHourBalance + changeNumber;
          formData.changeNumber = changeNumber;
        }

        UPDATE_TIME_SHEET(formData, sign.file).then(() => {
          setLoading(false);
          navigate("/time-sheets");
        });
      }
    } else {
      message.error("Total hours greater than 0");
      setLoading(false);
    }
  };

  const handleDelete = () => {
    DELETE_TIME_SHEET(`ids=${detailTimeSheet?._id}`).then(() => {
      setLoading(false);
      navigate("/time-sheets");
    });
  };

  const handleChangeTime = (e) => {
    setDateTime({
      ...dateTime,
      [e.target.name]: moment(e.target.value, "HH:mm"),
    });
  };

  // Save signature
  const saveSign = () => {
    setSign({
      file: dataURLtoFile(sigPad.current.toDataURL(), "signstaff.png"),
      url: sigPad.current.toDataURL(),
    });
    // message.success("Save Sign Success");
    setVisible(false);
  };

  // Set field leave
  const handleChangeLeave = (value) => {
    const newValue = [];
    value?.forEach((item) => {
      switch (item) {
        case "Annual":
          newValue.push({
            name: "annualLeaveHours",
            label: "Annual Leave Hours",
            value: item,
          });
          break;
        case "Leave Without Pay":
          newValue.push({
            name: "leaveWithoutPay",
            label: "Leave without pay hours",
            value: item,
          });
          break;
        case "Personal":
          newValue.push({
            name: "personalLeaveHours",
            label: "Personal Leave Hours",
            value: item,
          });
        default:
          break;
      }
    });
    if (value.includes("Public Holiday")) {
      setIsPublicHoliday(true);
      form.setFields([
        {
          name: "leave",
          value: ["Public Holiday"],
        },
      ]);
    } else {
      setIsPublicHoliday(false);
      setMultiLeave(newValue);

      form.setFields([
        {
          name: "leave",
          value: value,
        },
      ]);
    }
  };

  const handleChangeBankedHourAction = (value) => {
    if (value) {
      setBankedHoursAction(value);
    } else {
      setBankedHoursAction("");
    }
  };

  const handleChangeBankedHour = (e) => {
    if (e) {
      setBankedHours(e.target.value);
    }
  };

  return (
    <StandardFormLayout
      className={"time-sheet-layout"}
      hierachy={[
        { key: 1, label: "Time Sheets", path: "/time-sheets" },
        {
          key: 2,
          label:
            detailTimeSheet?._id.indexOf("new") === -1
              ? "Edit Time Record"
              : "Add Time Record",
        },
      ]}
      editPath="/time-sheets/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            name="form"
            form={form}
            requiredMark={false}
            labelCol={{ xl: { span: 2 }, md: { span: 4 } }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Title level={4}>Time Record</Title>

            <Form.Item label="Team Member" style={{ marginBottom: "0px" }}>
              <Link to={`/team/members/${teamMemberId}`} target="_blank">
                {firstname} {lastname}
              </Link>
            </Form.Item>

            <Form.Item label="Date">
              <Text> {`${currentDate}`}</Text>
            </Form.Item>

            <Form.Item
              label="Start Time"
              rules={[{ required: true, message: "Please select Start time!" }]}
            >
              <Input
                type="time"
                id="startTime"
                name="startTime"
                onChange={handleChangeTime}
                defaultValue={moment(dateTime.startTime).format("HH:mm")}
                value={moment(dateTime.startTime).format("HH:mm")}
                disabled={isPublicHoliday}
                inputReadOnly={windowDimensions.width > 768 ? false : true}
              ></Input>
            </Form.Item>

            <Form.Item
              label="Start Break"
              rules={[
                { required: true, message: "Please select Start Break!" },
              ]}
            >
              <Input
                type="time"
                id="startBreak"
                name="startBreak"
                defaultValue={moment(dateTime.startBreak).format("HH:mm")}
                value={moment(dateTime.startBreak).format("HH:mm")}
                disabled={isPublicHoliday}
                style={{ width: "100%" }}
                onChange={handleChangeTime}
                inputReadOnly={windowDimensions.width > 768 ? false : true}
              ></Input>
            </Form.Item>

            <Form.Item
              label="Finish Break"
              rules={[
                { required: true, message: "Please select Finish Break!" },
              ]}
            >
              <Input
                type="time"
                id="finishBreak"
                name="finishBreak"
                disabled={isPublicHoliday}
                defaultValue={moment(dateTime.finishBreak).format("HH:mm")}
                value={moment(dateTime.finishBreak).format("HH:mm")}
                style={{ width: "100%" }}
                onChange={handleChangeTime}
                inputReadOnly={windowDimensions.width > 768 ? false : true}
              ></Input>
            </Form.Item>

            <Form.Item
              label="Finish Time"
              rules={[
                { required: true, message: "Please select Finish time!" },
              ]}
            >
              <Input
                type="time"
                id="finishTime"
                name="finishTime"
                defaultValue={moment(dateTime.finishTime).format("HH:mm")}
                value={moment(dateTime.finishTime).format("HH:mm")}
                disabled={isPublicHoliday}
                style={{ width: "100%" }}
                onChange={handleChangeTime}
                inputReadOnly={windowDimensions.width > 768 ? false : true}
              ></Input>
            </Form.Item>

            <Form.Item label="Total Hours" name="totalHours">
              <Input
                disabled
                style={{
                  border: "none",
                  backgroundColor: "#fff",
                  color: "#000",
                  padding: 0,
                }}
              />
            </Form.Item>

            {isPublicHoliday && (
              <Alert
                message="Public Holiday"
                description="You do not need to enter any times"
                type="info"
                showIcon
                style={{
                  marginBottom: "24px",
                }}
              />
            )}

            <Form.Item label="Leave" name="leave">
              <Select
                mode="multiple"
                allowClear
                showSearch={false}
                style={{ width: "100%" }}
                placeholder="Select leave"
                onChange={handleChangeLeave}
              >
                {!isPublicHoliday ? (
                  children.map((c) => {
                    return (
                      <Option key={c.id} value={c.value} label={c.value} />
                    );
                  })
                ) : (
                  <Option
                    key={1}
                    value={"Public Holiday"}
                    label={"Public Holiday"}
                  />
                )}
              </Select>
            </Form.Item>

            {multiLeave?.map((m, index) => (
              <Form.Item
                label={m.label}
                name={m.name}
                key={index}
                rules={[
                  {
                    required: true,
                    message: `${m.label} Is Required`,
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(`Have to greater than 0`)
                      );
                    },
                  }),
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            ))}

            {detailPayments?.bankedHoursUsed && (
              <Form.Item label="Banked Hours" name="bankedHoursAction">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select Type Of Banked Hours"
                  onChange={handleChangeBankedHourAction}
                >
                  <Option
                    key={"withdraw"}
                    value={"withdraw"}
                    label={"Withdraw"}
                  >
                    Withdraw
                  </Option>
                  <Option key={"deposit"} value={"deposit"} label={"Deposit"}>
                    Deposit
                  </Option>
                </Select>
              </Form.Item>
            )}
            {bankedHoursAction && (
              <Form.Item
                label={
                  bankedHoursAction === "withdraw"
                    ? "Withdraw Hours"
                    : "Deposit Hours"
                }
                name="bankedHours"
              >
                <Input onChange={handleChangeBankedHour} />
              </Form.Item>
            )}
            {sign.url && (
              <img className="signpad_img" src={sign.url} alt="sign" />
            )}
            <Form.Item label="Signature" name="signature">
              <Button type="primary" onClick={() => setVisible(true)}>
                Sign
              </Button>
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
              {detailTimeSheet?._id !== "new" && (
                <Form.Item>
                  <Button
                    type="primary"
                    loading={loading}
                    danger
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </Form.Item>
              )}
            </div>
            {/* Modal sign  */}
            <Modal
              title="Signature"
              visible={visible}
              onCancel={() => setVisible(false)}
              footer={[
                <div key={sigPad}>
                  <Button
                    style={{ backgroundColor: "grey", borderColor: "grey" }}
                    type="primary"
                    onClick={() => {
                      sigPad.current.clear();
                      setSign({
                        file: null,
                        url: "",
                      });
                    }}
                  >
                    Clear
                  </Button>
                  <Button type="primary" onClick={saveSign}>
                    Save
                  </Button>
                </div>,
              ]}
            >
              <SignaturePad ref={sigPad} />
            </Modal>
          </Form>
        )
      }
      customContent={
        detailTimeSheet && detailTimeSheet?._id !== "new" && (
          <div
            className={"time-sheet-layout"}
            style={{
              marginTop: 20,
              backgroundColor: "white",
              padding: "16px !important",
            }}
          >
            <TimesheetComment
              role={role}
              organisationId={organisationId}
              userId={_id}
              memberId={teamMemberId}
              detailTimesheet={detailTimeSheet}
              firstName={firstname}
              lastName={lastname}
            />
          </div>
        )
      }
    />
  );
};

export default EditEmploymentAwards;
