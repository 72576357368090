/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Form, Input, Button, Typography, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//function
import { submitLogin } from "../../store/auth/loginSlice";

const { Title } = Typography;

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const onFinish = (values) => {
    setLoading(true);
    dispatch(submitLogin(values))
      .then((data) => {
        if (data.type === "/login/loginSuccess") {
          navigate({ to: "/dashboard", pathname: "/dashboard" });
        }
      })
      .catch((err) => {
        setHasError(true);
        console.log(err);
        setErrorMessage("Login failed. Please contact admin.");
      })
      .finally(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login">
      <p
        style={{
          color: "red",
          margin: "8px",
        }}
      ></p>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="form-login"
        align="center"
        requiredMark={false}
      >
        <div style={{ width: "45%", margin: "0 auto", marginBottom: 30 }}>
          <img
            alt="insight_logo"
            src={"/images/insight-brand-dark.png"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        {/* <Title level={3}>Insight</Title> */}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <h4 className="forgotPass" onClick={() => navigate("/reset_password")}>
          Forgot password
        </h4>

        <Form.Item className="btn-login">
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Form.Item>
        {hasError ? (
          <Alert message="Error" description={errorMessage} type="error" />
        ) : (
          ""
        )}
      </Form>
      <div className="rules">
        <a href="/legal/privacy-policy" target="_blank">
          Privacy Policy
        </a>
        <span>|</span>
        <a href="/legal/tos" target="_blank">
          Terms of Service
        </a>
      </div>
    </div>
  );
};

export default Login;
