import { Form, Select, Spin, Typography, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import "../../styles/teamMember.scss";

import { LoadingOutlined } from "@ant-design/icons";
import {
  FETCH_ALL_TEAM_MEMBER_IN_ORGANISATION,
  setTeamMembers,
} from "../../store/team-member-store/reducer";
import { FETCH_ALL_TIME_SHEET_HAVE_BANKED_FOR_TEAMMEMBER } from "../../store/time-sheet-store/reducer";
import { FETCH_DETAIL_TRANSACTION_BY_TEAM_MEMBER } from "../../store/common-store/reducer";

const { Text } = Typography;
const columns = [
  {
    title: "DateTime",
    key: "dateTime",
    render: (records) => {
      return moment(records.dateTime).format("DD-MMM");
    },
    sorter: (a, b) => a?.dateTime?.localeCompare(b?.dateTime),
  },
  {
    title: "Deposit",
    key: "deposit",
    align: "right",
    render: (records) => {
      if (
        records.bankedHoursAction &&
        records.bankedHoursAction === "deposit"
      ) {
        return records?.bankedHours?.toFixed(2);
      } else {
        return "";
      }
    },
    sorter: (a, b) => b?.bankedHours - a?.bankedHours,
  },
  {
    title: "Withdraw",
    key: "withDraw",
    align: "right",
    render: (records) => {
      if (
        records.bankedHoursAction &&
        records.bankedHoursAction === "withdraw"
      ) {
        return -Math.abs(records?.bankedHours)?.toFixed(2);
      } else {
        return "";
      }
    },
    sorter: (a, b) => b?.bankedHours - a?.bankedHours,
  },
  {
    title: "Banked Hours Balance",
    key: "bankedHourBalance",
    align: "right",
    render: (records) => {
      return records?.bankedHourBalance?.toFixed(2);
    },
    sorter: (a, b) => b?.bankedHourBalance - a?.bankedHourBalance,
  },
];

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditFleetCompletePreStart = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { teamMembers } = useSelector(({ team }) => team.teamReducer);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [currentMember, setCurrentMember] = useState(null);
  const [currentTransaction, setCurrentTransaction] = useState(0);
  const [checkSubmit, setCheckSubmit] = useState({
    onsubmit: false,
    canSubmit: false,
  });

  const onFinish = () => {
    setCheckSubmit({
      ...checkSubmit,
      onsubmit: true,
    });
    if (checkSubmit.canSubmit) {
      setLoading(true);
    } else {
    }
  };

  const handleFleetChange = async (value, record) => {
    // fetch prestart type from selected vehicle
    const member = record.record;
    if (member) {
      setCurrentMember(member);
    }
    setInitialLoading(true);
    const timeSheet = await FETCH_ALL_TIME_SHEET_HAVE_BANKED_FOR_TEAMMEMBER({
      organisationId,
      memberId: value,
    });
    setCurrentData(timeSheet);

    const transaction = await FETCH_DETAIL_TRANSACTION_BY_TEAM_MEMBER(value);
    if (transaction) {
      setCurrentTransaction(transaction);
    }
    setInitialLoading(false);
  };

  const fetchSubData = async () => {
    const allTeamMember = await FETCH_ALL_TEAM_MEMBER_IN_ORGANISATION({
      organisationId,
    });
    allTeamMember
      .sort((a, b) => b.firstName.localeCompare(a.firstName))
      .reverse();
    dispatch(setTeamMembers(allTeamMember));
    setInitialLoading(false);
  };

  useEffect(() => {
    fetchSubData();
  }, []);

  return (
    <StandardFormLayout
      hierachy={[
        {
          key: 1,
          label: "Banked Hour Report",
        },
      ]}
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            name="basic"
            requiredMark={false}
            form={form}
            onFinish={onFinish}
            className="form-fleet"
            scrollToFirstError
          >
            <Form.Item
              label={<Text strong>Team Member</Text>}
              name="teamMember"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                onChange={handleFleetChange}
              >
                {teamMembers &&
                  teamMembers.map((team) => (
                    <Select.Option
                      key={team?._id}
                      value={team?._id}
                      record={team}
                    >
                      {team.firstName} {team.lastName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            {currentData && currentMember && (
              <>
                <Form.Item label={<Text strong>Starting Balance</Text>}>
                  <Text>{currentMember.bankedHoursBalance}</Text>
                </Form.Item>

                <Form.Item>
                  <Table
                    columns={columns}
                    dataSource={currentData}
                    pagination={false}
                    sticky={{ offsetHeader: 64 }}
                  />
                </Form.Item>

                <Form.Item label={<Text strong>Current Balance</Text>}>
                  <Text>{currentTransaction.bankedHoursBalance}</Text>
                </Form.Item>
              </>
            )}
          </Form>
        )
      }
    />
  );
};

export default EditFleetCompletePreStart;
