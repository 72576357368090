import { Form, Input } from "antd";
import "../../../styles/teamMember.scss";

const FormIndustry = ({ industries, setIndustries }) => {
  const [form] = Form.useForm();

  const handleChange = (e) => {
    const value = e.target.value;
    setIndustries({
      ...industries,
      description: value,
    });
  };

  return (
    <Form
      labelAlign="left"
      form={form}
      requiredMark={false}
      labelCol={{ span: 5 }}
    >
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true }]}
      >
        <Input onChange={handleChange} />
      </Form.Item>
    </Form>
  );
};

export default FormIndustry;
