import EditTimeSheetsAdmin from "../../components/TimeSheetsAdmin/EditTimeSheetsAdmin";

const DetailTimeSheets = () => {
  return (
    <>
      <EditTimeSheetsAdmin />
    </>
  );
};

export default DetailTimeSheets;
