import EditItemCategory from "../../../components/ItemCategory/EditItemCategory";

const ItemsEdit = () => {
  return (
    <>
      <EditItemCategory />
    </>
  );
};

export default ItemsEdit;
