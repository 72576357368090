import {
  Form,
  Input,
  Button,
  Select,
  Spin,
  List,
  Card,
  Radio,
  Modal,
  message,
  Typography,
  Space,
  notification,
} from "antd";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import "../../styles/teamMember.scss";
import {
  FETCH_DETAIL_FLEET_COMPLETE_PRESTART,
  CREATE_FLEET_COMPLETE_PRESTART,
  UPDATE_FLEET_COMPLETE_PRESTART,
  NEW_FLEET_COMPLETE_PRESTART,
  setDetailFleetCompltePrestart,
} from "../../store/fleet-prestart-store/reducer";
import SignaturePad from "react-signature-canvas";
import {
  CREATE_FLEET_ISSUE,
  FETCH_ALL_VEHICLES,
  FETCH_DETAIL_VEHICLE,
  setVehicles,
} from "../../store/fleet-store/reducer";
import { dataURLtoFile } from "../../utils/core/UrlToFile";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditFleetCompletePreStart = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let sigPad = useRef({});
  const [form] = Form.useForm();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { organisationId, _id, email } = useSelector(({ auth }) => auth.user);
  const { vehicles } = useSelector(({ fleets }) => fleets.fleetReducer);
  const { detailFleetCompleteStart } = useSelector(
    ({ fleetPreStart }) => fleetPreStart.fleetPreStartReducer
  );
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [currentData, setCurrentData] = useState(null);
  const [checkSubmit, setCheckSubmit] = useState({
    onsubmit: false,
    canSubmit: false,
  });
  const [visible, setVisible] = useState(false);
  const [sign, setSign] = useState({
    file: null,
    url: "",
  });

  const onFinish = () => {
    setCheckSubmit({
      ...checkSubmit,
      onsubmit: true,
    });
    if (checkSubmit.canSubmit) {
      setLoading(true);
      const dataSubmit = { ...currentData };
      dataSubmit.organisationId = organisationId;
      dataSubmit.email = email;
      dataSubmit._id = detailFleetCompleteStart._id;
      dataSubmit.teamMember = _id;

      const handleCategory = dataSubmit.categories.map((c) => {
        if (!c.isQualifier && !c.mandatory) {
          return {
            ...c,
            questions: [],
          };
        }
        return c;
      });

      const questions = []
      dataSubmit.categories.forEach((c) => {
        c.questions.forEach(q => questions.push(q))
      })

      dataSubmit.categories = handleCategory;
      dataSubmit.questions = questions.filter(q => q.issue)
      if (id === "new") {
        CREATE_FLEET_COMPLETE_PRESTART(dataSubmit, sign.file)
          .then((data) => {
            notification.success({
              message: "Prestart completed successfully"
            });
            setLoading(false);
            navigate(`/fleet/prestarts/completing/${data._id}`);
          })
          .catch((err) => {
             notification.error({
              message: err
            })
          });
      } else {
        UPDATE_FLEET_COMPLETE_PRESTART(dataSubmit, sign.file).then((data) => {
          setLoading(false);
          navigate("/fleet/prestarts/completing");
        });
      }
    }
  };

  const handleFleetChange = async (value) => {
    // fetch prestart type from selected vehicle
    setInitialLoading(true);
    let detailVehicleChange = await FETCH_DETAIL_VEHICLE(value);
    if (detailVehicleChange) {
      const fleetPreStart = detailVehicleChange.fleetPreStart;
      if (fleetPreStart) {
        const updatedCategories = fleetPreStart.categories.map((c) => {
          const updatedQuestions = c.questions.map((q, index) => {
            return {
              question: q,
              answer: "Yes",
              issue: "",
            };
          });

          return {
            ...c,
            questions: updatedQuestions,
          };
        });
        setCurrentData({
          ...currentData,
          usageUnit: fleetPreStart.usageUnit,
          usageUnitValue: fleetPreStart.usageUnitValue
            ? fleetPreStart.usageUnitValue
            : detailVehicleChange.lastOdometer,
          categories: updatedCategories,
          fleetId: value,
          lastOdometer: detailVehicleChange.lastOdometer,
          fleetPreStart: fleetPreStart._id,
        });
        form.setFields([
          {
            name: "usageUnitValue",
            value: fleetPreStart.usageUnitValue
              ? fleetPreStart.usageUnitValue
              : detailVehicleChange.lastOdometer,
          },
        ]);
        setInitialLoading(false);
      } else {
        message.error("Have No Prestart with this Fleet ID")
        setInitialLoading(false)
      }
    }
  };

  const onChangeAnswer = ({ category, index, e }) => {
    const updatedCategories = currentData.categories.map((c) => {
      if (c._id === category._id) {
        let questions = [...c.questions];
        const updateQuestions = questions.map((q, i) => {
          if (index === i) {
            return {
              ...q,
              answer: e.target.value === "No" ? "No" : "Yes",
              issue: e.target.value === "No" ? q.issue : "",
            };
          }
          return q;
        });
        return {
          ...c,
          questions: updateQuestions,
        };
      }
      return c;
    });
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
  };

  const onChangeQualifier = ({ category, e }) => {
    const updatedCategories = currentData.categories.map((c) => {
      if (c._id === category._id) {
        if (e.target.value === "No") {
          return {
            ...c,
            isQualifier: false,
          };
        } else {
          return {
            ...c,
            isQualifier: true,
          };
        }
      }
      return c;
    });
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
  };

  const onHandleChangeIssue = ({ category, index, e }) => {
    const updatedCategories = currentData.categories.map((c) => {
      if (c._id === category._id) {
        let questions = [...c.questions];
        const updateQuestions = questions.map((q, i) => {
          if (index === i) {
            return {
              ...q,
              issue: e.target.value,
            };
          }
          return q;
        });
        return {
          ...c,
          questions: updateQuestions,
        };
      }
      return c;
    });
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
  };

  const onHandleChange = (prop) => (e) => {
    setCurrentData({
      ...currentData,
      [prop]: e.target.value,
    });
  };

  const fetchSubData = async () => {
    const fleetData = await FETCH_ALL_VEHICLES(organisationId);
    if (fleetData) {
      dispatch(setVehicles(fleetData));
    }
  };

  const saveSign = () => {
    setSign({
      file: dataURLtoFile(sigPad.current.toDataURL(), "signstaff.png"),
      url: sigPad.current.toDataURL(),
    });
    setVisible(false);
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      if (id === "new") {
        dispatch(NEW_FLEET_COMPLETE_PRESTART());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_FLEET_COMPLETE_PRESTART(id).then((data) => {
          dispatch(setDetailFleetCompltePrestart(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailFleetCompleteStart) {
      setSign({
        file: null,
        url: detailFleetCompleteStart.signature,
      });
      setCurrentData(detailFleetCompleteStart);
      form.setFields([
        {
          name: "fleetId",
          value: detailFleetCompleteStart.fleetId?._id,
        },
        {
          name: "usageUnitValue",
          value: detailFleetCompleteStart.usageUnitValue,
        },
        {
          name: "title",
          value: detailFleetCompleteStart.title,
        },
        {
          name: "notes",
          value: detailFleetCompleteStart.notes,
        },
      ]);
    }
  }, [detailFleetCompleteStart]);

  useEffect(() => {
    if (currentData) {
      let disabled = false;
      currentData.categories.forEach((c) => {
        c.questions.forEach((q, index) => {
          if (!q.answer && (!q.issue || q.issue === "")) {
            disabled = true;
          }
        });
      });
      if (sign.url && !disabled) {
        setCheckSubmit({
          onsubmit: false,
          canSubmit: true,
        });
      } else {
        setCheckSubmit({
          onsubmit: false,
          canSubmit: false,
        });
      }
    }
  }, [currentData, sign]);

  return (
    <StandardFormLayout
      hierachy={[
        {
          key: 1,
          label: "Completed Prestarts",
          path: "/fleet/prestarts/completing",
        },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/fleet/prestarts/completing/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Space direction="vertical" className="form__space">
            <Form
              name="basic"
              layout="vertical"
              requiredMark={false}
              form={form}
              onFinish={onFinish}
              className="form-fleet"
              scrollToFirstError
            >
              <Form.Item
                label="Fleet ID"
                name="fleetId"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  // filterOption={(input, option) =>
                  //   option?.children?.toLowerCase().includes(input?.toLowerCase())
                  // }
                  // filterSort={(optionA, optionB) =>
                  //   optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())
                  // }
                  style={{ width: "100%" }}
                  onChange={handleFleetChange}
                  disabled={id !== "new"}
                >
                  {vehicles &&
                    vehicles.map((vehicle) => (
                      <Select.Option key={vehicle?._id} value={vehicle?._id}>
                        {vehicle.autoNumber} - {vehicle.registration} (
                        {vehicle.nickName})
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              {currentData &&
                currentData.categories &&
                currentData.categories.length > 0 ? (
                <>
                  <Form.Item
                    label={`${currentData.usageUnit[0].toUpperCase() +
                      currentData.usageUnit.slice(1)
                      }`}
                    name="usageUnitValue"
                    rules={[
                      {
                        required: true,
                        message: "Usage Unit Is Required",
                      },
                      () => ({
                        validator(_, value) {
                          if (!value || currentData.lastOdometer <= value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`Greater than ${currentData.lastOdometer}`)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      onChange={onHandleChange("usageUnitValue")}
                      disabled={id !== "new"}
                    />
                  </Form.Item>

                  <Form.Item>
                    <List
                      dataSource={currentData?.categories}
                      renderItem={(category) => (
                        <Card
                          title={category.title}
                          size="small"
                          style={{
                            marginBottom: "15px",
                          }}
                          extra={
                            !category.mandatory ? (
                              <Space>
                                <Typography>{category.qualifier}</Typography>
                                <Radio.Group
                                  disabled={id !== "new"}
                                  options={["Yes", "No"]}
                                  optionType="button"
                                  buttonStyle="solid"
                                  value={category.isQualifier ? "Yes" : "No"}
                                  onChange={(e) =>
                                    onChangeQualifier({ category, e })
                                  }
                                />
                              </Space>
                            ) : (
                              ""
                            )
                          }
                        >
                          {category.mandatory ||
                            (!category.mandatory && category.isQualifier) ? (
                            <List
                              dataSource={category.questions}
                              renderItem={(question, index) => (
                                <>
                                  <List.Item
                                    actions={[
                                      <Radio.Group
                                        options={["Yes", "No"]}
                                        disabled={id !== "new"}
                                        value={question.answer === "Yes" ? "Yes" : "No"}
                                        onChange={(e) =>
                                          onChangeAnswer({ category, index, e })
                                        }
                                        optionType="button"
                                        buttonStyle="solid"
                                      />,
                                    ]}
                                  >
                                    {question.question}
                                  </List.Item>
                                  {question.answer === "No" && (
                                    <>
                                      <Form.Item
                                        label={<strong>Issue</strong>}
                                        rules={[{ required: true }]}
                                        style={{
                                          marginTop: "5px",
                                        }}
                                      >
                                        <Input
                                          className={
                                            checkSubmit.onsubmit &&
                                              !question.issue
                                              ? `ant-input ant-input-status-error`
                                              : ""
                                          }
                                          value={question.issue}
                                          disabled={id !== "new"}
                                          onChange={(e) =>
                                            onHandleChangeIssue({
                                              category,
                                              index,
                                              e,
                                            })
                                          }
                                        />
                                        {checkSubmit.onsubmit &&
                                          !question.issue && (
                                            <div>
                                              <div className="ant-form-item-explain-error">
                                                Issue Is Required
                                              </div>
                                            </div>
                                          )}
                                      </Form.Item>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ) : (
                            ""
                          )}
                        </Card>
                      )}
                    />
                  </Form.Item>

                  <Form.Item label="Additional Notes" name="notes">
                    <Input.TextArea
                      onChange={onHandleChange("notes")}
                      disabled={id !== "new"}
                    />
                  </Form.Item>

                  <Form.Item label="Signature" name="signature">
                    {checkSubmit.onsubmit && !sign.url && id === "new" && (
                      <div>
                        <div className="ant-form-item-explain-error">
                          Signature Is Required
                        </div>
                      </div>
                    )}
                    <Modal
                      title="Signature"
                      visible={visible}
                      onCancel={() => setVisible(false)}
                      footer={[
                        <>
                          <Button
                            style={{
                              backgroundColor: "grey",
                              borderColor: "grey",
                            }}
                            type="primary"
                            onClick={() => {
                              sigPad.current.clear();
                              setSign({
                                file: null,
                                url: "",
                              });
                            }}
                          >
                            Clear
                          </Button>
                          <Button type="primary" onClick={saveSign}>
                            Save
                          </Button>
                        </>,
                      ]}
                    >
                      <SignaturePad ref={sigPad} />
                    </Modal>
                    {id === "new" && (
                      <Button type="primary" onClick={() => setVisible(true)}>
                        Sign
                      </Button>
                    )}
                  </Form.Item>

                  {sign.url && (
                    <div style={{ margin: "10px 0" }}>
                      <img
                        style={{ width: "60%" }}
                        className="signpad_img"
                        src={sign.url}
                        alt="sign"
                      />
                    </div>
                  )}
                  {id === "new" && (
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Create
                      </Button>
                    </Form.Item>
                  )}
                </>
              ) : (
                ""
              )}
            </Form>
          </Space>
        )
      }
    />
  );
};

export default EditFleetCompletePreStart;
