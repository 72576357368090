import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Badge } from "antd";
import StandardTableTabPaneLayout from "../../layouts/StandardTableTabPaneLayout/StandardTableTabPaneLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_QUOTE,
  setQuote,
  DELETING_QUOTE,
} from "../../store/quote-store/reducer";
import { INVOICE_STATUS } from "../../constants";

const QuoteModule = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { quote } = useSelector(({ quote }) => quote.quoteReducer);
  const [filterAccount, setFilterAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [dataList, setDataList] = useState([]);

  const [tabSort, setTabSort] = useState({
    tabs: [
      { type: "All" },
      { type: "Draft", length: 0 },
      { type: "Awaiting Approval", length: 0 },
      { type: "Awaiting Payment", length: 0 },
      { type: "Paid", length: 0 },
      { type: "Repeating", length: 0 },
    ],
    tabChoosed: "All",
  });

  const [csv, setCsv] = useState({
    headers: [
      {
        label: "contact",
        key: "contact",
      },
      {
        label: "date",
        key: "date",
      },
      {
        label: "deliveryDate",
        key: "deliveryDate",
      },
      {
        label: "reference",
        key: "reference",
      },
      {
        label: "Status",
        key: "status",
      },
    ],
    data: [],
    fileName: "allquote.csv",
  });

  const columns = [
    {
      title: "Quote Number",
      dataIndex: "quoteNumber",
      key: "quoteNumber",
      sorter: (a, b) => a.quoteNumber.localeCompare(b.quoteNumber),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (contact) => contact?.name,
      sorter: (a, b) => a.contact.localeCompare(b.contact),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return status;
      },
      sorter: (a, b) => b.status - a.status,
    },
  ];

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        contact: e.contact,
        date: e.date,
        dueDate: e.dueDate,
        reference: e.reference,
        status: e.status,
      });
    });
    return csvData;
  };

  const FETCH_DATA = () => {
    setIsLoading(true);
    FETCH_ALL_QUOTE(organisationId)
      .then((data) => dispatch(setQuote(data)))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (quote) {
      const columns = handleColumnCsv(quote);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [quote]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    let filterCode = [];
    let filterCodeResponsive = [];

    quote?.forEach((ele) => {
      let filterResponsive = {
        _id: ele?._id,
        title: `${ele?.contact?.name}`,
        description: `${ele?.reference}`,
      };

      if (tabSort?.tabChoosed?.toLowerCase() !== "all") {
        if (tabSort?.tabChoosed?.toLowerCase() === ele?.status?.toLowerCase()) {
          filterCode.push(ele);
          filterCodeResponsive.push(filterResponsive);
        }
      } else {
        filterCode = [...quote];
        filterCodeResponsive.push(filterResponsive);
      }
    });

    setFilterAccount(filterCode);
    setDataList(filterCodeResponsive);
  }, [quote, tabSort]);

  useEffect(() => {
    const drafts = [];
    const sents = [];
    const declineds = [];
    const accepteds = [];
    const invoiceds = [];

    quote.map((ele) => {
      if (ele.status.toUpperCase() === INVOICE_STATUS.DRAFT) {
        drafts.push(ele);
      } else if (ele.status.toUpperCase() === INVOICE_STATUS.SENT) {
        sents.push(ele);
      } else if (ele.status.toUpperCase() === INVOICE_STATUS.DECLINED) {
        declineds.push(ele);
      } else if (ele.status.toUpperCase() === INVOICE_STATUS.ACCEPTED) {
        accepteds.push(ele);
      } else if (ele.status.toUpperCase() === INVOICE_STATUS.INVOICED) {
        invoiceds.push(ele);
      }
    });

    setTabSort({
      ...tabSort,
      tabs: [
        { type: "All" },
        { type: "Draft", length: drafts?.length },
        { type: "Sent", length: sents?.length },
        { type: "Desclined", length: declineds?.length },
        { type: "Accepted", length: accepteds?.length },
        { type: "Invoiced", length: invoiceds?.length },
      ],
    });
  }, [quote]);

  return (
    <div className="team_member">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableTabPaneLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Quote" }]}
            dataSource={filterAccount}
            tabSort={tabSort}
            setTabSort={setTabSort}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_QUOTE}
            table="quote"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Quote" }]}
            dataList={dataList}
            tabSort={tabSort}
            setTabSort={setTabSort}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_QUOTE}
            table="quote"
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteModule;