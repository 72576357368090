import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
//state
const postWebsiteSlice = createSlice({
  name: "teamStore",
  initialState: {
    employmentTypes: [],
    memberAwards: [],
    memberPositions: [],
    teamMembers: [],
    detailEmploymentType: null,
    detailMemberAward: null,
    detailMemeberPosition: null,
    detailTeamMember: null,
  },
  reducers: {
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload;
      state.detailTeamMember = null;
    },
    setDetailTeamMember: (state, action) => {
      state.detailTeamMember = action.payload;
    },
    setEmploymentTypes: (state, action) => {
      state.employmentTypes = action.payload;
      state.detailEmploymentType = null;
    },
    setDetailEmploymentType: (state, action) => {
      state.detailEmploymentType = action.payload;
    },
    setMemberAwards: (state, action) => {
      state.memberAwards = action.payload;
      state.detailMemberAward = null;
    },
    setDetailMemberAward: (state, action) => {
      state.detailMemberAward = action.payload;
    },
    setMemberPositions: (state, action) => {
      state.memberPositions = action.payload;
      state.detailMemeberPosition = null;
    },
    setDetailMemberPosition: (state, action) => {
      state.detailMemeberPosition = action.payload;
    },
  },
});

export default postWebsiteSlice.reducer;

//action
export const {
  setDetailEmploymentType,
  setDetailMemberAward,
  setDetailMemberPosition,
  setDetailTeamMember,
  setEmploymentTypes,
  setMemberAwards,
  setMemberPositions,
  setTeamMembers,
} = postWebsiteSlice.actions;

//team member
export const FETCH_ALL_TEAM_MEMBER_IN_ORGANISATION = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/users/user_all`, {
        params: query,
      })
      .then((res) => {
        return res.data.payload.users;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_TEAM_MEMBER_IN_ORGANISATION = async (
  organisationId,
  memberId
) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/users/user_detail`, {
        params: {
          organisationId,
          memberId,
        },
      })
      .then((res) => {
        return res.data.payload.user;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_TEAM_MEMBER = async (data, photo) => {
  try {
    const formData = new FormData();
    if (photo && photo.file) {
      formData.append("photo", photo.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/users/user_create`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      )
      .then((res) => {
        return res.data.payload.user;
      })
      .catch((err) => {
        throw err.response
      })
  } catch (err) {
    throw err.response;
  }
};

export const UPDATING_TEAM_MEMBER = async (data, photo) => {
  try {
    const formData = new FormData();
    if (photo && photo.file) {
      formData.append("photo", photo.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/users/user_update`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      )
      .then((res) => {
        return res.data.payload.user;
      })
      .catch((err) => {
        throw err.response
      })
  } catch (err) {
    throw err;
  }
};

export const DELETING_TEAM_MEMBER = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/users/user_delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

//position
export const FETCH_ALL_MEMBER_POSITION_IN_ORGANISATION = async (
  organisationId
) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/member-position/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_MEMBER_POSITION_IN_ORGANISATION = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/member-position/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_MEMBER_POSITION = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/member-position/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_MEMBER_POSITION = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/member-position/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_MEMBER_POSITION = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/member-position/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

//employment type
export const FETCH_ALL_EMPLOYMENT_TYPE_IN_ORGANISATION = async (
  organisationId
) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/employment-type/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_EMPLOYMENT_TYPE_IN_ORGANISATION = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/employment-type/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_EMPLOYMENT_TYPE = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/employment-type/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_EMPLOYMENT_TYPE = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/employment-type/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_EMPLOYMENT_TYPE = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/employment-type/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

//employment type
export const FETCH_ALL_MEMBER_AWARD_IN_ORGANISATION = async (
  organisationId
) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/employment-award/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_MEMBER_AWARD_IN_ORGANISATION = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/employment-award/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_MEMBER_AWARD = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/employment-award/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_MEMBER_AWARD = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/employment-award/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_MEMBER_AWARD = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/employment-award/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.message;
      });
  } catch (err) {
    throw err;
  }
};

export const NEW_TEAM_MEMBER = () => (dispatch) => {
  const newModel = {
    _id: "new",
    organisationId: "",
    departmentId: "",
    employmentTypeId: "",
    positionId: "",
    employeeAwardId: "",
    nickName: "",
    firstName: "",
    lastName: "",
    awardLevel: "",
    emailPersonal: "",
    mobilePersonal: "",
    addressPersonal: "",
    emailWork: "",
    photo: "",
    isDeletePhoto: false,
    mobileWork: "",
    dateOfJoining: moment(),
    bankedHoursBalance: 0,
    labourCost: 0,
    dateOfBirth: moment(),
    USI: "",
    gender: "",
    payrollProfile: [],
    includeInPayroll: true,
    active: true,
    timesheetReminder: true,
  };
  return dispatch(setDetailTeamMember(newModel));
};

export const NEW_MEMBER_AWARD = () => (dispatch) => {
  const newModel = {
    _id: "new",
    code: "",
    status: true,
  };
  return dispatch(setDetailMemberAward(newModel));
};

export const NEW_MEMBER_POSITION = () => (dispatch) => {
  const newModel = {
    _id: "new",
    description: "",
    status: true,
  };
  return dispatch(setDetailMemberPosition(newModel));
};

export const NEW_EMPLOYMENT_TYPE = () => (dispatch) => {
  const newModel = {
    _id: "new",
    description: "",
    status: true,
  };
  return dispatch(setDetailEmploymentType(newModel));
};
