import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import moment from "moment";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_FLEET_COMPLETE_PRESTART,
  setFleetCompletePrestarts,
  DELETE_FLEET_COMPLETE_PRESTART,
  FETCH_ALL_FLEET_COMPLETE_PRESTART_FOR_USER,
} from "../../store/fleet-prestart-store/reducer";
import axios from "axios";

const { Text } = Typography;
const FleetCompletePreStart = () => {
  const dispatch = useDispatch();
  const { organisationId, role, _id } = useSelector(({ auth }) => auth.user);
  const { fleetCompletePrestarts } = useSelector(
    ({ fleetPreStart }) => fleetPreStart.fleetPreStartReducer
  );
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    xPos: 0,
    yPos: 0,
    isLoading: false,
    record: null,
  });
  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Usage Unit",
        key: "usageUnit",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        label: "Categories",
        key: "categories",
      },
    ],
    data: [],
    fileName: "allFleetPrestart.csv",
  });
  const [dataList, setDataList] = useState([]);

  const columns = [
   
    {
      title: "Prestart ID",
      dataIndex: "uniqueNumber",
      key: "uniqueNumber",
      sorter: (a, b) => a?.uniqueNumber?.localeCompare(b?.uniqueNumber),
    },
    {
      title: "Fleet ID",
      dataIndex: "fleetId",
      key: "fleetId",
      sorter: (a, b) => b?.fleetId?.autoNumber - a?.fleetId?.autoNumber,
      render: (fleetId) => {
        return <Text>{fleetId?.autoNumber}</Text>;
      },
    },
    {
      title: "Rego",
      dataIndex: "fleetId",
      key: "fleetId",
      sorter: (a, b) =>
        a?.fleetId?.registration?.localeCompare(b?.fleetId?.registration),
      render: (fleetId) => {
        return <Text>{fleetId?.registration}</Text>;
      },
    },
    {
      title: "Make",
      dataIndex: "fleetId",
      key: "fleetId",
      sorter: (a, b) =>
        a?.fleetId?.make?.description?.localeCompare(
          b?.fleetId?.make?.description
        ),
      render: (fleetId) => {
        return <Text>{fleetId?.make?.description}</Text>;
      },
    },
    {
      title: "Model",
      dataIndex: "fleetId",
      key: "fleetId",
      sorter: (a, b) =>
        a?.fleetId?.make?.model?.description.localeCompare(
          b?.fleetId?.make?.model?.description
        ),
      render: (fleetId) => {
        return <Text>{fleetId?.make?.model?.description}</Text>;
      },
    },
    {
      title: "Kilometers/Hours",
      dataIndex: "usageUnit",
      key: "usageUnit",
      sorter: (a, b) => a?.usageUnit?.localeCompare(b?.usageUnit),
      render: (usageUnit) => {
        return <Text>{usageUnit}</Text>;
      },
    },
   
    {
      title: "Date Completed",
      dataIndex: "dateTime",
      key: "dateTime",
      sorter: (a, b) => moment(a.dateTime).unix() - moment(b.dateTime).unix(),
      defaultSortOrder: "ascent",
      sortDirections: ["ascent"],
    },
    {
      title: "Completed By",
      dataIndex: "teamMember",
      key: "teamMember",
      sorter: (a, b) => a?.teamMember?.firstName.localeCompare(b?.teamMember?.firstName),
      render: (teamMember) => {
        return (
          <Text>
            {teamMember?.firstName} {teamMember?.lastName}
          </Text>
        );
      },
    },
  ];

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        usageUnit: e.usageUnit,
        status: e.status,
        categories: e.categories,
      });
    });
    return csvData;
  };

  const FETCH_DATA_ADMIN = () => {
    FETCH_ALL_FLEET_COMPLETE_PRESTART(organisationId)
      .then((data) => dispatch(setFleetCompletePrestarts(data)))
      .finally(() => setIsLoading(false));
  };

  const FETCH_DATA_USER = () => {
    FETCH_ALL_FLEET_COMPLETE_PRESTART_FOR_USER(organisationId, _id)
      .then((data) => dispatch(setFleetCompletePrestarts(data)))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (role.includes("admin")) {
      FETCH_DATA_ADMIN();
    } else {
      FETCH_DATA_USER();
    }
  }, [organisationId, role]);

  useEffect(() => {
    if (fleetCompletePrestarts) {
      const columns = handleColumnCsv(fleetCompletePrestarts);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [fleetCompletePrestarts]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    if (role.length === 1 && role[0] === "user") {
      window.location.replace(`/fleet/prestarts/completing/new`);
    }
  }, []);

  useEffect(() => {
    const newDoc = [];
    fleetCompletePrestarts?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: ele?.uniqueNumber + `${ele?.title ? ` - ${ele?.title}` : ""}`,
        description: `${ele?.teamMember?.lastName} ${ele?.teamMember?.firstName} / ${ele?.dateTime}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [fleetCompletePrestarts]);

  const onToogleMenuDownload = async () => {
    try {
      setContextMenu({
        ...contextMenu,
        isLoading: true,
      });
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/pdf/base64`,
        {
          templateId: "421810",
          data: contextMenu.record,
          name: "Complete Fleet Prestart",
        },
        { responseType: "blob" }
      );

      const url = URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Complete Fleet Prestart.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      setContextMenu({
        ...contextMenu,
        visible: false,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Completed Prestarts" }]}
            dataSource={fleetCompletePrestarts}
            columns={columns}
            isPagination={{
              current: 1,
              pageSize: 100,
            }}
            FETCH_DATA={
              role.includes("admin") ? FETCH_DATA_ADMIN : FETCH_DATA_USER
            }
            DELETE_FUNC={DELETE_FLEET_COMPLETE_PRESTART}
            table="fleet/prestarts/completing"
            rightMenu={true}
            optionMenu={[
              { title: "Download", onToogleMenu: onToogleMenuDownload },
            ]}
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            sticky={{ offsetHeader: 64 }}
            scroll={{ x: 1500 }}
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Completed Prestarts" }]}
            dataList={dataList}
            rightMenu={true}
            columns={columns}
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            FETCH_DATA={
              role.includes("admin") ? FETCH_DATA_ADMIN : FETCH_DATA_USER
            }
            DELETE_FUNC={DELETE_FLEET_COMPLETE_PRESTART}
            table="fleet/prestarts/completing"
          />
        </div>
      </div>
    </div>
  );
};

export default FleetCompletePreStart;
