import ItemChartOfAccounts from "../../../components/ItemChartOfAccounts";

const Item = () => {
  return (
    <>
      <ItemChartOfAccounts />
    </>
  );
};

export default Item;
