import ItemCategory from "../../../components/ItemCategory";

const Item = () => {
  return (
    <>
      <ItemCategory />
    </>
  );
};

export default Item;
