import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Badge } from "antd";
import { useDispatch, useSelector } from "react-redux";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import {
  FETCH_ALL_VEHICLES,
  setVehicles,
  DELETE_VEHICLE,
} from "../../store/fleet-store/reducer";

const FeetVehicle = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { vehicles } = useSelector(({ fleets }) => fleets.fleetReducer);
  const [isLoading, setIsLoading] = useState(true);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [dataList, setDataList] = useState([]);

  const columns = [
    {
      title: "ID",
      dataIndex: "autoNumber",
      key: "autoNumber",
      sorter: (a, b) => a.autoNumber - b.autoNumber,
      width: 80,
    },
    {
      title: "Nick Name",
      dataIndex: "nickName",
      key: "nickName",
      sorter: (a, b) => a.nickName.localeCompare(b.nickName),
    },
    {
      title: "Fuel Type",
      dataIndex: "fuelType",
      key: "fuelType",
      sorter: (a, b) => a.fuelType.localeCompare(b.fuelType),
    },
    {
      title: "Registration",
      dataIndex: "registration",
      key: "registration",
      sorter: (a, b) => a.registration.localeCompare(b.registration),
    },
    {
      title: "Vin Number",
      dataIndex: "vinNumber",
      key: "vinNumber",
      sorter: (a, b) => a.vinNumber.localeCompare(b.vinNumber),
    },
    {
      title: "Engin Number",
      dataIndex: "enginNumber",
      key: "enginNumber",
      sorter: (a, b) => a.enginNumber.localeCompare(b.enginNumber),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "InActive"} />;
      },
      sorter: (a, b) => b.status - a.status,
    },
  ];

  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Nick Name",
        key: "nickName",
      },
      {
        label: "Vin Number",
        key: "vinNumber",
      },
      {
        label: "Engin Number",
        key: "enginNumber",
      },
      {
        label: "Category",
        key: "category",
      },
      {
        label: "Make Model",
        key: "make",
      },
      {
        label: "GVM",
        key: "GVM",
      },
      {
        label: "TARE",
        key: "TARE",
      },
      {
        label: "Last Odo Meter",
        key: "lastOdometer",
      },
      {
        label: "Last Odo Meter Date",
        key: "lastOdometerDate",
      },
      {
        label: "Registration",
        key: "registration",
      },
      {
        label: "Seats",
        key: "seats",
      },
      {
        label: "Fuel Type",
        key: "fuelType",
      },
      {
        label: "Year",
        key: "year",
      },
      {
        label: "Toll Id",
        key: "tollId",
      },
      {
        label: "Notes",
        key: "notes",
      },
      {
        label: "Driver Url",
        key: "driverUrl",
      },
      {
        label: "Color",
        key: "color",
      },
      {
        label: "Status",
        key: "status",
      },
    ],
    data: [],
    fileName: "allFleetVehicles.csv",
  });

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        nickName: e.nickName,
        vinNumber: e.vinNumber,
        enginNumber: e.enginNumber,
        category: e.category?.description,
        make: e.make?.description,
        TARE: e.TARE,
        GVM: e.GVM,
        lastOdometer: e.lastOdometer,
        lastOdometerDate: e.lastOdometerDate,
        registration: e.registration,
        seats: e.seats,
        fuelType: e.fuelType,
        year: e.year,
        tollId: e.tollId,
        notes: e.notes,
        status: e.status,
        driverUrl: e.driverUrl,
        color: e.color,
      });
    });
    return csvData;
  };

  const FETCH_DATA = () => {
    if (organisationId) {
      FETCH_ALL_VEHICLES(organisationId)
        .then((data) => dispatch(setVehicles(data)))
        .finally(() => setIsLoading(false));
    }
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (vehicles) {
      const columns = handleColumnCsv(vehicles);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [vehicles]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    vehicles?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.autoNumber} - ${ele?.nickName}`,
        description: `${ele?.fuelType} - ${ele?.vinNumber} - ${ele?.enginNumber}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [vehicles]);

  return (
    <div className=" table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Fleet Vehicle" }]}
            dataSource={vehicles}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_VEHICLE}
            table="fleet/vehicle"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Fleet Vehicle" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_VEHICLE}
            table="fleet/vehicle"
          />
        </div>
      </div>
    </div>
  );
};

export default FeetVehicle;
