import React, { useState, useEffect } from "react";
import { Statistic, Row, Col, Card, Layout, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/StandardFormLayout.module.scss";
import StandardContainerLayout from "../../../layouts/StandardContainerLayout";
import {
  FETCH_ALL_TEAM_MEMBER_IN_ORGANISATION,
  setTeamMembers,
} from "../../../store/team-member-store/reducer";

const { Content } = Layout;

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { teamMembers } = useSelector(({ team }) => team.teamReducer);
  const [loading, setLoading] = useState(false);
  const FETCH_DATA = () => {
    setLoading(true);
    if (organisationId) {
      FETCH_ALL_TEAM_MEMBER_IN_ORGANISATION({ organisationId }).then((data) => {
        dispatch(setTeamMembers(data));
        setLoading(false);
      });
    }
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  return (
    <StandardContainerLayout>
      <Content className={styles.dashboard__content}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6}>
            <Card className={styles.ant_card}>
              {!loading ? (
                <Statistic title="Team Members" value={teamMembers?.length} />
              ) : (
                <Skeleton />
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={6}></Col>
          <Col xs={24} sm={24} md={6}></Col>
          <Col xs={24} sm={24} md={6}></Col>
        </Row>
      </Content>
    </StandardContainerLayout>
  );
};

export default AdminDashboard;
