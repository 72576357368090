import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Badge, Typography } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_FLEET_ISSUE,
  FETCH_ALL_FLEET_ISSUES,
  setFleetIssues,
} from "../../store/fleet-store/reducer";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";

const { Text } = Typography;

const FleetIssues = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { issues } = useSelector(({ fleets }) => fleets.fleetReducer);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Description",
        key: "description",
      },
      {
        label: "Active",
        key: "active",
      },
    ],
    data: [],
    fileName: "allFleetIssues.csv",
  });
  const columns = [
    {
      title: "Fleet Number",
      dataIndex: "fleetCompletePrestartId",
      key: "fleetCompletePrestartId",
      sorter: (a, b) =>
        a.fleetCompletePrestartId?.uniqueNumber.localeCompare(b.fleetCompletePrestartId?.uniqueNumber),
      render: (fleetCompletePrestartId) => {
        return <Text>{fleetCompletePrestartId?.uniqueNumber}</Text>;
      },
    },
    {
      title: "Fleet Vehicle",
      dataIndex: "fleetId",
      key: "fleetId",
      sorter: (a, b) =>
        a.fleetId?.registration.localeCompare(b.fleetId?.registration),
      render: (fleetId) => {
        return <Text>{fleetId?.registration}</Text>;
      },
    },
    {
      title: "Fleet Prestart",
      dataIndex: "fleetPreStartId",
      key: "fleetPreStartId",
      sorter: (a, b) =>
        a.fleetPreStartId?.title.localeCompare(b.fleetPreStartId?.title),
      render: (fleetPreStartId) => {
        return <Text>{fleetPreStartId?.title}</Text>;
      },
    },
    {
      title: "Question",
      dataIndex: "issueQuestion",
      key: "issueQuestion",
      sorter: (a, b) => b.issueQuestion.localeCompare(a.issueQuestion),
    },
    {
      title: "Description",
      dataIndex: "issueDescription",
      key: "issueDescription",
      sorter: (a, b) => b.issueDescription.localeCompare(a.issueDescription),
    },
    {
      title: "Date Time",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      render: (dateTime) => {
        return <Text>{moment(dateTime).format("DD-MMM-YYYY")}</Text>;
      },
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Open" : "Closed"} />;
      },
      sorter: (a, b) => b.status.localeCompare(a.status),
    },
  ];

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        description: e.code,
        active: e.active,
      });
    });
    return csvData;
  };

  const FETCH_DATA = () => {
    FETCH_ALL_FLEET_ISSUES(organisationId)
      .then((data) => dispatch(setFleetIssues(data)))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (issues) {
      const columns = handleColumnCsv(issues);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [issues]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    issues?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.issueDescription}`,
        description: `${ele?.fleetPreStartId?.title} - ${moment(
          ele?.dateTime
        ).format("DD-MMM-YYYY")}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [issues]);

  return (
    <div className="table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Fleet Issues" }]}
            dataSource={issues}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_FLEET_ISSUE}
            table="fleet/issues"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Fleet Issues" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_FLEET_ISSUE}
            table="fleet/issues"
          />
        </div>
      </div>
    </div>
  );
};

export default FleetIssues;
