import Settings from "../../components/settings";

const SettingComp = () => {
  return (
    <>
      <Settings />
    </>
  );
};

export default SettingComp;
