import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin, message, Select } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  CREATING_ACCOUNT_CODE,
  FETCH_DETAIL_ACCOUNT_CODE,
  NEW_ITEM_ACCOUNT_CODE,
  setDetailAccountCode,
  UPDATING_ACCOUNT_CODE,
} from "../../store/items-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { TAX_DATA } from "../../constants";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Option, OptGroup } = Select;

const ItemAccountCode = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailAccountCode } = useSelector(({ items }) => items.itemReducer);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [accountTypeData, setAccountTypeData] = useState([
    {
      nameGroup: "ASSETS",
      items: [
        { id: 1, value: "Current Asset" },
        { id: 2, value: "Fixed Asset" },
        { id: 3, value: "Inventory" },
        { id: 4, value: "Non-current Asset" },
        { id: 5, value: "Repayment" },
      ],
    },
    { nameGroup: "EQUITY", items: [{ id: 6, value: "Equity" }] },
    {
      nameGroup: "EXPENSES",
      items: [
        { id: 7, value: "Depreciation" },
        { id: 8, value: "Direct Costs" },
        { id: 9, value: "Expense" },
        { id: 10, value: "Overhead" },
      ],
    },
    {
      nameGroup: "LIABILITIES",
      items: [
        { id: 11, value: "Current Liability" },
        { id: 12, value: "Liability" },
        { id: 13, value: "Non-current Liability" },
      ],
    },
    {
      nameGroup: "REVENUE",
      items: [
        { id: 14, value: "Other Income" },
        { id: 15, value: "Revenue" },
        { id: 16, value: "Sales" },
      ],
    },
  ]);
  const [nameGroupChoose, setNameGroupChoose] = useState("");

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailAccountCode?._id;
    values.code = values.code.toUpperCase();
    values.typeGroups = nameGroupChoose;
    if (id === "new") {
      CREATING_ACCOUNT_CODE(values)
        .then((data) => {
          setLoading(false);
          navigate("/chart_of_accounts");
        })
        .catch((err) => {
          const res = err.response.data.payload.message;
          message.error(res);
          setLoading(false);
        });
    } else {
      UPDATING_ACCOUNT_CODE(values)
        .then((data) => {
          setLoading(false);
          navigate("/chart_of_accounts");
        })
        .catch((err) => {
          const res = err.response.data.payload.message;
          message.error(res);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const updateState = () => {
      if (id === "new") {
        dispatch(NEW_ITEM_ACCOUNT_CODE());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_ACCOUNT_CODE(id).then((data) => {
          dispatch(setDetailAccountCode(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailAccountCode) {
      setNameGroupChoose(detailAccountCode?.typeGroups)
      form.setFields([
        {
          name: "name",
          value: detailAccountCode.name,
        },
        {
          name: "description",
          value: detailAccountCode.description,
        },
        {
          name: "code",
          value: detailAccountCode.code.toUpperCase(),
        },
        {
          name: "type",
          value: detailAccountCode.type,
        },
        {
          name: "type",
          value: detailAccountCode.type,
        },
        {
          name: "tax",
          value: detailAccountCode.tax,
        },
        {
          name: "status",
          value: detailAccountCode.status,
        },
      ]);
    }
  }, [detailAccountCode]);

  const handleChangeType = (value, option) => {
    accountTypeData.filter((ele) => {
      ele.items.filter((i) => {
        if (value === i.value) {
          setNameGroupChoose(ele.nameGroup);
        }
      });
    });
  };

  return (
    <StandardFormLayout
      hierachy={[
        {
          key: 1,
          label: "Chart of Accountss",
          path: "/chart_of_accounts",
        },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/chart_of_accounts/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            requiredMark={false}
            form={form}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input maxLength={10} />
            </Form.Item>

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input maxLength={150} />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Account Type"
              name="type"
              rules={[{ required: true }]}
            >
              <Select onChange={handleChangeType}>
                {accountTypeData.map((ele, index) => (
                  <OptGroup label={ele.nameGroup} key={index}>
                    {ele.items.map((i) => (
                      <Option value={i.value} key={i.id}>
                        {i.value}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Tax"
              name="tax"
              rules={[{ required: true }]}
            >
              <Select>
                {TAX_DATA.map((ele) => (
                  <Option value={ele.value} key={ele.id}>
                    {ele.value}
                  </Option>
                ))}

              </Select>
            </Form.Item>

            <Form.Item label="Active" name="status" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default ItemAccountCode;
