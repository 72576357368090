import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Badge } from "antd";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_DEPARTMENTS_IN_ORGANISATION,
  setDepartments,
  DELETING_DEPARTMENT,
} from "../../store/department-store/reducer";

const Departments = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const { departments } = useSelector(
    ({ departments }) => departments.departmentReducer
  );
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [dataList, setDataList] = useState([]);

  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Name",
        key: "name",
      },

      {
        label: "Status",
        key: "status",
      },
    ],
    data: [],
    fileName: "Paymentss.csv",
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => b.status - a.status,
      render: (status) => {
        let type = status ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "Inactive"} />;
      },
    },
  ];

  const FETCH_DATA = () => {
    FETCH_ALL_DEPARTMENTS_IN_ORGANISATION(organisationId)
      .then((data) => {
        dispatch(setDepartments(data));
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        name: e.name,
        status: e.status,
      });
    });
    return csvData;
  };

  useEffect(() => {
    if (departments) {
      const columns = handleColumnCsv(departments);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [departments]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    departments?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.name}`,
        description: ``,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [departments]);

  return (
    <div className="table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Departments" }]}
            dataSource={departments}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_DEPARTMENT}
            table="departments"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Departments" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_DEPARTMENT}
            table="departments"
          />
        </div>
      </div>
    </div>
  );
};

export default Departments;
