/* eslint-disable array-callback-return */
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";

//Components
import routes from "../layouts/StandardConfigLayout/routerConfig";
import Head from "../layouts/Head/Head";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SideBar from "../layouts/SideBar";

const { Content } = Layout;
const App = () => {
  const { user } = useSelector(({ auth }) => auth);
  const [currentRoutes, setCurrentRoutes] = useState([]);

  useEffect(() => {
    let currentRoutes = [];
    if (!user || (user && (!user.role || user.role.length === 0))) {
      currentRoutes = routes.filter((route) => route.auth.length === 0);
    } else {
      routes.forEach((route) => {
        const roles = route.auth;
        let intersection = _.intersection(roles, user.role);
        if (intersection.length > 0) {
          currentRoutes.push(route);
        }
      });
    }
    setCurrentRoutes(currentRoutes);
  }, [user]);

  return (
    <Layout className="layout" style={{ minHeight: "100vh" }}>
      <Routes>
        {currentRoutes.map((c) => {
          return (
            <Route
              key={c.path}
              path={c.path}
              element={
                <>
                  {c.settings?.layout?.sider ? <SideBar /> : ""}
                  {c.settings?.layout?.head ? <Head /> : ""}
                  <Content>
                    <c.component />
                  </Content>
                </>
              }
            />
          );
        })}
      </Routes>
    </Layout>
  );
};

export default App;
