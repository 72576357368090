import Organisitions from "../../components/Organisations";

const Organisition = () => {
  return (
    <>
      <Organisitions />
    </>
  );
};

export default Organisition;
