import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Badge, Avatar, Image } from "antd";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import { TeamOutlined } from "@ant-design/icons";
import {
  FETCH_ALL_CONTACT,
  setContacts,
  setIndustry,
  DELETING_CONTACT,
  FETCH_ALL_INDUSTRY,
} from "../../store/contact-store/reducer";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const { contacts, industry } = useSelector(
    ({ contact }) => contact.contactReducer
  );
  const [showAvatar, setShowAvatar] = useState({
    state: false,
    src: "",
  });
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    xPos: 0,
    yPos: 0,
    record: null,
    isLoading: false,
  });

  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Industry",
        key: "industry",
      },
      {
        label: "Phone",
        key: "phone",
      },
      {
        label: "Email",
        key: "Email",
      },
      {
        label: "Website",
        key: "Website",
      },
      {
        label: "Portal Address",
        key: "portalAddress",
      },
      {
        label: "Street Address",
        key: "streetAddress",
      },
      {
        label: "Tax",
        key: "tax",
      },
      {
        label: "Account",
        key: "account",
      },
      {
        label: "GST",
        key: "gst",
      },
      {
        label: "Terms",
        key: "terms",
      },
    ],
    data: [],
    fileName: "allContacts.csv",
  });

  const columns = [
    {
      title: "Image",
      dataIndex: "logo",
      key: "logo",
      align: "center",
      width: 80,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setShowAvatar({
              state: true,
              src: record.logo,
            });
          },
        };
      },
      render: (image) =>
        image > 0 ? (
          <Image src={image} />
        ) : (
          <Avatar
            style={{ color: "#5468ff", backgroundColor: "#e6f7ff" }}
            icon={<TeamOutlined />}
            src={image}
          ></Avatar>
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/contact/view/${record._id}`);
          },
        };
      },
    },

    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      sorter: (a, b) =>
        a?.industry?.description?.localeCompare(b?.industry?.description),
      render: (data) => {
        return data?.description;
      },

      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/contact/view/${record._id}`);
          },
        };
      },
    },

    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
      sorter: (a, b) => a.emailAddress.localeCompare(b.emailAddress),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/contact/view/${record._id}`);
          },
        };
      },
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/contact/view/${record._id}`);
          },
        };
      },
    },
    {
      title: "Postal Address",
      dataIndex: "postalCountry",
      key: "postalCountry",
      sorter: (a, b) => a.postalAddress.localeCompare(b.postalAddress),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/contact/view/${record._id}`);
          },
        };
      },
    },

    {
      title: "Status",
      dataIndex: "contactStatus",
      key: "contactStatus",
      render: (status) => {
        let type = status ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "Archived"} />;
      },
      sorter: (a, b) => b.status - a.status,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/contact/view/${record._id}`);
          },
        };
      },
    },
  ];

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        name: e.name,
        industry: e.industry,
        email: e.email,
        phone: e.phone,
        portalAddress: e.portalAddress,
        sreetAddress: e.sreetAddress,
        gst: e.gst,
        account: e.account,
        tax: e.tax,
        terms: e.terms,
        status: e.status,
      });
    });
    return csvData;
  };

  const FETCH_DATA = () => {
    FETCH_ALL_INDUSTRY(organisationId)
      .then((data) => {
        dispatch(setIndustry(data));
      })
      .finally(() => setIsLoading(false));

    FETCH_ALL_CONTACT(organisationId)
      .then((data) => {
        dispatch(setContacts(data));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (contacts) {
      const columns = handleColumnCsv(contacts);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [contacts]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    contacts?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.name}`,
        description: `${ele?.emailAddress} - ${ele?.phone}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [contacts]);

  const onToogleMenuOpen = () => {
    navigate(`/contact/view/${contextMenu?.record?._id}`);
    setContextMenu({ visible: false });
  };

  return (
    <div className="table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Contact" }]}
            dataSource={contacts}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_CONTACT}
            rightMenu={true}
            optionMenu={[{ title: "View", onToogleMenu: onToogleMenuOpen }]}
            contextMenu={contextMenu}
            isHaveRowAction={false}
            setContextMenu={setContextMenu}
            table="contact"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Contact" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            isHaveRowAction={false}
            DELETE_FUNC={DELETING_CONTACT}
            rightMenu={true}
            optionMenu={[{ title: "View", onToogleMenu: onToogleMenuOpen }]}
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            table="contact"
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
