import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin, message, Modal } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  FETCH_DETAIL_PERSON,
  CREATING_PERSON,
  setDetailPerson,
  NEW_PERSON,
  UPDATING_PERSON,
} from "../../store/contact-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const EditContactPerson = ({
  isModalVisible,
  setIsModalVisible,
  contactId,
}) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const { contactId, id } = useParams();
  const { detailPerson } = useSelector(({ contact }) => contact.contactReducer);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailPerson ? detailPerson._id : "new";
    values.contactId = contactId;

    if (isModalVisible.id === "new") {
      CREATING_PERSON(values)
        .then((data) => {
          setLoading(false);
          setIsModalVisible({ ...isModalVisible, visible: false });
        })
        .catch((err) => {
          const errorMessage = err.data?.payload?.message;
          if (errorMessage) {
            message.error(errorMessage);
          }
          setLoading(false);
        });
    } else {
      UPDATING_PERSON(values).then((data) => {
        setLoading(false);
        setIsModalVisible({ ...isModalVisible, visible: false });
      });
    }
  };

  useEffect(() => {
    setInitialLoading(true);
    const updateState = async () => {
      if (isModalVisible.id === "new") {
        dispatch(NEW_PERSON());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_PERSON(isModalVisible.id).then((data) => {
          dispatch(setDetailPerson(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, isModalVisible.id]);

  useEffect(() => {
    if (detailPerson) {
      form.setFields([
        {
          name: "contactId",
          value: detailPerson.contactId,
        },
        {
          name: "firstName",
          value: detailPerson.firstName,
        },
        {
          name: "phone",
          value: detailPerson.phone,
        },
        {
          name: "lastName",
          value: detailPerson.lastName,
        },
        {
          name: "title",
          value: detailPerson.title,
        },
        {
          name: "email",
          value: detailPerson.email,
        },
        {
          name: "status",
          value: detailPerson.status,
        },
        // {
        //   name: "includeInEmail",
        //   value: detailPerson.includeInEmail,
        // },
      ]);
    }
  }, [detailPerson]);

  return (
    <Modal
      title="Contact Person"
      visible={isModalVisible.visible}
      footer={null}
      onCancel={() => setIsModalVisible({ ...isModalVisible, visible: false })}
      className="form-contact-view"
    >
      <StandardFormLayout
        form={
          initialLoading ? (
            <Spin spinning={initialLoading} indicator={antIcon}></Spin>
          ) : (
            <Form
              labelAlign="left"
              form={form}
              requiredMark={false}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 18 }}
              onFinish={onFinish}
            >
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
              >
                <Input placeholder="Last Name" />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="phone"
              >
                <Input placeholder="Phone" />
              </Form.Item>

              <Form.Item
                label="Title"
                name="title"
              >
                <Input placeholder="Title" />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true }]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item label="Status" name="status" valuePropName="checked">
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>

              {/* <Form.Item
                label="Include Email"
                name="includeInEmail"
                valuePropName="checked"
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item> */}

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )
        }
      />
    </Modal>
  );
};

export default EditContactPerson;
