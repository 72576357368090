import EditPayments from "../../components/Payments/EditPayments";

const PaymentEdit = () => {
  return (
    <>
      <EditPayments />
    </>
  );
};

export default PaymentEdit;
