import axios from "axios";
import jwtDecode from "jwt-decode";
import FuseUtils from "../utils/Utils";

class JwtService extends FuseUtils.EventEmitter {
    init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

    setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					console.log("err", err)
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token');
						this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

    
	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');
			return;
		}
		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

    signInWithEmailAndPassword = (email, password) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${process.env.REACT_APP_BACK_URL}/api/auth/login`, {
						email: email.toLowerCase().trim(),
						password
				})
				.then(response => {
					if (response.data.payload.user) {
						this.setSession(response.data.payload.access_token);
						resolve(response.data.payload.user);
					}
				})
				.catch(err => {
					console.log(err)
					reject(err)
				});
		});
	};

    signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${process.env.REACT_APP_BACK_URL}/api/auth/access_token`, {
					params: {
						access_token: this.getAccessToken()
					}
				})
				.then(response => {
					if (response.data.payload.user) {
						this.setSession(this.getAccessToken());
						resolve(response.data.payload.user);
					} else {
						this.logout();
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(error => {
					this.logout();
					reject(new Error('Failed to login with token.'));
				});
		});
	};

    updateUserData = user => {
		const data = JSON.stringify(user);
		return axios.post(`${process.env.REACT_APP_BACK_URL}/api/users/user_update`, {data});
	};

    transferUserOrganisation = (user, choosenOrganisationId) => {
		return axios.get(`${process.env.REACT_APP_BACK_URL}/api/watchUser/watch_user_transfer_org`, {
			params: {
				_id: user._id,
				email: user.email,
				organisationId: choosenOrganisationId,
			}
		})
	}

    setSession = access_token => {
		if (access_token != null) {
			localStorage.setItem('jwt_access_token', access_token);
			// axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	logout = () => {
		this.setSession(null);
		window.location.href = "/login";
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};
}


const instance = new JwtService();

export default instance;