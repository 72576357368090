import EditTimeSheets from "../../components/TimeSheets/EditTimeSheets";

const DetailTimeSheets = () => {
  return (
    <>
      <EditTimeSheets />
    </>
  );
};

export default DetailTimeSheets;
