import Quote from "../../components/Quote";

const Quotes = () => {
  return (
    <>
      <Quote />
    </>
  );
};

export default Quotes;
