import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  message,
  TimePicker,
  Typography,
  Spin,
  Modal,
  InputNumber,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_DETAIL_TIME_SHEET_BY_IDDATE,
  CREATE_TIME_SHEET,
  UPDATE_TIME_SHEET,
  NEW_TIME_SHEET,
  setDetailTimeSheetById,
} from "../../store/time-sheet-store/reducer";
import {
  FETCH_PAYMENT_PROFILE_BY_TEAMMEMBER,
  setDetailPayment,
} from "../../store/payment-store/reducer";
import moment from "moment";
import SignaturePad from "react-signature-canvas";
import { dataURLtoFile } from "../../utils/core/UrlToFile";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Title } = Typography;

const EditEmploymentAwards = () => {
  const format = "HH:mm";
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { detailTimeSheetById } = useSelector(
    ({ timeSheet }) => timeSheet.TimeSheetReducer
  );
  const { detailPayments } = useSelector(
    ({ payments }) => payments.paymentReducer
  );
  const { organisationId, teamMemberId } = useSelector(({ auth }) => auth.user);
  const { idMember, currentDate } = useParams();
  const { TextArea } = Input;
  const [initialLoading, setInitialLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  let sigPad = useRef({});
  const [loading, setLoading] = useState(false);
  const [sign, setSign] = useState({
    file: null,
    url: "",
  });
  const [dateTime, setDateTime] = useState({
    startTime: 0,
    startBreak: 0,
    finishBreak: 0,
    finishTime: 0,
  });
  const [multiLeave, setMultiLeave] = useState();
  const [children, setChildren] = useState([
    { id: 1, value: "Public Holiday" },
  ]);

  const fetchSubData = async () => {
    const payProfile = await FETCH_PAYMENT_PROFILE_BY_TEAMMEMBER(
      organisationId,
      teamMemberId
    );
    if (payProfile) {
      dispatch(setDetailPayment(payProfile));
    }
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      FETCH_DETAIL_TIME_SHEET_BY_IDDATE(idMember).then((data) => {
        if (data.status === 404) {
          dispatch(
            NEW_TIME_SHEET({ dateTime: currentDate, teamMember: idMember })
          );
          setInitialLoading(false);
        } else {
          dispatch(setDetailTimeSheetById(data.data.payload.data));
          setInitialLoading(false);
        }
      });
    };
    updateState();
  }, [dispatch, idMember, currentDate]);

  useEffect(() => {
    if (detailTimeSheetById) {
      setSign({
        file: null,
        url: detailTimeSheetById.signature,
      });
      if (detailTimeSheetById?.leave) {
        setMultiLeave(detailTimeSheetById?.leave);
        handleChangeLeave(detailTimeSheetById?.leave);
      }
      form.setFields([
        {
          name: "_id",
          value: detailTimeSheetById?._id,
        },
        {
          name: "dateTime",
          value: detailTimeSheetById?.dateTime,
        },
        {
          name: "startTime",
          value: moment(detailTimeSheetById?.startTime),
        },
        {
          name: "startBreak",
          value: moment(detailTimeSheetById?.startBreak),
        },

        {
          name: "finishBreak",
          value: moment(detailTimeSheetById?.finishBreak),
        },
        {
          name: "finishTime",
          value: moment(detailTimeSheetById?.finishTime),
        },
        {
          name: "totalHours",
          value: detailTimeSheetById?.totalHours.toFixed(2),
        },
        {
          name: "leave",
          value: detailTimeSheetById?.leave,
        },
        {
          name: "signature",
          value: detailTimeSheetById?.signature,
        },
        {
          name: "comment",
          value: detailTimeSheetById?.comment,
        },
        {
          name: "payrollProfile",
          value: detailTimeSheetById?.payrollProfile,
        },
        {
          name: "day",
          value: detailTimeSheetById?.day,
        },
        {
          name: "overTimeHours",
          value: detailTimeSheetById?.overTimeHours,
        },
        {
          name: "annualLeaveHours",
          value: detailTimeSheetById?.annualLeaveHours,
        },
        {
          name: "personalLeaveHours",
          value: detailTimeSheetById?.personalLeaveHours,
        },
        {
          name: "leaveWithoutPay",
          value: detailTimeSheetById?.leaveWithoutPay,
        },
        {
          name: "bankedHours",
          value: detailTimeSheetById?.bankedHours,
        },
        {
          name: "wasThisDayWorkedAway",
          value: detailTimeSheetById?.wasThisDayWorkedAway,
        },
        {
          name: "workedAwayHours",
          value: detailTimeSheetById?.workedAwayHours,
        },
      ]);
    }
  }, [detailTimeSheetById]);

  useEffect(() => {
    let secondsStartToFinish =
      moment(dateTime.finishTime).diff(moment().startOf("day"), "seconds") -
      moment(dateTime.startTime).diff(moment().startOf("day"), "seconds");
    let secondsBreakTime =
      moment(dateTime.finishBreak).diff(moment().startOf("day"), "seconds") -
      moment(dateTime.startBreak).diff(moment().startOf("day"), "seconds");

    let total = detailPayments?.includeBreakInTotalHours
      ? (secondsStartToFinish - secondsBreakTime) / 3600
      : secondsStartToFinish / 3600;

    form.setFields([
      {
        name: "totalHours",
        value: total.toFixed(2),
      },
    ]);
  }, [dateTime]);

  // check leave of pay profiles
  useEffect(() => {
    const newChild = [{ id: 1, value: "Public Holiday" }];

    if (detailPayments?.leave?.annualLeave) {
      newChild.push({ id: 2, value: "Annual" });
    }
    if (detailPayments?.leave?.personalLeave) {
      newChild.push({ id: 3, value: "Personal" });
    }
    if (detailPayments?.leave?.annualLeaveLoading) {
      newChild.push({ id: 4, value: "Leave Without Pay" });
    }

    form.setFields([
      {
        name: "startTime",
        value: moment(detailPayments?.standardTimes?.startTime),
      },
      {
        name: "startBreak",
        value: moment(detailPayments?.standardTimes?.startBreak),
      },
      {
        name: "finishBreak",
        value: moment(detailPayments?.standardTimes?.endBreak),
      },
      {
        name: "finishTime",
        value: moment(detailPayments?.standardTimes?.finishTime),
      },
    ]);

    setChildren(newChild);
    setDateTime({
      finishBreak: moment(detailPayments?.standardTimes?.endBreak),
      finishTime: moment(detailPayments?.standardTimes?.finishTime),
      startBreak: moment(detailPayments?.standardTimes?.startBreak),
      startTime: moment(detailPayments?.standardTimes?.startTime),
    });
  }, [detailPayments]);

  const onFinish = (values) => {
    // setLoading(true);
    let secondsStartToFinish =
      moment(values.finishTime).diff(moment().startOf("day"), "seconds") -
      moment(values.startTime).diff(moment().startOf("day"), "seconds");
    let secondsBreakTime =
      moment(values.finishBreak).diff(moment().startOf("day"), "seconds") -
      moment(values.startBreak).diff(moment().startOf("day"), "seconds");
    let formData = {
      _id: detailTimeSheetById ? detailTimeSheetById?._id : "new",
      organisationId,
      dateTime: detailTimeSheetById
        ? detailTimeSheetById?.dateTime
        : currentDate,
      startTime: values?.startTime,
      startBreak: values?.startBreak,
      finishBreak: values?.finishBreak,
      finishTime: values?.finishTime,
      totalHours: (secondsStartToFinish - secondsBreakTime) / 3600,
      leave: values?.leave,
      comment: values?.comment,
      payrollProfile: detailPayments?._id,
      day: 2,
      overTimeHours: values?.overTimeHours,
      annualLeaveHours: values?.annualLeaveHours,
      personalLeaveHours: values?.personalLeaveHours,
      leaveWithoutPay: values?.leaveWithoutPay,
      bankedHours: values?.bankedHours,
      wasThisDayWorkedAway: true,
      workedAwayHours: values?.workedAwayHours,
      teamMember: idMember,
    };
    if (!detailTimeSheetById) {
      CREATE_TIME_SHEET(formData, sign.file).then(() => {
        setLoading(false);
        navigate("/payroll/all-records");
      });
    } else {
      UPDATE_TIME_SHEET(formData, sign.file).then(() => {
        setLoading(false);
        navigate("/payroll/all-records");
      });
    }
  };

  const onChange = (e, name) => {
    setDateTime({
      ...dateTime,
      [name]: moment(e),
    });
  };

  const saveSign = () => {
    setSign({
      file: dataURLtoFile(sigPad.current.toDataURL(), "signstaff.png"),
      url: sigPad.current.toDataURL(),
    });
    // message.success("Save Sign Success");
    setVisible(false);
  };

  // Set field leave
  const handleChangeLeave = (value) => {
    const newValue = [];
    value?.forEach((item) => {
      switch (item) {
        case "Annual":
          newValue.push({
            name: "annualLeaveHours",
            label: "Annual Leave Hours",
            value: item,
          });
          break;
        case "Leave Without Pay":
          newValue.push({
            name: "leaveWithoutPay",
            label: "Leave without pay hours",
            value: item,
          });
          break;
        case "Personal":
          newValue.push({
            name: "personalLeaveHours",
            label: "Personal Leave Hours",
            value: item,
          });
          break;
        default:
          break;
      }
    });
    setMultiLeave(newValue);
    form.setFields([
      {
        name: "leave",
        value: value,
      },
    ]);
  };

  return (
    <StandardFormLayout
      hierachy={[
        {
          key: 1,
          label: "Time Sheets",
          path: "/payroll/all-records",
        },
        {
          key: 2,
          label:
            detailTimeSheetById?._id.indexOf("new") === -1
              ? "Edit Time Record"
              : "Add Time Record",
        },
      ]}
      editPath="/payroll/all-records/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            form={form}
            requiredMark={false}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Title level={5}>
              Date: <strong>{currentDate}</strong>
            </Title>
            <Form.Item
              label="Start Time"
              name="startTime"
              rules={[{ required: true, message: "Please select leave time!" }]}
            >
              <TimePicker
                format={format}
                onChange={(e) => onChange(e, "startTime")}
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Form.Item>

            <Form.Item
              label="Start Break"
              name="startBreak"
              rules={[{ required: true, message: "Please select leave time!" }]}
            >
              <TimePicker
                format={format}
                onChange={(e) => onChange(e, "startBreak")}
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Form.Item>

            <Form.Item
              label="Finish Break"
              name="finishBreak"
              rules={[{ required: true, message: "Please select leave time!" }]}
            >
              <TimePicker
                format={format}
                onChange={(e) => onChange(e, "finishBreak")}
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Form.Item>

            <Form.Item
              label="Finish Time"
              name="finishTime"
              rules={[{ required: true, message: "Please select leave time!" }]}
            >
              <TimePicker
                format={format}
                onChange={(e) => onChange(e, "finishTime")}
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Form.Item>

            <Form.Item
              label="Total Hours"
              name="totalHours"
              className="totalHoursTime"
            >
              <Input
                disabled
                style={{
                  border: "none",
                  backgroundColor: "#fff",
                  color: "#000",
                  padding: 0,
                }}
              />
            </Form.Item>

            <Form.Item label="Leave" name="leave">
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select leave"
                onChange={handleChangeLeave}
                options={children}
              />
            </Form.Item>

            {multiLeave?.map((m, index) => (
              <Form.Item
                label={m?.label}
                name={m?.name}
                key={index}
                rules={[
                  {
                    required:
                      detailTimeSheetById && detailTimeSheetById[m?.name]
                        ? true
                        : false,
                    message: "Please hours greater than 0!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} min={1} />
              </Form.Item>
            ))}

            {detailPayments?.bankedHoursUsed && (
              <Form.Item label="Banked Hours" name="bankedHours">
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            )}
            {sign.url && (
              <img className="signpad_img" src={sign.url} alt="sign" />
            )}

            <Form.Item
              label="Signature"
              name="signature"
            // rules={[
            //   {
            //     required: !sign.file ? true : false,
            //     message: "Please sign!",
            //   },
            // ]}
            >
              <Button type="primary" onClick={() => setVisible(true)}>
                Sign
              </Button>
            </Form.Item>

            <Form.Item label="Comment" name="comment">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
            {/* Modal sign  */}
            <Modal
              title="Signature"
              visible={visible}
              onCancel={() => setVisible(false)}
              footer={[
                <div key={sigPad}>
                  <Button
                    style={{ backgroundColor: "grey", borderColor: "grey" }}
                    type="primary"
                    onClick={() => {
                      sigPad.current.clear();
                      setSign({
                        file: null,
                        url: "",
                      });
                    }}
                  >
                    Clear
                  </Button>
                  <Button type="primary" onClick={saveSign}>
                    Save
                  </Button>
                </div>,
              ]}
            >
              <SignaturePad ref={sigPad} />
            </Modal>
          </Form>
        )
      }
    />
  );
};

export default EditEmploymentAwards;
