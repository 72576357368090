import React, { useState, useEffect } from "react";
import { Form, Input, Button, Switch, InputNumber, Spin } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_DETAIL_DEPARTMENT_IN_ORGANISATION,
  setDetailDepartment,
  CREATING_DEPARTMENT,
  UPDATING_DEPARTMENT,
  NEW_DEPARTMENT,
} from "../../store/department-store/reducer";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditEmploymentAwards = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const { idDepartment } = useParams();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { detailDepartment } = useSelector(
    ({ departments }) => departments.departmentReducer
  );
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const updateState = () => {
      if (idDepartment === "new") {
        dispatch(NEW_DEPARTMENT());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_DEPARTMENT_IN_ORGANISATION(idDepartment).then((data) => {
          dispatch(setDetailDepartment(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, idDepartment]);

  const onFinish = (values) => {
    setLoading(true);
    values._id = detailDepartment._id;
    values.organisationId = organisationId;
    if (idDepartment === "new") {
      CREATING_DEPARTMENT(values).then((data) => {
        setLoading(false);
        navigate("/departments");
      });
    } else {
      UPDATING_DEPARTMENT(values).then((data) => {
        setLoading(false);
        navigate("/departments");
      });
    }
  };

  useEffect(() => {
    if (detailDepartment) {
      form.setFields([
        {
          name: "name",
          value: detailDepartment?.name,
        },
        {
          name: "annualTurnOverTarget",
          value: detailDepartment?.annualTurnOverTarget,
        },
        {
          name: "operatingCostPerHour",
          value: detailDepartment?.operatingCostPerHour,
        },

        {
          name: "profitMarginTarget",
          value: detailDepartment?.profitMarginTarget,
        },
        {
          name: "status",
          value: detailDepartment?.status,
        },
      ]);
    }
  }, [detailDepartment]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Departments", path: "/departments" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/departments/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            form={form}
            requiredMark={false}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Annual TurnOver Target"
              name="annualTurnOverTarget"
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Operating CostPerHour"
              name="operatingCostPerHour"
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item label="Profit Margin Target" name="profitMarginTarget">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditEmploymentAwards;
