import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";

//state
const postWebsiteSlice = createSlice({
  name: "teamStore",
  initialState: {
    documentCategory: null,
    detailDocumentCategory: null,
    documents: null,
    detailDocument: null,
  },
  reducers: {
    setDocumentCategory: (state, action) => {
      state.documentCategory = action.payload;
    },
    setDetailDocumentCategory: (state, action) => {
      state.detailDocumentCategory = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setDetailDocuments: (state, action) => {
      state.detailDocument = action.payload;
    },
  },
});

export default postWebsiteSlice.reducer;

//action
export const {
  setDocumentCategory,
  setDetailDocumentCategory,
  setDocuments,
  setDetailDocuments,
} = postWebsiteSlice.actions;

//Document
export const FETCH_ALL_DOCUMENT = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/documents/all`, {
        params: query,
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_ALL_DOCUMENT_BY_CATEGORY = async (organisationId, description) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/documents/allByCategory`, {
        params: {
          organisationId,
          description
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_DOCUMENT_IN_ORGANISATION = async (
  organisationId,
  id
) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/documents/detail`, {
        params: {
          organisationId,
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CHECK_FILE_DRIVER = async (
  fileId
) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/documents/checkFileDriver`, {
        params: {
          fileId
        }
      })
      .then((res) => {
        return res.data.payload.data;
      })
      .catch((err) => {
        return null
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_DOCUMENT = async (data, file) => {
  try {
    const formData = new FormData();
    if (file && file.file) {
      formData.append("file", file.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/documents/create`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_DOCUMENT = async (data, photo) => {
  try {
    const formData = new FormData();
    if (photo && photo.file) {
      formData.append("file", photo.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/documents/update`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_DOCUMENT = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/documents/delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

//Document Category

export const FETCH_DOCUMENT_CATEGORY = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/documents/category/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_DOCUMENT_CATEGORY = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/documents/category/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_DOCUMENT_CATEGORY = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/documents/category/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_DOCUMENT_CATEGORY = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/documents/category/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_DOCUMENT_CATEGORY = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/documents/category/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

export const NEW_DOCUMENT = () => (dispatch) => {
  const newModel = {
    _id: "new",
    title: "",
    category: "",
    fileType: "",
    fileUrl: "",
    fileName: "",
    expire: false,
    association: [],
    versionNumber: 1,
    versionDate: moment(),
    teamMember: "",
    fleet: "",
    permission: "",
    active: true,
  };
  return dispatch(setDetailDocuments(newModel));
};

export const NEW_DOCUMENT_CATEGORY = () => (dispatch) => {
  const newModel = {
    _id: "new",
    description: "",
    code: "",
    active: true,
  };
  return dispatch(setDetailDocumentCategory(newModel));
};
