import PurchaseOrders from "../../../components/PurchaseOrders";
const Purchases = () => {
  return (
    <>
      <PurchaseOrders />
    </>
  );
};

export default Purchases;
