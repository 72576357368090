import FLeetCompletePreStarts from "../../components/FLeetCompletePreStart";

const FleetCompletePreStartPage = () => {
  return (
    <>
      <FLeetCompletePreStarts />
    </>
  );
};

export default FleetCompletePreStartPage;
