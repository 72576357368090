import React, { useState, useEffect } from "react";
import { Collapse, Table, Tooltip, Button, Badge, Space, Modal } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_PERSON,
  setPerson,
  DELETING_PERSON,
} from "../../../store/contact-store/reducer";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../styles/StandardTableLayout.module.scss";
import EditContactPerson from "../EditContactPerson";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Panel } = Collapse;

const columns = [
  {
    title: "Name",
    render: (data) => {
      return (
        <>
          {data.firstName} {data.lastName}
        </>
      );
    },
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      let type = status === true ? "success" : "error";
      return <Badge status={type} text={status ? "Active" : "InActive"} />;
    },
  },
];

const ContactsViewTable = ({ detailContact, initialLoading }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { persons } = useSelector(({ contact }) => contact.contactReducer);
  const { role } = useSelector(({ auth }) => auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [saveRow, setSaveRow] = useState([]);
  const [rowsSelected, setRowsSelected] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState({
    visible: false,
    id: "new",
  });

  const FETCH_DATA = () => {
    const newData = [];
    FETCH_ALL_PERSON(id)
      .then((data) => {
        data &&
          data?.map((ele, index) => {
            newData.push({
              ...ele,
              key: ele._id,
            });
          });
        dispatch(setPerson(newData));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    FETCH_DATA();
  }, [id, isModalVisible]);

  const rowSelection = {
    onChange: (selectedRowKeys, record) => {
      setSaveRow(record);
      selectedRowKeys && selectedRowKeys?.length > 0
        ? setRowsSelected(true)
        : setRowsSelected(false);
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      name: record.description,
    }),
  };

  const showConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to delete these records?",
      icon: <ExclamationCircleOutlined />,
      content: "The operation cannot be undone.",
      onOk() {
        const newSource = saveRow?.map((s) => {
          return `ids=${s._id}`;
        });

        DELETING_PERSON(newSource.join("&")).then((data) => {
          FETCH_DATA();
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleClickRow = (record) => {
    setIsModalVisible({
      visible: true,
      id: record?._id,
    });
  };

  const onAddButtonClickHandler = () => {
    setIsModalVisible({
      visible: true,
      id: "new",
    });
  };

  return (
    <div className="table-view-contact">
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Contacts" key="1">
          <div className="site-layout-content" style={{ minHeight: "inherit" }}>
            <div className={styles.actionBar}>
              {rowsSelected && (
                <Space>
                  <Button icon={<DeleteOutlined />} onClick={showConfirm}>
                    Delete
                  </Button>
                </Space>
              )}

              <div className={styles.rightActions}>
                <Space>
                  {role?.indexOf("admin") !== -1 && (
                    <Tooltip placement="top" title="Add">
                      <Button
                        type="primary"
                        // icon={<PlusOutlined />}
                        onClick={onAddButtonClickHandler}
                      >
                        Add Contact Person
                      </Button>
                    </Tooltip>
                  )}
                </Space>
              </div>
            </div>
            <Table
              size="small"
              rowClassName={"clickable"}
              onRow={(record) => {
                return {
                  onClick: () => {
                    handleClickRow(record);
                  },
                };
              }}
              bordered
              rowSelection={rowSelection}
              pagination={{ pageSize: 50 }}
              columns={columns}
              dataSource={persons}
              loading={{ indicator: antIcon, spinning: isLoading }}
              sticky={{ offsetHeader: 64 }}
              scroll={{ x: 1500 }}
            />
          </div>
        </Panel>
      </Collapse>
      <EditContactPerson
        contactId={id}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
};

export default ContactsViewTable;
