import {
  Form,
  Input,
  Button,
  Switch,
  Spin,
  Select,
  Upload,
  Typography,
  Image,
  Space,
  message,
  Divider,
  Radio
} from "antd";
import { useEffect, useState } from "react";
import {
  UploadOutlined,
  PlusOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import {
  FETCH_ITEM_CATEGORY,
  FETCH_ACOUNT_CODE,
  setItemCategory,
  setAccountCode,
  NEW_ITEMS,
  FETCH_DETAIL_ITEM_IN_ORGANISATION,
  setDetailItems,
  CREATING_ITEM,
  UPDATING_ITEM,
} from "../../store/items-store/reducer";
import cryptoRandomString from 'crypto-random-string';
import { useDispatch, useSelector } from "react-redux";
import ModalItems from "./components/ModalItems";

import {
  FETCH_ALL_USERS_NOT_MATCH_IN_ORGANISATION,
  setUsers,
} from "../../store/user-store/reducer";
import {
  FETCH_ALL_DEPARTMENTS_IN_ORGANISATION,
  setDepartments,
} from "../../store/department-store/reducer";
import { TAX_DATA, TAX_RATE_DATA } from "../../constants";
import FuseUtils from "../../utils/Utils";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Option, OptGroup } = Select;
const yesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const EditTeamMember = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { organisationId, _id } = useSelector(({ auth }) => auth.user);
  const { detailItems, itemCategory, accountCode } = useSelector(
    ({ items }) => items.itemReducer
  );
  const { departments } = useSelector(
    ({ departments }) => departments.departmentReducer
  );
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [accountData, setAccountData] = useState([]);
  const [showAccountData, setShowAccountData] = useState({
    purchase: [],
    sell: []
  })
  const [fileList, setFileList] = useState([]);
  const [deletePhoto, setDeletePhoto] = useState({
    image: "",
    isDeletePhoto: false,
  });
  const [itemPurchaseSellState, setItemPurchaseSellState] = useState({
    isPurchase: false,
    isSell: true
  })
  const [isModalVisible, setIsModalVisible] = useState({
    visible: false,
    type: "",
  });

  const onHandleRemoveImage = () => {
    setFileList([]);
    setDeletePhoto({
      image: detailItems?.image,
      isDeletePhoto: true,
    });
  };

  const onHandlePurchaseChange = ({ target: { value } }) => {
    setItemPurchaseSellState({
      ...itemPurchaseSellState,
      isPurchase: value
    })
    if (!value) {
      form.setFields([
        {
          name: "costPrice",
          value: 0
        },
        {
          name: "purchaseAccount",
          value: ""
        },
        {
          name: "purchaseTax",
          value: ""
        },
        {
          name: "purchaseDescription",
          value: ""
        },
      ])
    }
  }

  const onHandleSellChange = ({ target: { value } }) => {
    setItemPurchaseSellState({
      ...itemPurchaseSellState,
      isSell: value
    })
    if (!value) {
      form.setFields([
        {
          name: "salePrice",
          value: 0
        },
        {
          name: "saleAccount",
          value: ""
        },
        {
          name: "saleTax",
          value: ""
        },
        {
          name: "saleDescription",
          value: ""
        },
      ])
    }
  }

  const onHandleChangeCategory = (e, data) => {
    let uniqueSkuNumber = cryptoRandomString({
      length: 4,
      type: "numeric"
    });
    const name = data.data.code;
    form.setFields([
      {
        name: "sku",
        value: name + uniqueSkuNumber
      }
    ])
  }

  const onSearchAccount = prop => (e) => {
    if (e) {
      const filterArr = FuseUtils.filterArrayByString(accountData, e)
      switch (prop) {
        case "PURCHASE": setShowAccountData({
          ...showAccountData,
          purchase: filterArr
        }); break;
        case "SELL": setShowAccountData({
          ...showAccountData,
          sell: filterArr
        }); break;
      }
    }
  }

  const props = {
    name: "file",
    maxCount: 1,
    className: "upload-list-inline",
    listType: "picture",
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        setDeletePhoto({
          image: detailItems?.image ? detailItems?.image : "",
          isDeletePhoto: detailItems?.image ? true : false,
        });
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url,
          },
        ]);
      };
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailItems._id;
    values.image = detailItems ? deletePhoto.image : deletePhoto.image;
    values.isDeletePhoto = deletePhoto.isDeletePhoto;
    //take code
    const saleAccount = accountCode?.filter((a) => a._id === values.saleAccount);
    if (saleAccount && saleAccount.length > 0) {
      values.saleAccountCodeXero = saleAccount[0].code;
    }

    const purchaseAccount = accountCode?.filter((a) => a._id === values.purchaseAccount);
    if (purchaseAccount && purchaseAccount.length > 0) {
      values.purchaseAccountCodeXero = purchaseAccount[0].code;
    }

    if (id === "new") {
      CREATING_ITEM(values, fileList[0])
        .then((data) => {
          setLoading(false);
          navigate("/items");
        })
        .catch((err) => {
          const errorMessage = err.data?.payload?.message;
          if (errorMessage) {
            message.error(errorMessage);
          }
          setLoading(false);
        });
    } else {
      UPDATING_ITEM(values, fileList[0])
        .then((data) => {
          setLoading(false);
          navigate("/items");
        })
        .catch((err) => {
          const errorMessage = err.data?.payload?.message;

          if (errorMessage) {
            message.error(errorMessage);
          }
          setLoading(false);
        });
    }
  };

  const fetchSubData = async () => {
    const category = await FETCH_ITEM_CATEGORY(organisationId);
    if (category) {
      dispatch(setItemCategory(category));
    }
    const accountCode = await FETCH_ACOUNT_CODE(organisationId);
    if (accountCode) {
      const categoriseAccount = []
      const filterAssets = accountCode.filter(e => e.typeGroups === "ASSETS")
      const assets = {
        nameGroup: "ASSETS",
        items: filterAssets
      }
      const filterEquities = accountCode.filter(e => e.typeGroups === "EQUITY")
      const equities = {
        nameGroup: "EQUITY",
        items: filterEquities
      }
      const filterLiabilities = accountCode.filter(e => e.typeGroups === "LIABILITIES")
      const liabilities = {
        nameGroup: "LIABILITIES",
        items: filterLiabilities
      }
      const filterExpenses = accountCode.filter(e => e.typeGroups === "EXPENSES")
      const expenses = {
        nameGroup: "EXPENSES",
        items: filterExpenses
      }
      const filterRevenue = accountCode.filter(e => e.typeGroups === "REVENUE")
      const revenue = {
        nameGroup: "REVENUE",
        items: filterRevenue
      }
      categoriseAccount.push(assets)
      categoriseAccount.push(expenses)
      categoriseAccount.push(equities)
      categoriseAccount.push(liabilities)
      categoriseAccount.push(revenue)
      setShowAccountData({
        sell: categoriseAccount,
        purchase: categoriseAccount
      })
      setAccountData(categoriseAccount)
      dispatch(setAccountCode(accountCode));
    }

    const loggedUser = await FETCH_ALL_USERS_NOT_MATCH_IN_ORGANISATION(
      organisationId,
      id
    );
    if (loggedUser) {
      dispatch(setUsers(loggedUser));
    }

    const departmentData = await FETCH_ALL_DEPARTMENTS_IN_ORGANISATION(
      organisationId
    );
    if (departmentData) {
      dispatch(setDepartments(departmentData));
    }
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      if (id === "new") {
        dispatch(NEW_ITEMS());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_ITEM_IN_ORGANISATION(organisationId, id).then((data) => {
          dispatch(setDetailItems(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailItems) {
      if (detailItems.image) {
        setFileList([
          {
            uid: "2",
            status: "done",
            file: null,
            url: detailItems.image,
          },
        ]);
      }
      setItemPurchaseSellState({
        isPurchase: detailItems.isPurchase,
        isSell: detailItems.isSell
      })
      form.setFields([
        {
          name: "category",
          value: detailItems.category,
        },
        {
          name: "image",
          value: detailItems.image,
        },
        {
          name: "sku",
          value: detailItems.sku,
        },
        {
          name: "department",
          value: detailItems.department,
        },
        {
          name: "name",
          value: detailItems.name,
        },
        {
          name: "isPurchase",
          value: detailItems.isPurchase,
        },
        {
          name: "costPrice",
          value: detailItems.costPrice,
        },
        {
          name: "purchaseAccount",
          value: detailItems.purchaseAccount,
        },

        {
          name: "purchaseTax",
          value: detailItems.purchaseTax,
        },
        {
          name: "purchaseDescription",
          value: detailItems.purchaseDescription,
        },
        {
          name: "isSell",
          value: detailItems.isSell,
        },
        {
          name: "salePrice",
          value: detailItems.salePrice,
        },
        {
          name: "saleAccount",
          value: detailItems.saleAccount,
        },
        {
          name: "saleTax",
          value: detailItems.saleTax,
        },
        {
          name: "saleDescription",
          value: detailItems.saleDescription,
        },
        {
          name: "status",
          value: detailItems.status,
        },
      ]);

    }
  }, [detailItems]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Items", path: "/items" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/items/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            name="basic"
            labelAlign="left"
            autoComplete="off"
            labelWrap={true}
            form={form}
            scrollToFirstError
            onFinish={onFinish}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            requiredMark={false}
          >
            <Divider orientation="left">Item Details</Divider>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true, message: "Missing Category" }]}
            >
              <Select
                showSearch
                onChange={(e, data) => onHandleChangeCategory(e, data)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    ?.toString()
                    ?.replace(/,/gi, "")
                    .toLowerCase()
                    ?.includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA?.children
                    ?.toString()
                    ?.replace(/,/gi, "")
                    ?.toLowerCase()
                    ?.localeCompare(
                      optionB?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        ?.toLowerCase()
                    )
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Space align="center" style={{ padding: "0 8px 4px" }}>
                      <Typography.Link
                        onClick={() =>
                          setIsModalVisible({
                            visible: true,
                            type: "Category",
                          })
                        }
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <PlusOutlined /> Add item
                      </Typography.Link>
                    </Space>
                  </>
                )}
              >
                {itemCategory &&
                  itemCategory.map((type) => {
                    return (
                      <Option key={type._id} value={type._id} data={type}>
                        {type.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Code"
              name="sku"
              rules={[{ required: true, message: "Missing Sku" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Missing Name" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Department"
              name="department"
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {departments &&
                  departments.map((type) => {
                    return (
                      <Option key={type._id} value={type._id}>
                        {type.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item label="Image">
              <Upload {...props} onRemove={() => setFileList([])}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
              <div>
                {fileList.length > 0 ? (
                  fileList.map((l, index) => (
                    <div
                      key={index}
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        position: "relative",
                        width: "max-content",
                      }}
                    >
                      {id !== "new" && fileList[0]?.url !== "" && (
                        <div
                          onClick={() => onHandleRemoveImage()}
                          style={{
                            right: "0px",
                            position: "absolute",
                            zIndex: 9,
                            cursor: "pointer",
                          }}
                        >
                          <CloseCircleOutlined />
                        </div>
                      )}
                      <Image
                        src={l.url}
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </Form.Item>

            <Divider orientation="left">Purchase</Divider>

            <Form.Item label="Do we purchase this" name="isPurchase">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                onChange={onHandlePurchaseChange}
                options={yesNoOptions}
              />
            </Form.Item>

            {itemPurchaseSellState.isPurchase ? (
              <>
                <Form.Item label="Cost Price" name="costPrice">
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Account"
                  name="purchaseAccount"
                  rules={[{ required: true }]}
                >
                  <Select
                    onSearch={onSearchAccount("PURCHASE")}
                    showSearch
                    filterOption={false}
                  >
                    {showAccountData.purchase.map((ele, index) => (
                      <OptGroup label={ele.nameGroup} key={index}>
                        {ele.items.map((i) => (
                          <Option value={i._id} key={i._id}>
                            {i.code} - {i.name}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Tax Rate" name="purchaseTax"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {TAX_RATE_DATA.map((ele) => (
                      <Option value={ele.name} key={ele.id}>
                        {ele.value}
                      </Option>
                    ))}

                  </Select>
                </Form.Item>

                <Form.Item label="Purchase Description" name="purchaseDescription">
                  <Input />
                </Form.Item>
              </>
            ) : (
              ''
            )}

            <Divider orientation="left">Sell</Divider>

            <Form.Item label="Do we sell this" name="isSell">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                onChange={onHandleSellChange}
                options={yesNoOptions}
              />
            </Form.Item>

            {itemPurchaseSellState.isSell ? (
              <>
                <Form.Item label="Sale Price" name="salePrice">
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Account"
                  name="saleAccount"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    onSearch={onSearchAccount("SELL")}
                    filterOption={false}
                  >
                    {showAccountData.sell.map((ele, index) => (
                      <OptGroup label={ele.nameGroup} key={index}>
                        {ele.items.map((i) => (
                          <Option value={i._id} key={i._id}>
                            {i.code} - {i.name}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Tax Rate" name="saleTax"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {TAX_RATE_DATA.map((ele) => (
                      <Option value={ele.name} key={ele.id}>
                        {ele.value}
                      </Option>
                    ))}

                  </Select>
                </Form.Item>

                <Form.Item label="Sale Description" name="saleDescription">
                  <Input />
                </Form.Item>
              </>
            ) : (
              ''
            )}

            <div>
              {isModalVisible.visible && (
                <ModalItems
                  form={form}
                  fetchSubData={fetchSubData}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                />
              )}
            </div>

            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditTeamMember;
