import VehicleModels from "../../../components/VehicleModels";

const VehicleModelsPage = () => {
  return (
    <>
      <VehicleModels />
    </>
  );
};

export default VehicleModelsPage;
