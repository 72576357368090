import Bill from "../../components/Bill";

const Bills = () => {
  return (
    <>
      <Bill />
    </>
  );
};

export default Bills;
