import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  CREATING_INDUSTRY,
  FETCH_DETAIL_INDUSTRY,
  NEW_INDUSTRY,
  setDetailIndustry,
  UPDATING_INDUSTRY,
} from "../../store/contact-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const EditIndustry = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailIndustry } = useSelector(
    ({ contact }) => contact.contactReducer
  );
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailIndustry._id;
    if (id === "new") {
      CREATING_INDUSTRY(values).then((data) => {
        setLoading(false);
        navigate("/contact/industry");
      });
    } else {
      UPDATING_INDUSTRY(values).then((data) => {
        setLoading(false);
        navigate("/contact/industry");
      });
    }
  };

  useEffect(() => {
    const updateState = () => {
      if (id === "new") {
        dispatch(NEW_INDUSTRY());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_INDUSTRY(id).then((data) => {
          dispatch(setDetailIndustry(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailIndustry) {
      form.setFields([
        {
          name: "description",
          value: detailIndustry.description,
        },
        {
          name: "status",
          value: detailIndustry.status,
        },
      ]);
    }
  }, [detailIndustry]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Positions", path: "/contact/industry" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/contact/industry/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            form={form}
            requiredMark={false}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditIndustry;
