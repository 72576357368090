import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Switch,
  Spin,
  Select,
  Upload,
  Checkbox,
  Typography,
  Image,
  Space,
  message,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import {
  UploadOutlined,
  PlusOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import {
  TAX_DATA,
  TERM_PAYMENT_DATA,
  TERM_PAYMENT_IVOICE_DATA,
} from "../../constants";

import ModalContact from "./components/ModalContact";

import {
  FETCH_DETAIL_CONTACT,
  NEW_CONTACT,
  setDetailContact,
  CREATING_CONTACT,
  UPDATING_CONTACT,
  FETCH_ALL_INDUSTRY,
  setIndustry,
} from "../../store/contact-store/reducer";
import {
  FETCH_ACOUNT_CODE,
  setAccountCode,
} from "../../store/items-store/reducer";
import {
  FETCH_ALL_USERS_NOT_MATCH_IN_ORGANISATION,
  setUsers,
} from "../../store/user-store/reducer";
import { useDispatch, useSelector } from "react-redux";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Option, OptGroup } = Select;
const { Title } = Typography;
const CheckboxGroup = Checkbox.Group;
const contactOptions = ["Customer", "Supplier"];

const EditContact = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { organisationId, _id } = useSelector(({ auth }) => auth.user);
  const { accountCode } = useSelector(({ items }) => items.itemReducer);
  const { detailContact, industry } = useSelector(
    ({ contact }) => contact.contactReducer
  );

  const { data } = useSelector(({ user }) => user.userReducer);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [showAccountData, setShowAccountData] = useState({
    purchase: [],
    sell: [],
  });

  const [isModalVisible, setIsModalVisible] = useState({
    visible: false,
    type: "",
  });

  const [deletePhoto, setDeletePhoto] = useState({
    photo: "",
    isDeletePhoto: false,
  });

  const [address, setAddress] = useState();

  const onHandleRemoveImage = () => {
    setFileList([]);
    setDeletePhoto({
      photo: detailContact?.photo,
      isDeletePhoto: true,
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailContact._id;
    values.photo = deletePhoto.photo;
    values.isDeletePhoto = deletePhoto.isDeletePhoto;
    values.xeroId = detailContact.xeroId;
    const saleAccount = accountCode?.filter(
      (a) => a._id === values.salesDefaultAccountCode
    );
    if (saleAccount && saleAccount.length > 0) {
      values.salesDefaultAccountCodeXero = saleAccount[0].code;
    }

    const purchaseAccount = accountCode?.filter(
      (a) => a._id === values.purchasesDefaultAccountCode
    );
    if (purchaseAccount && purchaseAccount.length > 0) {
      values.purchaseDefaultAccountCodeXero = purchaseAccount[0].code;
    }

    if (id === "new") {
      CREATING_CONTACT(values, fileList[0])
        .then((data) => {
          setLoading(false);
          navigate("/contact");
        })
        .catch((err) => {
          const errorMessage = err.data?.payload?.message;
          if (errorMessage) {
            message.error(errorMessage);
          }
          setLoading(false);
        });
    } else {
      UPDATING_CONTACT(values, fileList[0])
        .then((data) => {
          setLoading(false);
          navigate("/contact");
        })
        .catch((err) => {
          const errorMessage = err.data?.payload?.message;
          if (errorMessage) {
            message.error(errorMessage);
          }
          setLoading(false);
        });
    }
  };

  const props = {
    name: "file",
    maxCount: 1,
    className: "upload-list-inline",
    listType: "picture",
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        setDeletePhoto({
          photo: detailContact?.photo ? detailContact?.photo : "",
          isDeletePhoto: detailContact?.photo ? true : false,
        });
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url,
          },
        ]);
      };
    },
  };

  const fetchSubData = async () => {
    const industryData = await FETCH_ALL_INDUSTRY(organisationId);
    if (industryData) {
      dispatch(setIndustry(industryData));
    }

    const accountCode = await FETCH_ACOUNT_CODE(organisationId);
    if (accountCode) {
      const categoriseAccount = [];
      const filterAssets = accountCode.filter((e) => e.typeGroups === "ASSETS");
      const assets = {
        nameGroup: "ASSETS",
        items: filterAssets,
      };
      const filterEquities = accountCode.filter(
        (e) => e.typeGroups === "EQUITY"
      );
      const equities = {
        nameGroup: "EQUITY",
        items: filterEquities,
      };
      const filterLiabilities = accountCode.filter(
        (e) => e.typeGroups === "LIABILITIES"
      );
      const liabilities = {
        nameGroup: "LIABILITIES",
        items: filterLiabilities,
      };
      const filterExpenses = accountCode.filter(
        (e) => e.typeGroups === "EXPENSES"
      );
      const expenses = {
        nameGroup: "EXPENSES",
        items: filterExpenses,
      };
      const filterRevenue = accountCode.filter(
        (e) => e.typeGroups === "REVENUE"
      );
      const revenue = {
        nameGroup: "REVENUE",
        items: filterRevenue,
      };
      categoriseAccount.push(assets);
      categoriseAccount.push(expenses);
      categoriseAccount.push(equities);
      categoriseAccount.push(liabilities);
      categoriseAccount.push(revenue);
      setShowAccountData({
        sell: categoriseAccount,
        purchase: categoriseAccount,
      });
      dispatch(setAccountCode(accountCode));
    }

    const loggedUser = await FETCH_ALL_USERS_NOT_MATCH_IN_ORGANISATION(
      organisationId,
      id
    );
    if (loggedUser) {
      dispatch(setUsers(loggedUser));
    }
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      if (id === "new") {
        dispatch(NEW_CONTACT());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_CONTACT(id).then((data) => {
          dispatch(setDetailContact(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailContact) {
      if (detailContact.logo) {
        setFileList([
          {
            uid: "2",
            status: "done",
            file: null,
            url: detailContact.photo,
          },
        ]);
      }
      setAddress({
        streetAddress: detailContact?.postalStreet,
        streetSuburb: detailContact?.postalSuburb,
        streetState: detailContact?.postalState,
        streetPostalCode: detailContact?.postalPostCode,
        streetCountry: detailContact?.postalCountry,
      });
      form.setFields([
        {
          name: "type",
          value: detailContact.type,
        },
        {
          name: "name",
          value: detailContact.name,
        },
        {
          name: "industry",
          value: detailContact.industry?._id,
        },
        {
          name: "phone",
          value: detailContact.phone,
        },
        {
          name: "emailAddress",
          value: detailContact.emailAddress,
        },
        {
          name: "website",
          value: detailContact.website,
        },
        {
          name: "postalStreet",
          value: detailContact.postalStreet,
        },
        {
          name: "postalSuburb",
          value: detailContact.postalSuburb,
        },
        {
          name: "postalState",
          value: detailContact.postalState,
        },
        {
          name: "postalPostCode",
          value: detailContact.postalPostCode,
        },
        {
          name: "postalCountry",
          value: detailContact.postalCountry,
        },
        {
          name: "streetSuburb",
          value: detailContact.streetSuburb,
        },
        {
          name: "streetState",
          value: detailContact.streetState,
        },
        {
          name: "streetPostalCode",
          value: detailContact.streetPostalCode,
        },
        {
          name: "streetCountry",
          value: detailContact.streetCountry,
        },
        {
          name: "salesDefaultLineAmountType",
          value: detailContact.salesDefaultLineAmountType,
        },
        {
          name: "salesDefaultAccountCode",
          value: detailContact.salesDefaultAccountCode,
        },
        {
          name: "accountsReceivableTaxType",
          value: detailContact.accountsReceivableTaxType,
        },
        {
          name: "saleTermDay",
          value: detailContact.saleTermDay,
        },
        {
          name: "saleTerm",
          value: detailContact.saleTerm,
        },
        {
          name: "purchasesDefaultLineAmountType",
          value: detailContact.purchasesDefaultLineAmountType,
        },
        {
          name: "purchasesDefaultAccountCode",
          value: detailContact.purchasesDefaultAccountCode,
        },
        {
          name: "accountsPayableTaxType",
          value: detailContact.accountsPayableTaxType,
        },
        {
          name: "purchaseTermDay",
          value: detailContact.purchaseTermDay,
        },
        {
          name: "purchaseTerm",
          value: detailContact.purchaseTerm,
        },
        {
          name: "taxNumber",
          value: detailContact.taxNumber,
        },
        {
          name: "bankAccountNumber",
          value: detailContact.bankAccountNumber,
        },
        {
          name: "bankAccountName",
          value: detailContact.bankAccountName,
        },
        {
          name: "bankDetail",
          value: detailContact.bankDetail,
        },
        {
          name: "contactStatus",
          value: detailContact.contactStatus,
        },
      ]);
    }
  }, [detailContact]);

  const handleChangeAddress = (e, name) => {
    setAddress({
      ...address,
      [name]: e.target.value,
    });
  };


  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Contact", path: "/contact" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/contact/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            labelWrap={true}
            form={form}
            scrollToFirstError
            onFinish={onFinish}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            requiredMark={false}
          >
            <Row gutter={32}>
              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <Divider orientation="left">Contact Information</Divider>

                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: "Missing Type" }]}
                >
                  <CheckboxGroup options={contactOptions} />
                </Form.Item>

                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: "Missing Name" }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>

                <Form.Item
                  label="Industry"
                  name="industry"
                  rules={[{ required: true, message: "Missing Industry" }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        .toLowerCase()
                        ?.includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        ?.toLowerCase()
                        ?.localeCompare(
                          optionB?.children
                            ?.toString()
                            ?.replace(/,/gi, "")
                            ?.toLowerCase()
                        )
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Space align="center" style={{ padding: "0 8px 4px" }}>
                          <Typography.Link
                            onClick={() =>
                              setIsModalVisible({
                                visible: true,
                                type: "Industry",
                              })
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <PlusOutlined /> Add item
                          </Typography.Link>
                        </Space>
                      </>
                    )}
                  >
                    {industry &&
                      industry.map((type) => {
                        return (
                          <Option key={type._id} value={type._id}>
                            {type.description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item label="Logo">
                  <Upload {...props} onRemove={() => setFileList([])}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <div>
                    {fileList.length > 0 ? (
                      fileList.map((l, index) => (
                        <div
                          key={index}
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            position: "relative",
                            width: "max-content",
                          }}
                        >
                          {id !== "new" && fileList[0]?.url !== "" && (
                            <div
                              onClick={() => onHandleRemoveImage()}
                              style={{
                                right: "0px",
                                position: "absolute",
                                zIndex: 9,
                                cursor: "pointer",
                              }}
                            >
                              <CloseCircleOutlined />
                            </div>
                          )}
                          <Image
                            src={l.url}
                            style={{
                              maxWidth: "100px",
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </Form.Item>

                <Form.Item label="Phone" name="phone">
                  <Input placeholder="Phone" />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="emailAddress"
                  rules={[{ required: true, message: "Missing Email" }]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item label="Website" name="website">
                  <Input placeholder="Website" />
                </Form.Item>

                {/* Portal address  */}
                <Form.Item className="form-address" label="Portal Address">
                  <Form.Item name="postalStreet">
                    <Input
                      placeholder="Street"
                      onChange={(e) => handleChangeAddress(e, "streetAddress")}
                    />
                  </Form.Item>

                  <Form.Item name="postalSuburb">
                    <Input
                      placeholder="Suburb"
                      name="suburb"
                      onChange={(e) => handleChangeAddress(e, "streetSuburb")}
                    />
                  </Form.Item>

                  <div className="portal">
                    <Form.Item name="postalState">
                      <Input
                        placeholder="State"
                        onChange={(e) => handleChangeAddress(e, "streetState")}
                      />
                    </Form.Item>

                    <Form.Item name="postalPostCode">
                      <Input
                        placeholder="Postal"
                        onChange={(e) => handleChangeAddress(e, "streetPostalCode")}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item name="postalCountry">
                    <Input
                      placeholder="Country"
                      onChange={(e) => handleChangeAddress(e, "streetCountry")}
                    />
                  </Form.Item>
                </Form.Item>

                {/* Street address  */}

                <Form.Item
                  className="form-address"
                  label={
                    <div className="saveAsPostal">
                      <p>Street Address</p>
                      <p
                        onClick={() => {
                          form.setFieldsValue({
                            streetAddress: address?.streetAddress,
                            streetSuburb: address?.streetSuburb,
                            streetState: address?.streetState,
                            streetPostalCode: address?.streetPostalCode,
                            streetCountry: address?.streetCountry,
                          });
                        }}
                      >
                        Same as postal address
                      </p>
                    </div>
                  }
                >
                  <Form.Item name="streetAddress">
                    <Input placeholder="Street" />
                  </Form.Item>

                  <Form.Item name="streetSuburb">
                    <Input placeholder="Suburb" />
                  </Form.Item>

                  <div className="portal">
                    <Form.Item name="streetState">
                      <Input placeholder="State" />
                    </Form.Item>

                    <Form.Item name="streetPostalCode">
                      <Input placeholder="Postal" />
                    </Form.Item>
                  </div>

                  <Form.Item className="form-address" name="streetCountry">
                    <Input placeholder="Country" />
                  </Form.Item>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <Divider orientation="left">Financial Details</Divider>
                {/* Sales */}
                <Title style={{ fontSize: 14 }} level={4}>
                  Sales
                </Title>

                <Form.Item label="Account" name="salesDefaultAccountCode">
                  <Select
                    placeholder="Default Account"
                    //  onSearch={onSearchAccount("PURCHASE")}
                    showSearch
                    filterOption={false}
                  >
                    {showAccountData.purchase.map((ele, index) => (
                      <OptGroup label={ele.nameGroup} key={index}>
                        {ele.items.map((i) => (
                          <Option value={i._id} key={i._id}>
                            {i.code} - {i.name}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Default GST" name="accountsReceivableTaxType">
                  <Select placeholder="Default GST">
                    {TAX_DATA.map((ele) => (
                      <Option value={ele.value} key={ele.id}>
                        {ele.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item className="form-terms" label="Term">
                  <div className="portal">
                    <Form.Item name="saleTermDay">
                      <Input placeholder="Days" />
                    </Form.Item>

                    <Form.Item name="saleTerm">
                      <Select placeholder="Terms">
                        {TERM_PAYMENT_IVOICE_DATA.map((ele) => (
                          <Option value={ele.value} key={ele.id}>
                            {ele.text}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>

                {/* Purchases */}
                <Title style={{ fontSize: 14 }} level={4}>
                  Purchases
                </Title>

                <Form.Item label="Account" name="purchasesDefaultAccountCode">
                  <Select
                    placeholder="Default Account"
                    //  onSearch={onSearchAccount("PURCHASE")}
                    showSearch
                    filterOption={false}
                  >
                    {showAccountData.purchase.map((ele, index) => (
                      <OptGroup label={ele.nameGroup} key={index}>
                        {ele.items.map((i) => (
                          <Option value={i._id} key={i._id}>
                            {i.code} - {i.name}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Default GST" name="accountsPayableTaxType">
                  <Select placeholder="Default GST">
                    {TAX_DATA.map((ele) => (
                      <Option value={ele.value} key={ele.id}>
                        {ele.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item className="form-terms" label="Terms">
                  <div className="portal">
                    <Form.Item name="purchaseTermDay">
                      <Input placeholder="Days" />
                    </Form.Item>

                    <Form.Item name="purchaseTerm">
                      <Select placeholder="Terms">
                        {TERM_PAYMENT_DATA.map((ele) => (
                          <Option value={ele.value} key={ele.id}>
                            {ele.text}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>

                {/* Other */}
                <Title style={{ fontSize: 14 }} level={4}>
                  Other
                </Title>
                <Form.Item label="ABN" name="taxNumber">
                  <Input placeholder="Austraulian Business Number" />
                </Form.Item>
                <Form.Item label="Batch Payments" className="form-address">
                  <div className="portal">
                    <Form.Item name="bankAccountNumber">
                      <Input placeholder="Bank Account Number" />
                    </Form.Item>
                    <Form.Item name="bankAccountName">
                      <Input placeholder="Bank Account Name" />
                    </Form.Item>
                  </div>
                  <Form.Item name="bankDetail">
                    <Input placeholder="Bank Detail" />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="contactStatus"
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Form.Item>

                <div>
                  {isModalVisible.visible && (
                    <ModalContact
                      form={form}
                      fetchSubData={fetchSubData}
                      isModalVisible={isModalVisible}
                      setIsModalVisible={setIsModalVisible}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        )
      }
    />
  );
};

export default EditContact;
