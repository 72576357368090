import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
//state
const postWebsiteSlice = createSlice({
  name: "departmentStore",
  initialState: {
    departments: [],
    detailDepartment: null,
  },
  reducers: {
    setDepartments: (state, action) => {
      state.departments = action.payload;
      state.detailDepartment = null;
    },
    setDetailDepartment: (state, action) => {
      state.detailDepartment = action.payload;
    },
  },
});

export default postWebsiteSlice.reducer;

//action
export const { setDepartments, setDetailDepartment } = postWebsiteSlice.actions;

//team member
export const FETCH_ALL_DEPARTMENTS_IN_ORGANISATION = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/departments/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.departments;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_DETAIL_DEPARTMENT_IN_ORGANISATION = async (departmentId) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/departments/detail?departmentId=${departmentId}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const CREATING_DEPARTMENT = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/departments/create`, data)
      .then((res) => {
        return res.data.payload.user;
      });
  } catch (err) {
   throw err
  }
};

export const UPDATING_DEPARTMENT = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/departments/update`, data)
      .then((res) => {
        return res.data.payload.user;
      });
  } catch (err) {
   throw err
  }
};

export const DELETING_DEPARTMENT = async ( query ) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/departments/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_DEPARTMENT = () => (dispatch) => {
  const newModel = {
    _id: "new",
    status: true,
    organisationId: "",
    annualTurnOverTarget: 0,
    operatingCostPerHour: 0,
    profitMarginTarget: 0,
  };
  return dispatch(setDetailDepartment(newModel));
};
