import React from "react";
import "./styles/styles.scss";
import "antd/dist/antd.css";
import Main from "./pages";
import store from "./store";
import { Provider } from "react-redux";
import Auth from "./store/auth/Auth";
import Authorization from "./utils/core/Authorization";

const App = () => {
  return (
    <Provider store={store}>
      <Auth>
        <Authorization>
          <Main />
        </Authorization>
      </Auth>
    </Provider>
  );
};

export default App;
