import Document from "../../../components/Document";

const QualificationsTraining = () => {
  return (
    <>
      <Document description="Qualification"/>
    </>
  );
};

export default QualificationsTraining;
