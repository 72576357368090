import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Layout,
  Row,
  Col,
  Tabs,
  Typography,
  Card,
  Button,
  Space,
  notification,
} from "antd";
import Users from "./Users";
import { setUsers } from "../../store/user-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import EditUserModal from "./EditUserModal";
import EditOrganisation from "./EditOrganisation";
import { NEW_USER } from "../../store/user-store/reducer";
import { FORM_TYPE } from "../../constants";
import { UPDATING_ORG } from "../../store/org-store/reducer";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Title } = Typography;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Settings = () => {
  const dispatch = useDispatch();
  const [currentOrg, setCurrentOrg] = useState();
  const [requiredOrgFields, setRequiredOrgFields] = useState({
    parentFolderId: "",
    adminDocumentUrl: "",
    userDocumentUrl: "",
  })
  const [fileList, setFileList] = useState([]);
  const [updateOrgButtonLoading, setUpdateOrgButtonLoading] = useState(false);
  const { data } = useSelector(({ user }) => user.userReducer);
  const { user } = useSelector(({ auth }) => auth);
  const [editModalVisible, setEditModalVisible] = useState({
    isOpen: false,
    type: "",
  });
  const [editModalTitle, setEditModalTitle] = useState("");

  const handleAddUser = () => {
    setEditModalTitle("Add User");
    dispatch(NEW_USER());
    setEditModalVisible({
      isOpen: true,
      type: FORM_TYPE.CREATE,
    });
  };

  const handleEditUser = () => {
    setEditModalTitle("Edit User");
    setEditModalVisible({
      isOpen: true,
      type: FORM_TYPE.UPDATE,
    });
  };

  const handleEditOrg = async () => {
    let isSubmit = true
    for (const [key, value] of Object.entries(requiredOrgFields)) {
      if (value === "") {
        isSubmit = false
      }
    }
    if (isSubmit) {
      setUpdateOrgButtonLoading(true);
      UPDATING_ORG(user.organisationId, currentOrg, fileList)
        .then((data) => {
          notification.success({
            message: "Update Organisation Success",
          });
        })
        .finally(() => setUpdateOrgButtonLoading(false));
    }
  };

  const handleUpdateUser = (user, type) => {
    switch (type) {
      case FORM_TYPE.CREATE: dispatch(setUsers([user, ...data])); break;
      case FORM_TYPE.UPDATE:
        {
          const updateArr = data.map((obj) => {
            if (obj._id === user._id) {
              return user;
            }
            return obj;
          });
          dispatch(setUsers(updateArr));
        }
        break;
      case FORM_TYPE.DELETE:
        {
          const filterArr = data.filter((obj) => obj._id !== user);
          dispatch(setUsers(filterArr));
        }
        break;
      default:
        break;
    }
  };

  const tabs = [
    {
      key: 1,
      title: "Organisation",
      content: (
        <EditOrganisation
          setRequiredOrgFields={setRequiredOrgFields}
          requiredOrgFields={requiredOrgFields}
          setCurrentOrg={setCurrentOrg}
          currentOrg={currentOrg}
          setFileList={setFileList}
          fileList={fileList}
        />
      ),
      actions: [
        <Button
          type="primary"
          onClick={handleEditOrg}
          loading={updateOrgButtonLoading}
        >
          Update
        </Button>,
      ],
    },
    {
      key: 2,
      title: "Users",
      content: (
        <Users editUser={handleEditUser} handleUpdateUser={handleUpdateUser} />
      ),
      actions: [
        <Button type="primary" onClick={handleAddUser}>
          Add User
        </Button>,
      ],
    },
  ];

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return (
    <>
      <Content className="settings">
        <Row>
          <Col>
            <Card
              style={{
                minHeight: "400px",
                background: "#fff",
              }}
            >
              <Tabs tabPosition={windowDimensions.width > 768 ? "left": "top" }>
                {tabs.map((tab) => {
                  return (
                    <TabPane tab={tab.title} key={tab.key}>
                      <Title level={4} style={{ marginBottom: "20px" }}>
                        {tab.title}
                        <div style={{ float: "right" }}>
                          <Space>
                            {tab.actions.length > 0
                              ? tab.actions.map((action, index) => {
                                return <span key={index}>{action}</span>;
                              })
                              : ""}
                          </Space>
                        </div>
                      </Title>
                      {tab.content}
                    </TabPane>
                  );
                })}
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Content>
      <EditUserModal
        title={editModalTitle}
        visible={editModalVisible}
        type={editModalVisible.type}
        onCancel={() => setEditModalVisible(false)}
        handleUpdateUser={handleUpdateUser}
      />
    </>
  );
};

export default Settings;
