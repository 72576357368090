import Payments from "../../components/Payments";

const Paymentt = () => {
  return (
    <>
      <Payments />
    </>
  );
};

export default Paymentt;
