import React, { useState, useEffect } from "react";
import { Collapse, Descriptions, Typography, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ACOUNT_CODE,
  setAccountCode,
} from "../../../store/items-store/reducer";

const { Panel } = Collapse;
const { Text } = Typography;
const antIcon = (
  <LoadingOutlined
    style={{ fontSize: "40px", width: "100%", padding: "20px" }}
    spin
  />
);

const Details = ({ detailContact, initialLoading }) => {
  const dispatch = useDispatch();
  const { accountCode } = useSelector(({ items }) => items.itemReducer);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [defaultAccount, setDefaultAccount] = useState({
    sales: "",
    purchases: "",
  });

  const FETCH_DATA = () => {
    FETCH_ACOUNT_CODE(organisationId).then((data) => {
      data?.map((ele, index) => {
        if (ele?._id === detailContact?.purchasesDefaultAccountCode) {
          setDefaultAccount({
            ...defaultAccount,
            purchases: `${ele?.code} - ${ele?.name}`,
          });
        }
        if (ele?._id === detailContact?.salesDefaultAccountCode) {
          setDefaultAccount({
            ...defaultAccount,
            sales: `${ele?.code} - ${ele?.name}`,
          });
        }
      });
      dispatch(setAccountCode(data));
    });
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId, detailContact]);

  return (
    <div>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        {initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Panel header="Details" key="1">
            <Descriptions title="Contact Details" column={2}>
              <Descriptions.Item label="Phone">
                {detailContact?.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {detailContact?.emailAddress}
              </Descriptions.Item>
              <Descriptions.Item label="Postal Address">
                {detailContact?.postalStreet}
              </Descriptions.Item>
              <Descriptions.Item label="Street Address">
                {detailContact?.streetAddress}
              </Descriptions.Item>
              <Descriptions.Item label="Industry">
                {detailContact?.industry?.description}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {detailContact?.contactStatus ? "Active" : "Inactive"}
              </Descriptions.Item>
            </Descriptions>

            <Descriptions title="Financial Details" column={2}>
              <Descriptions.Item>
                <Text strong>Sales</Text>
              </Descriptions.Item>
              <Descriptions.Item>
                <Text strong>Purchases</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Default Tax">
                {detailContact?.salesDefaultLineAmountType}
              </Descriptions.Item>
              <Descriptions.Item label="Default Tax">
                {detailContact?.purchasesDefaultLineAmountType}
              </Descriptions.Item>
              <Descriptions.Item label="Default Account">
                {defaultAccount.sales}
              </Descriptions.Item>
              <Descriptions.Item label="Default Account">
                {defaultAccount.purchases}
              </Descriptions.Item>
              <Descriptions.Item>
                <Text strong>Tax</Text>
              </Descriptions.Item>
              <Descriptions.Item></Descriptions.Item>
              <Descriptions.Item label="ABN">
                {detailContact?.taxNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Default Sales GST">
                {detailContact?.accountsReceivableTaxType}
              </Descriptions.Item>
              <Descriptions.Item label="Default Purchases GST">
                {detailContact?.accountsPayableTaxType}
              </Descriptions.Item>
              <Descriptions.Item></Descriptions.Item>
              <Descriptions.Item>
                <Text strong>Bank</Text>
              </Descriptions.Item>
              <Descriptions.Item></Descriptions.Item>
              <Descriptions.Item label="Bank Number">
                {detailContact?.batchPayaments}
              </Descriptions.Item>
            </Descriptions>
          </Panel>
        )}
      </Collapse>
    </div>
  );
};

export default Details;
