import EditItems from "../../components/Items/EditItems";

const ItemsEdit = () => {
  return (
    <>
      <EditItems />
    </>
  );
};

export default ItemsEdit;
