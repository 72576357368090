import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin, message } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  NEW_VEHICLE_MODEL,
  FETCH_DETAIL_VEHICLE_MODEL,
  setDetailVehicleModel,
  CREATE_VEHICLE_MODEL,
  UPDATE_VEHICLE_MODEL,
  FETCH_ALL_VEHICLE_MODEL,
  setVehicleModel,
} from "../../store/fleet-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const DetailVehicleModel = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { models, detailModel } = useSelector(
    ({ fleets }) => fleets.fleetReducer
  );
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailModel ? detailModel._id : "new";
    const unique = models.every(
      (ele) => ele.description !== values.description
    );
    if (id === "new") {
      if (!unique) {
        message.error("Description already exists");
        setLoading(false);
      } else {
        CREATE_VEHICLE_MODEL(values)
        .then((data) => {
          setLoading(false);
          navigate("/fleet/vehicle/models");
        })
        .catch(err => {
          const errorMessage = err.data?.payload?.message;
          if (errorMessage) {
            message.error(errorMessage);
          }
          setLoading(false);
        })
      }
    } else {
      UPDATE_VEHICLE_MODEL(values).then((data) => {
        setLoading(false);
        navigate("/fleet/vehicle/models");
      });
    }
  };

  const FETCH_DATA = () => {
    setInitialLoading(true);
    FETCH_ALL_VEHICLE_MODEL(organisationId).then((data) => {
      dispatch(setVehicleModel(data));
    });
  };

  useEffect(() => {
    FETCH_DATA();
    const updateState = async () => {
      if (id === "new") {
        dispatch(NEW_VEHICLE_MODEL());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_VEHICLE_MODEL(id).then((data) => {
          dispatch(setDetailVehicleModel(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailModel) {
      form.setFields([
        {
          name: "description",
          value: detailModel.description,
        },
        {
          name: "active",
          value: detailModel.active,
        },
      ]);
    }
  }, [detailModel]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Fleet Category", path: "/fleet/vehicle/models" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/fleet/categories/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            form={form}
            requiredMark={false}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Status" name="active" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default DetailVehicleModel;
