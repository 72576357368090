import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Badge } from "antd";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_VEHICLE_MAKE,
  setVehicleMakes,
  DELETE_VEHICLE_MAKE,
} from "../../store/fleet-store/reducer";

const VehicleMakes = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const { makes } = useSelector(({ fleets }) => fleets.fleetReducer);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [dataList, setDataList] = useState([]);
  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a?.description?.localeCompare(b?.description),
    },

    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "InActive"} />;
      },
      sorter: (a, b) => b.active - a.active,
    },
  ];
  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Description",
        key: "description",
      },
      {
        label: "Model",
        key: "model",
      },
      {
        label: "Active",
        key: "active",
      },
    ],
    data: [],
    fileName: "allVehicleMakes.csv",
  });

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        description: e?.description,
        model: e?.model?.description,
        active: e?.active,
      });
    });
    return csvData;
  };

  const FETCH_DATA = () => {
    FETCH_ALL_VEHICLE_MAKE(organisationId)
      .then((data) => dispatch(setVehicleMakes(data)))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (makes) {
      const columns = handleColumnCsv(makes);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [makes]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    makes?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.description}`,
        description: `${ele?.model?.description}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [makes]);

  return (
    <div className="table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Vehicle Makes" }]}
            dataSource={makes}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_VEHICLE_MAKE}
            table="fleet/vehicle/makes"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Vehicle Makes" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_VEHICLE_MAKE}
            table="fleet/vehicle/makes"
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleMakes;
