import { Form, Input } from "antd";
import "../../../styles/teamMember.scss";

const FormEmployType = ({ award, setAward }) => {
  const [form] = Form.useForm();

  const handleChange = (e, name) => {
    const value = e.target.value;
    setAward({
      ...award,
      [name]: value,
    });
  };

  return (
    <Form
      labelAlign="left"
      requiredMark={false}
      form={form}
      labelCol={{ span: 5 }}
    >
      <Form.Item label="Title" name="title" rules={[{ required: true }]}>
        <Input onChange={(e) => handleChange(e, "title")} />
      </Form.Item>
      <Form.Item label="Code" name="code" rules={[{ required: true }]}>
        <Input onChange={(e) => handleChange(e, "code")} />
      </Form.Item>
    </Form>
  );
};

export default FormEmployType;
