import EditContact from "../../components/Contact/EditContact";

const Contact = () => {
  return (
    <>
      <EditContact />
    </>
  );
};

export default Contact;
