import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Badge } from "antd";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_FLEET_CATEGORIES,
  setCategories,
  DELETE_FLEET_CATEGORY,
} from "../../store/fleet-store/reducer";

const FleetCategory = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { categories } = useSelector(({ fleets }) => fleets.fleetReducer);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Description",
        key: "description",
      },
      {
        label: "Active",
        key: "active",
      },
    ],
    data: [],
    fileName: "allEmploymentAwards.csv",
  });
  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },

    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "InActive"} />;
      },
      sorter: (a, b) => b.active - a.active,
    },
  ];

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        description: e.code,
        active: e.active,
      });
    });
    return csvData;
  };

  const FETCH_DATA = () => {
    FETCH_ALL_FLEET_CATEGORIES(organisationId)
      .then((data) => dispatch(setCategories(data)))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (categories) {
      const columns = handleColumnCsv(categories);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [categories]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    categories?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.description}`,
        description: `${ele?.status ? "Active" : "Inactive"}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [categories]);

  return (
    <div className="table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Fleet Categories" }]}
            dataSource={categories}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_FLEET_CATEGORY}
            table="fleet/categories"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Fleet Categories" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_FLEET_CATEGORY}
            table="fleet/categories"
          />
        </div>
      </div>
    </div>
  );
};

export default FleetCategory;
