import TimeSheets from "./index";
import EditTimeSheets from "./detail";
import TableList from "./tableList";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    //employment type
    {
      path: "/payroll/payroll-summary",
      component: TimeSheets,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true
        },
      },
    },
    {
      path: "/payroll/time-sheets/:idMember/:name",
      component: TableList,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true
        },
      },
    },
    {
      path: "/payroll/time-sheets/:idMember/:currentDate/:name",
      component: EditTimeSheets,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
  ],
};

export default router;
