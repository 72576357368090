import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";

import {
  DELETE_TIME_SHEET,
  FETCH_ALL_TIME_SHEET,
  setAllTimeSheets,
} from "../../store/time-sheet-store/reducer";
import moment from "moment";

const TimeRecords = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { allTimeSheets } = useSelector(
    ({ timeSheet }) => timeSheet.TimeSheetReducer
  );
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Name",
        key: "name",
      },
      {
        label: "DateTime",
        key: "dateTime",
      },
      {
        label: "Ordinary",
        key: "totalHours",
      },
      {
        label: "Annual Leave",
        key: "annualLeaveHours",
      },
      {
        label: "Banked Hours",
        key: "bankedHours",
      },
      {
        label: "Banked Hours Balance",
        key: "bankedHourBalance",
      },
      {
        label: "Leave Without Pay",
        key: "leaveWithoutPay",
      },
      {
        label: "Over Time Hours",
        key: "overTimeHours",
      },
      {
        label: "Worked Away Hours",
        key: "workedAwayHours",
      },
    ],
    data: [],
    fileName: "allTimeSheets.csv",
  });
  const [dataList, setDataList] = useState([]);

  const columns = [
    {
      title: "Name",
      key: "Name",
      sorter: (a, b) => a?.lastName.localeCompare(b?.lastName),
      render: (data) => (
        <>
          {data?.firstName} {data?.lastName}
        </>
      ),
    },
    {
      title: "DateTime",
      key: "dateTime",
      render: (records) => {
        return records?.dateTime;
      },
      sorter: (a, b) => a?.dateTime?.localeCompare(b?.dateTime),
    },
    // {
    //   title: "Creator",
    //   key: "personCreatedRecord",
    //   render: (records) => {
    //     return records.personCreatedRecord
    //   },
    //   sorter: (a, b) =>
    //     a?.personCreatedRecord?.localeCompare(b?.personCreatedRecord),
    // },
    // {
    //   title: "Last Modified User",
    //   key: "lastPersonModifiedRecord",
    //   render: (records) => {
    //     return records.lastPersonModifiedRecord
    //   },
    //   sorter: (a, b) =>
    //     a?.lastPersonModifiedRecord?.localeCompare(b?.lastPersonModifiedRecord),
    // },
    // {
    //   title: "Created",
    //   key: "created",
    //   render: (records) => {
    //     if(records.created) {
    //       return moment(records?.created).format("DD-MMM HH:mm:ss");
    //     } return ""
    //   },
    //   sorter: (a, b) =>
    //     a?.created?.localeCompare(b?.created),
    // },
    // {
    //   title: "Updated",
    //   key: "updated",
    //   render: (records) => {
    //     if(records.created) {
    //       return moment(records?.updated).format("DD-MMM HH:mm:ss");
    //     } return ""
    //   },
    //   sorter: (a, b) =>
    //     a?.updated?.localeCompare(b?.updated),
    // },
    {
      title: "Ordinary",
      key: "totalHours",
      render: (records) => {
        return records?.totalHours?.toFixed(2);
      },
      sorter: (a, b) => b?.totalHours - a?.totalHours,
    },
    {
      title: "Annual Leave",
      key: "annualLeaveHours",
      render: (records) => {
        return records?.annualLeaveHours?.toFixed(2);
      },
      sorter: (a, b) => b?.annualLeaveHours - a?.annualLeaveHours,
    },
    {
      title: "Banked Hours",
      key: "bankedHours",
      render: (records) => {
        return records?.bankedHours?.toFixed(2);
      },
      sorter: (a, b) => b?.bankedHours - a?.bankedHours,
    },
    {
      title: "Banked Hours Balance",
      key: "bankedHourBalance",
      render: (records) => {
        return records?.bankedHourBalance?.toFixed(2);
      },
      sorter: (a, b) => b?.bankedHourBalance - a?.bankedHourBalance,
    },
    {
      title: "Leave Without Pay",
      key: "leaveWithoutPay",
      render: (records) => {
        return records?.leaveWithoutPay?.toFixed(2);
      },
      sorter: (a, b) => b?.leaveWithoutPay - a?.leaveWithoutPay,
    },
    {
      title: "Over Time Hours",
      key: "overTimeHours",
      render: (records) => {
        return records?.overTimeHours?.toFixed(2);
      },
      sorter: (a, b) => b?.overTimeHours - a?.overTimeHours,
    },
    {
      title: "Worked Away Hours",
      key: "workedAwayHours",
      render: (records) => {
        return records?.workedAwayHours?.toFixed(2);
      },

      sorter: (a, b) => b?.workedAwayHours - a?.workedAwayHours,
    },
  ];

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        name: `̀${e.firstName} ${e.lastName}`,
        dateTime: e.dateTime,
        totalHours: e.totalHours,
        annualLeaveHours: e.annualLeaveHours,
        bankedHours: e.bankedHours,
        bankedHourBalance: e.bankedHourBalance,
        leaveWithoutPay: e.leaveWithoutPay,
        overTimeHours: e.overTimeHours,
        workedAwayHours: e.workedAwayHours,
      });
    });
    return csvData;
  };

  const FETCH_DATA = () => {
    FETCH_ALL_TIME_SHEET(organisationId)
      .then((data) =>
        dispatch(
          setAllTimeSheets(
            data
              .map((d) => {
                return {
                  ...d.employee,
                  ...d.timeSheet,
                };
              })
              .map((d) => {
                return {
                  ...d,
                  dateTime: moment(d.dateTime).format("DD-MMM-YYYY"),
                };
              })
          )
        )
      )
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (allTimeSheets) {
      const columns = handleColumnCsv(allTimeSheets);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [allTimeSheets]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    allTimeSheets?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: ele?.comment,
        description: `${ele?.lastName} ${ele?.firstName} / ${ele?.dateTime}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [allTimeSheets]);

  return (
    <div className="table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hasNew={false}
            hierachy={[{ key: 1, label: "All Time Sheets" }]}
            dataSource={allTimeSheets}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_TIME_SHEET}
            table="payroll/all-records"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hasNew={false}
            hierachy={[{ key: 1, label: "All Time Sheets" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_TIME_SHEET}
            table="payroll/all-records"
          />
        </div>
      </div>
    </div>
  );
};

export default TimeRecords;
