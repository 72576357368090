import { Form, Input, Switch } from "antd";
import "../../../styles/teamMember.scss";

const FormEmployType = ({ category, setCategory }) => {
  const [form] = Form.useForm();

  const handleChange = (e, name) => {
    const value = e.target.value;
    setCategory({
      ...category,
      [name]: value,
    });
  };

  return (
    <Form
      labelAlign="left"
      form={form}
      requiredMark={false}
      labelCol={{ span: 5 }}
    >
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input onChange={(e) => handleChange(e, "name")} />
      </Form.Item>
      <Form.Item
        label="Code"
        name="code"
        rules={[
          {
            required: true,
            message: "The Code must not exceed 4 characters.",
          },
        ]}
      >
        <Input maxLength={4} onChange={(e) => handleChange(e, "code")} />
      </Form.Item>
      <Form.Item label="Status" name="status" valuePropName="checked">
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={(e) => handleChange(e, "status")}
        />
      </Form.Item>
    </Form>
  );
};

export default FormEmployType;
