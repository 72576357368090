import Bill from "./index";
import EditBill from "./detail";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    //employment type
    {
      path: "/bill",
      component: Bill,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/bill/:id/",
      component: EditBill,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
  ],
};

export default router;
