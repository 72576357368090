const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    translate: "Dashboard",
    type: "item",
    icon: "dashboard",
    url: "/dashboard",
    auth: ["admin", "user", "manager"],
  },
  {
    id: "my-zone",
    title: "My Zone",
    translate: "My Zone",
    type: "collapse",
    icon: "people",
    auth: ["admin", "user", "manager"],
    children: [
      {
        id: "time-sheets",
        title: "My Timesheet",
        translate: "My Timesheet",
        type: "item",
        url: "/time-sheets",
        auth: ["admin", "user", "manager"],
      },
    ],
  },
  {
    id: "payroll",
    title: "Payroll",
    translate: "Payroll",
    type: "collapse",
    icon: "people",
    auth: ["admin"],
    children: [
      {
        id: "pay-profiles",
        title: "Pay Profiles",
        translate: "Pay Profiles",
        type: "item",
        url: "/payroll/pay-profiles",
        auth: ["admin"],
      },
      {
        id: "payroll-summary",
        title: "Payroll Summary",
        translate: "Payroll Summary",
        type: "item",
        url: "/payroll/payroll-summary",
        auth: ["admin"],
      },
      {
        id: "all-record",
        title: "All Time Record",
        translate: "All Time Record",
        type: "item",
        url: "/payroll/all-records",
        auth: ["admin"],
      },
    ],
  },

  {
    id: "Team",
    title: "Team",
    translate: "Team",
    type: "collapse",
    icon: "people",
    auth: ["admin", "manager"],
    children: [
      {
        id: "team-member",
        title: "Team Members",
        translate: "Team Members",
        type: "item",
        url: "/team/members",
        auth: ["admin", "manager"],
      },
    ],
  },
  {
    id: "Fleet",
    title: "Fleet",
    translate: "Fleet",
    type: "collapse",
    icon: "people",
    auth: ["admin", "user", "manager"],
    children: [
      {
        id: "fleet-completed-prestart",
        title: "Completed Prestarts",
        translate: "Complete Prestart",
        type: "item",
        url: "/fleet/prestarts/completing",
        auth: ["admin", "manager"],
      },
      {
        id: "fleet-prestart",
        title: "Prestart Checklists",
        translate: "Prestart Checklists",
        type: "item",
        url: "/fleet/prestarts",
        auth: ["admin", "manager"],
      },
      {
        id: "fleet-vehicle",
        title: "Fleet Register",
        translate: "Fleet Register",
        type: "item",
        url: "/fleet/vehicle",
        auth: ["admin", "manager"],
      },
      {
        id: "fleet-issue",
        title: "Fleet Issues",
        translate: "Fleet Issues",
        type: "item",
        url: "/fleet/issues",
        auth: ["admin", "manager"],
      },
      {
        id: "fleet-completing-prestart",
        title: "Complete Prestart",
        translate: "Complete Prestart",
        type: "item",
        url: "/fleet/prestarts/completing/new",
        auth: ["admin", "user", "manager"],
      },
    ],
  },
  {
    id: "admin",
    title: "Admin",
    translate: "Admin",
    type: "collapse",
    icon: "admin",
    auth: ["admin"],
    children: [
      {
        id: "employment-type",
        title: "Employment Types",
        translate: "Employment Types",
        type: "item",
        url: "/employment/types",
        auth: ["admin"],
      },
      {
        id: "team-member-position",
        title: "Positions",
        translate: "Positions",
        type: "item",
        url: "/team/positions",
        auth: ["admin"],
      },
      {
        id: "employment-award",
        title: "Employment Awards",
        translate: "Employment Awards",
        type: "item",
        url: "/employment/awards",
        auth: ["admin"],
      },
      {
        id: "department",
        title: "Departments",
        translate: "Departments",
        type: "item",
        url: "/departments",
        auth: ["admin"],
      },
      {
        id: "organisations",
        title: "Organisations",
        translate: "Organisations",
        type: "item",
        url: "/team/organisations",
        auth: ["admin"],
      },
      {
        id: "vehicle-model",
        title: "Vehicle Models",
        translate: "Vehicle Models",
        type: "item",
        url: "/fleet/vehicle/models",
        auth: ["admin"],
      },
      {
        id: "vehicle-make",
        title: "Vehicle Makes",
        translate: "Vehicle Makes",
        type: "item",
        url: "/fleet/vehicle/makes",
        auth: ["admin"],
      },
      {
        id: "fleet-categories",
        title: "Fleet Categories",
        translate: "Fleet Categories",
        type: "item",
        url: "/fleet/categories",
        auth: ["admin"],
      },
      {
        id: "accountCode",
        title: "Chart of Accounts",
        translate: "Chart of Accounts",
        type: "item",
        url: "/chart_of_accounts",
        auth: ["admin"],
      },
      {
        id: "reports",
        title: "Reports",
        translate: "Reports",
        type: "item",
        url: "/reports",
        auth: ["admin"],
      },
    ],
  },
  {
    id: "documents",
    title: "Documents",
    translate: "Documents",
    type: "collapse",
    icon: "documents",
    auth: ["admin", "user", "manager"],
    children: [
      {
        id: "Document",
        title: "Documents",
        translate: "Document",
        type: "item",
        url: "/documents",
        auth: ["admin", "manager"],
      },
      {
        id: "Documentcategories",
        title: "Document Categories",
        translate: "Document Categories",
        type: "item",
        url: "/documents/categories",
        auth: ["admin", "manager"],
      },
      {
        id: "DocumentRegister",
        title: "Document Register",
        translate: "Document Register",
        type: "item",
        url: "/documents/register",
        auth: ["admin", "manager"],
      },
      {
        id: "CompanyPolicies",
        title: "Company Policies",
        translate: "Company Policies",
        type: "item",
        url: "/documents/policies",
        auth: ["admin", "user", "manager"],
      },
      {
        id: "SafetyDataSheets",
        title: "Safety Data Sheets",
        translate: "Safety Data Sheets",
        type: "item",
        url: "/documents/safety-data-sheets",
        auth: ["admin", "user", "manager"],
      },
      {
        id: "SafeWorkMethodStatements",
        title: "Safe Work Method Statements",
        translate: "Safe Work Method Statements",
        type: "item",
        url: "/documents/safety-work-method-statements",
        auth: ["admin", "user", "manager"],
      },
      {
        id: "SafeWorkProcedures",
        title: "Safe Work Procedures",
        translate: "Safe Work Procedures",
        type: "item",
        url: "/documents/safety-work-procedures",
        auth: ["admin", "user", "manager"],
      },
      // {
      //   id: "QualificationsTraining",
      //   title: "Qualifications & Training",
      //   translate: "Qualifications & Training",
      //   type: "item",
      //   url: "/documents/qualification-and-training",
      //   auth: ["admin", "user"],
      // },
    ],
  },
  {
    id: "items",
    title: "Items",
    translate: "Items",
    type: "collapse",
    icon: "people",
    auth: ["admin", "manager"],
    children: [
      {
        id: "item",
        title: "Items",
        translate: "Items",
        type: "item",
        url: "/items",
        auth: ["admin", "manager"],
      },
      {
        id: "category",
        title: "Item Category",
        translate: "Item Category",
        type: "item",
        url: "/items/category",
        auth: ["admin", "manager"],
      },
    ],
  },
  {
    id: "contact",
    title: "Contact",
    translate: "Contact",
    type: "collapse",
    icon: "people",
    auth: ["admin", "manager"],
    children: [
      {
        id: "contacts",
        title: "Contacts",
        translate: "Contacts",
        type: "item",
        url: "/contact",
        auth: ["admin", "manager"],
      },
      {
        id: "industry",
        title: "Industry",
        translate: "Industry",
        type: "item",
        url: "/contact/industry",
        auth: ["admin", "manager"],
      },
    ],
  },
  // {
  //   id: "purchases",
  //   title: "Purchases",
  //   translate: "Purchases",
  //   type: "collapse",
  //   icon: "people",
  //   auth: ["admin", "user", "manager"],
  //   children: [
  //     {
  //       id: "purchases",
  //       title: "Purchases",
  //       translate: "Purchases",
  //       type: "item",
  //       icon: "people",
  //       url: "/purchase",
  //       auth: ["admin", "user"],
  //     },
  //     {
  //       id: "bills",
  //       title: "Bill",
  //       translate: "Bill",
  //       type: "item",
  //       icon: "people",
  //       url: "/bill",
  //       auth: ["admin", "user"],
  //     },
  //   ],
  // },
  // {
  //   id: "invoice",
  //   title: "Invoice",
  //   translate: "Invoice",
  //   type: "item",
  //   icon: "people",
  //   url: "/invoice",
  //   auth: ["admin", "user"],
  // },
  // {
  //   id: "quote",
  //   title: "Quote",
  //   translate: "Quote",
  //   type: "item",
  //   icon: "people",
  //   url: "/quote",
  //   auth: ["admin", "user"],
  // },
  // {
  //   id: "receipt",
  //   title: "Receipt",
  //   translate: "Receipt",
  //   type: "item",
  //   icon: "people",
  //   url: "/receipt",
  //   auth: ["admin", "user"],
  // },
];

export default navigationConfig;
