import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Badge } from "antd";
import StandardTableTabPaneLayout from "../../layouts/StandardTableTabPaneLayout/StandardTableTabPaneLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_INVOICE,
  setInvoices,
  DELETING_INVOICE,
} from "../../store/invoice-store/reducer";
import { CheckCircleOutlined } from "@ant-design/icons";
import { INVOICE_STATUS } from "../../constants";

const InvoiceModule = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { invoices } = useSelector(({ invoice }) => invoice.invoiceReducer);
  const [filterAccount, setFilterAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [dataList, setDataList] = useState([]);

  const [tabSort, setTabSort] = useState({
    tabs: [
      { type: "All" },
      { type: "Draft", length: 0 },
      { type: "Awaiting Approval", length: 0 },
      { type: "Awaiting Payment", length: 0 },
      { type: "Paid", length: 0 },
      { type: "Repeating", length: 0 },
    ],
    tabChoosed: "All",
  });

  const [csv, setCsv] = useState({
    headers: [
      {
        label: "contact",
        key: "contact",
      },
      {
        label: "date",
        key: "date",
      },
      {
        label: "deliveryDate",
        key: "deliveryDate",
      },
      {
        label: "reference",
        key: "reference",
      },
      {
        label: "sentToContact",
        key: "sentToContact",
      },
      {
        label: "Status",
        key: "status",
      },
    ],
    data: [],
    fileName: "allBills.csv",
  });

  const columns = [
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (contact) => contact?.name,
      sorter: (a, b) => a.contact.localeCompare(b.contact),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Sent To Contact",
      dataIndex: "sentToContact",
      key: "sentToContact",
      render: (sentToContact) => {
        return (
          <>
            {sentToContact ? (
              <span
                style={{
                  color: "rgb(33, 186, 69)",
                  fontSize: "12px",
                  marginLeft: 7,
                }}
              >
                <CheckCircleOutlined color="#21ba45" /> Sent
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
      sorter: (a, b) => a.sentToContact - b.sentToContact,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return status;
      },
      sorter: (a, b) => b.status - a.status,
    },
  ];

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        contact: e.contact,
        date: e.date,
        dueDate: e.dueDate,
        reference: e.reference,
        status: e.status,
      });
    });
    return csvData;
  };

  const FETCH_DATA = () => {
    FETCH_ALL_INVOICE(organisationId)
      .then((data) => dispatch(setInvoices(data)))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (invoices) {
      const columns = handleColumnCsv(invoices);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [invoices]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    let filterCode = [];
    let filterCodeResponsive = [];

    invoices?.forEach((ele) => {
      let filterResponsive = {
        _id: ele?._id,
        title: `${ele?.contact?.name}`,
        description: `${ele?.reference}`,
      };

      if (tabSort?.tabChoosed?.toLowerCase() !== "all") {
        if (tabSort?.tabChoosed?.toLowerCase() === ele?.status?.toLowerCase()) {
          filterCode.push(ele);
          filterCodeResponsive.push(filterResponsive);
        }
      } else {
        filterCode = [...invoices];
        filterCodeResponsive.push(filterResponsive);
      }
    });

    setFilterAccount(filterCode);
    setDataList(filterCodeResponsive);
  }, [invoices, tabSort]);

  useEffect(() => {
    const drafts = [];
    const approves = [];
    const payments = [];
    const paid = [];
    const repeat = [];

    invoices.map((invoice) => {
      if (invoice.status.toUpperCase() === INVOICE_STATUS.DRAFT) {
        drafts.push(invoice);
      } else if (invoice.status.toUpperCase() === INVOICE_STATUS.SUBMITTED) {
        approves.push(invoice);
      } else if (invoice.status.toUpperCase() === INVOICE_STATUS.AUTHORISED) {
        payments.push(invoice);
      } else if (invoice.status.toUpperCase() === INVOICE_STATUS.PAID) {
        paid.push(invoice);
      } else if (invoice.status.toUpperCase() === INVOICE_STATUS.VOIDED) {
        repeat.push(invoice);
      }
    });
    setTabSort({
      ...tabSort,
      tabs: [
        { type: "All" },
        { type: "Draft", length: drafts?.length },
        { type: "Awaiting Approval", length: approves?.length },
        { type: "Awaiting Payment", length: payments?.length },
        { type: "Paid", length: paid?.length },
        { type: "Repeating", length: repeat?.length },
      ],
    });
  }, [invoices]);

  return (
    <div className="team_member">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableTabPaneLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Invoice" }]}
            dataSource={filterAccount}
            tabSort={tabSort}
            setTabSort={setTabSort}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_INVOICE}
            table="invoice"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Invoice" }]}
            dataList={dataList}
            tabSort={tabSort}
            setTabSort={setTabSort}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_INVOICE}
            table="invoice"
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceModule;
