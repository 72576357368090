import Receipt from "./index";
import EditReceipt from "./detail";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    //employment type
    {
      path: "/receipt",
      component: Receipt,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/receipt/:id/",
      component: EditReceipt,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
  ],
};

export default router;
