import FleetCategory from "../../../components/FleetCategories";

const Categories = () => {
  return (
    <>
      <FleetCategory />
    </>
  );
};

export default Categories;
