import FleetPrestarts from "../../components/FleetPreStart";

const FleetPreStartPage = () => {
  return (
    <>
      <FleetPrestarts />
    </>
  );
};

export default FleetPreStartPage;
