/* eslint-disable jsx-a11y/anchor-is-valid */
// style for UI
import { Button } from "antd";
const styleMenu = {
  background: "#fff",
  padding: "5px 10px",
  cursor: "pointer",
  borderBottom: "1px solid #0000000d",
};

// Open position menu right click
export const handleOpenContextMenu = (e, record, setContextMenu) => {
  e.preventDefault();
  setContextMenu({
    visible: true,
    xPos: `${e?.pageX}px`,
    yPos: `${e?.pageY}px`,
    record: record,
  });
};

// Show UI menu context
export const ContextMenu = ({ optionMenu, contextMenu }) => {
  
  return (
    <>
      {contextMenu?.visible && (
        <div
          style={{
            position: "absolute",
            left: contextMenu?.xPos,
            top: contextMenu?.yPos,
            zIndex: 9999,
            lineHeight: "1.8",
            boxShadow: "0 2px 8px rgb(0 0 0 / 15%)",
          }}
        >
          {optionMenu?.map((opt, index) => (
            <div style={styleMenu} key={index} onClick={opt?.onToogleMenu}>
              <Button type="text" style={{ color: "#000" }} loading={contextMenu?.isLoading}>{opt?.title}</Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
