import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Authorization = (props) => {
  const { user } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!user || (user && (!user.role || user.role.length === 0))) {
      if (
        pathname.includes("/password_change") ||
        pathname.includes("/reset_password") ||
        pathname.includes("/legal/privacy-policy") ||
        pathname.includes("/legal/tos")
      ) {
        navigate({ to: pathname, pathname: pathname }, { replace: true });
      } else {
        navigate({ to: "/login", pathname: "/login" }, { replace: true });
      }
    } else {
      if (pathname === "/login") {
        navigate({ to: "dashboard", pathname: "dashboard" }, { replace: true });
      } else {
        navigate({ to: pathname, pathname: pathname }, { replace: true });
      }
    }
  }, [user, pathname]);

  return props.children;
};

export default Authorization;
