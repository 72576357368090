import EditItemAccountCode from "../../../components/ItemChartOfAccounts/EditChartOfAccountsEdit";

const ItemsEdit = () => {
  return (
    <>
      <EditItemAccountCode />
    </>
  );
};

export default ItemsEdit;
