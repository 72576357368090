import React, { useState } from "react";
import { Form, Space, Button, Dropdown, Modal, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import StandardCardLayout from "../layouts/StandardCardLayout/StandardCardLayout";
import { INVOICE_STATUS, LINE_ITEM_TABLE_TYPE } from "../constants";
import {
  DELETING_PURCHASE,
  FETCH_ALL_PURCHASE,
} from "../store/contact-store/reducer";
import {
  CREATING_BILL,
  DELETING_BILL,
  FETCH_ALL_BILL,
} from "../store/bill-store/reducer";
import { useSelector } from "react-redux";
import { DOWNLOAD_GOOGLE_CLOUD_FILE } from "../store/bill-store/reducer";
import EmailModal from "./modals/EmailModal";
import ConvertBillModal from "./modals/ConvertBillModal";

const InvoiceCardWithAction = ({
  type,
  disabled,
  loading,
  xeroPath,
  setLoading,
  currentData,
  setStatus,
  title,
  hierachy,
  editPath,
  UPDATING_FUNC,
  form,
  totalData,
  lineItems,
}) => {
  let navigate = useNavigate();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [billModal, setBillModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const handleDeleteInvoice = async () => {
    setLoading({
      ...loading,
      deleted: true,
    });
    try {
      switch (type) {
        case LINE_ITEM_TABLE_TYPE.PURCHASE_ORDER:
          DELETING_PURCHASE(`ids=${currentData._id}`).then((data) => {
            FETCH_ALL_PURCHASE(organisationId);
            navigate("/purchase");
            setLoading({
              ...loading,
              deleted: true,
            });
          });
          break;

        case LINE_ITEM_TABLE_TYPE.BILL:
          DELETING_BILL(`ids=${currentData._id}`).then((data) => {
            FETCH_ALL_BILL(organisationId);
            navigate("/bill");
            setLoading({
              ...loading,
              deleted: true,
            });
          });
          break;

        case LINE_ITEM_TABLE_TYPE.INVOICE:
          DELETING_BILL(`ids=${currentData._id}`).then((data) => {
            FETCH_ALL_BILL(organisationId);
            navigate("/invoice");
            setLoading({
              ...loading,
              deleted: true,
            });
          });
          break;

        case LINE_ITEM_TABLE_TYPE.QUOTE:
          DELETING_BILL(`ids=${currentData._id}`).then((data) => {
            FETCH_ALL_BILL(organisationId);
            navigate("/quote");
            setLoading({
              ...loading,
              deleted: true,
            });
          });
          break;
        default:
          break;
      }
    } catch (err) {}
  };

  const handleVoidedInvoice = async () => {
    setLoading({
      ...loading,
      voided: true,
    });
    try {
      switch (type) {
        case LINE_ITEM_TABLE_TYPE.BILL:
          {
            const updateRecord = {
              status: INVOICE_STATUS.VOIDED,
              _id: currentData?._id,
            };
            UPDATING_FUNC(updateRecord).then((data) => {
              setLoading({
                approved: false,
                draft: false,
                submitted: false,
                voided: false,
                deleted: false,
              });
              navigate("/bill");
            });
          }
          break;

        case LINE_ITEM_TABLE_TYPE.INVOICE:
          {
            const updateRecord = {
              status: INVOICE_STATUS.VOIDED,
              _id: currentData?._id,
            };
            UPDATING_FUNC(updateRecord).then((data) => {
              setLoading({
                approved: false,
                draft: false,
                submitted: false,
                voided: false,
                deleted: false,
              });
              navigate("/invoice");
            });
          }
          break;

        // case LINE_ITEM_TABLE_TYPE.QUOTE: {
        //     const updateRecord = {
        //         status: INVOICE_STATUS.VOIDED,
        //         _id: currentData?._id,
        //     };
        //     UPDATING_FUNC(updateRecord).then((data) => {
        //         setLoading({
        //             approved: false,
        //             draft: false,
        //             submitted: false,
        //             voided: false,
        //             deleted: false,
        //         });
        //         navigate("/invoice");
        //     });
        // }; break;
        default:
          break;
      }
    } catch (err) {}
  };

  // [Handle] Convert to bill
  const handleConvertToBilled = () => {
    setBillModal(!billModal);
  };

  const onDownload = async () => {
    try {
      const file = JSON.parse(currentData.image);
      const name = file.name;
      await DOWNLOAD_GOOGLE_CLOUD_FILE(name);
    } catch (err) {
      console.log(err);
    }
  };

  // [handle] convert quote status
  const handleConvertToQuoteStatus = async (status) => {
    switch (status) {
      case INVOICE_STATUS.ACCEPTED:
        {
          const updateRecord = {
            status: INVOICE_STATUS.ACCEPTED,
            _id: currentData?._id,
            contactXeroCode: currentData.contact?.xeroId,
            date: currentData.date,
          };
          UPDATING_FUNC(updateRecord).then((data) => {
            setLoading({
              approved: false,
              draft: false,
              submitted: false,
              voided: false,
              deleted: false,
            });
            navigate("/quote");
          });
        }
        break;
      case INVOICE_STATUS.DECLINED:
        {
          const updateRecord = {
            status: INVOICE_STATUS.DECLINED,
            _id: currentData?._id,
            contactXeroCode: currentData.contact?.xeroId,
            date: currentData.date,
          };
          UPDATING_FUNC(updateRecord).then((data) => {
            setLoading({
              approved: false,
              draft: false,
              submitted: false,
              voided: false,
              deleted: false,
            });
            navigate("/quote");
          });
        }
        break;
      case INVOICE_STATUS.SENT:
        {
          const updateRecord = {
            status: INVOICE_STATUS.SENT,
            _id: currentData?._id,
            contactXeroCode: currentData.contact?.xeroId,
            date: currentData.date,
          };
          UPDATING_FUNC(updateRecord).then((data) => {
            setLoading({
              approved: false,
              draft: false,
              submitted: false,
              voided: false,
              deleted: false,
            });
            navigate("/quote");
          });
        }
        break;
      default:
        break;
    }
  };

  const renderExtraAction = () => {
    if (currentData && currentData._id !== "new") {
      switch (type) {
        case LINE_ITEM_TABLE_TYPE.PURCHASE_ORDER:
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="text"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        window.open(xeroPath, "_blank");
                      }}
                    >
                      Open in Xero
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      type="text"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setEmailModal(true);
                      }}
                    >
                      Email
                    </Button>
                  </Menu.Item>
                  {currentData && currentData.image && (
                    <Menu.Item>
                      <Button
                        type="text"
                        style={{
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          onDownload();
                        }}
                      >
                        DownLoad
                      </Button>
                    </Menu.Item>
                  )}
                  {currentData &&
                  (currentData.status === INVOICE_STATUS.DRAFT ||
                    currentData.status === INVOICE_STATUS.SUBMITTED) ? (
                    <Menu.Item>
                      <Button
                        type="text"
                        onClick={() =>
                          Modal.confirm({
                            title: "Are you sure?",
                            content: (
                              <div className="modal-text">
                                <p>This action cannot be undone.</p>
                              </div>
                            ),
                            onOk: () => handleDeleteInvoice(),
                          })
                        }
                        loading={loading.deleted}
                      >
                        Delete
                      </Button>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  <Menu.Item>
                    <Button
                      type="text"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={handleConvertToBilled}
                    >
                      Convert to bill
                    </Button>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button>Options</Button>
            </Dropdown>
          );
        case LINE_ITEM_TABLE_TYPE.BILL:
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="text"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        window.open(xeroPath, "_blank");
                      }}
                    >
                      Open in Xero
                    </Button>
                  </Menu.Item>
                  {currentData && currentData.image && (
                    <Menu.Item>
                      <Button
                        type="text"
                        style={{
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          onDownload();
                        }}
                      >
                        DownLoad
                      </Button>
                    </Menu.Item>
                  )}
                  {currentData &&
                  (currentData.status === INVOICE_STATUS.DRAFT ||
                    currentData.status === INVOICE_STATUS.SUBMITTED) ? (
                    <Menu.Item>
                      <Button
                        type="text"
                        onClick={() =>
                          Modal.confirm({
                            title: "Are you sure?",
                            content: (
                              <div className="modal-text">
                                <p>This action cannot be undone.</p>
                              </div>
                            ),
                            onOk: () => handleDeleteInvoice(),
                          })
                        }
                        loading={loading.deleted}
                      >
                        Delete
                      </Button>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {currentData &&
                  currentData.status === INVOICE_STATUS.AUTHORISED ? (
                    <Menu.Item>
                      <Button
                        type="text"
                        onClick={() =>
                          Modal.confirm({
                            title: "Are you sure?",
                            content: (
                              <div className="modal-text">
                                <p>This action cannot be undone.</p>
                              </div>
                            ),
                            onOk: () => handleVoidedInvoice(),
                          })
                        }
                        loading={loading.voided}
                      >
                        Void
                      </Button>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </Menu>
              }
            >
              <Button>Options</Button>
            </Dropdown>
          );
        case LINE_ITEM_TABLE_TYPE.INVOICE:
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="text"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        window.open(xeroPath, "_blank");
                      }}
                    >
                      Open in Xero
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      type="text"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setEmailModal(true);
                      }}
                    >
                      Email
                    </Button>
                  </Menu.Item>
                  {currentData && currentData.image && (
                    <Menu.Item>
                      <Button
                        type="text"
                        style={{
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          onDownload();
                        }}
                      >
                        DownLoad
                      </Button>
                    </Menu.Item>
                  )}
                  {currentData &&
                  (currentData.status === INVOICE_STATUS.DRAFT ||
                    currentData.status === INVOICE_STATUS.SUBMITTED) ? (
                    <Menu.Item>
                      <Button
                        type="text"
                        onClick={() =>
                          Modal.confirm({
                            title: "Are you sure?",
                            content: (
                              <div className="modal-text">
                                <p>This action cannot be undone.</p>
                              </div>
                            ),
                            onOk: () => handleDeleteInvoice(),
                          })
                        }
                        loading={loading.deleted}
                      >
                        Delete
                      </Button>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {currentData &&
                  currentData.status === INVOICE_STATUS.AUTHORISED ? (
                    <Menu.Item>
                      <Button
                        type="text"
                        onClick={() =>
                          Modal.confirm({
                            title: "Are you sure?",
                            content: (
                              <div className="modal-text">
                                <p>This action cannot be undone.</p>
                              </div>
                            ),
                            onOk: () => handleVoidedInvoice(),
                          })
                        }
                        loading={loading.voided}
                      >
                        Void
                      </Button>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </Menu>
              }
            >
              <Button>Options</Button>
            </Dropdown>
          );
        case LINE_ITEM_TABLE_TYPE.QUOTE:
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="text"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        window.open(xeroPath, "_blank");
                      }}
                    >
                      Open in Xero
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      type="text"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setEmailModal(true);
                      }}
                    >
                      Email
                    </Button>
                  </Menu.Item>
                  {currentData.status === INVOICE_STATUS.SENT ? (
                    <>
                      <Menu.Item>
                        <Button
                          type="text"
                          style={{
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            handleConvertToQuoteStatus(INVOICE_STATUS.ACCEPTED);
                          }}
                        >
                          Mark As Accepted
                        </Button>
                      </Menu.Item>
                      <Menu.Item>
                        <Button
                          type="text"
                          style={{
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            handleConvertToQuoteStatus(INVOICE_STATUS.DECLINED);
                          }}
                        >
                          Mark As Declined
                        </Button>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {currentData.status === INVOICE_STATUS.ACCEPTED ? (
                    <>
                      <Menu.Item>
                        <Button
                          type="text"
                          style={{
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            handleConvertToQuoteStatus(INVOICE_STATUS.SENT);
                          }}
                        >
                          Unmark As Accepted
                        </Button>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {currentData.status === INVOICE_STATUS.DECLINED ? (
                    <>
                      <Menu.Item>
                        <Button
                          type="text"
                          style={{
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            handleConvertToQuoteStatus(INVOICE_STATUS.SENT);
                          }}
                        >
                          Unmark As Declined
                        </Button>
                      </Menu.Item>
                    </>
                  ) : (
                    ""
                  )}
                </Menu>
              }
            >
              <Button>Options</Button>
            </Dropdown>
          );
        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  };

  const renderActions = () => {
    if (currentData && !disabled) {
      if (
        type === LINE_ITEM_TABLE_TYPE.PURCHASE_ORDER ||
        type === LINE_ITEM_TABLE_TYPE.BILL ||
        type === LINE_ITEM_TABLE_TYPE.INVOICE
      ) {
        switch (currentData.status) {
          case INVOICE_STATUS.DRAFT: {
            return [
              <div
                style={{
                  display: "flex",
                  margin: "0 10px",
                }}
              >
                <Space>
                  <Form.Item className="mg-0">
                    <Button
                      htmlType="submit"
                      type="secondary"
                      loading={loading.draft}
                      onClick={() => {
                        setLoading({
                          ...loading,
                          draft: true,
                        });
                        setStatus(INVOICE_STATUS.DRAFT);
                      }}
                    >
                      Save as draft
                    </Button>
                  </Form.Item>
                  <Form.Item className="mg-0">
                    <Button
                      htmlType="submit"
                      type="secondary"
                      loading={loading.submitted}
                      onClick={() => {
                        setLoading({
                          ...loading,
                          submitted: true,
                        });
                        setStatus(INVOICE_STATUS.SUBMITTED);
                      }}
                    >
                      Save &amp; submit for approval
                    </Button>
                  </Form.Item>
                </Space>
              </div>,
              <Form.Item
                style={{ float: "right", margin: 0, marginRight: "25px" }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit_btn"
                  loading={loading.approved}
                  onClick={() => {
                    setLoading({
                      ...loading,
                      approved: true,
                    });
                    setStatus(INVOICE_STATUS.AUTHORISED);
                  }}
                >
                  Approve
                </Button>
              </Form.Item>,
            ];
          }
          case INVOICE_STATUS.SUBMITTED: {
            return [
              <div
                style={{
                  display: "flex",
                  margin: "0 10px",
                }}
              >
                <Form.Item className="mg-0">
                  <Button
                    htmlType="submit"
                    type="secondary"
                    loading={loading.submitted}
                    onClick={() => {
                      setStatus(INVOICE_STATUS.SUBMITTED);
                      setLoading({
                        ...loading,
                        submitted: true,
                      });
                    }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>,
              <Form.Item
                style={{ float: "right", margin: 0, marginRight: "25px" }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit_btn"
                  loading={loading.approved}
                  onClick={() => {
                    setStatus(INVOICE_STATUS.AUTHORISED);
                    setLoading({
                      ...loading,
                      approved: true,
                    });
                  }}
                >
                  Approve
                </Button>
              </Form.Item>,
            ];
          }
          case INVOICE_STATUS.AUTHORISED: {
            return [
              <div
                style={{
                  display: "flex",
                  margin: "0 10px",
                }}
              >
                <Form.Item className="mg-0">
                  <Button
                    htmlType="submit"
                    type="secondary"
                    loading={loading.approved}
                    onClick={() => {
                      setStatus(INVOICE_STATUS.AUTHORISED);
                      setLoading({
                        ...loading,
                        approved: true,
                      });
                    }}
                  >
                    Update
                  </Button>
                </Form.Item>
              </div>,
            ];
          }
        }
      }

      if (type === LINE_ITEM_TABLE_TYPE.QUOTE) {
        switch (currentData.status) {
          case INVOICE_STATUS.DRAFT: {
            return [
              <div
                style={{
                  display: "flex",
                  margin: "0 10px",
                }}
              >
                <Space>
                  <Form.Item className="mg-0">
                    <Button
                      htmlType="submit"
                      type="secondary"
                      loading={loading.draft}
                      onClick={() => {
                        setLoading({
                          ...loading,
                          draft: true,
                        });
                        setStatus(INVOICE_STATUS.DRAFT);
                      }}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Space>
              </div>,
            ];
          }
          default: {
            return [
              <div
                style={{
                  display: "flex",
                  margin: "0 10px",
                }}
              >
                <Space>
                  <Form.Item className="mg-0">
                    <Button
                      htmlType="submit"
                      type="secondary"
                      loading={loading.draft}
                      onClick={() => {
                        setLoading({
                          ...loading,
                          draft: true,
                        });
                      }}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Space>
              </div>,
            ];
          }
        }
      }
    }
  };

  return (
    <>
      <StandardCardLayout
        title={
          <>
            {title}
            {currentData?.sentToContact ? (
              <span
                style={{
                  color: "rgb(33, 186, 69)",
                  fontSize: "12px",
                  marginLeft: 7,
                }}
              >
                <CheckCircleOutlined color="#21ba45" /> Sent
              </span>
            ) : (
              ""
            )}
          </>
        }
        detail={currentData}
        hierachy={hierachy}
        editPath={editPath}
        form={form}
        extra={renderExtraAction()}
        actions={renderActions()}
      />

      {/* modal */}
      {emailModal && (
        <EmailModal
          visible={emailModal}
          setVisible={setEmailModal}
          data={currentData}
          type={type}
          hierachy={hierachy}
          totalData={totalData}
        />
      )}
      {billModal && (
        <ConvertBillModal
          visible={billModal}
          setVisible={setBillModal}
          data={currentData}
          setLoading={setLoading}
          datalineItems={lineItems}
        />
      )}
    </>
  );
};

export default InvoiceCardWithAction;
