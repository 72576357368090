import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import StandardListLayout from "../../layouts/StandardListTimeSheetLayout";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  FETCH_TOTAL_TIME_SHEET,
  setTotalOrdinaryMember,
} from "../../store/time-sheet-store/reducer";
import { FETCH_PAYMENT_PROFILE_BY_TEAMMEMBER } from "../../store/payment-store/reducer";
import {
  FETCH_DETAIL_TRANSACTION_BY_TEAM_MEMBER,
  setDetailTransaction,
} from "../../store/common-store/reducer";
import TimesheetCommentView from "../TimesheetComments/CommentView";

const TimeSheets = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { organisationId, _id, teamMemberId, role } = useSelector(
    ({ auth }) => auth.user
  );
  const { detailTransaction } = useSelector(
    ({ common }) => common.commonReducer
  );
  const [comments, setComments] = useState([]);
  const date = moment().toDate();
  const now = moment().locale("en-AU");
  const setDay = (normalDate, conditionDate) => {
    // t5 -> t7
    if (date.getDay() >= 4 && date.getDay() <= 6) {
      return conditionDate;
    } else {
      return normalDate;
    }
  };

  const thursday = setDay(
    now.clone().weekday(1).subtract(4, "days").format("DD-MMM-YYYY"),
    now.clone().weekday(1).add(3, "days").format("DD-MMM-YYYY")
  );

  const friday = setDay(
    now.clone().weekday(1).subtract(3, "days").format("DD-MMM-YYYY"),
    now.clone().weekday(1).add(4, "days").format("DD-MMM-YYYY")
  );
  const saturday = setDay(
    now.clone().weekday(1).subtract(2, "days").format("DD-MMM-YYYY"),
    now.clone().weekday(1).add(5, "days").format("DD-MMM-YYYY")
  );
  const sunday = setDay(
    now.clone().weekday(1).subtract(1, "days").format("DD-MMM-YYYY"),
    now.clone().weekday(1).add(6, "days").format("DD-MMM-YYYY")
  );
  const monday = setDay(
    now.clone().weekday(1).format("DD-MMM-YYYY"),
    now.clone().weekday(1).add(7, "days").format("DD-MMM-YYYY")
  );
  const tuesday = setDay(
    now.clone().weekday(1).add(1, "days").format("DD-MMM-YYYY"),
    now.clone().weekday(1).add(8, "days").format("DD-MMM-YYYY")
  );
  const wednesday = setDay(
    now.clone().weekday(1).add(2, "days").format("DD-MMM-YYYY"),
    now.clone().weekday(1).add(9, "days").format("DD-MMM-YYYY")
  );

  const [dateData, setDateData] = useState([
    {
      _id: thursday,
      dateTime: thursday,
      dateText: "Thursday",
      totalHours: 0,
    },
    {
      _id: friday,
      dateTime: friday,
      dateText: "Friday",
      totalHours: 0,
    },
    {
      _id: saturday,
      dateTime: saturday,
      dateText: "Saturday",
      totalHours: 0,
    },
    {
      _id: sunday,
      dateTime: sunday,
      dateText: "Sunday",
      totalHours: 0,
    },
    { _id: monday, dateTime: monday, dateText: "Monday", totalHours: 0 },
    {
      _id: tuesday,
      dateTime: tuesday,
      dateText: "Tuesday",
      totalHours: 0,
    },
    {
      _id: wednesday,
      dateTime: wednesday,
      dateText: "Wednesday",
      totalHours: 0,
    },
  ]);

  const convertReduce = (value) => {
    const initialValue = 0;
    const sum = value?.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
    return sum;
  };

  const FETCH_TOTAL = async () => {
    setIsLoading(true);
    const payrollProfile = await FETCH_PAYMENT_PROFILE_BY_TEAMMEMBER(
      organisationId,
      teamMemberId
    );

    const data = await FETCH_TOTAL_TIME_SHEET({
      memberId: teamMemberId,
      organisationId,
      query: moment().format("DD-MMM-YYYY"),
    });
    if (data) {
      let overtime150 = 0.0;
      let overtime200 = 0.0;
      let ordinaryHourBonus = 0;
      const arrDate = [];
      const matchDate = [];
      const cms = [];
      dateData?.map((d) => {
        data?.map((f) => {
          if (d.dateTime === moment(f.dateTime).format("DD-MMM-YYYY")) {
            cms.push(...f.comments);
            let eachOrdinaryHours = f.totalHours;
            let eachOvertime150 = 0.0;
            let eachOvertime200 = 0.0;
            let bankedHours = 0.0;
            let paidLunch = 0.0;
            let bonus = 0.0;
            if (payrollProfile?.overtimeUsed) {
              if (payrollProfile.overtime.calculated === "daily") {
                eachOvertime150 = 0.0;
                if (
                  eachOrdinaryHours > payrollProfile?.overtime?.overtime150after
                )
                  eachOvertime150 =
                    eachOvertime150 +
                    (eachOrdinaryHours -
                      payrollProfile?.overtime?.overtime150after);
                eachOrdinaryHours = eachOrdinaryHours - eachOvertime150;
              }
            }
            if (
              payrollProfile?.paidLunch &&
              eachOrdinaryHours >= payrollProfile?.payLunchWhenHoursExceed
            ) {
              paidLunch = 0.5;
            }

            if (payrollProfile?.bonusHoursUsed) {
              if (eachOrdinaryHours > payrollProfile?.payBonusWhenHoursExceed) {
                bonus =
                  eachOrdinaryHours - payrollProfile?.payBonusWhenHoursExceed;
              }
            }

            if (f?.bankedHoursAction) {
              if (f?.bankedHoursAction === "deposit") {
                bankedHours = f?.bankedHours;
              } else {
                bankedHours = f?.bankedHours * -1;
              }
            }

            overtime150 += eachOvertime150;
            overtime200 += eachOvertime200;
            let totalHours = f.totalHours;
            arrDate.push({
              ...d,
              bankedHourBalance: f.bankedHourBalance,
              bankedHours,
              leaveWithoutPay: f.leaveWithoutPay,
              personalLeaveHours: f.personalLeaveHours,
              annualLeaveHours: f.annualLeaveHours,
              totalHours: totalHours,
              ordinaryHours: eachOrdinaryHours,
              overtime150: eachOvertime150,
              overtime200: eachOvertime200,
              paidLunch,
              bonus,
              startTime: f.startTime
                ? moment(f.startTime).format("HH:mm")
                : "00:00",
              startBreak: f.startBreak
                ? moment(f.startBreak).format("HH:mm")
                : "00:00",
              finishBreak: f.finishBreak
                ? moment(f.finishBreak).format("HH:mm")
                : "00:00",
              finishTime: f.finishTime
                ? moment(f.finishTime).format("HH:mm")
                : "00:00",
            });
            matchDate.push(d.dateTime);
          }
        });
        if (matchDate.indexOf(d.dateTime) === -1) {
          arrDate.push({
            ...d,
            ordinaryHours: 0,
            overtime150: 0,
            bankedHourBalance: 0,
            bankedHours: 0,
            paidLunch: 0,
            bonus: 0,
            leaveWithoutPay: 0,
            personalLeaveHours: 0,
            annualLeaveHours: 0,
          });
        }
      });

      // handle comments
      const sortedArray = cms.sort(
        (a, b) =>
          new moment(a.addedTime).format("DD-MMM-YYYY HH:mm:ss") -
          new moment(b.addedTime).format("DD-MMM-YYYY HH:mm:ss")
      );
      setComments(sortedArray);
      // get total hours
      const bankedHours = arrDate.map((hour) => hour.bankedHours);
      const ordinariHours = arrDate.map((hour) => hour.ordinaryHours);
      const leaveHours = arrDate.map((hour) => hour.annualLeaveHours);
      const personalLeaveHours = arrDate.map((hour) => hour.personalLeaveHours);
      const leaveWithoutPay = arrDate.map((hour) => hour.leaveWithoutPay);
      const bonus = arrDate.map((hour) => hour.bonus);
      const paidLunch = arrDate.map((hour) => hour.paidLunch);

      let sumOrdinary = convertReduce(ordinariHours);
      if (payrollProfile?.overtimeUsed) {
        if (payrollProfile.overtime.calculated === "weekly") {
          if (!payrollProfile.overtime.overtime200Used) {
            if (sumOrdinary > payrollProfile.overtime.overtime150after) {
              overtime150 =
                sumOrdinary - payrollProfile.overtime.overtime150after;
              sumOrdinary = sumOrdinary - overtime150;
            }
          } else {
            if (
              sumOrdinary > payrollProfile.overtime.overtime150after &&
              sumOrdinary <= payrollProfile.overtime.overtime200after
            ) {
              overtime150 =
                sumOrdinary - payrollProfile.overtime.overtime150after;
              sumOrdinary = sumOrdinary - overtime150;
            } else if (sumOrdinary > payrollProfile.overtime.overtime200after) {
              overtime150 =
                payrollProfile.overtime.overtime200after -
                payrollProfile.overtime.overtime150after;
              overtime200 =
                sumOrdinary - payrollProfile.overtime.overtime200after;
              sumOrdinary = sumOrdinary - overtime150 - overtime200;
            }
          }
        }
      }

      if (payrollProfile?.paidLunch) {
        let paidLunchTotal = convertReduce(paidLunch);
        sumOrdinary = sumOrdinary - paidLunchTotal;
      }

      if (payrollProfile?.bankedHoursUsed) {
        sumOrdinary -= convertReduce(bankedHours);
      }

      if (payrollProfile?.includeOrdinaryHoursOverStandardWeek) {
        if (sumOrdinary > payrollProfile?.hours?.weekly) {
          ordinaryHourBonus = sumOrdinary - payrollProfile?.hours?.weekly;
          sumOrdinary = sumOrdinary - ordinaryHourBonus;
        }
      }

      dispatch(
        setTotalOrdinaryMember({
          payrollProfile,
          totalHours: sumOrdinary,
          ordinaryHourBonus,
          overtime150: overtime150,
          overtime200: overtime200,
          bonus: convertReduce(bonus),
          paidLunch: convertReduce(paidLunch),
          bankedHourBalance: Number(detailTransaction?.bankedHoursBalance),
          bankedHours: convertReduce(bankedHours),
          annualleaveHours: convertReduce(leaveHours),
          personalLeaveHours: convertReduce(personalLeaveHours),
          leaveWithoutPay: convertReduce(leaveWithoutPay),
        })
      );
      setDateData(arrDate);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const FETCH_SUBDATA = async () => {
    const transaction = await FETCH_DETAIL_TRANSACTION_BY_TEAM_MEMBER(
      teamMemberId
    );
    if (transaction) {
      dispatch(setDetailTransaction(transaction));
    }
  };

  useEffect(() => {
    if (detailTransaction) {
      FETCH_TOTAL();
    }
  }, [detailTransaction]);

  useEffect(() => {
    FETCH_SUBDATA();
  }, [teamMemberId]);

  useEffect(() => {
    setDateData([
      {
        _id: thursday,
        dateTime: thursday,
        dateText: "Thursday",
        totalHours: 0,
      },
      {
        _id: friday,
        dateTime: friday,
        dateText: "Friday",
        totalHours: 0,
      },
      {
        _id: saturday,
        dateTime: saturday,
        dateText: "Saturday",
        totalHours: 0,
      },
      {
        _id: sunday,
        dateTime: sunday,
        dateText: "Sunday",
        totalHours: 0,
      },
      { _id: monday, dateTime: monday, dateText: "Monday", totalHours: 0 },
      {
        _id: tuesday,
        dateTime: tuesday,
        dateText: "Tuesday",
        totalHours: 0,
      },
      {
        _id: wednesday,
        dateTime: wednesday,
        dateText: "Wednesday",
        totalHours: 0,
      },
    ]);
  }, []);

  return (
    <div className="table-timesheet">
      <StandardListLayout
        canAccess={teamMemberId ? true : false}
        isLoading={isLoading}
        hierachy={[{ key: 1, label: "Timesheets" }]}
        dataSource={dateData}
        table="time-sheets"
      />
      {comments.length > 0 && (
        <TimesheetCommentView comments={comments} role={role} />
      )}
    </div>
  );
};

export default TimeSheets;
