import Document from "../../../components/Document";

const SafeWorkProcedure = () => {
  return (
    <>
      <Document description="Safe Work Procedure"/>
    </>
  );
};

export default SafeWorkProcedure;
