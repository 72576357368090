/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import React, { useState } from "react";
import { Form, Input, Button, Typography, Alert } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";

const ResetCode = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    isSuccess: false,
    text: ``,
  });

  const onFinish = (values) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/auth/code_reset`, {
        email: values.email.toLowerCase().trim(),
      })
      .then(() => {
        setLoading(false);
        setMessage({
          isSuccess: true,
          text: `Check your email for a link to reset your password. If it doesn’t appear within a
        few minutes, check your spam folder`,
        });
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          isSuccess: false,
          text: "It looks like your email isn't in our database. Please retry with a different email or contact support.",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className='login'>
      <p
        style={{
          color: "red",
          margin: "8px",
        }}></p>

      <Form
        name='basic'
        layout='vertical'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        className='form-login'
        align='center'
        requiredMark={false}>
        <div style={{ width: "45%", margin: "0 auto", marginBottom: 30 }}>
          <img
            alt='insight_logo'
            src={"/images/insight-brand-dark.png"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        {message.isSuccess ? (
          <Alert
            message={<p style={{ textAlign: "left", fontSize: "1.3rem", margin: 0 }}>Success</p>}
            type="success"
            description={
              <div style={{ textAlign: "left", fontSize: "1rem" }}>
                <p style={{ margin: '0 0 12px 0' }}>{message.text}</p>
                <Link to={"/login"} style={{ fontSize: 16, fontWeight: 500 }}>
                  Back To Sign In
                </Link>
              </div>
            }
            showIcon
          />
        ) : (

          <>
            <p style={{ textAlign: "left", fontSize: "1.1rem" }}>
              Enter your user account's verified email address and we will send
              you a password reset link
            </p>

            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Have to be an email",
                },
              ]}>
              <Input placeholder='Enter your email address*' />
            </Form.Item>

            <Form.Item className='btn-login'>
              <Button
                type='primary'
                htmlType='submit'
                loading={loading}
              // style={{
              //   borderColor: errorEmail && "grey",
              //   backgroundColor: errorEmail && "grey",
              // }}
              >
                Send password reset email
              </Button>
            </Form.Item>

            {message.text && !message.isSuccess && (
              <Alert
                message='Error'
                description={message.text}
                type='error'
              />
            )}
          </>
        )}

      </Form>

    </div>
  );
};

export default ResetCode;
