import DocumentCategory from "../../../components/DocumentCategory";

const Document = () => {
  return (
    <>
      <DocumentCategory />
    </>
  );
};

export default Document;
