import EditIndustry from "../../../components/IndustryContact/EditIndustry";

const Industry = () => {
  return (
    <>
      <EditIndustry />
    </>
  );
};

export default Industry;
