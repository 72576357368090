import React from "react";
import "antd/dist/antd.css";
import { Button, Avatar, Popover, Typography } from "antd";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/auth/userSlice";

const { Text } = Typography;

const UserPopover = (props) => {
  const dispatch = useDispatch();
  return (
    <Popover
      placement="bottomRight"
      content={
        <div>
          <Text strong>
            {props.user.firstname} {props.user.lastname}
          </Text>
          <br />
          <Text>{props.user.email}</Text>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                dispatch(logoutUser());
              }}
            >
              Logout
            </Button>
            <Text
              style={{
                fontSize: "13px",
                opacity: 0.777,
              }}
            >
              v3.0
            </Text>
          </div>
        </div>
      }
      trigger="click"
      overlayStyle={{
        width: "300px",
        position: "fixed",
      }}
    >
      <Button
        style={{
          float: "right",
          color: "#FFF",
          marginTop: "12px",
          backgroundColor: "inherit",
          border: "none",
          paddingRight: 0,
        }}
      >
        <Avatar>
          {props.user.firstname.charAt(0).toUpperCase()}
          {props.user.lastname.charAt(0).toUpperCase()}
        </Avatar>
      </Button>
    </Popover>
  );
};


export default UserPopover;