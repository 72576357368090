import TableListTimeSheets from "../../components/TimeSheetsAdmin/TableListTimeSheets";

const TableTimeSheet = () => {
  return (
    <>
      <TableListTimeSheets />
    </>
  );
};

export default TableTimeSheet;
