import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment"
//state
const postWebsiteSlice = createSlice({
  name: "invoiceStore",
  initialState: {
    invoices: [],
    detailInvoice: null,
  },
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload;
      state.detailInvoice = null;
    },
    setDetailInvoice: (state, action) => {
      state.detailInvoice = action.payload;
    },
  },
});

export default postWebsiteSlice.reducer;

//action
export const { setInvoices, setDetailInvoice } = postWebsiteSlice.actions;

//invoice
export const FETCH_ALL_INVOICE = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/invoice/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_INVOICE = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/invoice/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_INVOICE = async (data, file) => {
  try {
    const formData = new FormData();
    if (file && file.file) {
      formData.append("file", file.file);
    }
    formData.append("data", JSON.stringify(data));

    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/invoice/create`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_INVOICE = async (data, file) => {
  try {
    const formData = new FormData();
    if (file && file.file) {
      formData.append("file", file.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/invoice/update`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_INVOICE = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/invoice/delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

export const EMAIL_INVOICE = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/invoice/email`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const NEW_INVOICE = () => (dispatch) => {
  const newModel = {
    _id: "new",
    contact: "",
    image: "",
    date: moment(),
    dueDate: "",
    orderNumber: "",
    reference: "",
    sentToContact: false,
    status: "DRAFT",
  };
  return dispatch(setDetailInvoice(newModel));
};
