import React from "react";
import EmploymentTypes from "../../../../components/EmploymentTypes";

const Types = () => {
  return (
    <>
      <EmploymentTypes />
    </>
  );
};

export default Types;
