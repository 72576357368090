/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../styles/StandardTableLayout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import {
  Input,
  Layout,
  Table,
  Breadcrumb,
  Space,
  Button,
  Modal,
  Tooltip,
  Dropdown,
  Menu,
  Drawer,
  DatePicker,
  Tabs,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  SearchOutlined,
  MenuOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { setCsvExportData } from "../../store/user-store/reducer";
import {
  setEmployTimeSheet,
  setSearchDateTime,
} from "../../store/time-sheet-store/reducer";
import FuseUtils from "../../utils/Utils";
import {
  ContextMenu,
  handleOpenContextMenu,
} from "../../shared-components/ContextMenu";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Content } = Layout;
const { TabPane } = Tabs;

const StandardTableLayout = ({
  isPagination,
  csvHeader,
  csvData,
  csvFileName,
  isLoading,
  setIsLoading,
  isHaveRowAction,
  hierachy,
  dataSource,
  tabSort,
  setTabSort,
  columns,
  FETCH_DATA,
  DELETE_FUNC,
  table,
  hasSelection,
  hasToolbar,
  hasNew,
  dateRanger,
  rightMenu,
  optionMenu,
  contextMenu,
  setContextMenu,
  className,
}) => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(isPagination);

  const { role, organisationId } = useSelector(({ auth }) => auth.user);
  const { searchDateTime } = useSelector(
    ({ timeSheet }) => timeSheet.TimeSheetReducer
  );
  const [configLayout, setConfigLayout] = useState({
    hasSelection: true,
    hasToolbar: true,
    hasRowAction: true,
    hasNew: true,
    rightMenu: rightMenu,
    isPagination: true,
  });
  const [tableData, setTableData] = useState([]);
  const [rowsSelected, setRowsSelected] = useState(false);
  const [saveRow, setSaveRow] = useState([]);
  const [visible, setVisible] = useState(false);
  const [tempDate, setTempDate] = useState();
  let navigate = useNavigate();
  const onAddButtonClickHandler = (event) => {
    navigate(`/${table}/new`);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (configLayout.isPagination) {
      setPagination(pagination);
    } else {
      setPagination(false);
    }
  };

  const showConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to delete these records?",
      icon: <ExclamationCircleOutlined />,
      content: "The operation cannot be undone.",
      onOk() {
        const newSource = saveRow?.map((s) => {
          if (table?.search("contact/person") !== -1) {
            return `id=${s._id}&contactXeroId=${s?.contactId?.xeroId}`;
          } else if (table === "team/members" || table === "departments") {
            return `ids=${s._id}&organisationId=${organisationId}`;
          } else {
            return `ids=${s._id}`;
          }
        });

        DELETE_FUNC(newSource.join("&")).then((data) => {
          FETCH_DATA();
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, record) => {
      setSaveRow(record);
      selectedRowKeys && selectedRowKeys?.length > 0
        ? setRowsSelected(true)
        : setRowsSelected(false);
    },
    getCheckboxProps: (record) => ({
      disabled:
        record?.description === "Policy" ||
        record?.description === "Safety Data Sheet" ||
        record?.description === "Safe Work Method Statement" ||
        record?.description === "Safe Work Procedure" ||
        record?.description === "Qualification",
      // Column configuration not to be checked
      name: record.description,
    }),
  };

  const moreOptionsMenu = (
    <Menu style={{ minWidth: "150px" }}>
      <Menu.Item key="0">Print</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={() => dispatch(setCsvExportData(tableData))}>
        <CSVLink
          data={csvData && csvData}
          headers={csvHeader && csvHeader}
          separator={","}
          filename={csvFileName && csvFileName}
        >
          Export
        </CSVLink>
      </Menu.Item>
    </Menu>
  );

  const onSearchDrawerClose = () => {
    setVisible(false);
  };

  const showSearchDrawer = () => {
    setVisible(true);
  };

  const handleClickRow = (record) => {
    if (table === "documents" && role?.indexOf("admin") === -1) {
      window.open(
        record.fileUrl &&
          record.fileUrl !== "" &&
          JSON.parse(record.fileUrl).webViewLink,
        "_blank"
      );
    } else {
      setIsLoading(true);
      if (table === "payroll/payroll-summary") {
        dispatch(setEmployTimeSheet(record));
        setIsLoading(false);
        navigate(
          `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
        );
      } else {
        setIsLoading(false);
        navigate(`/${table}/${record._id}`);
      }
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setTempDate(
      searchText.length === 0
        ? tableData
        : FuseUtils.filterArrayByString(tableData, searchText)
    );
  };

  useEffect(() => {
    const newData = [];
    if (dataSource) {
      dataSource &&
        dataSource?.map((ele, index) => {
          newData.push({
            ...ele,
            key:
              table === "payroll/payroll-summary" ? ele.employee._id : ele._id,
          });
        });
      setTableData(newData);
    }
  }, [dataSource, columns, hierachy, table, isHaveRowAction]);

  useEffect(() => {
    if (saveRow) {
      dispatch(setCsvExportData(saveRow));
    }
  }, [saveRow]);

  useEffect(() => {
    let config = {
      hasSelection: true,
      hasToolbar: true,
      hasRowAction: true,
      hasNew: true,
      isPagination: true,
    };
    if (isPagination !== undefined && !isPagination) {
      config.isPagination = isPagination;
      setPagination(false);
    }
    if (isHaveRowAction !== undefined && !isHaveRowAction) {
      config.hasRowAction = isHaveRowAction;
    }
    if (hasSelection !== undefined && !hasSelection) {
      config.hasSelection = hasSelection;
    }
    if (hasToolbar !== undefined && !hasToolbar) {
      config.hasToolbar = hasToolbar;
    }
    if (hasNew !== undefined && !hasNew) {
      config.hasNew = hasNew;
    }
    setConfigLayout(config);
  }, [
    dataSource,
    columns,
    hierachy,
    table,
    isHaveRowAction,
    hasSelection,
    hasToolbar,
    hasNew,
    rightMenu,
    isPagination,
  ]);

  const onChangeBetweenDate = (date, dateString) => {
    dispatch(setSearchDateTime(date));
  };

  const tableAntUI = () => (
    <Table
      size="small"
      rowClassName={"clickable"}
      onChange={handleTableChange}
      onRow={(record) => {
        if (configLayout.hasRowAction) {
          if (rightMenu) {
            return {
              onClick: () => {
                handleClickRow(record);
                setContextMenu({ visible: false });
              },
              onContextMenu: (e) => {
                handleOpenContextMenu(e, record, setContextMenu);
              },
            };
          } else {
            return {
              onClick: () => {
                handleClickRow(record);
                setContextMenu({ visible: false });
              },
            };
          }
        }
      }}
      bordered
      rowSelection={configLayout.hasSelection ? rowSelection : false}
      pagination={pagination}
      columns={columns}
      dataSource={tempDate ? tempDate : tableData}
      loading={{ indicator: antIcon, spinning: isLoading }}
      sticky={{ offsetHeader: 64 }}
      scroll={{
        x: "calc(700px + 40%)",
      }}
    />
  );

  const onChangeTabs = (key) => {
    setTabSort({
      ...tabSort,
      tabChoosed: key,
    });
  };

  return (
    <>
      <ContextMenu optionMenu={optionMenu} contextMenu={contextMenu} />

      <Content
        className={styles.content}
        onClick={() =>
          role?.indexOf("admin") !== -1 && setContextMenu({ visible: false })
        }
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          {hierachy?.map((item) => {
            return (
              <Breadcrumb.Item key={item.key}>{item.label}</Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        <div className={`site-layout-content ${className}`}>
          <div className={styles.actionBar}>
            {rowsSelected && (
              <Space>
                <Button icon={<DeleteOutlined />} onClick={showConfirm}>
                  Delete
                </Button>
                <CSVLink
                  data={csvData ? csvData : []}
                  headers={csvHeader && csvHeader}
                  separator={","}
                  filename={"records"}
                >
                  <Button icon={<ExportOutlined />}>Export</Button>
                </CSVLink>
              </Space>
            )}

            <div className={styles.rightActions}>
              <Space>
                {dateRanger && (
                  <div className="date">
                    <DatePicker
                      onChange={onChangeBetweenDate}
                      value={searchDateTime}
                      format={"DD-MMM-YYYY"}
                    />
                  </div>
                )}
                {configLayout.hasToolbar && (
                  <>
                    <Tooltip placement="topLeft" title="Search">
                      <Button
                        icon={<SearchOutlined />}
                        onClick={showSearchDrawer}
                      ></Button>
                    </Tooltip>
                    {role?.indexOf("admin") !== -1 && configLayout.hasNew && (
                      <Tooltip placement="top" title="Add">
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={onAddButtonClickHandler}
                        ></Button>
                      </Tooltip>
                    )}
                    {role?.indexOf("admin") !== -1 && (
                      <Dropdown overlay={moreOptionsMenu} trigger={["click"]}>
                        <Tooltip placement="topRight" title="More Options">
                          <Button icon={<MenuOutlined />}></Button>
                        </Tooltip>
                      </Dropdown>
                    )}
                  </>
                )}
              </Space>
            </div>
          </div>

          {tabSort && (
            <Tabs defaultActiveKey="0" onChange={onChangeTabs}>
              {tabSort?.tabs?.map((ele) => (
                <TabPane tab={ele} key={ele} />
              ))}
            </Tabs>
          )}

          {/* Table  */}
          {tableAntUI()}
        </div>
      </Content>

      {/* Drawer  */}
      <Drawer
        title="Search"
        placement="right"
        onClose={onSearchDrawerClose}
        visible={visible}
        footer={
          <Space>
            <Button type="primary" onClick={onSearchDrawerClose}>
              Search
            </Button>
          </Space>
        }
      >
        <Input onChange={(e) => handleSearch(e)} />
      </Drawer>
    </>
  );
};

export default StandardTableLayout;
