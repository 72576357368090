import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  CREATING_MEMBER_AWARD,
  FETCH_DETAIL_MEMBER_AWARD_IN_ORGANISATION,
  NEW_MEMBER_AWARD,
  setDetailMemberAward,
  UPDATING_MEMBER_AWARD,
} from "../../store/team-member-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditEmploymentAwards = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { idAward } = useParams();
  const { detailMemberAward } = useSelector(({ team }) => team.teamReducer);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailMemberAward._id;
    if (idAward === "new") {
      CREATING_MEMBER_AWARD(values).then((data) => {
        setLoading(false);
        navigate("/employment/awards");
      });
    } else {
      UPDATING_MEMBER_AWARD(values).then((data) => {
        setLoading(false);
        navigate("/employment/awards");
      });
    }
  };

  useEffect(() => {
    const updateState = () => {
      if (idAward === "new") {
        dispatch(NEW_MEMBER_AWARD());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_MEMBER_AWARD_IN_ORGANISATION(idAward).then((data) => {
          dispatch(setDetailMemberAward(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, idAward]);

  useEffect(() => {
    if (detailMemberAward) {
      form.setFields([
        {
          name: "code",
          value: detailMemberAward.code,
        },
        {
          name: "title",
          value: detailMemberAward.title,
        },
        {
          name: "status",
          value: detailMemberAward.status,
        },
      ]);
    }
  }, [detailMemberAward]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Employment Awards", path: "/employment/awards" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/employment/awards/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            requiredMark={false}
            form={form}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item label="Title" name="title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Code" name="code" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditEmploymentAwards;
