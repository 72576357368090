import React from "react";
import { Card, Row, Col, Button, Layout } from "antd";
import styles from "../../../styles/StandardFormLayout.module.scss";
import StandardContainerLayout from "../../../layouts/StandardContainerLayout";
import { useNavigate } from "react-router-dom";
const { Content } = Layout;

const UserDashboard = () => {
  const navigator = useNavigate();

  const onRedirectToTimeSheet = () => {
    navigator("/time-sheets");
  };
  return (
    <StandardContainerLayout>
      <Content className={styles.dashboard__content}>
        <Row gutter={16}>
          <Col span={24}>
            <Card>
              <Button
                type="primary"
                size="large"
                block
                onClick={onRedirectToTimeSheet}
              >
                Timesheet
                {/* Link to the timesheet page */}
              </Button>
            </Card>
          </Col>
        </Row>
      </Content>
    </StandardContainerLayout>
  );
};

export default UserDashboard;
