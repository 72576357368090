import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import cryptoRandomString from 'crypto-random-string'; 
//state
const postWebsiteSlice = createSlice({
  name: "itemStore",
  initialState: {
    items: [],
    detailItems: null,
    itemCategory: [],
    detailItemCategory: null,
    accountCode: [],
    detailAccountCode: null,
  },
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
      state.detailItems = null;
    },
    setDetailItems: (state, action) => {
      state.detailItems = action.payload;
    },
    setItemCategory: (state, action) => {
      state.itemCategory = action.payload;
      state.detailItemCategory = null;
    },
    setDetailItemCategory: (state, action) => {
      state.detailItemCategory = action.payload;
    },
    setAccountCode: (state, action) => {
      state.accountCode = action.payload;
      state.detailAccountCode = null;
    },
    setDetailAccountCode: (state, action) => {
      state.detailAccountCode = action.payload;
    },
  },
});

export default postWebsiteSlice.reducer;

//action
export const {
  setItems,
  setDetailItems,
  setItemCategory,
  setDetailItemCategory,
  setAccountCode,
  setDetailAccountCode,
} = postWebsiteSlice.actions;

//team member
export const FETCH_ALL_ITEMS = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/item/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_ITEM_IN_ORGANISATION = async (organisationId, id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/item/detail`, {
        params: {
          organisationId,
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_ITEM = async (data, image) => {
  try {
    const formData = new FormData();
    if (image && image.file) {
      formData.append("image", image.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/item/create`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      })
      .catch((err) => {
        throw err.response;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_ITEM = async (data, image) => {
  try {
    const formData = new FormData();
    if (image && image.file) {
      formData.append("image", image.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/item/update`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      })
      .catch((err) => {
        throw err.response;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_ITEMS = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/item/delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

//Items Category

export const FETCH_ITEM_CATEGORY = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/item/category/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_ITEM_CATEGORY = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/item/category/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_ITEM_CATEGORY = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/item/category/create`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_ITEM_CATEGORY = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/item/category/update`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_ITEM_CATEGORY = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/item/category/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

//Items Chart of Accounts

export const FETCH_ACOUNT_CODE = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/item/accountCode/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_ACCOUNT_CODE = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/item/accountCode/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_ACCOUNT_CODE = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/item/accountCode/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_ACCOUNT_CODE = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/item/accountCode/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_ACCOUNT_CODE = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/item/accountCode/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

export const NEW_ITEMS = () => (dispatch) => {
  let uniqueSkuString = cryptoRandomString({
    length: 3,
    type: "distinguishable"
  });

  let uniqueSkuNumber = cryptoRandomString({
    length: 4,
    type: "numeric"
  });

  const newModel = {
    _id: "new",
    image: "",
    name: "",
    saleDescription: "",
    category: "",
    unit: "",
    accountCode: "",
    department: "",
    isPurchase: false,
    isSell: false,
    purchasePrice: "",
    salePrice: "",
    sku: uniqueSkuString+uniqueSkuNumber,
    status: true,
  };
  return dispatch(setDetailItems(newModel));
};

export const NEW_ITEM_CATEGORY = () => (dispatch) => {
  const newModel = {
    _id: "new",
    name: "",
    code: "",
    status: true,
  };
  return dispatch(setDetailItemCategory(newModel));
};

export const NEW_ITEM_ACCOUNT_CODE = () => (dispatch) => {
  const newModel = {
    _id: "new",
    name: "",
    type: "",
    description: "",
    code: "",
    status: true,
  };
  return dispatch(setDetailAccountCode(newModel));
};
