import Report from "./index";
import BankedHourReport from "./bankedHour";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    //employment type
    {
      path: "/reports",
      component: Report,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
        path: "/reports/banked-hour",
        component: BankedHourReport,
        auth: ["admin", "user"],
        settings: {
          layout: {
            head: true,
            sider: true,
          },
        },
      },
  ],
};

export default router;
