import React from "react";
import { Layout } from 'antd';
import styles from "../../styles/StandardFormLayout.module.scss";
const { Content } = Layout;

const StandardContainerLayout = (props) => {
    return (
        <div className="table-organisations table-payments">
                <Content className={styles.container}>
                    {props.children}
                </Content>
        </div>
    )
}

export default StandardContainerLayout;