import Login from "./Login";
import ChangePassword from "./Password/ChangePassword";
import ResetPassword from "./Password/ResetCode";
import Setting from "./Settings";
import MemberRouter from "./Team/TeamMembers/router";
import SubTeamRouter from "./Team/SubTeam/router";
import DepartmentRouter from "./Departments/router";
import OrganistaionRouter from "./Organisations/router";
import FleetRouter from "./Fleet/router";
import PaymentsRouter from "./Payments/router";
import Dashboard from "./Dashboard";
import TimeSheets from "./TimeSheets/router";
import FleetPrestarts from "./FleetPrestart/router";
import TimeSheetsAdmin from "./TimeSheetsAdmin/router";
import FleetCompletePrestarts from "./FleetCompletingPrestart/router";
import AllRecords from "./AllRecords/router";
import Document from "./Documents/router";
import Item from "./Items/router";
import TOF from "./TermOfService";
import Policy from "./PrivacyPolicy";
import Contact from "./Contact/router";
import Bill from "./Bill/router";
import Invoice from "./Invoice/router";
import Quote from "./Quote/router";
import Receipt from "./Receipt/router";
import Report from "./Reports/router";

const PagesConfigs = [
  MemberRouter,
  SubTeamRouter,
  DepartmentRouter,
  OrganistaionRouter,
  FleetRouter,
  PaymentsRouter,
  TimeSheets,
  FleetPrestarts,
  TimeSheetsAdmin,
  AllRecords,
  FleetCompletePrestarts,
  Document,
  Item,
  Contact,
  Bill,
  Invoice,
  Quote,
  Receipt,
  Report,
  {
    routes: [
      {
        path: "/login",
        component: Login,
        auth: [],
        settings: {
          layout: {},
        },
      },
      {
        path: "/reset_password",
        component: ResetPassword,
        auth: [],
        settings: {
          layout: {},
        },
      },
      {
        path: "/password_change/:code",
        component: ChangePassword,
        auth: [],
        settings: {
          layout: {},
        },
      },
      {
        path: "/",
        component: Dashboard,
        auth: ["admin", "user", "manager"],
        settings: {
          layout: {
            head: true,
            sider: true,
          },
        },
      },
      {
        path: "/dashboard",
        component: Dashboard,
        auth: ["admin", "user", "manager"],
        settings: {
          layout: {
            head: true,
            sider: true,
          },
        },
      },
      {
        path: "/settings",
        component: Setting,
        auth: ["admin", "manager"],
        settings: {
          layout: {
            head: true,
            sider: true,
          },
        },
      },
      {
        path: "/legal/privacy-policy",
        component: Policy,
        auth: [],
        settings: {
          layout: {},
        },
      },
      {
        path: "/legal/tos",
        component: TOF,
        auth: [],
        settings: {
          layout: {},
        },
      },
    ],
  },
];

export default PagesConfigs;
