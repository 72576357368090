import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Avatar, Badge } from "antd";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_MEMBER_AWARD_IN_ORGANISATION,
  setMemberAwards,
  DELETING_MEMBER_AWARD,
} from "../../store/team-member-store/reducer";

const EmploymentAwards = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { memberAwards } = useSelector(({ team }) => team.teamReducer);
  const [isLoading, setIsLoading] = useState(true);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Code",
        key: "code",
      },
      {
        label: "Title",
        key: "title",
      },
      {
        label: "Status",
        key: "status",
      },
    ],
    data: [],
    fileName: "allEmploymentAwards.csv",
  });
  const [dataList, setDataList] = useState([]);

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        code: e.code,
        title: e.title,
        status: e.status,
      });
    });
    return csvData;
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "InActive"} />;
      },
      sorter: (a, b) => b.status - a.status,
    },
  ];
  const FETCH_DATA = () => {
    FETCH_ALL_MEMBER_AWARD_IN_ORGANISATION(organisationId)
      .then((data) => dispatch(setMemberAwards(data)))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (memberAwards) {
      const columns = handleColumnCsv(memberAwards);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [memberAwards]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    memberAwards?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.title}`,
        description: `${ele?.code}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [memberAwards]);

  return (
    <div className="team_member">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Employment Awards" }]}
            dataSource={memberAwards}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_MEMBER_AWARD}
            table="employment/awards"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Employment Awards" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_MEMBER_AWARD}
            table="employment/awards"
          />
        </div>
      </div>
    </div>
  );
};

export default EmploymentAwards;
