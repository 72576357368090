import Departments from "../../components/Departments";

const Department = () => {
  return (
    <>
      <Departments />
    </>
  );
};

export default Department;
