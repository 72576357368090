import React, { useState, useEffect } from "react";
import moment from "moment";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  FETCH_DETAIL_PAYMENTS,
  CREATE_PAYMENTS,
  UPDATE_PAYMENTS,
  setDetailPayment,
  NEW_PAYMENT,
} from "../../store/payment-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  TimePicker,
  Radio,
  Checkbox,
  Divider,
  InputNumber,
  Select,
  Button,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { TextArea } = Input;
const { Option } = Select;

const payTemplate = {
  description: "General", // profile name
  detailedDescription: "", // description
  hours: { weekly: 40, daily: 9 }, // hours /week & hours /day
  days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"], // days /week
  publicHolidaysPaid: true, // are public holidays paid
  bankedHoursUsed: true, // include banked hours
  paidLunch: false, // include break time in total hours
  includeBreakInTotalHours: false,
  payLunchWhenHoursExceed: 5, // paid lunch when hours exceed
  bonusHoursUsed: true, // include paid lunch
  payBonusWhenHoursExceed: 8.5, // paid lunch when hours exceed
  includeOrdinaryHoursOverStandardWeek: false, // not sure what this is
  standardTimes: {
    startTime: "05:30", // start time
    startBreak: "11:30", // star break
    endBreak: "12:00", // end break
    finishTime: "16:00", // finish time
  },
  leave: {
    personalLeave: true, // include personal leave
    annualLeave: true, // include annaul leave
    annualLeaveLoading: true, // include annual leave loading
  },
  overtimeUsed: true, // Include overtime
  overtime: {
    calculated: "weekly", // How is overtime calculated
    overtime150Used: true, // Include 150% overtime rate
    overtime150after: 40, // When does 150% commence
    overtime200Used: false, // Include 200% overtime rate
    overtime200after: 42, // When does 200% commence (weekly)
  },
  allowances: {
    firstAid: false, // Include first aid allowance
    leadingHand: false, // Include leading hand allowance
    meal: false, // Include meal allowance
    plant: false, // Include plant operator allowance
    workingAway: false, // Include working away rate
  },
  status: true, // Status
};

const dayOptions = [
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Sunday", value: "sunday" },
];

const yesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export default function DetailPayments() {
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { payId } = useParams();
  const { detailPayments } = useSelector(
    ({ payments }) => payments.paymentReducer
  );
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [paidLunch, setPaidLunch] = useState(payTemplate.paidLunch);
  const [bonusHours, setBonusHours] = useState(payTemplate.bankedHoursUsed);
  const [overtime, setOvertime] = useState(payTemplate.overtimeUsed);
  const [overtime150, setOvertime150] = useState(
    payTemplate.overtime.overtime150Used
  );
  const [overtime200, setOvertime200] = useState(
    payTemplate.overtime.overtime200Used
  );

  const onFinish = async (values) => {
    setLoading(true);
    let formValue = {
      _id: payId !== "new" ? detailPayments._id : "New",
      organisationId,
      description: values.description
        ? values.description
        : payTemplate.description,
      detailedDescription: values.detailedDescription
        ? values.detailedDescription
        : payTemplate.detailedDescription,
      hours: {
        weekly:
          values.weekly !== undefined
            ? values.weekly
            : payTemplate.hours.weekly,
        daily: values.daily ? values.daily : payTemplate.hours.daily,
      },
      days: values.days !== undefined ? values.days : payTemplate.days,
      publicHolidaysPaid:
        values.publicHolidaysPaid !== undefined
          ? values.publicHolidaysPaid
          : payTemplate.publicHolidaysPaid,
      bankedHoursUsed:
        values.bankedHoursUsed !== undefined
          ? values.bankedHoursUsed
          : payTemplate.bankedHoursUsed,
      paidLunch:
        values.paidLunch !== undefined
          ? values.paidLunch
          : payTemplate.paidLunch,
      includeBreakInTotalHours:
        values.includeBreakInTotalHours !== undefined
          ? values.includeBreakInTotalHours
          : payTemplate?.includeBreakInTotalHours,
      payLunchWhenHoursExceed:
        values.payLunchWhenHoursExceed !== undefined
          ? values.payLunchWhenHoursExceed
          : payTemplate.payLunchWhenHoursExceed,
      bonusHoursUsed:
        values.bonusHoursUsed !== undefined
          ? values.bonusHoursUsed
          : payTemplate.bonusHoursUsed,
      payBonusWhenHoursExceed:
        values.payBonusWhenHoursExceed !== undefined
          ? values.payBonusWhenHoursExceed
          : payTemplate.payBonusWhenHoursExceed,
      includeOrdinaryHoursOverStandardWeek:
        values.includeOrdinaryHoursOverStandardWeek !== undefined
          ? values.includeOrdinaryHoursOverStandardWeek
          : payTemplate.includeOrdinaryHoursOverStandardWeek,
      standardTimes: {
        startTime:
          values.startTime !== undefined
            ? values.startTime
            : payTemplate.standardTimes.startTime,
        startBreak:
          values.startBreak !== undefined
            ? values.startBreak
            : payTemplate.standardTimes.startBreak,
        endBreak:
          values.endBreak !== undefined
            ? values.endBreak
            : payTemplate.standardTimes.endBreak,
        finishTime:
          values.finishTime !== undefined
            ? values.finishTime
            : payTemplate.standardTimes.finishTime,
      },
      leave: {
        personalLeave:
          values.personalLeave !== undefined
            ? values.personalLeave
            : payTemplate.leave.personalLeave,
        annualLeave:
          values.annualLeave !== undefined
            ? values.annualLeave
            : payTemplate.leave.annualLeave,
        annualLeaveLoading:
          values.annualLeaveLoading !== undefined
            ? values.annualLeaveLoading
            : payTemplate.leave.annualLeaveLoading,
      },
      overtimeUsed:
        values.overtimeUsed !== undefined
          ? values.overtimeUsed
          : payTemplate.overtimeUsed,
      overtime: {
        calculated:
          values.calculated !== undefined
            ? values.calculated
            : payTemplate.overtime.calculated,
        overtime150Used:
          values.overtime150Used !== undefined
            ? values.overtime150Used
            : payTemplate.overtime.overtime150Used,
        overtime150after:
          values.overtime150after !== undefined
            ? values.overtime150after
            : payTemplate.overtime.overtime150after,
        overtime200Used:
          values.overtime200Used !== undefined
            ? values.overtime200Used
            : payTemplate.overtime.overtime200Used,
        overtime200after:
          values.overtime200after !== undefined
            ? values.overtime200after
            : payTemplate.overtime.overtime200after,
      },
      allowances: {
        firstAid:
          values.firstAid !== undefined
            ? values.firstAid
            : payTemplate.allowances.firstAid,
        leadingHand:
          values.leadingHand !== undefined
            ? values.leadingHand
            : payTemplate.allowances.leadingHand,
        meal:
          values.meal !== undefined ? values.meal : payTemplate.allowances.meal,
        plant:
          values.plant !== undefined
            ? values.plant
            : payTemplate.allowances.plant,
        workingAway:
          values.workingAway !== undefined
            ? values.workingAway
            : payTemplate.allowances.workingAway,
      },
      status: values.status !== undefined ? values.status : payTemplate.status,
    };

    if (payId === "new") {
      CREATE_PAYMENTS(formValue).then((data) => {
        setLoading(false);
        navigate("/payroll/pay-profiles");
      });
    } else {
      UPDATE_PAYMENTS(formValue).then((data) => {
        setLoading(false);
        navigate("/payroll/pay-profiles");
      });
    }
  };

  useEffect(() => {
    const updateState = () => {
      if (payId === "new") {
        dispatch(NEW_PAYMENT());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_PAYMENTS(payId).then((data) => {
          dispatch(setDetailPayment(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, payId]);

  useEffect(() => {
    if (payId !== "new" && detailPayments) {
      setOvertime(detailPayments.overtimeUsed)
      setOvertime150(detailPayments.overtime?.overtime150Used)
      setOvertime200(detailPayments.overtime?.overtime200Used)
      setBonusHours(detailPayments?.bonusHoursUsed)
      setPaidLunch(detailPayments?.paidLunch)
      form.setFields([
        {
          name: "description",
          value: detailPayments?.description,
        },
        {
          name: "active",
          value: detailPayments?.status,
        },
        {
          name: "detailedDescription",
          value: detailPayments?.detailedDescription,
        },

        {
          name: "publicHolidaysPaid",
          value: detailPayments?.publicHolidaysPaid,
        },
        {
          name: "bankedHoursUsed",
          value: detailPayments?.bankedHoursUsed,
        },
        {
          name: "paidLunch",
          value: detailPayments?.paidLunch,
        },
        {
          name: "includeBreakInTotalHours",
          value: detailPayments?.includeBreakInTotalHours,
        },
        {
          name: "payLunchWhenHoursExceed",
          value: detailPayments?.payLunchWhenHoursExceed,
        },
        {
          name: "bonusHoursUsed",
          value: detailPayments?.bonusHoursUsed,
        },
        {
          name: "payBonusWhenHoursExceed",
          value: detailPayments?.payBonusWhenHoursExceed,
        },
        {
          name: "includeOrdinaryHoursOverStandardWeek",
          value: detailPayments?.includeOrdinaryHoursOverStandardWeek,
        },

        {
          name: "personalLeave",
          value: detailPayments?.leave?.personalLeave,
        },
        {
          name: "annualLeave",
          value: detailPayments?.leave?.annualLeave,
        },
        {
          name: "annualLeaveLoading",
          value: detailPayments?.leave?.annualLeaveLoading,
        },
        {
          name: "overtimeUsed",
          value: detailPayments?.overtimeUsed,
        },
        {
          name: "weekly",
          value: detailPayments?.hours?.weekly,
        },
        {
          name: "daily",
          value: detailPayments?.hours?.daily,
        },
        {
          name: "days",
          value: detailPayments?.days,
        },
        {
          name: "publicHolidaysPaid",
          value: detailPayments?.publicHolidaysPaid,
        },
        {
          name: "startTime",
          value: moment(detailPayments?.standardTimes?.startTime),
        },
        {
          name: "startBreak",
          value: moment(detailPayments?.standardTimes?.startBreak),
        },
        {
          name: "endBreak",
          value: moment(detailPayments?.standardTimes?.endBreak),
        },
        {
          name: "finishTime",
          value: moment(detailPayments?.standardTimes?.finishTime),
        },
        {
          name: "calculated",
          value: detailPayments?.overtime?.calculated,
        },
        {
          name: "overtime150Used",
          value: detailPayments?.overtime?.overtime150Used,
        },
        {
          name: "overtime150after",
          value: detailPayments?.overtime?.overtime150after,
        },
        {
          name: "overtime200Used",
          value: detailPayments?.overtime?.overtime200Used,
        },
        {
          name: "overtime200after",
          value: detailPayments?.overtime?.overtime200after,
        },
        {
          name: "firstAid",
          value: detailPayments?.allowances?.firstAid,
        },
        {
          name: "leadingHand",
          value: detailPayments?.allowances?.leadingHand,
        },
        {
          name: "meal",
          value: detailPayments?.allowances?.meal,
        },
        {
          name: "plant",
          value: detailPayments?.allowances?.plant,
        },
        {
          name: "workingAway",
          value: detailPayments?.allowances?.workingAway,
        },
        {
          name: "status",
          value: detailPayments?.status,
        },
      ]);
    }
  }, [detailPayments]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Pay profiles", path: "/payroll/pay-profiles" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/payroll/pay-profiles/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            name="basic"
            form={form}
            scrollToFirstError
            layout="vertical"
            labelAlign="left"
            requiredMark={false}
            onFinish={onFinish}
          >
            <Form.Item label="Description" name="description">
              <Input defaultValue={payTemplate.description} />
            </Form.Item>

            <Form.Item label="Detailed Description" name="detailedDescription">
              <TextArea
                rows={2}
                defaultValue={payTemplate.detailedDescription}
              />
            </Form.Item>

            <Form.Item label="Hours /week" name="weekly">
              <InputNumber
                style={{ width: "100%" }}
                defaultValue={payTemplate.hours.weekly}
              />
            </Form.Item>

            <Form.Item label="Work Days" name="days">
              <Checkbox.Group options={dayOptions} />
            </Form.Item>

            <Form.Item label="Hours /day" name="daily">
              <InputNumber
                style={{ width: "100%" }}
                defaultValue={payTemplate.hours.daily}
              />
            </Form.Item>

            <Form.Item
              label="Are public holidays paid?"
              name="publicHolidaysPaid"
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.publicHolidaysPaid}
              />
            </Form.Item>

            <Form.Item label="Include banked hours?" name="bankedHoursUsed">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.bankedHoursUsed}
              />
            </Form.Item>

            <Form.Item
              label="Include break time in total hours?"
              name="includeBreakInTotalHours"
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.includeBreakInTotalHours}
              />
            </Form.Item>

            <Form.Item label="Include paid lunch?" name="paidLunch">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                onChange={(value) => {
                  setPaidLunch(value.target.value);
                }}
                defaultValue={payTemplate.paidLunch}
              />
            </Form.Item>

            {paidLunch ? (
              <Form.Item
                label="Pay lunch when hours Exceed?"
                name="payLunchWhenHoursExceed"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  defaultValue={payTemplate.payLunchWhenHoursExceed}
                />
              </Form.Item>
            ) : (
              ""
            )}

            <Form.Item label="Include bonus hours?" name="bonusHoursUsed">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                onChange={(value) => {
                  setBonusHours(value.target.value);
                }}
                defaultValue={payTemplate.bonusHoursUsed}
              />
            </Form.Item>

            {bonusHours ? (
              <Form.Item
                label="Bonus paid when hours exceed?"
                name="payBonusWhenHoursExceed"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  defaultValue={payTemplate.payBonusWhenHoursExceed}
                />
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item
              label="Include ordinary hours over standard week?"
              name="includeOrdinaryHoursOverStandardWeek"
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.includeOrdinaryHoursOverStandardWeek}
              />
            </Form.Item>

            <Divider orientation="left">Standard Times</Divider>

            <Form.Item
              label="Start Time"
              name="startTime"
              rules={[{ required: true }]}
            >
              <TimePicker
                defaultOpenValue={moment("00:00", "HH:mm")}
                style={{ width: "100%" }}
                format="HH:mm"
                inputReadOnly
              />
            </Form.Item>

            <Form.Item
              label="Start Break"
              name="startBreak"
              rules={[{ required: true }]}
            >
              <TimePicker
                defaultOpenValue={moment("00:00", "HH:mm")}
                style={{ width: "100%" }}
                format="HH:mm"
                inputReadOnly
              />
            </Form.Item>

            <Form.Item
              label="End Break"
              name="endBreak"
              rules={[{ required: true }]}
            >
              <TimePicker
                defaultOpenValue={moment("00:00", "HH:mm")}
                style={{ width: "100%" }}
                format="HH:mm"
                inputReadOnly
              />
            </Form.Item>

            <Form.Item
              label="Finish Time"
              name="finishTime"
              rules={[{ required: true }]}
            >
              <TimePicker
                defaultOpenValue={moment("00:00", "HH:mm")}
                style={{ width: "100%" }}
                format="HH:mm"
                inputReadOnly
              />
            </Form.Item>

            <Divider orientation="left">Leave</Divider>

            <Form.Item label="Include personal leave?" name="personalLeave">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.leave.personalLeave}
              />
            </Form.Item>

            <Form.Item label="Include annual leave?" name="annualLeave">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.leave.annualLeave}
              />
            </Form.Item>

            <Form.Item
              label="Include annual leave loading?"
              name="annualLeaveLoading"
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.leave.annualLeaveLoading}
              />
            </Form.Item>

            <Divider orientation="left">Overtime</Divider>

            <Form.Item label="Include overtime?" name="overtimeUsed">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                onChange={(value) => {
                  setOvertime(value.target.value);
                }}
                defaultValue={overtime}
              />
            </Form.Item>
            {overtime ? (
              <>
                <Form.Item
                  label="How is overtime calculated?"
                  name="calculated"
                >
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={[
                      { label: "Weekly", value: "weekly" },
                      { label: "Daily", value: "daily" },
                    ]}
                    defaultValue={payTemplate.overtime.calculated}
                  />
                </Form.Item>

                <Form.Item
                  label="Include 150% overtime rate?"
                  name="overtime150Used"
                >
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={yesNoOptions}
                    onChange={(value) => {
                      setOvertime150(value.target.value);
                    }}
                    defaultValue={overtime150}
                  />
                </Form.Item>

                {overtime150 ? (
                  <Form.Item
                    label="When does 150% commence?"
                    name="overtime150after"
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      defaultValue={payTemplate.overtime.overtime150after}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}

                <Form.Item
                  label="Include 200% overtime rate?"
                  name="overtime200Used"
                >
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={yesNoOptions}
                    onChange={(value) => {
                      setOvertime200(value.target.value);
                    }}
                    defaultValue={overtime200}
                  />
                </Form.Item>

                {overtime200 ? (
                  <Form.Item
                    label="When does 200% commence?"
                    name="overtime200after"
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      defaultValue={payTemplate.overtime.overtime200after}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            <Divider orientation="left">Allowances</Divider>

            <Form.Item label="Include first aid allowance?" name="firstAid">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.allowances.firstAid}
              />
            </Form.Item>

            <Form.Item
              label="Include leading hand allowance?"
              name="leadingHand"
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.allowances.leadingHand}
              />
            </Form.Item>

            <Form.Item label="Include meal allowance?" name="meal">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.allowances.meal}
              />
            </Form.Item>

            <Form.Item label="Include plant operator allowance?" name="plant">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.allowances.plant}
              />
            </Form.Item>

            <Form.Item label="Include working away rate?" name="workingAway">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={yesNoOptions}
                defaultValue={payTemplate.allowances.workingAway}
              />
            </Form.Item>

            <Form.Item label="Status" name="status">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                style={{ width: "100%" }}
                defaultValue={payTemplate.status}
              >
                <Option value={true}>Active</Option>
                <Option value={false}>Inactive</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
}
