import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Avatar, Badge, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { UserOutlined } from "@ant-design/icons";
import {
  FETCH_ALL_TEAM_MEMBER_IN_ORGANISATION,
  setTeamMembers,
  DELETING_TEAM_MEMBER,
} from "../../store/team-member-store/reducer";
import { useNavigate } from "react-router-dom";

const TeamMembers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [showAvatar, setShowAvatar] = useState({
    state: false,
    src: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const { teamMembers } = useSelector(({ team }) => team.teamReducer);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [csv, setCsv] = useState({
    headers: [
      {
        key: "firstName",
        label: "First Name",
      },
      {
        key: "lastName",
        label: "Last Name",
      },
      {
        key: "nickName",
        label: "Nick Name",
      },
      {
        key: "userName",
        label: "User Name",
      },
      {
        key: "Photo",
        label: "photo",
      },
      {
        key: "dateOfBirth",
        label: "Date Of Birth",
      },
      {
        key: "USI",
        label: "USI",
      },
      {
        key: "employmentTypeId",
        label: "Employment Type",
      },
      {
        key: "positionId",
        label: "Position",
      },
      {
        key: "employeeAwardId",
        label: "Employee Award",
      },
      {
        key: "awardLevel",
        label: "Award Level",
      },
      {
        key: "dateOfJoining",
        label: "Date Of Joining",
      },
      {
        key: "departmentId",
        label: "Department",
      },
      {
        key: "labourCost",
        label: "Labour Cost",
      },
      {
        key: "emailPersonal",
        label: "Email Personal",
      },
      {
        key: "mobilePersonal",
        label: "Mobile Personal",
      },
      {
        key: "addressPersonal",
        label: "Address Personal",
      },
      {
        key: "payrollProfile",
        label: "Payroll Profile",
      },
      {
        key: "emailWork",
        label: "Wort Email",
      },
      {
        key: "mobileWork",
        label: "Work Mobile",
      },
      {
        key: "includeInPayroll",
        label: "Include In Payroll",
      },
      {
        key: "timesheetReminder",
        label: "Timesheet Reminder",
      },
      {
        key: "active",
        label: "Active",
      },
    ],
    data: [],
    fileName: "allTeamMembers.csv",
  });
  const [dataList, setDataList] = useState([]);

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        firstName: e.firstName,
        lastName: e.lastName,
        nickName: e.nickName,
        userName: e.userName,
        photo: e.photo,
        dateOfBirth: e.dateOfBirth,
        USI: e.USI,
        employmentTypeId: e.employmentType?.description,
        positionId: e.position?.description,
        employeeAwardId: e.employeeAward?.code,
        awardLevel: e.awardLevel,
        dateOfJoining: e.dateOfJoining,
        departmentId: e.departmentId?.name,
        labourCost: e.labourCost,
        emailPersonal: e.emailPersonal,
        mobilePersonal: e.mobilePersonal,
        addressPersonal: e.addressPersonal,
        payrollProfile: e.payrollProfile,
        emailWork: e.emailWork,
        mobileWork: e.mobileWork,
        includeInPayroll: e.includeInPayroll,
        timesheetReminder: e.timesheetReminder,
        active: e.active,
      });
    });
    return csvData;
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "photo",
      key: "photo",
      width: 80,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setShowAvatar({
              state: true,
              src: record.photo,
            });
          },
        };
      },
      render: (image) =>
        image > 0 ? (
          <Image src={image} />
        ) : (
          <Avatar
            style={{ color: "#5468ff", backgroundColor: "#e6f7ff" }}
            icon={<UserOutlined />}
            src={image}
          ></Avatar>
        ),
    },
    {
      title: "Name",
      sorter: (a, b) => a?.firstName.localeCompare(b?.firstName),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/team/members/${record._id}`);
          },
        };
      },
      render: (data) => (
        <>
          {data.firstName} {data.lastName}
        </>
      ),
      sortDirections: ["descend"],
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/team/members/${record._id}`);
          },
        };
      },
      sorter: (a, b) =>
        a?.position?.description.localeCompare(b?.position?.description),
      render: (data) => <>{data?.description}</>,
    },
    {
      title: "Department",
      dataIndex: "departmentId",
      key: "departmentId",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/team/members/${record._id}`);
          },
        };
      },
      sorter: (a, b) =>
        a?.departmentId?.name.localeCompare(b?.departmentId?.name),
      render: (data) => <>{data.name}</>,
    },
    {
      title: "Work Email",
      dataIndex: "emailWork",
      key: "emailWork",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/team/members/${record._id}`);
          },
        };
      },
      sorter: (a, b) => a?.emailWork.localeCompare(b?.emailWork),
    },
    {
      title: "Work Mobile",
      dataIndex: "mobileWork",
      key: "mobileWork",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/team/members/${record._id}`);
          },
        };
      },
      sorter: (a, b) => a?.mobileWork.localeCompare(b?.mobileWork),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/team/members/${record._id}`);
          },
        };
      },
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "InActive"} />;
      },
      sorter: (a, b) => b.active - a.active,
    },
  ];

  const FETCH_DATA = () => {
    if (organisationId) {
      FETCH_ALL_TEAM_MEMBER_IN_ORGANISATION({ organisationId })
        .then((data) => {
          data.sort((a, b) => b.firstName.localeCompare(a.firstName)).reverse();
          dispatch(setTeamMembers(data));
        })
        .finally(() => setIsLoading(false));
    }
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (teamMembers) {
      const columns = handleColumnCsv(teamMembers);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [teamMembers]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    teamMembers?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.lastName} ${ele?.firstName}`,
        description: `${ele?.mobilePersonal ? `${ele?.mobilePersonal}` : ""}
         ${ele?.position ? `/ ${ele?.position?.description}` : ""}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [teamMembers]);

  return (
    <div className="team_member">
      {showAvatar.state && (
        <Image
          width={100}
          style={{ display: "none" }}
          src={showAvatar.src}
          preview={{
            visible: showAvatar,
            src: showAvatar.src,
            onVisibleChange: (value) => {
              setShowAvatar({
                state: value,
                src: "",
              });
            },
          }}
        />
      )}
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            isHaveRowAction={false}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Team Members" }]}
            dataSource={teamMembers}
            columns={columns}
            DELETE_FUNC={DELETING_TEAM_MEMBER}
            FETCH_DATA={FETCH_DATA}
            table="team/members"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            isHaveRowAction={false}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Team Members" }]}
            dataList={dataList}
            columns={columns}
            DELETE_FUNC={DELETING_TEAM_MEMBER}
            FETCH_DATA={FETCH_DATA}
            table="team/members"
          />
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;
