import React, { useState } from "react";
import { Row, Col, Layout, List, Typography } from "antd";
import styles from "../../styles/StandardFormLayout.module.scss";
import StandardContainerLayout from "../../layouts/StandardContainerLayout";
import "../../styles/_setting.scss";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Content } = Layout;
const Settings = () => {
  const navigate = useNavigate();
  const firstRowData = [
    {
      label: "Banked Hour Report",
      function: () => {
        navigate("/reports/banked-hour");
      },
    },
  ];

  const secondRowData = [
  ];
  return (
    <StandardContainerLayout
      hierachy={[
        { key: 1, label: "Settings", path: "/settings" },
        { key: 2, label: "Sites" },
      ]}
    >
      <Content className={styles.dashboard__content}>
        <Title
          style={{
            fontSize: "48px",
            fontWeight: "350",
          }}
        >
          Reports
        </Title>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12}>
            <List
              bordered
              dataSource={firstRowData}
              renderItem={(item) => (
                <List.Item className="list-item" onClick={item.function}>
                  <Typography.Text className="list-text">
                    {item.label}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Col>
          {/* <Col xs={24} sm={24} md={12}>
            <List
              bordered
              dataSource={secondRowData}
              renderItem={(item) => (
                <List.Item className="list-item" onClick={item.function}>
                  <Typography.Text className="list-text">
                    {item.label}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Col> */}
        </Row>
      </Content>
    </StandardContainerLayout>
  );
};

export default Settings;
