import EditPurchaseOrder from "../../../components/PurchaseOrders/EditPurchaseOrder";

const Purchases = () => {
  return (
    <>
      <EditPurchaseOrder />
    </>
  );
};

export default Purchases;
