import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin, DatePicker, Select } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  NEW_ISSUE,
  FETCH_DETAIL_FLEET_ISSUE,
  CREATE_FLEET_ISSUE,
  UPDATE_FLEET_ISSUE,
  setDetailFleetIssue,
} from "../../store/fleet-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditFleetIssue = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailIssue } = useSelector(({ fleets }) => fleets.fleetReducer);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailIssue._id;
    if (id === "new") {
      CREATE_FLEET_ISSUE(values).then((data) => {
        setLoading(false);
        navigate("/fleet/issues");
      });
    } else {
      UPDATE_FLEET_ISSUE(values).then((data) => {
        setLoading(false);
        navigate("/fleet/issues");
      });
    }
  };

  useEffect(() => {
    const updateState = async () => {
      if (id === "new") {
        dispatch(NEW_ISSUE());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_FLEET_ISSUE(id).then((data) => {
          dispatch(setDetailFleetIssue(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailIssue) {
      form.setFields([
        {
          name: "fleetId",
          value: detailIssue.fleetId?.registration,
        },
        {
          name: "fleetPreStartId",
          value: detailIssue.fleetPreStartId?.title,
        },
        {
          name: "dateTime",
          value: moment(detailIssue.dateTime),
        },
        {
          name: "issueDescription",
          value: detailIssue.issueDescription,
        },
        {
          name: "active",
          value: detailIssue.active,
        },
      ]);
    }
  }, [detailIssue]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Fleet Issues", path: "/fleet/issues" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/fleet/issues/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            form={form}
            requiredMark={false}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Fleet Vehicle"
              name="fleetId"
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Fleet Prestart"
              name="fleetPreStartId"
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Date Time"
              name="dateTime"
              rules={[{ required: true }]}
            >
              <DatePicker format="DD-MMM-YYYY HH:mm:ss" />
            </Form.Item>

            <Form.Item
              label="Description"
              name="issueDescription"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Status" name="active" valuePropName="checked">
              <Switch checkedChildren="Open" unCheckedChildren="Closed" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditFleetIssue;
