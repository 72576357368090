import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
//state
const paySlice = createSlice({
  name: "userStore",
  initialState: {
    payments: [],
    detailPayments: null,
  },
  reducers: {
    setPayment: (state, action) => {
      state.payments = action.payload;
      state.detailPayments = null;
    },
    setDetailPayment: (state, action) => {
      state.detailPayments = action.payload;
    },
  },
});

export default paySlice.reducer;

//action
export const { setPayment, setDetailPayment } = paySlice.actions;

//category
export const FETCH_ALL_PAYMENTS = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/pay-profile/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_DETAIL_PAYMENTS = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/pay-profile/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};


export const FETCH_PAYMENT_PROFILE_BY_TEAMMEMBER = async (
  organisationId,
  loggedMemberId
) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/pay-profile/detailByMember`, {
        params: {
          organisationId,
          loggedMemberId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const CREATE_PAYMENTS = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/pay-profile/create`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const UPDATE_PAYMENTS = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/pay-profile/update`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const DELETE_PAYMENTS = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/pay-profile/delete?${query}`)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_PAYMENT = () => (dispatch) => {
  const newModel = {
    _id: "new",
    description: "General",
    detailedDescription: "",
    hours: { weekly: 40, daily: 9 },
    days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    publicHolidaysPaid: true,
    bankedHoursUsed: true,
    paidLunch: false,
    includeBreakInTotalHours: false,
    payLunchWhenHoursExceed: 5,
    bonusHoursUsed: true,
    payBonusWhenHoursExceed: 8.5,
    includeOrdinaryHoursOverStandardWeek: false,
    standardTimes: {
      startTime: "05:30",
      startBreak: "11:30",
      endBreak: "12:00",
      finishTime: "16:00",
    },
    leave: {
      personalLeave: true,
      annualLeave: true,
      annualLeaveLoading: true,
    },
    overtimeUsed: true,
    overtime: {
      calculated: "weekly",
      overtime150Used: true,
      oveertime150after: 40,
      overtime200Used: false,
      oveertime200after: 42,
    },
    allowances: {
      firstAid: false,
      leadingHand: false,
      meal: false,
      plant: false,
      workingAway: false,
    },
    status: true,
  };
  return dispatch(setDetailPayment(newModel));
};
