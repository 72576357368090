import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Select, Spin } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  NEW_VEHICLE_MAKE,
  FETCH_DETAIL_VEHICLE_MAKE,
  setDetailVehicleMake,
  CREATE_VEHICLE_MAKE,
  UPDATE_VEHICLE_MAKE,
  setVehicleModel,
  FETCH_ALL_VEHICLE_MODEL,
} from "../../store/fleet-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Option } = Select;

const DetailVehicleMake = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailMake, models } = useSelector(
    ({ fleets }) => fleets.fleetReducer
  );
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailMake._id;
    if (id === "new") {
      CREATE_VEHICLE_MAKE(values).then((data) => {
        setLoading(false);
        navigate("/fleet/vehicle/makes");
      });
    } else {
      UPDATE_VEHICLE_MAKE(values).then((data) => {
        setLoading(false);
        navigate("/fleet/vehicle/makes");
      });
    }
  };

  useEffect(() => {
    const updateState = () => {
      if (id === "new") {
        dispatch(NEW_VEHICLE_MAKE());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_VEHICLE_MAKE(id).then((data) => {
          dispatch(setDetailVehicleMake(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailMake) {
      form.setFields([
        {
          name: "description",
          value: detailMake.description,
        },
        {
          name: "active",
          value: detailMake.active,
        },
        {
          name: "model",
          value: detailMake?.model?._id,
        },
      ]);
    }
  }, [detailMake]);

  useEffect(() => {
    FETCH_ALL_VEHICLE_MODEL(organisationId).then((data) =>
      dispatch(setVehicleModel(data))
    );
  }, [organisationId]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Vehicle Makes", path: "/fleet/vehicle/makes" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/fleet/vehicle/make/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            form={form}
            requiredMark={false}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Vehicle Model" name="model">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {models &&
                  models.map((type) => {
                    return (
                      <Option key={type._id} value={type._id}>
                        {type.description}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item label="Status" name="active" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default DetailVehicleMake;
