import "antd/dist/antd.css";
import { Form, Input, Button, Switch } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";

const EditOrganisations = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();

  const onFinish = (values) => {
    navigate("/team/organisations");
  };

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Organisations" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/team/organisations/new"
      form={
        <Form
          labelAlign="left"
          requiredMark={false}
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 8 }}
          onFinish={onFinish}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Mobile" name="mobile">
            <Input />
          </Form.Item>
          <Form.Item label="Status" name="status" valuePropName="checked">
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};

export default EditOrganisations;
