import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Badge } from "antd";
import StandardTableTabPaneLayout from "../../layouts/StandardTableTabPaneLayout/StandardTableTabPaneLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_PURCHASE,
  setPurchases,
  DELETING_PURCHASE,
} from "../../store/contact-store/reducer";
import { CheckCircleOutlined } from "@ant-design/icons";
import { INVOICE_STATUS } from "../../constants";

const Purchase = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const { purchases } = useSelector(({ contact }) => contact.contactReducer);
  const [dataList, setDataList] = useState([]);
  const [filterAccount, setFilterAccount] = useState([]);
  const [tabSort, setTabSort] = useState({
    tabs: [
      { type: "All" },
      { type: "Draft", length: 0 },
      { type: "Submitted", length: 0 },
      { type: "Authorised", length: 0 },
      { type: "Billed", length: 0 },
    ],
    tabChoosed: "All",
  });

  const [csv, setCsv] = useState({
    headers: [
      {
        label: "contact",
        key: "contact",
      },
      {
        label: "date",
        key: "date",
      },
      {
        label: "deliveryDate",
        key: "deliveryDate",
      },
      {
        label: "reference",
        key: "reference",
      },
      {
        label: "Status",
        key: "status",
      },
    ],
    data: [],
    fileName: "allPurchase.csv",
  });
  const [isLoading, setIsLoading] = useState(true);
  const columns = [
    {
      title: "Order Number",
      dataIndex: "orderNumber",
      key: "orderNumber",
      sorter: (a, b) => a.orderNumber.localeCompare(b.orderNumber),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (contact) => contact?.name,
      sorter: (a, b) => a.contact?.name.localeCompare(b.contact?.name),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Sent To Contact",
      dataIndex: "sentToContact",
      key: "sentToContact",
      render: (sentToContact) => {
        return (
          <>
            {sentToContact ? (
              <span
                style={{
                  color: "rgb(33, 186, 69)",
                  fontSize: "12px",
                  marginLeft: 7,
                }}
              >
                <CheckCircleOutlined color="#21ba45" /> Sent
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
      sorter: (a, b) => a.sentToContact - b.sentToContact,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return status;
      },
      sorter: (a, b) => b.status - a.status,
    },
  ];

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        contact: e.contact,
        date: e.date,
        deliveryDate: e.deliveryDate,
        orderNumber: e.orderNumber,
        reference: e.reference,
        status: e.status,
      });
    });
    return csvData;
  };

  const FETCH_DATA = () => {
    FETCH_ALL_PURCHASE(organisationId)
      .then((data) => {
        dispatch(setPurchases(data));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (purchases) {
      const columns = handleColumnCsv(purchases);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [purchases]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    let filterCode = [];
    let filterCodeResponsive = [];

    purchases?.forEach((ele) => {
      let filterResponsive = {
        _id: ele?._id,
        title: `${ele?.orderNumber}`,
        description: `${ele?.reference}`,
      };

      if (tabSort?.tabChoosed?.toLowerCase() !== "all") {
        if (tabSort?.tabChoosed?.toLowerCase() === ele?.status?.toLowerCase()) {
          filterCode.push(ele);
          filterCodeResponsive.push(filterResponsive);
        }
      } else {
        filterCode = [...purchases];
        filterCodeResponsive.push(filterResponsive);
      }
    });

    setFilterAccount(filterCode);
    setDataList(filterCodeResponsive);
  }, [purchases, tabSort]);

  useEffect(() => {
    const drafts = [];
    const approves = [];
    const awaiting = [];
    const billed = [];

    purchases.map((bill) => {
      if (bill.status.toUpperCase() === INVOICE_STATUS.DRAFT) {
        drafts.push(bill);
      } else if (bill.status.toUpperCase() === INVOICE_STATUS.SUBMITTED) {
        awaiting.push(bill);
      } else if (bill.status.toUpperCase() === INVOICE_STATUS.AUTHORISED) {
        approves.push(bill);
      } else if (bill.status.toUpperCase() === INVOICE_STATUS.BILLED) {
        billed.push(bill);
      }
    });
    setTabSort({
      ...tabSort,
      tabs: [
        { type: "All" },
        { type: "Draft", length: drafts?.length },
        { type: "Awaiting Approval", length: awaiting?.length },
        { type: "Approved", length: approves?.length },
        { type: "Billed", length: billed?.length },
      ],
    });
  }, [purchases]);

  return (
    <div className="table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableTabPaneLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Purchase Orders" }]}
            dataSource={filterAccount}
            tabSort={tabSort}
            setTabSort={setTabSort}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_PURCHASE}
            table="purchase"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Purchase Orders" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_PURCHASE}
            table="purchase"
          />
        </div>
      </div>
    </div>
  );
};

export default Purchase;
