import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
//state
const postWebsiteSlice = createSlice({
  name: "billStore",
  initialState: {
    bills: [],
    detailBill: null,
  },
  reducers: {
    setBills: (state, action) => {
      state.bills = action.payload;
      state.detailBill = null;
    },
    setDetailBill: (state, action) => {
      state.detailBill = action.payload;
    },
  },
});

export default postWebsiteSlice.reducer;

//action
export const { setBills, setDetailBill } = postWebsiteSlice.actions;

//Bill
export const FETCH_ALL_BILL = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/bill/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_BILL = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/bill/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_BILL = async (data, file) => {
  try {
    const formData = new FormData();
    if (file && file.file) {
      formData.append("file", file.file);
    }
    formData.append("data", JSON.stringify(data));

    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/bill/create`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    // console.log(JSON.parse(err.response.data.payload.message))
    throw err.response.data.payload.message;
  }
};

export const UPDATING_BILL = async (data, file) => {
  try {
    const formData = new FormData();
    if (file && file.file) {
      formData.append("file", file.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/bill/update`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    // console.log(JSON.parse(err.response.data.payload.message))
    throw err.response.data.payload.message;
  }
};

export const DELETING_BILL = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/bill/delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

export const DOWNLOAD_GOOGLE_CLOUD_FILE = async (name) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/google/download`, {
        params: {
          name: name
        },
        responseType: "blob"
      })
      .then((data) => {
        const url = URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      });
  } catch (err) {
    throw err;
  }
}

export const UPDATING_INVOICE_STATUS = async ({ _id, status}) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/invoice/status`, {
        params: {
          _id,
          status
        }
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    console.log(JSON.parse(err.response.data.payload.message))
    throw err;
  }
};

export const NEW_BILL = () => (dispatch) => {
  const newModel = {
    _id: "new",
    contact: "",
    image: "",
    date: moment(),
    dueDate: "",
    orderNumber: "",
    reference: "",
    sentToContact: false,
    status: "DRAFT",
  };
  return dispatch(setDetailBill(newModel));
};
