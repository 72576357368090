import {
  Form,
  Input,
  Spin,
  Space,
  DatePicker,
  Select,
  message,
} from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  CREATING_QUOTE,
  FETCH_DETAIL_QUOTE,
  NEW_QUOTE,
  setDetailQuote,
  UPDATING_QUOTE,
  UPDATING_QUOTE_STATUS,
} from "../../store/quote-store/reducer";
import {
  FETCH_ALL_CONTACT,
  setContacts,
} from "../../store/contact-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import LineItemTableWithAction from "../../shared-components/LineItemTableWithAction";
import {
  INVOICE_STATUS,
  LINE_ITEM_TABLE_TYPE,
  TAX_RATE_DATA,
} from "../../constants";
import moment from "moment";
import InvoiceCardWithAction from "../../shared-components/InvoiceCardWithAction";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Option } = Select;

const EditQuote = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { contacts } = useSelector(({ contact }) => contact.contactReducer);
  const { detailQuote } = useSelector(({ quote }) => quote.quoteReducer);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState({
    draft: false,
    submitted: false,
    approved: false,
    voided: false,
    deleted: false,
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [status, setStatus] = useState();
  const [readyLineItemState, setReadyLineItemState] = useState(false);
  const [total, setTotal] = useState({
    subTotal: 0.0,
    GST: 0.0,
    total: 0.0,
  });
  const [lineItems, setLineItems] = useState([]);
  const lineItemColumns = [
    {
      title: "Item",
      dataIndex: "item",
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
    },
    {
      title: "Unit Price",
      dataIndex: "unitAmount",
      editable: true,
    },
    {
      title: "Account",
      dataIndex: "account",
      editable: true,
    },
    {
      title: "Tax Rate",
      dataIndex: "taxType",
      editable: true,
    },
    {
      title: "Department",
      dataIndex: "department",
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: true,
    },
  ];

  const onFinish = async (values) => {
    if (readyLineItemState && checkValidLineItem()) {
      setLoading(true);
      values.organisationId = organisationId;
      values._id = detailQuote._id;
      values.sentToContact = false;
      values.date = values.date.format("YYYY-MM-DD");
      if (values.expiryDate) {
        values.expiryDate = values.expiryDate.format("YYYY-MM-DD");
      }
      values.status = status;

      //take contact xeroCode
      const contactXeroCode = contacts.filter(
        (c) => c._id === values.contact
      )[0].xeroId;
      values.contactXeroCode = contactXeroCode;

      //handle line items
      const handleLineItems = lineItems?.map((l) => {
        return {
          itemId: l.itemId,
          position: l.position,
          itemCode: l.itemCode,
          description: l.description,
          quantity: Number(l.quantity),
          unitAmount: Number(l.unitAmount),
          accountCodeId: l.accountCodeId,
          accountCode: l.accountCode,
          amount: l.amount,
          taxType: l.taxTypeId,
          account: l.account,
          item: l.item,
          tracking: [
            {
              name: "Department",
              option: l.department,
            },
          ],
        };
      });

      values.lineItems = handleLineItems;

      if (id === "new") {
        CREATING_QUOTE(values).then((data) => {
          setLoading(false);
          navigate("/quote");
        });
      } else {
        UPDATING_QUOTE(values).then((data) => {
          setLoading(false);
          navigate("/quote");
        });
      }
    } else {
      message.error("Validate");
    }
  };

  const checkValidLineItem = () => {
    let valid = true;
    if (lineItems.length) {
      for (let i = 0; i < lineItems.length; i++) {
        if (!lineItems[i].itemId) {
          valid = false;
          break;
        }
      }
    } else {
      valid = false;
    }
    return valid;
  };

  const fetchSubData = async () => {
    const contacts = await FETCH_ALL_CONTACT(organisationId);
    if (contacts) {
      dispatch(setContacts(contacts));
    }
  };

  const handleTotalPriceLineItems = (array) => {
    let totalPrice = 0;
    array.forEach((ele) => {
      totalPrice = Number(
        totalPrice + Number(ele.quantity) * Number(ele.unitAmount)
      );
    });
    form.setFields([
      {
        name: "Total",
        value: Number(totalPrice).toFixed(2),
      },
    ]);
    const subTotal = totalPrice;
    setTotal({
      subTotal: subTotal,
      GST: subTotal * 0.1,
      total: subTotal + subTotal * 0.1,
    });
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      if (id === "new") {
        dispatch(NEW_QUOTE());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_QUOTE(id).then((data) => {
          dispatch(setDetailQuote(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [id]);

  useEffect(() => {
    if (id !== "new") {
      setReadyLineItemState(true);
    }
    if (detailQuote) {
      const handleLineItems = detailQuote?.lineItems?.map((l) => {
        const taxType = TAX_RATE_DATA.filter((t) => t.name === l.taxType)[0];
        return {
          _id: l._id,
          position: l.position,
          item: l.itemId?.name,
          itemId: l.itemId?._id,
          itemCode: l.itemId?.sku,
          description: l.description,
          taxType: taxType?.value,
          taxTypeId: l.taxType,
          quantity: l.quantity,
          unitAmount: l.unitAmount,
          amount: Number(l.unitAmount) * Number(l.quantity),
          account: `${l.accountCodeId?.code} - ${l.accountCodeId?.name}`,
          accountCodeId: l.accountCodeId?._id,
          accountCode: l.accountCodeId?.code,
          department: l.tracking[0]?.option,
        };
      });

      if (handleLineItems) {
        setLineItems(handleLineItems);
      }

      setStatus(detailQuote?.status)
      form.setFields([
        {
          name: "contact",
          value: detailQuote.contact?._id,
        },
        {
          name: "date",
          value: detailQuote.date ? moment(detailQuote.date) : null,
        },
        {
          name: "expiryDate",
          value: detailQuote.expiryDate ? moment(detailQuote.expiryDate) : null,
        },
        {
          name: "quoteNumber",
          value: detailQuote.quoteNumber,
        },
        {
          name: "reference",
          value: detailQuote.reference,
        },
        {
          name: "title",
          value: detailQuote.title,
        },
        {
          name: "summary",
          value: detailQuote.summary,
        },
        {
          name: "status",
          value: detailQuote.status,
        },
      ]);
    }
  }, [detailQuote]);

  useEffect(() => {
    if (lineItems) {
      handleTotalPriceLineItems(lineItems);
    }
  }, [lineItems]);

  return (
    <Form
      labelAlign="left"
      layout="vertical"
      form={form}
      requiredMark={false}
      onFinish={onFinish}
    >
      <InvoiceCardWithAction
        type={LINE_ITEM_TABLE_TYPE.QUOTE}
        currentData={detailQuote}
        xeroPath={`https://go.xero.com/app/!7dMn8/quotes/view/${detailQuote?.xeroId}`}
        loading={loading}
        setLoading={setLoading}
        setStatus={setStatus}
        disabled={detailQuote && (detailQuote.status === INVOICE_STATUS.BILLED)}
        UPDATING_FUNC={UPDATING_QUOTE_STATUS}
        title={detailQuote && detailQuote.status}
        hierachy={[
          { key: 1, label: "Quote", path: "/quote" },
          { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
        ]}
        editPath="/quote/new"
        form={
          initialLoading ? (
            <Spin spinning={initialLoading} indicator={antIcon}></Spin>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  marginBottom: 2,
                }}
              >
                <Space wrap>
                  <Form.Item
                    label="Contact"
                    name="contact"
                    style={{ width: 150 }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder="Contact"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toString()
                          ?.replace(/,/gi, "")
                          .toLowerCase()
                          ?.includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA?.children
                          ?.toString()
                          ?.replace(/,/gi, "")
                          ?.toLowerCase()
                          ?.localeCompare(
                            optionB?.children
                              ?.toString()
                              ?.replace(/,/gi, "")
                              ?.toLowerCase()
                          )
                      }
                    >
                      {contacts &&
                        contacts?.map((contact) => {
                          return (
                            <Option key={contact._id} value={contact._id}>
                              {contact.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="date"
                    label="Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select date!",
                      },
                    ]}
                    wrapperCol={{ sm: 24 }}
                  >
                    <DatePicker format="DD-MMM-YYYY" />
                  </Form.Item>

                  <Form.Item name="expiryDate" label="Expiry">
                    <DatePicker format="DD-MMM-YYYY" />
                  </Form.Item>

                  <Form.Item name="quoteNumber" label="Quote #">
                    <Input placeholder="Order Number" disabled={id !== "new"}/>
                  </Form.Item>

                  <Form.Item name="reference" label="Reference">
                    <Input placeholder="Reference" />
                  </Form.Item>

                  <Form.Item name="title" label="Title">
                    <Input placeholder="Title" />
                  </Form.Item>

                  <Form.Item name="summary" label="Summary">
                    <Input placeholder="Summary" />
                  </Form.Item>
                </Space>
              </div>
              <LineItemTableWithAction
                totalData={total}
                lineItems={lineItems}
                readLineItemState={readyLineItemState}
                setReadyLineItemState={setReadyLineItemState}
                setLineItems={setLineItems}
                lineItemColumns={lineItemColumns}
                type={LINE_ITEM_TABLE_TYPE.QUOTE}
              />
            </>
          )
        }
      />
    </Form>
  );
};

export default EditQuote;
