import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth";
import user from "./user-store";
import org from "./org-store";
import team from "./team-member-store";
import departments from "./department-store";
import fleets from "./fleet-store";
import payments from "./payment-store";
import timeSheet from "./time-sheet-store";
import fleetPreStart from "./fleet-prestart-store";
import documents from "./document-store";
import items from "./items-store";
import contact from "./contact-store";
import bill from "./bill-store";
import invoice from "./invoice-store";
import quote from "./quote-store";
import receipt from "./receipt-store";
import common from "./common-store";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    user,
    common,
    org,
    team,
    departments,
    fleets,
    payments,
    timeSheet,
    fleetPreStart,
    documents,
    items,
    contact,
    bill,
    invoice,
    quote,
    receipt,
  });

  /*
  Reset the redux store when user logged out
   */
  if (action.type === "auth/user/userLoggedOut") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
