import EditBill from "../../components/Bill/EditBill";

const Bills = () => {
  return (
    <>
      <EditBill />
    </>
  );
};

export default Bills;
