import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin, message } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  CREATING_DOCUMENT_CATEGORY,
  FETCH_DETAIL_DOCUMENT_CATEGORY,
  NEW_DOCUMENT_CATEGORY,
  setDetailDocumentCategory,
  UPDATING_DOCUMENT_CATEGORY,
} from "../../store/document-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditDocumentCategory = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailDocumentCategory } = useSelector(
    ({ documents }) => documents.documentReducer
  );
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailDocumentCategory?._id;
    values.code = values.code.toUpperCase();
    if (id === "new") {
      CREATING_DOCUMENT_CATEGORY(values)
      .then((data) => {
        setLoading(false);
        navigate("/documents/categories");
      })
      .catch(err => {
        const res = err.response.data.payload.message
        if(res.includes("duplicate")) {
          message.error(`Duplicate ${values.code} code`)
        }
        setLoading(false);
      })
    } else {
      UPDATING_DOCUMENT_CATEGORY(values)
      .then((data) => {
        setLoading(false);
        navigate("/documents/categories");
      })
      .catch(err => {
        const res = err.response.data.payload.message
        if(res.includes("duplicate")) {
          message.error(`Duplicate ${values.code} code`)
        }
        setLoading(false);
      })
    }
  };

  useEffect(() => {
    const updateState = () => {
      if (id === "new") {
        dispatch(NEW_DOCUMENT_CATEGORY());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_DOCUMENT_CATEGORY(id).then((data) => {
          dispatch(setDetailDocumentCategory(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailDocumentCategory) {
      form.setFields([
        {
          name: "code",
          value: detailDocumentCategory.code.toUpperCase(),
        },
        {
          name: "description",
          value: detailDocumentCategory.description,
        },
        {
          name: "active",
          value: detailDocumentCategory.active,
        },
      ]);
    }
  }, [detailDocumentCategory]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Document Categories", path: "/documents/categories" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/documents/categories/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            requiredMark={false}
            form={form}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Code" name="code" rules={[{ required: true, message: "The Code must not exceed 4 characters." }]}>
              <Input maxLength={4} />
            </Form.Item>
            <Form.Item label="Active" name="active" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditDocumentCategory;
