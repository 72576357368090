import Document from "../../../components/Document";

const Documents = () => {
  return (
    <>
      <Document description="Policy"/>
    </>
  );
};

export default Documents;
