import Contacts from "../../components/Contact";

const Contact = () => {
  return (
    <>
      <Contacts />
    </>
  );
};

export default Contact;
