import TimeSheets from "./index";
import EditTimeSheets from "./detail";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    //employment type
    {
      path: "/payroll/all-records",
      component: TimeSheets,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/payroll/all-records/:idMember/",
      component: EditTimeSheets,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
  ],
};

export default router;
