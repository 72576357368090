import React, { useState, useEffect } from "react";
import { Button, Form } from "antd";
import { CloseCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import StandardLineItemTableLayout from "../layouts/StandardTableLayout/StandardLineItemTableLayout";
import { LINE_ITEM_TABLE_TYPE, TAX_RATE_DATA } from "../constants";
import {
  FETCH_ACOUNT_CODE,
  FETCH_ALL_ITEMS,
  setAccountCode,
  setItems,
} from "../store/items-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_DEPARTMENTS_IN_ORGANISATION,
  setDepartments,
} from "../store/department-store/reducer";

export const EditableContext = React.createContext(null);

const renderAccount = (item) => ({
  item: item,
  value: `${item.code} - ${item.description}`,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ margin: "0px" }}>
          {item.code} - {item.description}
        </p>
      </div>
    </div>
  ),
});

const renderItem = (item) => ({
  item: item,
  value: item.name,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ margin: "0px" }}>{item.saleDescription}</p>
        <p style={{ margin: "0px" }}>Item Number: {item.sku}</p>
        <p style={{ margin: "0px" }}>Cost: {item.salePrice}</p>
      </div>
    </div>
  ),
});

const renderDepartment = (item) => ({
  item: item,
  value: item.name,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ margin: "0px" }}>{item.name}</p>
      </div>
    </div>
  ),
});

// Drag & Drop
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement((props) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
});

const SortableBody = SortableContainer((props) => <tbody {...props} />);

/* ********** Main funtion ****************/
const LineItemTableWithAction = ({
  totalData,
  lineItems,
  readyLineItemState,
  setReadyLineItemState,
  setLineItems,
  lineItemColumns,
  type,
  instructions,
}) => {
  const dispatch = useDispatch();
  const [lineItemColumnUpdate, setLineItemColumnUpdate] = useState([]);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { departments } = useSelector(
    ({ departments }) => departments.departmentReducer
  );
  const { accounCodes } = useSelector(({ items }) => items.itemReducer);
  const { items } = useSelector(({ items }) => items.itemReducer);

  const [handleAccounts, setHandleAccounts] = useState([]);
  const [handleDepartments, setHandleDepartments] = useState([]);
  const [handleItems, setHandleItems] = useState([]);
  const [showAccountData, setShowAccountData] = useState([]);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        lineItems.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setLineItems(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = lineItems.findIndex(
      (x) => x.position === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onAddLineItem = () => {
    const lineItem = {
      _id: lineItems.length,
      position: lineItems.length,
      item: "",
      itemId: "",
      itemCode: "",
      description: "",
      quantity: 0.0,
      unitAmount: 0.0,
      amount: 0.0,
      account: "",
      taxType: "",
      taxTypeId: "",
      accountCodeId: "",
      accounCodes: "",
      department: "",
    };
    setLineItems([...lineItems, lineItem]);
    // switch (type) {

    //   case LINE_ITEM_TABLE_TYPE.PURCHASE_ORDER:
    //     {
    //       const lineItem = {
    //         _id: lineItems.length,
    //         position: lineItems.length,
    //         item: "",
    //         itemId: "",
    //         itemCode: "",
    //         description: "",
    //         quantity: 0.0,
    //         unitAmount: 0.0,
    //         amount: 0.0,
    //         account: "",
    //         taxType: "",
    //         taxTypeId: "",
    //         accountCodeId: "",
    //         accounCodes: "",
    //         department: "",
    //       };

    //       setLineItems([...lineItems, lineItem]);
    //     }
    //     break;
    //   default:
    //     break;
    // }
  };

  const onEditLineItem = (element) => {
   
    switch (type) {
      case LINE_ITEM_TABLE_TYPE.PURCHASE_ORDER: {
        const taxRate = TAX_RATE_DATA.filter(
          (e) => e.name === element?.item?.purchaseTax
        )[0];
        return {
          item: `${element.item.sku} - ${element.item.name}`,
          itemId: element.item._id,
          itemCode: element.item.sku,
          department: element.item.department?.name,
          unitAmount: element.item?.costPrice || 0,
          taxType: taxRate?.value,
          taxTypeId: taxRate?.name,
          account: element.item.purchaseAccount?.name,
          accountCode: element.item.purchaseAccount?.code,
          accountCodeId: element.item.purchaseAccount?._id,
          description: element.item.purchaseDescription,
        };
      }
       
      case LINE_ITEM_TABLE_TYPE.INVOICE: {
        const taxRate = TAX_RATE_DATA.filter(
          (e) => e.name === element?.item?.saleTax
        )[0];
        return {
          item: `${element.item.sku} - ${element.item.name}`,
          itemId: element.item._id,
          itemCode: element.item.sku,
          department: element.item.department?.name,
          unitAmount: element.item?.salePrice || 0,
          taxType: taxRate?.value,
          taxTypeId: taxRate?.name,
          account: element.item.saleAccount?.name,
          accountCode: element.item.saleAccount?.code,
          accountCodeId: element.item.saleAccount?._id,
          description: element.item.saleDescription,
        };
      }
       
      case LINE_ITEM_TABLE_TYPE.BILL: {
        const taxRate = TAX_RATE_DATA.filter(
          (e) => e.name === element?.item?.purchaseTax
        )[0];
        return {
          item: `${element.item.sku} - ${element.item.name}`,
          itemId: element.item._id,
          itemCode: element.item.sku,
          department: element.item.department?.name,
          unitAmount: element.item?.costPrice || 0,
          taxType: taxRate?.value,
          taxTypeId: taxRate?.name,
          account: element.item.purchaseAccount?.name,
          accountCode: element.item.purchaseAccount?.code,
          accountCodeId: element.item.purchaseAccount?._id,
          description: element.item.purchaseDescription,
        };
      }

      case LINE_ITEM_TABLE_TYPE.QUOTE: {
        const taxRate = TAX_RATE_DATA.filter(
          (e) => e.name === element?.item?.saleTax
        )[0];
        return {
          item: `${element.item.sku} - ${element.item.name}`,
          itemId: element.item._id,
          itemCode: element.item.sku,
          department: element.item.department?.name,
          unitAmount: element.item?.salePrice || 0,
          taxType: taxRate?.value,
          taxTypeId: taxRate?.name,
          account: element.item.saleAccount?.name,
          accountCode: element.item.saleAccount?.code,
          accountCodeId: element.item.saleAccount?._id,
          description: element.item.saleDescription,
        };
      }
       
      default:
        return {};
    }
  };

  const onDeleteLineItem = (record) => {
    const idItems = [];
    const newLineItem = [...lineItems];
    newLineItem.filter((ele) => idItems.push(ele?._id));
    const index = idItems.indexOf(record?._id);

    if (index !== -1) {
      newLineItem.splice(index, 1);
    }
    setLineItems(newLineItem);
  };

  const fetchSubData = async () => {
    if (organisationId) {
      const items = await FETCH_ALL_ITEMS(organisationId);
      if (items) {
        dispatch(setItems(items));
      }

      const accounCodes = await FETCH_ACOUNT_CODE(organisationId);
      if (accounCodes) {
        const categoriseAccount = [];
        const filterAssets = accounCodes.filter(
          (e) => e.typeGroups === "ASSETS"
        );
        const assets = {
          nameGroup: "ASSETS",
          items: filterAssets,
        };
        const filterEquities = accounCodes.filter(
          (e) => e.typeGroups === "EQUITY"
        );
        const equities = {
          nameGroup: "EQUITY",
          items: filterEquities,
        };
        const filterLiabilities = accounCodes.filter(
          (e) => e.typeGroups === "LIABILITIES"
        );
        const liabilities = {
          nameGroup: "LIABILITIES",
          items: filterLiabilities,
        };
        const filterExpenses = accounCodes.filter(
          (e) => e.typeGroups === "EXPENSES"
        );
        const expenses = {
          nameGroup: "EXPENSES",
          items: filterExpenses,
        };
        const filterRevenue = accounCodes.filter(
          (e) => e.typeGroups === "REVENUE"
        );
        const revenue = {
          nameGroup: "REVENUE",
          items: filterRevenue,
        };
        categoriseAccount.push(assets);
        categoriseAccount.push(expenses);
        categoriseAccount.push(equities);
        categoriseAccount.push(liabilities);
        categoriseAccount.push(revenue);
        setShowAccountData(categoriseAccount);
        dispatch(setAccountCode(accounCodes));
      }

      const departments = await FETCH_ALL_DEPARTMENTS_IN_ORGANISATION(
        organisationId
      );
      if (departments) {
        dispatch(setDepartments(departments));
      }
    }
  };

  useEffect(() => {
    if (lineItemColumns) {
      setLineItemColumnUpdate([
        {
          dataIndex: "sort",
          width: 30,
          align: "center",
          className: "drag-visible",
          render: () => <DragHandle />,
        },
        ...lineItemColumns,
        {
          // title: "operation",
          dataIndex: "operation",
          align: "center",
          render: (_, record) =>
            lineItems.length > 0 ? (
              <CloseCircleOutlined onClick={() => onDeleteLineItem(record)} />
            ) : null,
        },
      ]);
    }
  }, [organisationId, lineItems]);

  useEffect(() => {
    if (items) {
      const handleItem = items.map((item) => {
        return renderItem(item);
      });
      setHandleItems(handleItem);
    }
    if (departments) {
      const handleDepartment = departments.map((department) => {
        return renderDepartment(department);
      });
      setHandleDepartments(handleDepartment);
    }
    if (accounCodes) {
      const handleAccount = accounCodes.map((account) => {
        return renderAccount(account);
      });
      setHandleAccounts(handleAccount);
    }
  }, [items, accounCodes, departments]);

  useEffect(() => {
    fetchSubData();
  }, [organisationId]);

  return (
    <StandardLineItemTableLayout
      showAccounts={showAccountData}
      accounts={handleAccounts}
      items={handleItems}
      departments={handleDepartments}
      totalData={totalData}
      lineItems={lineItems}
      setLineItems={setLineItems}
      lineItemColumns={lineItemColumnUpdate}
      readLineItemState={readyLineItemState}
      setReadyLineItemState={setReadyLineItemState}
      instructions={instructions}
      onAddLineItem={onAddLineItem}
      onEditLineItem={onEditLineItem}
      DraggableBodyRow={DraggableBodyRow}
      DraggableContainer={DraggableContainer}
    />
  );
};

export default LineItemTableWithAction;
