import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
//state
const postWebsiteSlice = createSlice({
  name: "userStore",
  initialState: {
    data: [],
    detailMember: null,
    searchText: "",
    success: "",
    csvExportData: [],
    visibleMenu: false,
  },
  reducers: {
    setUsers: (state, action) => {
      state.data = action.payload;
      state.detailMember = null;
    },
    setDetailUser: (state, action) => {
      state.detailMember = action.payload;
    },
    setCsvExportData: (state, action) => {
      state.csvExportData = action.payload;
    },
    setVisibleMenu: (state, action) => {
      state.visibleMenu = action.payload;

    }
  },
});

export default postWebsiteSlice.reducer;

//action
export const { setUsers, setDetailUser, setCsvExportData, setVisibleMenu } =
  postWebsiteSlice.actions;

export const FETCH_ALL_USERS_IN_ORGANISATION = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/watchUser/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.users;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_ALL_USERS_NOT_MATCH_IN_ORGANISATION = async (organisationId, memberId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/watchUser/allUserNotMatch`, {
        params: {
          organisationId,
          memberId
        },
      })
      .then((res) => {
        return res.data.payload.users;
      });
  } catch (err) {
   throw err
  }
};

export const CREATING_USER = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/watchUser/create`, data)
      .then((res) => {
        return res.data.payload.user;
      });
  } catch (err) {
   throw err
  }
};

export const UPDATING_USER = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/watchUser/update`, data)
      .then((res) => {
        return res.data.payload.user;
      });
  } catch (err) {
   throw err
  }
};

export const DELETING_USER = async ({ email, organisationId }) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/watchUser/delete?email=${email}&organisationId=${organisationId}`
      )
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_USER = () => (dispatch) => {
  const newModel = {
    _id: "new",
    email: "",
    password: "",
    lastName: "",
    firstName: "",
    role: [],
  };
  return dispatch(setDetailUser(newModel));
};
