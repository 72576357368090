import React from "react";
import EmploymentAwards from "../../../../components/EmploymentAwards";

const Award = () => {
  return (
    <>
      <EmploymentAwards />
    </>
  );
};

export default Award;
