import React from "react";
import {
  Comment,
  // Tooltip,
  List,
  Typography,
  Avatar,
  Space,
  Row,
  Col,
  // notification,
  Tag,
} from "antd";
// import {
//     CommentOutlined,
//     DeleteOutlined,
//     EditOutlined,
// } from "@ant-design/icons";
import moment from "moment";
import { Content } from "antd/lib/layout/layout";
import styles from "../../styles/StandardTableLayout.module.scss";

const splitName = (name) => {
  if (name) {
    const nameSplit = name.split(" ");
    const firstCharacters = nameSplit.reduce((last, current) => {
      return last.substring(1, 0) + current.substring(1, 0);
    });

    return firstCharacters;
  }
  return "";
};
const { Title } = Typography;

const TimesheetCommentView = ({ comments, role }) => {
  return (
    <Content className={styles["comment-timesheet-content"]}>
      <Row
        style={{
          borderRadius: "16px",
          boxShadow: "0px 0px 17px -2px #0000000a",
          background: "#fff",
        }}
      >
        <Col
          xs={24}
          xl={12}
          style={{
            padding: 30,
          }}
        >
          <Title level={4}> Comments</Title>
          <List
            className="comment-list"
            itemLayout="horizontal"
            dataSource={comments.filter(c => c.adminOnly)}
            renderItem={(item, index) => {
              return (
                item.adminOnly && role?.includes("admin") && 
                <li key={index}>
                  <Comment
                    author={
                      item.addedBy?.name ||
                      `${item?.addedBy?.firstName} ${item?.addedBy?.lastName}`
                    }
                    avatar={
                      item?.addedBy?.photo ? (
                        <Avatar
                          src={item?.addedBy?.photo}
                          alt={item.addedBy?.name}
                        />
                      ) : (
                        <Avatar style={{ backgroundColor: "#87d068" }}>
                          {splitName(item.addedBy?.name)}
                        </Avatar>
                      )
                    }
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item && item.description,
                        }}
                      ></div>
                    }
                    datetime={
                      <Space>
                        {moment(item.addedTime, "DD-MMM-YYYY").fromNow()}
                        {item.adminOnly && role?.includes("admin") && (
                          <>
                            <Tag color="#108ee9">{item.addedTime}</Tag>
                            <Tag color="default">Admin Only</Tag>
                          </>
                        )}
                      </Space>
                    }
                  />
                </li>
              )
            }}
          />

          <List
            className="comment-list"
            itemLayout="horizontal"
            dataSource={comments.filter(c => !c.adminOnly)}
            renderItem={(item, index) => {
              return (
                <li key={index}>
                  <Comment
                    author={
                      item.addedBy?.name ||
                      `${item?.addedBy?.firstName} ${item?.addedBy?.lastName}`
                    }
                    avatar={
                      item?.addedBy?.photo ? (
                        <Avatar
                          src={item?.addedBy?.photo}
                          alt={item.addedBy?.name}
                        />
                      ) : (
                        <Avatar style={{ backgroundColor: "#87d068" }}>
                          {splitName(item.addedBy?.name)}
                        </Avatar>
                      )
                    }
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item && item.description,
                        }}
                      ></div>
                    }
                    datetime={
                      <Space>
                        {moment(item.addedTime, "DD-MMM-YYYY").fromNow()}
                        {item.adminOnly && role?.includes("admin") && (
                          <>
                            <Tag color="#108ee9">{item.addedTime}</Tag>
                            <Tag color="default">Admin Only</Tag>
                          </>
                        )}
                      </Space>
                    }
                  />
                </li>
              )
            }}
          />

        </Col>
      </Row>
    </Content>
  );
};

export default TimesheetCommentView;
