import { Form, Input, Switch } from "antd";
import "../../../styles/teamMember.scss";

const FormCategories = ({ category, setCategory }) => {
  const [form] = Form.useForm();

  const handleChange = (e, name) => {
    const value = e.target.value;
    setCategory({
      ...category,
      [name]: value,
    });
  };

  return (
    <Form
      labelAlign="left"
      requiredMark={false}
      form={form}
      labelCol={{ span: 5 }}
    >
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true }]}
      >
        <Input onChange={(e) => handleChange(e, "description")} />
      </Form.Item>
      <Form.Item label="Code" name="code" rules={[{ required: true }]}>
        <Input onChange={(e) => handleChange(e, "code")} />
      </Form.Item>
      <Form.Item label="Status" name="active" valuePropName="checked">
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={(e) => handleChange(e, "active")}
        />
      </Form.Item>
    </Form>
  );
};

export default FormCategories;
