import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import cryptoRandomString from "crypto-random-string";
//state
const postWebsiteSlice = createSlice({
  name: "receiptStore",
  initialState: {
    receipt: [],
    detailReceipt: null,
  },
  reducers: {
    setReceipt: (state, action) => {
      state.receipt = action.payload;
      state.detailReceipt = null;
    },
    setDetailReceipt: (state, action) => {
      state.detailReceipt = action.payload;
    },
  },
});

export default postWebsiteSlice.reducer;

//action
export const { setReceipt, setDetailReceipt } = postWebsiteSlice.actions;

//Receipt
export const FETCH_ALL_RECEIPT = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/receipt/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_RECEIPT_IN_ORGANISATION = async (
  organisationId,
  id
) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/receipt/detail`, {
        params: {
          organisationId,
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_RECEIPT = async (data, image) => {
  try {
    const formData = new FormData();
    if (image && image.file) {
      formData.append("file", image.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/receipt/create`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      })
      .catch((err) => {
        throw err.response;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_RECEIPT = async (data, image) => {
  try {
    const formData = new FormData();
    if (image && image.file) {
      formData.append("file", image.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/receipt/update`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      })
      .catch((err) => {
        throw err.response;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_RECEIPT = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/receipt/delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

export const NEW_RECEIPT = () => (dispatch) => {
  const newModel = {
    _id: "new",
    organisationId: "",
    jobNumber: "",
    description: "",
    file: "",
    status: false,
  };
  return dispatch(setDetailReceipt(newModel));
};
