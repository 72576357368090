import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Button, Popover, Menu } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  setUserData,
  transferUserOrganisation,
} from "../../store/auth/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { MenuFoldOutlined } from "@ant-design/icons";
import { setVisibleMenu } from "../../store/user-store/reducer";

const UserPopover = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visiblePopup, setVisiblePopup] = useState(false);
  const currentOrg = props.user.selizationOrganisations.filter(
    (org) => org.organisationId === props.user.organisationId
  );
  const { visibleMenu } = useSelector(({ user }) => user.userReducer);

  const transferOrganisation = (choosenOrganisation) => {
    transferUserOrganisation(props.user, choosenOrganisation)
      .then((data) => {
        setVisiblePopup(false);
        navigate({ to: "/Dashboard", pathname: "/dashboard" });
        dispatch(
          setUserData({
            ...data.data.payload.user,
            selizationOrganisations: props.user.selizationOrganisations,
          })
        );
        dispatch(setVisibleMenu(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (visibleMenu) {
      setVisiblePopup(false);
    }
  }, [visibleMenu]);

  return (
    <div className="orgPop-draver">
      <MenuFoldOutlined
        className="icon-Menu"
        onClick={() => dispatch(setVisibleMenu(true))}
      />
      {props.user.role.includes("admin") ||
      props.user.selizationOrganisations.filter(
        (org) => org.organisationId !== props.user.organisationId
      ).length > 0 ? (
        <Popover
          placement="bottomRight"
          content={
            <>
              <Menu style={{ border: "none" }} selectable={false}>
                {props.user.role.includes("admin") && (
                  <>
                    <Menu.Item key="1">
                      <Link
                        to="/settings"
                        onClick={() => setVisiblePopup(false)}
                      >
                        Settings
                      </Link>
                    </Menu.Item>
                    <Menu.Divider />
                  </>
                )}
                {props.user.selizationOrganisations
                  .filter(
                    (org) => org.organisationId !== props.user.organisationId
                  )
                  .map((org, index) => {
                    return (
                      <Menu.Item
                        key={index}
                        onClick={() => transferOrganisation(org)}
                      >
                        {org.organisationName}
                      </Menu.Item>
                    );
                  })}
              </Menu>
            </>
          }
          visible={visiblePopup}
          overlayStyle={{ width: "300px", position: "fixed" }}
        >
          <Button
            type="primary"
            style={{
              float: "left",
              marginTop: 15,
              marginRight: "50px",
            }}
            ghost
            onClick={() => setVisiblePopup(!visiblePopup)}
          >
            {currentOrg[0].organisationName}
          </Button>
        </Popover>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserPopover;
