import { Card, Button, Layout, Breadcrumb } from "antd";
import React from "react";
import styles from "../../styles/StandardFormLayout.module.scss";
import { Link } from "react-router-dom";

const { Content } = Layout;
const StandardCardLayout = (props) => {
  return (
    <Content className={styles.content}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        {props?.hierachy?.map((item) => {
          return (
            <Breadcrumb.Item key={item.key}>
              {item.path ? (
                <Link key={item.key} to={item.path}>
                  {item.label}
                </Link>
              ) : (
                item.label
              )}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <Card
        className="custom-card"
        actions={props.actions}
        extra={props.extra}
        title={props.title}
      >
        {props.form}
      </Card>
    </Content>
  );
};

export default StandardCardLayout;
