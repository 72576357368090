import BankedReport from "../../components/AllRecords/BankedReport";

const DetailBankedReport = () => {
  return (
    <>
      <BankedReport />
    </>
  );
};

export default DetailBankedReport;
