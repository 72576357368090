import Quote from "./index";
import EditQuote from "./detail";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    //employment type
    {
      path: "/quote",
      component: Quote,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/quote/:id/",
      component: EditQuote,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
  ],
};

export default router;
