import React from "react";
import {
  Button,
  PageHeader,
  Radio,
  Dropdown,
  Menu,
  Tag,
  Spin,
  Modal,
} from "antd";
import {
  EllipsisOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { DELETING_CONTACT } from "../../../store/contact-store/reducer";

const HeaderView = ({ detailContact, view, setView }) => {
  const navigate = useNavigate();

  const showConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to delete these records?",
      icon: <ExclamationCircleOutlined />,
      content: "The operation cannot be undone.",
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onViewChange = (e) => {
    setView(e.target.value);
  };

  const handleDelete = () => {
    DELETING_CONTACT(`ids=${detailContact._id}`).then((data) => {
      navigate("/contact");
    });
  };

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => navigate("/contact")}
        title={detailContact?.name}
        avatar={detailContact?.logo !== "" ? detailContact?.logo : <div></div>}
        extra={[
          <Button
            key="1"
            type="primary"
            onClick={() => navigate(`/contact/${detailContact?._id}`)}
          >
            Edit
          </Button>,
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: <span onClick={showConfirm}>Delete</span>,
                  },
                ]}
              />
            }
            placement="bottomRight"
            trigger={["click"]}
          >
            <Button key="2" icon={<EllipsisOutlined />}></Button>
          </Dropdown>,
        ]}
      >
        <Radio.Group onChange={onViewChange} value={view}>
          <Radio value={"overview"}>Overview</Radio>
          <Radio disabled value={"timeline"}>
            Timeline
          </Radio>
        </Radio.Group>
      </PageHeader>
    </>
  );
};

export default HeaderView;
