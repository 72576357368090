import Document from "../../../components/Document";

const Documents = () => {
  return (
    <>
      <Document />
    </>
  );
};

export default Documents;
