import TimeSheets from "../../components/TimeSheets/index";

const TimeSheet = () => {
  return (
    <>
      <TimeSheets />
    </>
  );
};

export default TimeSheet;
