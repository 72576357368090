import Items from "../../components/Items";

const Item = () => {
  return (
    <>
      <Items />
    </>
  );
};

export default Item;
