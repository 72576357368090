import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Space,
  DatePicker,
  Select,
  Upload,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LineItemTableWithAction from "../../shared-components/LineItemTableWithAction";
import {
  INVOICE_STATUS,
  LINE_ITEM_TABLE_TYPE,
  PAYMENT_TERM_INVOICE_TYPE,
  TAX_RATE_DATA,
} from "../../constants";
import { CREATING_BILL } from "../../store/bill-store/reducer";
import {
  FETCH_ALL_CONTACT,
  setContacts,
  UPDATING_PURCHASE_STATUS,
} from "../../store/contact-store/reducer";
import {
  PaperClipOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const ConvertBillModal = ({
  visible,
  setVisible,
  data,
  setLoading,
  datalineItems,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { contacts } = useSelector(({ contact }) => contact.contactReducer);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [lineItems, setLineItems] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [status, setStatus] = useState(INVOICE_STATUS.DRAFT);
  const [readyLineItemState, setReadyLineItemState] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [total, setTotal] = useState({
    subTotal: 0.0,
    GST: 0.0,
    total: 0.0,
  });

  const lineItemColumns = [
    {
      title: "Item",
      dataIndex: "item",
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
    },
    {
      title: "Unit Price",
      dataIndex: "unitAmount",
      editable: true,
    },
    {
      title: "Account",
      dataIndex: "account",
      editable: true,
    },
    {
      title: "Tax Rate",
      dataIndex: "taxType",
      editable: true,
    },
    {
      title: "Department",
      dataIndex: "department",
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: true,
    },
  ];

  const handleCancel = () => {
    setVisible(false);
  };

  const props = {
    name: "file",
    maxCount: 1,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url,
          },
        ]);
      };
    },
  };

  const onSelectContact = (record) => {
    const term = record.item.saleTerm;
    const day = record.item.saleTermDay;
    switch (term) {
      case PAYMENT_TERM_INVOICE_TYPE.DAYSAFTERINVOICEDATE:
        form.setFields([
          {
            name: "dueDate",
            value: moment().add(Number(day), "days"),
          },
        ]);
        break;
      case PAYMENT_TERM_INVOICE_TYPE.DAYSAFTERINVOICEMONTH:
        form.setFields([
          {
            name: "dueDate",
            value: moment().endOf("month").add(Number(day), "days"),
          },
        ]);
        break;
      case PAYMENT_TERM_INVOICE_TYPE.OFCURRENTMONTH:
        form.setFields([
          {
            name: "dueDate",
            value: moment().add(Number(day) - moment().day() + 1, "days"),
          },
        ]);
        break;
      case PAYMENT_TERM_INVOICE_TYPE.OFFOLLOWINGMONTH:
        form.setFields([
          {
            name: "dueDate",
            value: moment().endOf("month").add(Number(day), "days"),
          },
        ]);
        break;
      default:
        break;
    }
  };

  const onFinish = async (values) => {
    values.organisationId = organisationId;
    if (data?.image) {
      values.image = data.image;
    }
    values._id = data._id;
    values.sentToContact = false;
    values.date = values.date.format("YYYY-MM-DD");
    if (values.dueDate) {
      values.dueDate = values.dueDate.format("YYYY-MM-DD");
    }
    values.status = status;

    //take contact xeroCode
    const contactXeroCode = contacts.filter((c) => c._id === values.contact)[0]
      .xeroId;
    values.contactXeroCode = contactXeroCode;

    //handle line items
    const handleLineItems = lineItems?.map((l) => {
      return {
        itemId: l.itemId,
        position: l.position,
        itemCode: l.itemCode,
        description: l.description,
        quantity: Number(l.quantity),
        unitAmount: Number(l.unitAmount),
        accountCodeId: l.accountCodeId,
        accountCode: l.accountCode,
        amount: l.amount,
        taxType: l.taxTypeId,
        account: l.account,
        item: l.item,
        tracking: [
          {
            name: "Department",
            option: l.department,
          },
        ],
      };
    });

    values.lineItems = handleLineItems;

    const updatePurchased = {
      _id: data?._id,
      status: INVOICE_STATUS.BILLED,
    };

    CREATING_BILL(values, null).then((data) => {
      setLoading(false);
    });
    UPDATING_PURCHASE_STATUS(updatePurchased).then((data) => {
      navigate("/bill");
    });
  };

  const handleTotalPriceLineItems = (array) => {
    let totalPrice = 0;
    array.forEach((ele) => {
      totalPrice = Number(
        totalPrice + Number(ele.quantity) * Number(ele.unitAmount)
      );
    });
    form.setFields([
      {
        name: "Total",
        value: Number(totalPrice).toFixed(2),
      },
    ]);
    const subTotal = totalPrice - totalPrice / 11;
    setTotal({
      subTotal: subTotal,
      GST: subTotal * 0.1,
      total: subTotal + subTotal * 0.1,
    });
  };

  useEffect(() => {
    setReadyLineItemState(true);
    if (data) {
      if (data.image) {
        const file = JSON.parse(data.image);
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url: file.url,
          },
        ]);
      }
      const handleLineItems = datalineItems?.map((l, i) => {
        const taxType = TAX_RATE_DATA.filter((t) => t.name === l.taxType)[0];
        return {
          _id: l._id,
          position: i,
          item: l.item,
          itemId: l.itemId,
          itemCode: l.itemCode,
          description: l.description,
          taxType: l.taxType,
          taxTypeId: l.taxTypeId,
          quantity: l.quantity,
          unitAmount: l.unitAmount,
          amount: Number(l.unitAmount) * Number(l.quantity),
          account: l.account,
          accountCodeId: l.accountCodeId,
          accountCode: l.accountCode,
          department: l.department,
        };
      });
      if (handleLineItems) {
        setLineItems(handleLineItems);
      }

      form.setFields([
        {
          name: "contact",
          value: data.contact?._id,
        },
        {
          name: "date",
          value: moment(data.date),
        },
        {
          name: "dueDate",
          value: moment(data.dueDate),
        },
        {
          name: "orderNumber",
          value: data.orderNumber,
        },
        {
          name: "reference",
          value: data.reference,
        },
        {
          name: "status",
          value: data.status,
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (lineItems) {
      handleTotalPriceLineItems(lineItems);
    }
  }, [lineItems]);

  const fetchSubData = async () => {
    const contacts = await FETCH_ALL_CONTACT(organisationId);
    if (contacts) {
      dispatch(setContacts(contacts));
    }
  };

  useEffect(() => {
    fetchSubData();
  }, []);

  return (
    <Modal
      title="Convert to bill"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={"90%"}
    >
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        labelAlign="left"
        scrollToFirstError
        autoComplete="on"
      >
        <Form.Item
          label="Contact"
          name="contact"
          style={{ width: 150 }}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Contact"
            optionFilterProp="children"
            onSelect={(e, record) => onSelectContact(record)}
            filterOption={(input, option) =>
              option?.children
                ?.toString()
                ?.replace(/,/gi, "")
                .toLowerCase()
                ?.includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              optionA?.children
                ?.toString()
                ?.replace(/,/gi, "")
                ?.toLowerCase()
                ?.localeCompare(
                  optionB?.children
                    ?.toString()
                    ?.replace(/,/gi, "")
                    ?.toLowerCase()
                )
            }
          >
            {contacts &&
              contacts?.map((contact) => {
                return (
                  <Option key={contact._id} value={contact._id} item={contact}>
                    {contact.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="date"
          label="Date"
          rules={[
            {
              type: "object",
              required: true,
              message: "Please select date!",
            },
          ]}
          wrapperCol={{ sm: 24 }}
        >
          <DatePicker format="DD-MMM-YYYY" />
        </Form.Item>

        <Form.Item name="dueDate" label="Due Date">
          <DatePicker format="DD-MMM-YYYY" />
        </Form.Item>

        <Form.Item name="reference" label="Reference">
          <Input placeholder="Reference" />
        </Form.Item>

        <Form.Item name="File" label=" ">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Upload {...props} showUploadList={false}>
              <Button icon={<FileAddOutlined />}></Button>
            </Upload>
            {fileList.length > 0
              ? fileList?.map((l, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        margin: "0 0 0 10px",
                        display: "flex",
                      }}
                    >
                      <PaperClipOutlined />
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "400px",
                        }}
                      >
                        <a
                          href={l.url}
                          target="_blank"
                          rel={l.name}
                          style={{
                            margin: "0 10px",
                          }}
                        >
                          {l.name}
                        </a>
                      </div>
                      <DeleteOutlined
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const index = fileList.indexOf(l.file);
                          const list = [...fileList];
                          list.splice(index, 1);
                          setFileList([...list]);
                        }}
                      />
                    </div>
                  );
                })
              : ""}
          </div>
        </Form.Item>

        <LineItemTableWithAction
          totalData={total}
          lineItems={lineItems}
          readLineItemState={readyLineItemState}
          setReadyLineItemState={setReadyLineItemState}
          setLineItems={setLineItems}
          lineItemColumns={lineItemColumns}
          type={LINE_ITEM_TABLE_TYPE.BILL}
        />
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Form.Item className="mg-0">
            <Button type="secondary" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item className="mg-0">
            <Button htmlType="submit" type="primary" loading={saveLoading}>
              Send
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default ConvertBillModal;
