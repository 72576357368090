import React, { useState, useEffect } from "react";
import {
  List,
  Card,
  Tooltip,
  Button,
  Space,
  Form,
  Input,
  Select,
  Switch,
  Spin,
} from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  NEW_FLEET_PRESTART,
  FETCH_DETAIL_FLEET_PRESTART,
  CREATE_FLEET_PRESTART,
  UPDATE_FLEET_PRESTART,
  setDetailFleetPrestart,
  MODAL_TYPE,
} from "../../store/fleet-prestart-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import FleetStartModal from "./components/FleetStartModal";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Option } = Select;

const EditFleetPreStart = () => {
  let navigate = useNavigate();
  const [fleetPrestartModalData, setFleetPrestartModalData] = useState({
    isOpen: false,
    type: "",
    title: "",
    data: null,
  });
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailFleetPrestart } = useSelector(
    ({ fleetPreStart }) => fleetPreStart.fleetPreStartReducer
  );
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [currentData, setCurrentData] = useState(null);
  const [modalInput, setModalInput] = useState("");
  const [formDisable, setFormDisable] = useState(false);

  const onOpenModalVisible = ({ category, index, type }) => {
    switch (type) {
      case MODAL_TYPE.ADD_QUESTION:
        setFleetPrestartModalData({
          isOpen: true,
          type: MODAL_TYPE.ADD_QUESTION,
          title: `Add New Question For ${category.title} Category`,
          data: category,
        });
        break;
      case MODAL_TYPE.ADD_CATEGORY:
        setFleetPrestartModalData({
          isOpen: true,
          type: MODAL_TYPE.ADD_CATEGORY,
          title: `New Category`,
        });
        break;
      case MODAL_TYPE.EDIT_CATEGORY:
        setFleetPrestartModalData({
          isOpen: true,
          type: MODAL_TYPE.EDIT_CATEGORY,
          title: `Rename "${category.title}" Category`,
          data: category,
        });
        break;
      case MODAL_TYPE.EDIT_QUESTION:
        setFleetPrestartModalData({
          isOpen: true,
          type: MODAL_TYPE.EDIT_QUESTION,
          title: `Edit "${category.questions[index]}"`,
          data: {
            category,
            index,
          },
        });
        break;
      case MODAL_TYPE.QUALIFIER:
        setFleetPrestartModalData({
          isOpen: true,
          type: MODAL_TYPE.QUALIFIER,
          title: `Qualifier "${category.title}"`,
          data: category,
        });
        break;
      default:
        break;
    }
  };

  const onHandleSubmitModal = (type) => {
    switch (type) {
      case MODAL_TYPE.ADD_CATEGORY:
        onAddCategory();
        break;
      case MODAL_TYPE.EDIT_CATEGORY:
        onEditCategory();
        break;
      case MODAL_TYPE.ADD_QUESTION:
        onAddQuestion();
        break;
      case MODAL_TYPE.EDIT_QUESTION:
        onEditQuestion();
        break;
      case MODAL_TYPE.QUALIFIER:
        onUpdateQualifierCategory();
        break;
      default:
        break;
    }
  };

  const onAddQuestion = () => {
    const workingCategory = fleetPrestartModalData.data;
    const updatedCategories = currentData.categories.map((c) => {
      if (c._id === workingCategory._id) {
        return {
          ...c,
          questions: [...c.questions, modalInput],
        };
      }
      return c;
    });
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
    onCloseModal();
  };

  const onEditQuestion = () => {
    const workingCategory = fleetPrestartModalData.data.category;
    const index = fleetPrestartModalData.data.index;
    const updatedCategories = currentData.categories.map((c) => {
      if (c._id === workingCategory._id) {
        let questions = [...c.questions];
        questions[index] = modalInput;
        return {
          ...c,
          questions,
        };
      }
      return c;
    });
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
    onCloseModal();
  };

  const onDeleteQuestion = ({ category, index }) => {
    const updatedCategories = currentData.categories.map((c) => {
      if (c._id === category._id) {
        let questions = [...c.questions];
        questions.splice(index, 1);
        return {
          ...c,
          questions,
        };
      }
      return c;
    });
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
  };

  const onAddCategory = () => {
    setCurrentData({
      ...currentData,
      categories: [
        ...currentData.categories,
        {
          _id: `new_${currentData.categories.length}`,
          mandatory: true,
          questions: [],
          title: modalInput,
        },
      ],
    });
    onCloseModal();
  };

  const onEditCategory = () => {
    const workingCategory = fleetPrestartModalData.data;
    const updatedCategories = currentData.categories.map((c) => {
      if (c._id === workingCategory._id) {
        return {
          ...c,
          title: modalInput,
        };
      }
      return c;
    });
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
    onCloseModal();
  };

  const onDeleteCategory = ({ category }) => {
    const updatedCategories = currentData.categories.filter(
      (c) => c._id !== category._id
    );
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
  };

  const onCloseModal = () => {
    setFleetPrestartModalData({
      isOpen: false,
      type: "",
      data: null,
      title: "",
    });
    setModalInput("");
  };

  const onHandleChange = (prop) => (e) => {
    if (prop === "usageUnit" || prop === "status") {
      setCurrentData({
        ...currentData,
        [prop]: e,
      });
    } else {
      setCurrentData({
        ...currentData,
        [prop]: e.target.value,
      });
    }
  };

  const onUpdateCategoryMandatory = ({ category, type }) => {
    let mandatory = true;
    if (type === "MANDATORY") {
      mandatory = false;
    }
    const updatedCategories = currentData.categories.map((c) => {
      if (c._id === category._id) {
        return {
          ...c,
          mandatory,
        };
      }
      return c;
    });
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
  };

  const onUpdateQualifierCategory = () => {
    const workingCategory = fleetPrestartModalData.data;
    const updatedCategories = currentData.categories.map((c) => {
      if (c._id === workingCategory._id) {
        return {
          ...c,
          qualifier: modalInput,
        };
      }
      return c;
    });
    setCurrentData({
      ...currentData,
      categories: updatedCategories,
    });
    onCloseModal();
  };

  const onFinish = async (values) => {
    setLoading(true);
    const dataSubmit = { ...currentData };
    dataSubmit.organisationId = organisationId;
    dataSubmit._id = detailFleetPrestart._id;
    const handleCategories = dataSubmit.categories.map((c) => {
      return _.omit(c, ["_id"]);
    });

    dataSubmit.categories = handleCategories;
    if (id === "new") {
      CREATE_FLEET_PRESTART(dataSubmit).then((data) => {
        setLoading(false);
        navigate("/fleet/prestarts");
      });
    } else {
      UPDATE_FLEET_PRESTART(dataSubmit).then((data) => {
        setLoading(false);
        navigate("/fleet/prestarts");
      });
    }
  };

  useEffect(() => {
    const updateState = () => {
      if (id === "new") {
        dispatch(NEW_FLEET_PRESTART());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_FLEET_PRESTART(id).then((data) => {
          dispatch(setDetailFleetPrestart(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailFleetPrestart) {
      setCurrentData(detailFleetPrestart);
      form.setFields([
        {
          name: "title",
          value: detailFleetPrestart.title,
        },
        {
          name: "usageUnit",
          value: detailFleetPrestart.usageUnit,
        },
        {
          name: "status",
          value: detailFleetPrestart.status,
        },
      ]);
    }
  }, [detailFleetPrestart]);

  //check disable submit
  useEffect(() => {
    if (currentData) {
      let disable = false;
      const categories = currentData.categories;
      categories.forEach((c) => {
        if (!c.mandatory && (!c.qualifier || c.qualifier === "")) {
          disable = true;
        }
      });

      setFormDisable(disable);
    }
  }, [currentData]);
  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Fleet Prestarts", path: "/fleet/prestarts" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/fleet/prestarts/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Space direction="vertical" className="form__space">
            <FleetStartModal
              setModalInput={setModalInput}
              modalInput={modalInput}
              title={fleetPrestartModalData.title}
              currentData={currentData}
              isVisible={fleetPrestartModalData.isOpen}
              type={fleetPrestartModalData.type}
              handleCancel={onCloseModal}
              handleOk={() => onHandleSubmitModal(fleetPrestartModalData.type)}
            />
            <Form
              name="basic"
              layout="vertical"
              requiredMark={false}
              form={form}
              scrollToFirstError
              onFinish={onFinish}
            >
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true }]}
              >
                <Input onChange={onHandleChange("title")} />
              </Form.Item>
              <Form.Item
                label="Usage Unit"
                name="usageUnit"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  style={{ width: "100%" }}
                  onChange={onHandleChange("usageUnit")}
                >
                  <Option value="kilometers">Kilometers</Option>
                  <Option value="hours">Hours</Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <List
                  grid={{ gutter: [16, 24], column: 1 }}
                  dataSource={currentData?.categories}
                  renderItem={(category) => (
                    <Card
                      title={`${category.title} ${
                        category.qualifier ? `- ${category.qualifier}` : ""
                      }`}
                      size="small"
                      extra={
                        <Space>
                          {category.mandatory ? (
                            <Button
                              onClick={() =>
                                onUpdateCategoryMandatory({
                                  category,
                                  type: "MANDATORY",
                                })
                              }
                            >
                              Mandatory
                            </Button>
                          ) : (
                            <Space>
                              <Space
                                direction="vertical"
                                size="small"
                                style={{
                                  alignItems: "center",
                                  color:
                                    !category.qualifier ||
                                    category.qualifier === ""
                                      ? "red"
                                      : "black",
                                }}
                              >
                                <Button
                                  style={{
                                    color:
                                      !category.qualifier ||
                                      category.qualifier === ""
                                        ? "red"
                                        : "black",
                                  }}
                                  onClick={() =>
                                    onOpenModalVisible({
                                      category,
                                      type: MODAL_TYPE.QUALIFIER,
                                    })
                                  }
                                >
                                  Qualifier
                                </Button>
                                {!category.qualifier ||
                                category.qualifier === "" ? (
                                  <span>Qualifier required</span>
                                ) : (
                                  ""
                                )}
                              </Space>
                              <Button
                                onClick={() =>
                                  onUpdateCategoryMandatory({
                                    category,
                                    type: "UNMANDATORY",
                                  })
                                }
                              >
                                Not Mandatory
                              </Button>
                            </Space>
                          )}
                          <Tooltip title="Rename">
                            <Button
                              icon={<EditOutlined />}
                              onClick={() =>
                                onOpenModalVisible({
                                  category: category,
                                  type: MODAL_TYPE.EDIT_CATEGORY,
                                })
                              }
                            />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => onDeleteCategory({ category })}
                            />
                          </Tooltip>
                        </Space>
                      }
                    >
                      <List
                        dataSource={category.questions}
                        renderItem={(question, index) => (
                          <List.Item
                            className="list-item__action-list"
                            actions={[
                              <Button
                                className="action-list"
                                onClick={() =>
                                  onOpenModalVisible({
                                    category: category,
                                    index: index,
                                    type: MODAL_TYPE.EDIT_QUESTION,
                                  })
                                }
                              >
                                Edit
                              </Button>,
                              <Button
                                className="action-list"
                                onClick={() =>
                                  onDeleteQuestion({ category, index })
                                }
                              >
                                Delete
                              </Button>,
                            ]}
                          >
                            {question}
                          </List.Item>
                        )}
                      />
                      <Button
                        type="dashed"
                        style={{ width: "100%" }}
                        onClick={() =>
                          onOpenModalVisible({
                            category: category,
                            type: MODAL_TYPE.ADD_QUESTION,
                          })
                        }
                      >
                        Add Question
                      </Button>
                    </Card>
                  )}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="dashed"
                  style={{ width: "100%" }}
                  onClick={() =>
                    onOpenModalVisible({ type: MODAL_TYPE.ADD_CATEGORY })
                  }
                >
                  Add Category
                </Button>
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true }]}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  checked={currentData?.status}
                  onChange={onHandleChange("status")}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={formDisable}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Space>
        )
      }
    />
  );
};

export default EditFleetPreStart;
