import { createSlice } from "@reduxjs/toolkit";
import jwtServices from "../../services/jwtServices";

export const setUserData = (user) => async (dispatch) => {
  dispatch(setUser(user));
};

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;
  if (!user.role || user.role.length === 0) {
    return null;
  }
  jwtServices.logout();
  return dispatch(userLoggedOut());
};

const initialState = {
  email: "",
  photoURL: "",
  username: "",
  firstname: "",
  lastName: "",
  role: [],
};

export const transferUserOrganisation =
  async (user, choosenOrganisation) => {

    if (
      !user.role ||
      user.role.length === 0 ||
      user.organisationId === choosenOrganisation.organisationId
    ) {
      // is guest
      // dispatch(loadingAction(false))
      return;
    }

    return jwtServices
      .transferUserOrganisation(user, choosenOrganisation.organisationId)
  };

const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
