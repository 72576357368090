import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
//state
const postWebsiteSlice = createSlice({
  name: "teamStore",
  initialState: {
    contacts: [],
    detailContact: null,
    industry: [],
    detailIndustry: null,
    purchases: [],
    detailPurchase: null,
    persons: [],
    detailPerson: null,
  },
  reducers: {
    setContacts: (state, action) => {
      state.contacts = action.payload;
      state.detailContact = null;
    },
    setDetailContact: (state, action) => {
      state.detailContact = action.payload;
    },
    setIndustry: (state, action) => {
      state.industry = action.payload;
      state.detailIndustry = null;
    },
    setDetailIndustry: (state, action) => {
      state.detailIndustry = action.payload;
    },
    setPurchases: (state, action) => {
      state.purchases = action.payload;
      state.detailPurchase = null;
    },
    setDetailPurchase: (state, action) => {
      state.detailPurchase = action.payload;
    },
    setPerson: (state, action) => {
      state.persons = action.payload;
      state.detailPerson = null;
    },
    setDetailPerson: (state, action) => {
      state.detailPerson = action.payload;
    },
  },
});

export default postWebsiteSlice.reducer;

//action
export const {
  setContacts,
  setDetailContact,
  setIndustry,
  setDetailIndustry,
  setPurchases,
  setDetailPurchase,
  setPerson,
  setDetailPerson,
} = postWebsiteSlice.actions;

//Contacts
export const FETCH_ALL_CONTACT = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/contact/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_CONTACT = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/contact/detail`, {
        params: {
          contactId: id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_CONTACT = async (data, logo) => {
  try {
    const formData = new FormData();
    if (logo && logo.file) {
      formData.append("logo", logo.file);
    }
    formData.append("data", JSON.stringify(data));

    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/contact/create`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_CONTACT = async (data, logo) => {
  try {
    const formData = new FormData();
    if (logo && logo.file) {
      formData.append("logo", logo.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/contact/update`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_CONTACT = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/contact/delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

//Industry
export const FETCH_ALL_INDUSTRY = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/industry/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_INDUSTRY = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/industry/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_INDUSTRY = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/industry/create`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_INDUSTRY = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/industry/update`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_INDUSTRY = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/industry/delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

//Purchase Orders
export const FETCH_ALL_PURCHASE = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/purchase-order/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_PURCHASE = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/purchase-order/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_PURCHASE = async (data, file) => {
  try {
    const formData = new FormData();
    if (file && file.file) {
      formData.append("file", file.file);
    }
    formData.append("data", JSON.stringify(data));

    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/purchase-order/create`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    console.log(JSON.parse(err.response.data.payload.message))
    throw err;
  }
};

export const UPDATING_PURCHASE = async (data, file) => {
  try {
    const formData = new FormData();
    if (file && file.file) {
      formData.append("file", file.file);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/purchase-order/update`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    console.log(JSON.parse(err.response.data.payload.message))
    throw err;
  }
};

export const UPDATING_PURCHASE_STATUS = async ({ _id, status}) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/purchase-order/status`, {
        params: {
          _id,
          status
        }
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    console.log(JSON.parse(err.response.data.payload.message))
    throw err;
  }
};

export const DELETING_PURCHASE = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/purchase-order/delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

export const EMAIL_PURCHASE = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/purchase-order/email`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

//Contact Person
export const FETCH_ALL_PERSON = async (contactId) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/contact/person/getByContact?contactId=${contactId}`
      )
      .then((res) => {
        return res.data.payload.people;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_PERSON = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/contact/person/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_PERSON = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/contact/person/create`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_PERSON = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/contact/person/update`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const DELETING_PERSON = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/contact/person/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

export const NEW_CONTACT = () => (dispatch) => {
  const newModel = {
    _id: "new",
    name: "",
    industry: "",
    phone: "",
    email: "",
    website: "",
    portalAddress: "",
    postalSuburb: "",
    postalState: "",
    postalAddress: "",
    postalCountry: "",
    streetAddress: "",
    streetSuburb: "",
    streetState: "",
    streetPostal: "",
    streetCountry: "",
    saleAccount: "",
    saleDefaultGST: "",
    saleDays: "",
    saleTerms: "",
    purchaseDefaultTax: "",
    purchaseAccount: "",
    purchaseDefaultGST: "",
    purchaseDays: "",
    abn: "",
    bankNumber: "",
    status: true,
  };
  return dispatch(setDetailContact(newModel));
};

export const NEW_INDUSTRY = () => (dispatch) => {
  const newModel = {
    _id: "new",
    description: "",
    status: true,
  };
  return dispatch(setDetailIndustry(newModel));
};

export const NEW_PURCHASE = () => (dispatch) => {
  const newModel = {
    _id: "new",
    contact: "",
    date: moment(),
    deliveryDate: "",
    orderNumber: "",
    reference: "",
    status: "DRAFT",
  };
  return dispatch(setDetailPurchase(newModel));
};

export const NEW_PERSON = () => (dispatch) => {
  const newModel = {
    _id: "new",
    contactId: "",
    firstName: "",
    phone: "",
    lastName: "",
    title: "",
    email: "",
    status: true,
  };
  return dispatch(setDetailPerson(newModel));
};
