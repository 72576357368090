import React from "react";
import "antd/dist/antd.css";
import styles from "../../styles/StandardFormLayout.module.scss";
import { Link } from "react-router-dom";

import { Layout, Breadcrumb } from "antd";

const { Content } = Layout;

const StandardFormLayout = (props) => {
  return (
    <Content className={styles.content}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        {props?.hierachy?.map((item) => {
          return (
            <Breadcrumb.Item key={item.key}>
              {item.path ? (
                <Link key={item.key} to={item.path}>
                  {item.label}
                </Link>
              ) : (
                item.label
              )}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <div className={`site-layout-content ${props.className}`}>
        {props.form}
      </div>
      {props.table && <div className="site-layout-content">{props.table}</div>}
      {props.customContent && <div>{props.customContent}</div>}
    </Content>
  );
};

export default StandardFormLayout;
