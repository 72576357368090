import { useSelector } from "react-redux";
import AdminDashboard from "../../components/Dashboard/AdminDashboard";
import UserDashboard from "../../components/Dashboard/UserDashboard";

const DashboardPages = () => {
  const { role } = useSelector(
    ({ auth }) => auth.user
  );

  return (
    <>
      {
        role.includes("admin") 
        ? <AdminDashboard/>
        : <UserDashboard/>
      }
    </>
  )
};

export default DashboardPages;
