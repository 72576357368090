import React, { useEffect, useState } from "react";
import {
    Comment,
    Tooltip,
    List,
    Typography,
    Avatar,
    Space,
    Button,
    Checkbox,
    Row,
    Col,
    notification,
    Tag,
    Modal
} from "antd";
import {
    CommentOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import moment from "moment";
import timezone from "moment-timezone"
import TextArea from "antd/lib/input/TextArea";
import { FETCH_DETAIL_TEAM_MEMBER_IN_ORGANISATION } from "../../store/team-member-store/reducer";
import { UPDATE_TIME_SHEET_COMMENTS } from "../../store/time-sheet-store/reducer";

const splitName = (name) => {
    if (name) {
      const nameSplit = name.split(" ");
      const firstCharacters = nameSplit.reduce((last, current) => {
        return last.substring(1, 0) + current.substring(1, 0);
      });

      return firstCharacters;
    }
    return "";
};
const { Title } = Typography
const TimesheetComment = ({
    role,
    userId,
    memberId,
    detailTimesheet,
    firstName,
    lastName
}) => {
    const [loading, setLoading] = useState(false)
    const [comments, setComments] = useState([]);
    const [commentId, setCommentId] = useState("new");
    const [comment, setComment] = useState("");
    const [isError, setIsError] = useState(false);
    const [adminOnly, setAdminOnly] = useState(false);
    const onHandleChangeInput = (e) => {
        if (e) {
            setComment(e.target.value)
        }
    }

    const onHandleChangeCheckBox = (e) => {
        if (e) {
            setAdminOnly(e.target.checked)
        }
    }

    const showConfirm = (index) => {
        Modal.confirm({
          title: "Are you sure you want to delete these records?",
          icon: <ExclamationCircleOutlined />,
          content: "The operation cannot be undone.",
          onOk() {
            onDelete(index)
          },
          onCancel() {
            console.log("Cancel");
          },
        });
    };

    const onDelete = async (index) => {
        try {
            //take user
            const cs = [...detailTimesheet?.comments]
            cs.splice(index, 1)

            const submitData = {
                _id: detailTimesheet._id,
                comments: cs
            }

            const submitAction = await UPDATE_TIME_SHEET_COMMENTS(submitData)
            if(submitAction) {
                notification.success({
                    message: "The Comment is Deleted"
                })

                //update comment
                setComments(submitData.comments)
            }
        } catch (err) {
            console.log(err)
            notification.error({
                message: err
            })
            setLoading(false)
        }
    }

    const onCancel = () => {
        setCommentId("new")
        setComment("")
        setIsError(false)
    }

    const onFinish = async () => {
        try {
            //take user
            if(comment) {
                setIsError(false)
                setLoading(true)
                let c = []
                if(commentId === "new") {
                    const newComment = {
                        description: comment,
                        addedBy: {
                            _id: memberId || userId,
                            name: `${firstName} ${lastName}`,
                            avatar: ""
                        },
                        addedTime: timezone().tz("Australia/Brisbane").format("DD-MMM-YYYY HH:mm:ss"),
                        adminOnly: adminOnly,
                        status: true,
                    }
                    c = detailTimesheet?.comments ? [...detailTimesheet?.comments, newComment] : [newComment]
                } else {
                    const tempArr = [...comments]
                    c = tempArr.map((t, index) => {
                        if(index === commentId) {
                            return {
                                ...t,
                                description: comment
                            }
                        } return t
                    })
                }
               
    
                const submitData = {
                    _id: detailTimesheet._id,
                    comments: c
                }
    
                const submitAction = await UPDATE_TIME_SHEET_COMMENTS(submitData)
                if(submitAction) {
                    notification.success({
                        message: "The Comment is Updated"
                    })
    
                    //update comment
                    setComments(submitData.comments)
                    setComment("")
                    setLoading(false)
                    setCommentId("new")
                }
            } else {
                setIsError(true)
            }
           
        } catch (err) {
            console.log(err)
            notification.error({
                message: err
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        if(detailTimesheet) {
            setComments(detailTimesheet.comments)
        }
    }, [detailTimesheet])


    return (
        <Row>
            <Col xs={24} xl={12} style={{
                padding: 16
            }}>
                <Title level={4}> Comments
                </Title>
                <List
                    className="comment-list"
                    itemLayout="horizontal"
                    dataSource={comments}
                    renderItem={(item, index) => (
                        <li key={index}>
                            <Comment
                                actions={
                                    (item.addedBy._id === memberId || item.addedBy._id === userId)
                                        ? [
                                            <Tooltip key={index}>
                                                <span
                                                    className="comment-action"
                                                    key={index}
                                                >
                                                    <span
                                                        style={{ marginRight: "10px" }}
                                                        onClick={() => showConfirm(index)}
                                                    >
                                                        <DeleteOutlined />
                                                        Delete
                                                    </span>
                                                </span>
                                            </Tooltip>,
                                            <Tooltip key={index}>
                                                <span
                                                    className="comment-action"
                                                    key={index}
                                                >
                                                    <span
                                                        onClick={() => {
                                                          setComment(item.description)
                                                          setCommentId(index)
                                                        }}
                                                    >
                                                        {" "}
                                                        <EditOutlined />
                                                        Edit
                                                    </span>
                                                </span>
                                            </Tooltip>,
                                        ]
                                        : []
                                }
                                author={
                                    item.addedBy?.name ||
                                    `${item?.addedBy?.firstName} ${item?.addedBy?.lastName}`
                                }
                                avatar={
                                    item?.addedBy?.photo ? (
                                        <Avatar
                                            src={item?.addedBy?.photo}
                                            alt={
                                                item.addedBy?.name
                                            }
                                        />
                                    ) : (
                                        <Avatar style={{ backgroundColor: "#87d068" }}>
                                            {splitName(
                                                item.addedBy?.name
                                            )}
                                        </Avatar>
                                    )
                                }
                                content={
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item && item.description,
                                        }}
                                    ></div>
                                }
                                datetime={
                                    <Space>
                                        {
                                            moment(
                                                item.addedTime,
                                                "DD-MMM-YYYY HH:mm:ss"
                                            ).fromNow()
                                        }
                                        {
                                            (item.adminOnly && role?.includes("admin")) && 
                                            (
                                                <>
                                                    <Tag color="#108ee9">{item.addedTime}</Tag>
                                                    <Tag color="default">Admin Only</Tag>
                                                </>
                                            )
                                        }
                                    </Space>
                                }
                            />
                        </li>
                    )}
                    footer={
                        <div>
                            <TextArea style={{
                                marginBottom: 10
                            }}
                                value={comment}
                                rows={5}
                                onChange={onHandleChangeInput}
                            />
                            {
                                isError && 
                                <p style={{
                                    color: "#ff4d4f"
                                }}>Please Add Comment</p>
                            }
                            <Space>
                                <Button type="primary" loading={loading} onClick={() => onFinish()}>{commentId === "new" ? "Add Comment" : "Update Comment"}</Button>
                                {
                                    commentId !== "new" && <Button onClick={() => onCancel()}>Cancel</Button>
                                }
                              
                                <Checkbox onChange={onHandleChangeCheckBox}>Admin Only</Checkbox>
                            </Space>
                        </div>
                    }
                />
            </Col>
        </Row>

    )
}

export default TimesheetComment