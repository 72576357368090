import { Modal, Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import "../../../styles/teamMember.scss";
import { useDispatch, useSelector } from "react-redux";
import FormVehicleMakes from "./formVehicleMakes";
import {
  CREATE_VEHICLE_MAKE,
  CREATE_FLEET_CATEGORY,
} from "../../../store/fleet-store/reducer";
import FormCategories from "./formCategories";

const ModalFleetVehicles = ({
  form,
  fetchSubData,
  isModalVisible,
  setIsModalVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const { organisationId } = useSelector(({ auth }) => auth.user);

  const [vhMakes, setvhMakes] = useState({
    organisationId: organisationId,
    _id: "new",
    active: true,
  });
  const [catego, setCatego] = useState({
    organisationId: organisationId,
    _id: "new",
    active: true,
  });

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18, color: "#fff" }} spin />
  );

  const handleOk = () => {
    setLoading(true);
    switch (isModalVisible.type) {
      case "Vehicle Makes":
        CREATE_VEHICLE_MAKE(vhMakes).then((data) => {
          fetchSubData();
          setTimeout(() => {
            form.setFields([
              {
                name: "make",
                value: data?._id,
              },
            ]);
            setLoading(false);
            setIsModalVisible(false);
          }, 1500);
        });
        break;
      case "Category":
        CREATE_FLEET_CATEGORY(catego).then((data) => {
          fetchSubData();
          setTimeout(() => {
            form.setFields([
              {
                name: "category",
                value: data?._id,
              },
            ]);
            setLoading(false);
            setIsModalVisible(false);
          }, 1000);
        });
        break;
      default:
        setLoading(false);
        break;
    }
  };

  const renderType = () => {
    switch (isModalVisible.type) {
      case "Vehicle Makes":
        return <FormVehicleMakes vhMakes={vhMakes} setvhMakes={setvhMakes} />;
      case "Category":
        return <FormCategories catego={catego} setCatego={setCatego} />;
      default:
        setLoading(false);
        break;
    }
  };

  return (
    <Modal
      title={"Add " + isModalVisible.type}
      visible={isModalVisible}
      onCancel={handleCancel}
      className="modal-sub"
      style={{ zIndex: 99999999 }}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="add"
          type="primary"
          onClick={handleOk}
          style={{ opacity: loading && 0.6 }}
        >
          {loading ? <Spin indicator={antIcon} /> : "Add"}
        </Button>,
      ]}
    >
      {renderType()}
    </Modal>
  );
};

export default ModalFleetVehicles;
