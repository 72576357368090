import { Form, Input, Select, Radio } from "antd";
import { useEffect, useState } from "react";
import "../../../styles/teamMember.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setVehicleModel,
  FETCH_ALL_VEHICLE_MODEL,
} from "../../../store/fleet-store/reducer";

const FormVehicleMakes = ({ catego, setCatego }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [radioState, setRadioState] = useState(false);

  const { fleetPrestarts } = useSelector(
    ({ fleetPreStart }) => fleetPreStart.fleetPreStartReducer
  );

  useEffect(() => {
    FETCH_ALL_VEHICLE_MODEL(organisationId).then((data) =>
      dispatch(setVehicleModel(data))
    );
  }, [organisationId]);

  const onChangeRadio = (e) => {
    setCatego({
      ...catego,
      prestart: e.target.value,
    });
    if (e.target.value === "Yes") {
      setRadioState(true);
    } else {
      setRadioState(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setCatego({
      ...catego,
      description: value,
    });
  };

  const handleChangeSelect = (value) => {
    setCatego({
      ...catego,
      model: value,
    });
  };

  return (
    <Form
      labelAlign="left"
      form={form}
      requiredMark={false}
      labelCol={{ span: 5 }}
    >
      <Form.Item label="Prestart" name="prestart">
        <Radio.Group
          options={["Yes", "No"]}
          value={radioState ? "Yes" : "No"}
          onChange={onChangeRadio}
        />
      </Form.Item>

      {radioState && (
        <Form.Item
          label="Fleet Prestart"
          name="fleetPreStart"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            style={{ width: "100%" }}
            onSelect={handleChangeSelect}
          >
            {fleetPrestarts &&
              fleetPrestarts.map((fleetPrestart) => (
                <Select.Option
                  key={fleetPrestart?._id}
                  value={fleetPrestart?._id}
                >
                  {fleetPrestart.title}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true }]}
      >
        <Input onChange={handleChange} />
      </Form.Item>
    </Form>
  );
};

export default FormVehicleMakes;
