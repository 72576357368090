import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
//state
const commonSlice = createSlice({
  name: "commonStore",
  initialState: {
    transactions: [],
    detailTransaction: null
  },
  reducers: {
    setTransactions: (state, action) => {
      state.transactions = action.payload;
      state.detailTransaction = null;
    },
    setDetailTransaction: (state, action) => {
      state.detailTransaction = action.payload;
    },
  },
});

export default commonSlice.reducer;

//action
export const { setDetailTransaction, setTransactions } =
  commonSlice.actions;

export const FETCH_DETAIL_TRANSACTION_BY_TEAM_MEMBER = async (teamMemberId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/transaction/detailByTeamMember`, {
        params: {
          teamMemberId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};