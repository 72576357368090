import jwtService from "../../services/jwtServices";
import { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { message } from "antd";
import { setUserData, logoutUser } from "./userSlice";

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return Promise.all([this.jwtCheck()]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  jwtCheck = () =>
    new Promise((resolve) => {
      jwtService.on("onAutoLogin", () => {
        // message.success({ message: "Login Successful" });
        /**
         * Sign in and retrieve user data from Api
         */
        jwtService
          .signInWithToken()
          .then((user) => {
            this.props.setUserData(user);
            resolve();
          })
          .catch((error) => {
            message.error(error.message);
            resolve();
          });
      });

      jwtService.on("onAutoLogout", (mes) => {
        if (mes) {
          message.info(mes);
        }
        this.props.logout();
        resolve();
      });

      jwtService.on("onNoAccessToken", () => {
        resolve();
      });
      jwtService.init();
      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? (
      <Spin
        style={{ width: " 100%", height: "100%" }}
        spinning={true}
        indicator={<LoadingOutlined style={{ fontSize: 60 }} />}
      />
    ) : (
      this.props.children
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
