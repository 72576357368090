import EditReceipt from "../../components/Receipt/EditReceipt";

const Receipts = () => {
  return (
    <>
      <EditReceipt />
    </>
  );
};

export default Receipts;
