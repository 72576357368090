import Invoice from "../../components/Invoice";

const Invoices = () => {
  return (
    <>
      <Invoice />
    </>
  );
};

export default Invoices;
