import Items from "./index";
import ItemsEdit from "./detail";
import Category from "./Category/index";
import CategoryEdit from "./Category/detail";
import ChartOfAccounts from "./ChartOfAccounts";
import ChartOfAccountsEdit from "./ChartOfAccounts/detail";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "/items/:id",
      component: ItemsEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/items",
      component: Items,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/items/category/:id",
      component: CategoryEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/items/category",
      component: Category,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/chart_of_accounts/:id",
      component: ChartOfAccountsEdit,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/chart_of_accounts",
      component: ChartOfAccounts,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
  ],
};

export default router;
