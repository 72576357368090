import React, { useState, useEffect } from "react";
import { Badge } from "antd";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_PAYMENTS,
  setPayment,
  DELETE_PAYMENTS,
} from "../../store/payment-store/reducer";

const Payment = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const { payments } = useSelector(({ payments }) => payments.paymentReducer);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [csv, setCsv] = useState({
    headers: [
      {
        key: "description",
        label: "Description",
      },
      {
        key: "detailedDescription",
        label: "Detailed Description",
      },
      {
        key: "hoursWeekly",
        label: "Hours Weekly",
      },
      {
        key: "hoursDaily",
        label: "Hours Daily",
      },
      {
        key: "days",
        label: "Days",
      },
      {
        key: "publicHolidaysPaid",
        label: "Public Holiday Paid",
      },
      {
        key: "bankedHoursUsed",
        label: "Banked Hours Used",
      },
      {
        key: "paidLunch",
        label: "Paid Lunch",
      },
      {
        key: "includeBreakInTotalHours",
        label: "Include Break In Total Hours",
      },
      {
        key: "payLunchWhenHoursExceed",
        label: "PayLunch When Hours Exceed",
      },
      {
        key: "bonusHoursUsed",
        label: "Bonus Hour Used",
      },
      {
        key: "payBonusWhenHoursExceed",
        label: "Pay Bonus When Hours Exceed",
      },
      {
        key: "includeOrdinaryHoursOverStandardWeek",
        label: "Include Ordinary Hours Over Standard Week",
      },
      {
        key: "startTime",
        label: "Start Time",
      },
      {
        key: "startBreak",
        label: "Start Break",
      },
      {
        key: "endBreak",
        label: "End Break",
      },
      {
        key: "finishTime",
        label: "Finish Time",
      },
      {
        key: "personalLeave",
        label: "Personal Leave",
      },
      {
        key: "annualLeave",
        label: "Annual Leave",
      },
      {
        key: "annualLeaveLoading",
        label: "Annual Leave Loading",
      },
      {
        key: "overtimeUsed",
        label: "Overtime Used",
      },
      {
        key: "overtimeCalculated",
        label: "Overtime Calculated",
      },
      {
        key: "overtime150Used",
        label: "Overtime 150 Used",
      },
      {
        key: "overtime150after",
        label: "Overtime 150 After",
      },
      {
        key: "overtime200Used",
        label: "Overtime 200 Used",
      },
      {
        key: "overtime200after",
        label: "Overtime 200 After",
      },
      {
        key: "allowancesFirstAid",
        label: "Allowances First Aid",
      },
      {
        key: "allowancesLeadingHand",
        label: "Allowances Leading Hand",
      },
      {
        key: "allowancesMeal",
        label: "Allowances Meal",
      },
      {
        key: "allowancesPlant",
        label: "Allowances Plant",
      },
      {
        key: "allowancesWorkingAway",
        label: "Allowances Working Away",
      },
      {
        key: "status",
        label: "status",
      },
    ],
    data: [],
    fileName: "allPayProfiles.csv",
  });
  const [dataList, setDataList] = useState([]);

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        description: e.description,
        detailedDescription: e.detailedDescription,
        hoursWeekly: e.hours?.weekly,
        hoursDaily: e.hours?.daily,
        days: e.days,
        publicHolidaysPaid: e.publicHolidaysPaid,
        bankedHoursUsed: e.bankedHoursUsed,
        paidLunch: e.paidLunch,
        includeBreakInTotalHours: e.includeBreakInTotalHours,
        payLunchWhenHoursExceed: e.payLunchWhenHoursExceed,
        bonusHoursUsed: e.bonusHoursUsed,
        payBonusWhenHoursExceed: e.payBonusWhenHoursExceed,
        includeOrdinaryHoursOverStandardWeek:
          e.includeOrdinaryHoursOverStandardWeek,
        startTime: e.standardTimes?.startTime,
        startBreak: e.standardTimes?.startBreak,
        endBreak: e.standardTimes?.endBreak,
        finishTime: e.standardTimes?.finishTime,
        personalLeave: e.leave?.personalLeave,
        annualLeave: e.leave?.annualLeave,
        annualLeaveLoading: e.leave?.annualLeaveLoading,
        overtimeUsed: e.overtimeUsed,
        overtimeCalculated: e.overtime?.calculated,
        overtime150Used: e.overtime?.overtime150Used,
        overtime150after: e.overtime?.overtime150after,
        overtime200Used: e.overtime?.overtime200Used,
        overtime200after: e.overtime?.overtime200after,
        allowancesFirstAid: e.allowances?.firstAid,
        allowancesLeadingHand: e.allowances?.leadingHand,
        allowancesMeal: e.allowances?.meal,
        allowancesPlant: e.allowances?.plant,
        allowancesWorkingAway: e.allowances?.workingAway,
        status: e.status,
      });
    });
    return csvData;
  };

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Work days",
      dataIndex: "days",
      key: "days",
      render: (days) => {
        return <span>{days.join(",")} </span>;
      },
      sorter: (a, b) => a.days.localeCompare(b.days),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (active) => {
        let type = active ? "success" : "error";
        return <Badge status={type} text={active ? "Active" : "InActive"} />;
      },
      sorter: (a, b) => b.status - a.status,
    },
  ];
  const FETCH_DATA = () => {
    FETCH_ALL_PAYMENTS(organisationId)
      .then((data) => dispatch(setPayment(data)))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (payments) {
      const columns = handleColumnCsv(payments);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [payments]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    payments?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: ele?.detailedDescription,
        description: ele?.description,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [payments]);

  return (
    <div className="table-organisations table-payments">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers}
            csvData={csv.data}
            csvFileName={csv.fileName}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Pay profiles" }]}
            dataSource={payments}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_PAYMENTS}
            table="payroll/pay-profiles"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers}
            csvData={csv.data}
            csvFileName={csv.fileName}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Pay profiles" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_PAYMENTS}
            table="payroll/pay-profiles"
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;
