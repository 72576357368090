import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Badge } from "antd";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ACOUNT_CODE,
  setAccountCode,
  DELETING_ACCOUNT_CODE,
} from "../../store/items-store/reducer";

const ItemAccountCode = () => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { accountCode } = useSelector(({ items }) => items.itemReducer);
  const [filterAccount, setFilterAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [dataList, setDataList] = useState([]);

  const [tabSort, setTabSort] = useState({
    tabs: [
      "All Accounts",
      "Assets",
      "Liabilities",
      "Equity",
      "Expenses",
      "Revenue",
      "Archive",
    ],
    tabChoosed: "All Accounts",
  });

  const [csv, setCsv] = useState({
    headers: [
      {
        label: "Code",
        key: "code",
      },
      {
        label: "Type",
        key: "type",
      },
      {
        label: "Description",
        key: "description",
      },
      {
        label: "Status",
        key: "status",
      },
    ],
    data: [],
    fileName: "allAccountCode.csv",
  });

  const handleColumnCsv = (array) => {
    let csvData = [];
    array?.forEach((e) => {
      csvData.push({
        code: e.code,
        type: e.type,
        description: e.description,
        status: e.status,
      });
    });
    return csvData;
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      sorter: (a, b) => a.tax.localeCompare(b.tax),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "InActive"} />;
      },
      sorter: (a, b) => b.status - a.status,
    },
  ];

  const FETCH_DATA = () => {
    FETCH_ACOUNT_CODE(organisationId)
      .then((data) => dispatch(setAccountCode(data)))
      .finally(() => setIsLoading(false));
    setIsLoading(false);
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (accountCode) {
      const columns = handleColumnCsv(accountCode);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [accountCode]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    setIsLoading(true);

    let filterCode = [];
    let filterCodeResponsive = [];

    accountCode?.forEach((ele) => {
      let filterResponsive = {
        _id: ele?._id,
        title: `${ele?.name}`,
        description: `${ele?.description}`,
      };

      if (tabSort?.tabChoosed?.toLowerCase() !== "all accounts") {
        if (
          tabSort?.tabChoosed?.toLowerCase() === ele?.typeGroups?.toLowerCase()
        ) {
          filterCode.push(ele);
          filterCodeResponsive.push(filterResponsive);
        }
      } else {
        filterCode = [...accountCode];
        filterCodeResponsive.push(filterResponsive);
      }
    });

    setFilterAccount(filterCode);
    setDataList(filterCodeResponsive);
    setIsLoading(false);
  }, [accountCode, tabSort]);

  return (
    <div className="team_member">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Chart of Accounts" }]}
            dataSource={filterAccount}
            tabSort={tabSort}
            setTabSort={setTabSort}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_ACCOUNT_CODE}
            table="chart_of_accounts"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            hierachy={[{ key: 1, label: "Chart of Accounts" }]}
            dataList={dataList}
            tabSort={tabSort}
            setTabSort={setTabSort}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETING_ACCOUNT_CODE}
            table="chart_of_accounts"
          />
        </div>
      </div>
    </div>
  );
};

export default ItemAccountCode;
