import EditDepartments from "../../components/Departments/EditDepartments";

const DepartmentDetail = () => {
  return (
    <>
      <EditDepartments />
    </>
  );
};

export default DepartmentDetail;
