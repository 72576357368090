import IndustryContact from "../../../components/IndustryContact";
const Industry = () => {
  return (
    <>
      <IndustryContact />
    </>
  );
};

export default Industry;
