import EditFleetPrestarts from "../../components/FleetPreStart/EditFleetPreStart";

const FleetStartDetail = () => {
  return (
    <>
      <EditFleetPrestarts />
    </>
  );
};

export default FleetStartDetail;
