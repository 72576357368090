import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Avatar, Badge, Image, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { AppstoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  FETCH_ALL_ITEMS,
  setItems,
  DELETING_ITEMS,
} from "../../store/items-store/reducer";

const Items = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [showAvatar, setShowAvatar] = useState({
    state: false,
    src: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const { items } = useSelector(({ items }) => items.itemReducer);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [csv, setCsv] = useState({
    headers: [
      {
        key: "firstName",
        label: "First Name",
      },
      {
        key: "image",
        label: "Image",
      },
      {
        key: "name",
        label: "Name",
      },
      {
        key: "saleDescription",
        label: "Sale Description",
      },
      {
        key: "category",
        label: "Category",
      },
      {
        key: "unit",
        label: "Unit",
      },
      {
        key: "accountCode",
        label: "Chart of Accounts",
      },
      {
        key: "department",
        label: "Department",
      },
      {
        key: "purchasePrice",
        label: "Purchase Price",
      },
      {
        key: "salePrice",
        label: "Sale Price",
      },
      {
        key: "sku",
        label: "Sku",
      },
    ],
    data: [],
    fileName: "allItems.csv",
  });
  const [dataList, setDataList] = useState([]);

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        image: e.image,
        name: e.name,
        saleDescription: e.saleDescription,
        category: e.category,
        unit: e.unit,
        accountCode: e.accountCode,
        department: e.department,
        purchasePrice: e.purchasePrice,
        salePrice: e.salePrice,
        sku: e.sku,
      });
    });
    return csvData;
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      width: 80,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setShowAvatar({
              state: true,
              src: record.image,
            });
          },
        };
      },
      render: (image) =>
        image > 0 ? (
          <Image src={image} />
        ) : (
          <Avatar
            style={{ color: "#5468ff", backgroundColor: "#e6f7ff" }}
            icon={<AppstoreOutlined />}
            src={image}
          ></Avatar>
        ),
    },
    {
      title: "Code",
      sorter: (a, b) => a?.sku.localeCompare(b?.sku),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/items/${record._id}`);
          },
        };
      },
      render: (data) => <>{data.sku}</>,
    },
    {
      title: "Category",
      sorter: (a, b) => a?.category?.code.localeCompare(b?.category?.code),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/items/${record._id}`);
          },
        };
      },
      render: (data) => <>{data?.category?.code} {data?.category?.name && ` - ${data?.category?.name}`}</>,
    },
    {
      title: "Name",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/items/${record._id}`);
          },
        };
      },
      render: (data) => <>{data.name}</>,
    },
    {
      title: "Cost Price",
      align: "right",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/items/${record._id}`);
          },
        };
      },
      sorter: (a, b) => Number(a.costPrice) - Number(b.costPrice),
      render: (data) => <>{data.costPrice ? Number(data.costPrice).toFixed(2) : "0.00"}</>,
    },
    {
      title: "Sale Price",
      align: "right",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/items/${record._id}`);
          },
        };
      },
      sorter: (a, b) => Number(a.salePrice) - Number(b.salePrice),
      render: (data) => <>{data.salePrice ? Number(data.salePrice).toFixed(2) : "0.00"}</>,
    },
    {
      title: "Department",
      sorter: (a, b) => a?.department?.name?.localeCompare(b?.department?.name),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/items/${record._id}`);
          },
        };
      },
      render: (data) => <>{data.department?.name}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/items/${record._id}`);
          },
        };
      },
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "InActive"} />;
      },
      sorter: (a, b) => b.status - a.status,
    },
  ];

  const FETCH_DATA = () => {
    if (organisationId) {
      FETCH_ALL_ITEMS(organisationId)
        .then((data) => {
          dispatch(setItems(data));
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (items) {
      const columns = handleColumnCsv(items);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [items]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    items?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.name}`,
        description: `${ele?.sku} - ${ele?.category?.name}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [items]);

  return (
    <div className="team_member">
      {showAvatar.state && (
        <Image
          width={100}
          style={{ display: "none" }}
          src={showAvatar.src}
          preview={{
            visible: showAvatar,
            src: showAvatar.src,
            onVisibleChange: (value) => {
              setShowAvatar({
                state: value,
                src: "",
              });
            },
          }}
        />
      )}
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            isHaveRowAction={false}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Items" }]}
            dataSource={items}
            columns={columns}
            DELETE_FUNC={DELETING_ITEMS}
            FETCH_DATA={FETCH_DATA}
            table="items"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            isHaveRowAction={false}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Items" }]}
            dataList={dataList}
            columns={columns}
            DELETE_FUNC={DELETING_ITEMS}
            FETCH_DATA={FETCH_DATA}
            table="items"
          />
        </div>
      </div>
    </div>
  );
};

export default Items;
