import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin, Radio, Select } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  NEW_CATEGORY,
  FETCH_DETAIL_FLEET_CATEGORIES,
  CREATE_FLEET_CATEGORY,
  UPDATE_FLEET_CATEGORY,
  setDetailCategory,
} from "../../store/fleet-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_FLEET_PRESTART,
  setFleetPrestarts,
} from "../../store/fleet-prestart-store/reducer";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditFleetCategory = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailCategory } = useSelector(({ fleets }) => fleets.fleetReducer);
  const { fleetPrestarts } = useSelector(
    ({ fleetPreStart }) => fleetPreStart.fleetPreStartReducer
  );
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [radioState, setRadioState] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailCategory._id;
    if (id === "new") {
      CREATE_FLEET_CATEGORY(values).then((data) => {
        setLoading(false);
        navigate("/fleet/categories");
      });
    } else {
      UPDATE_FLEET_CATEGORY(values).then((data) => {
        setLoading(false);
        navigate("/fleet/categories");
      });
    }
  };

  const onChangeRadio = (e) => {
    if (e.target.value === "Yes") {
      setRadioState(true);
    } else {
      setRadioState(false);
    }
  };

  const fetchSubData = async () => {
    const fleetData = await FETCH_ALL_FLEET_PRESTART(organisationId);
    if (fleetData) {
      dispatch(setFleetPrestarts(fleetData));
    }
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      if (id === "new") {
        dispatch(NEW_CATEGORY());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_FLEET_CATEGORIES(id).then((data) => {
          dispatch(setDetailCategory(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailCategory) {
      if (detailCategory.fleetPreStart) {
        setRadioState(true);
      }
      form.setFields([
        {
          name: "description",
          value: detailCategory.description,
        },
        {
          name: "active",
          value: detailCategory.active,
        },
        {
          name: "fleetPreStart",
          value: detailCategory.fleetPreStart?._id,
        },
        {
          name: "prestart",
          value: detailCategory.fleetPreStart ? "Yes" : "No",
        },
      ]);
    }
  }, [detailCategory]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Fleet Category", path: "/fleet/categories" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/fleet/categories/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            form={form}
            requiredMark={false}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item label="Prestart" name="prestart">
              <Radio.Group
                options={["Yes", "No"]}
                value={radioState ? "Yes" : "No"}
                onChange={onChangeRadio}
              />
            </Form.Item>

            {radioState && (
              <Form.Item
                label="Fleet Prestart"
                name="fleetPreStart"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  style={{ width: "100%" }}
                >
                  {fleetPrestarts &&
                    fleetPrestarts.map((fleetPrestart) => (
                      <Select.Option
                        key={fleetPrestart?._id}
                        value={fleetPrestart?._id}
                      >
                        {fleetPrestart.title}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Status" name="active" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditFleetCategory;
