import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Form, Input, Select, Space, Upload, Button, Image } from "antd";
import {
  FETCH_DETAIL_ORGANISATION,
  setDetailOrg,
} from "../../store/org-store/reducer";
import { useSelector, useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const EditOrganisation = ({
  setRequiredOrgFields,
  requiredOrgFields,
  currentOrg,
  setCurrentOrg,
  fileList,
  setFileList,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const { detailOrg } = useSelector(({ org }) => org.orgReducer);
  useEffect(() => {
    FETCH_DETAIL_ORGANISATION(user.organisationId).then((data) => {
      dispatch(setDetailOrg(data));
    });
  }, [user]);

  useEffect(() => {
    if (detailOrg) {
      setRequiredOrgFields({
        parentFolderId: detailOrg.parentFolderId,
        adminDocumentUrl: detailOrg.adminDocumentUrl,
        userDocumentUrl: detailOrg.userDocumentUrl,
      });
      form.setFields([
        {
          name: "displayName",
          value: detailOrg.displayName,
        },
        {
          name: "tradingName",
          value: detailOrg.tradingName,
        },
        {
          name: "ABN",
          value: detailOrg.ABN,
        },
        {
          name: "parentFolderId",
          value: detailOrg.parentFolderId,
        },
        {
          name: "adminDocumentUrl",
          value: detailOrg.adminDocumentUrl,
        },
        {
          name: "userDocumentUrl",
          value: detailOrg.userDocumentUrl,
        },
        {
          name: "invoiceTemplateId",
          value: detailOrg.invoiceTemplateId,
        },
        {
          name: "purchaseTemplateId",
          value: detailOrg.purchaseTemplateId,
        },
        {
          name: "quoteTemplateId",
          value: detailOrg.quoteTemplateId,
        },
        {
          name: "street",
          value: detailOrg.street,
        },
        {
          name: "suburb",
          value: detailOrg.suburb,
        },
        {
          name: "postcode",
          value: detailOrg.postcode,
        },
        {
          name: "state",
          value: detailOrg.state,
        },
        {
          name: "country",
          value: detailOrg.country,
        },
        {
          name: "status",
          value: detailOrg.status,
        },
      ]);
      setCurrentOrg(detailOrg);
      setFileList([
        {
          uid: "2",
          name: detailOrg.logo.name,
          status: "done",
          file: null,
          url: detailOrg.logo.url,
        },
      ]);
    }
  }, [detailOrg]);

  const handleChange = (prop) => (e) => {
    if (prop === "adminDocumentUrl" || prop === "userDocumentUrl") {
      setRequiredOrgFields({
        ...requiredOrgFields,
        [prop]: e.target.value,
      });
    }
    if (prop === "status") {
      setCurrentOrg({
        ...currentOrg,
        [prop]: e,
      });
    } else {
      setCurrentOrg({
        ...currentOrg,
        [prop]: e.target.value,
      });
    }
  };

  const props = {
    name: "file",
    maxCount: 1,
    className: "upload-list-inline",
    listType: "picture",
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url,
          },
        ]);
      };
    },
  };

  return (
    <Form name="user" layout="vertical" wrapperCol={{ span: 10 }} form={form}>
      <Form.Item label="Display Name" name="displayName">
        <Input
          placeholder="Display Name"
          onChange={handleChange("displayName")}
        />
      </Form.Item>
      <Form.Item label="Trading Name" name="tradingName">
        <Input
          placeholder="Trading Name"
          onChange={handleChange("tradingName")}
        />
      </Form.Item>

      <Form.Item
        label="Parent Folder ID"
        name="parentFolderId"
        rules={[{ required: true, message: "Parent Folder ID is required!" }]}
      >
        <Input
          placeholder="Parent Folder ID"
          onChange={handleChange("parentFolderId")}
        />
      </Form.Item>

      <Form.Item
        label="Admin Documents URL"
        name="adminDocumentUrl"
        rules={[
          { required: true, message: "Admin Documents URL is required!" },
        ]}
      >
        <Input
          placeholder="Admin Documents URL"
          onChange={handleChange("adminDocumentUrl")}
        />
      </Form.Item>

      <Form.Item
        label="User Documents URL"
        name="userDocumentUrl"
        rules={[{ required: true, message: "User Documents URL is required!" }]}
      >
        <Input
          placeholder="User Documents URL"
          onChange={handleChange("userDocumentUrl")}
        />
      </Form.Item>

      <Form.Item label="Australian Business Number" name="ABN">
        <Input placeholder="ABN" onChange={handleChange("ABN")} />
      </Form.Item>

      <Form.Item
        label="Invoice Template ID"
        name="invoiceTemplateId"
      >
        <Input
          placeholder="Invoice Template ID"
          onChange={handleChange("invoiceTemplateId")}
        />
      </Form.Item>

      <Form.Item
        label="Purchase Template ID"
        name="purchaseTemplateId"
      >
        <Input
          placeholder="Purchase Template ID"
          onChange={handleChange("purchaseTemplateId")}
        />
      </Form.Item>

      <Form.Item
        label="Quote Template ID"
        name="quoteTemplateId"
      >
        <Input
          placeholder="Quote Template ID"
          onChange={handleChange("quoteTemplateId")}
        />
      </Form.Item>

      <Form.Item label="Logo">
        <Upload {...props} onRemove={() => setFileList([])}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
        <div>
          {fileList.length > 0 ? (
            fileList.map((l, index) => (
              <div
                key={index}
                style={{
                  marginTop: "10px",
                  display: "flex",
                }}
              >
                <Image
                  src={l.url}
                  style={{
                    maxWidth: "100px",
                  }}
                />
                <p
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  {l.name}
                </p>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </Form.Item>
      <Form.Item label="Address (redesign)">
        <Input.Group>
          <Space style={{ width: "100%" }} direction="vertical">
            <Form.Item name="street">
              <Input
                name="street"
                placeholder="Street"
                onChange={handleChange("street")}
              />
            </Form.Item>
            <Form.Item name="suburb">
              <Input
                name="suburb"
                placeholder="Suburb"
                onChange={handleChange("suburb")}
              />
            </Form.Item>
            <Form.Item name="postcode">
              <Input
                name="postcode"
                placeholder="Postcode"
                onChange={handleChange("postcode")}
              />
            </Form.Item>
            <Form.Item name="state">
              <Input
                name="state"
                placeholder="State"
                onChange={handleChange("state")}
              />
            </Form.Item>
            <Form.Item name="country">
              <Input
                name="country"
                placeholder="Country"
                onChange={handleChange("country")}
              />
            </Form.Item>
          </Space>
        </Input.Group>
      </Form.Item>
      <Form.Item label="Status" name="status" id="select-drop">
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          onChange={handleChange("status")}
        >
          <Option value={true}>Active</Option>
          <Option value={false}>Inactive</Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export default EditOrganisation;
