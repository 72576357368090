import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import {
  FETCH_DETAIL_CONTACT,
  setDetailContact,
} from "../../../store/contact-store/reducer";

import { useDispatch, useSelector } from "react-redux";
import DetailView from "./DetailViewContact";
import TableView from "./TableViewContact";
import PageHeader from "./PageHeader";

const Details = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailContact } = useSelector(
    ({ contact }) => contact.contactReducer
  );
  const [initialLoading, setInitialLoading] = useState(true);
  const [view, setView] = useState('overview')

  useEffect(() => {
    const updateState = async () => {
      FETCH_DETAIL_CONTACT(id).then((data) => {
        dispatch(setDetailContact(data));
        setInitialLoading(false);
      });
    };
    updateState();
  }, [dispatch, id]);

  return (
    <div className="view-form">
      <PageHeader
        detailContact={detailContact}
        view={view}
        setView={setView}
      />
      <DetailView
        detailContact={detailContact}
        initialLoading={initialLoading}
      />
      <TableView
        detailContact={detailContact}
        initialLoading={initialLoading}
      />
    </div>
  );
};

export default Details;
