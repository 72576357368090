import Award from "./Awards";
import AwardDetail from "./Awards/detail";
import Type from "./Types";
import TypeDetail from "./Types/detail";
import Position from "./Positions";
import PositionDetail from "./Positions/detail";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    //employment type
    {
      path: "/employment/types/",
      component: Type,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/employment/types/:idType",
      component: TypeDetail,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },

    //employment award
    {
      path: "/employment/awards",
      component: Award,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/employment/awards/:idAward",
      component: AwardDetail,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },

    //position
    {
      path: "/team/positions",
      component: Position,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/team/positions/:idPosition",
      component: PositionDetail,
      auth: ["admin"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
  ],
};

export default router;
