import Department from "./index";
import DepartmentDetail from "./detail";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    //employment type
    {
      path: "/departments/",
      component: Department,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true
        }
      }
    },
    {
      path: "/departments/:idDepartment",
      component: DepartmentDetail,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
  ],
};

export default router;
