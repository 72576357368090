import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
//state
const orgSlice = createSlice({
  name: "fleetPreStartStore",
  initialState: {
    fleetPrestarts: [],
    detailFleetPrestart: null,
    fleetCompletePrestarts: [],
    detailFleetCompleteStart: null
  },
  reducers: {
    setFleetPrestarts: (state, action) => {
      state.fleetPrestarts = action.payload;
      state.detailFleetPrestart = null;
    },
    setDetailFleetPrestart: (state, action) => {
      state.detailFleetPrestart = action.payload;
    },
    setFleetCompletePrestarts: (state, action) => {
      state.fleetCompletePrestarts = action.payload;
      state.detailFleetCompleteStart = null;
    },
    setDetailFleetCompltePrestart: (state, action) => {
      state.detailFleetCompleteStart = action.payload;
    }
  },
});

export default orgSlice.reducer;

//action
export const {
  setFleetPrestarts,
  setDetailFleetPrestart,
  setFleetCompletePrestarts,
  setDetailFleetCompltePrestart
} = orgSlice.actions;

export const MODAL_TYPE = {
  ADD_CATEGORY: "ADD CATEGORY",
  EDIT_CATEGORY: "EDIT CATEGORY",
  ADD_QUESTION: "ADD QUESTION",
  EDIT_QUESTION: "EDIT QUESTION",
  QUALIFIER: "QUALIFIER"
}
//fleet prestart
export const FETCH_ALL_FLEET_PRESTART = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet-prestart/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_DETAIL_FLEET_PRESTART = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet-prestart/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const CREATE_FLEET_PRESTART = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet-prestart/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const UPDATE_FLEET_PRESTART = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet-prestart/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const DELETE_FLEET_PRESTART = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/fleet-prestart/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_FLEET_PRESTART = () => (dispatch) => {
  const newModel = {
    _id: "new",
    organisationId: "",
    title: "",
    usageUnit: "",
    status: true,
    categories: [],
  };
  return dispatch(setDetailFleetPrestart(newModel));
};

//complete fleet prestart
export const FETCH_ALL_FLEET_COMPLETE_PRESTART = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet-completing-prestart/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_ALL_FLEET_COMPLETE_PRESTART_FOR_USER = async (organisationId, loggedUserId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet-completing-prestart/allForUser`, {
        params: {
          organisationId,
          loggedUserId
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_DETAIL_FLEET_COMPLETE_PRESTART = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet-completing-prestart/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const CREATE_FLEET_COMPLETE_PRESTART = async (data, sign) => {
  try {
    const formData = new FormData();
    if (sign) {
      formData.append("photo", sign);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet-completing-prestart/create`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      )
      .then((res) => {
        return res.data.payload.data;
      })
      .catch(error => {
        throw error.response.data.payload.message
      });
  } catch (err) {
    throw err
  }
};

export const UPDATE_FLEET_COMPLETE_PRESTART = async (data, sign) => {
  try {
    const formData = new FormData();
    if (sign) {
      formData.append("photo", sign);
    }
    formData.append("data", JSON.stringify(data));
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet-completing-prestart/update`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const DELETE_FLEET_COMPLETE_PRESTART = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/fleet-completing-prestart/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_FLEET_COMPLETE_PRESTART = () => (dispatch) => {
  const newModel = {
    _id: "new",
    fleetId: "",
    teamMember: "",
    organisationId: "",
    title: "",
    usageUnit: "",
    usageUnitValue: "",
    notes: "",
    status: true,
    categories: [],
  };
  return dispatch(setDetailFleetCompltePrestart(newModel));
};
