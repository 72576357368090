import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Space, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { LINE_ITEM_TABLE_TYPE } from "../../constants";
import { EMAIL_INVOICE } from "../../store/invoice-store/reducer";
import { 
  EMAIL_QUOTE,
  setDetailQuote
} from "../../store/quote-store/reducer"
import {
  setDetailPurchase,
  EMAIL_PURCHASE,
} from "../../store/contact-store/reducer";
import { useParams } from "react-router-dom";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const EmailModal = ({
  visible,
  setVisible,
  data,
  type,
  hierachy,
  totalData,
}) => {
  const dispatch = useDispatch();
  const { organisationId, selizationOrganisations } = useSelector(({ auth }) => auth.user);

  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  
  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = async (values) => {
    try {
      setSaveLoading(true);
      let validEmail = true;
      const { emails, subject, message: mes } = values;
      const splitEmails = emails.split(",");
      splitEmails.forEach((email) => {
        if (!validateEmail(email)) {
          validEmail = false;
        }
      });

      if (validEmail) {
        let lineItemsArr = [];
        for (let i = 0; i < data.lineItems.length; i++) {
          const li = data.lineItems[i];
          lineItemsArr.push({
            Quantity: li.quantity,
            Item_Code: li.itemId?.sku,
            Description: li.description,
            Unit_Price: li.unitAmount,
            Amount: Number(li.unitAmount) * Number(li.quantity),
          });
        }

        let dataSubmit;

        switch (type) {
          case LINE_ITEM_TABLE_TYPE.PURCHASE_ORDER:
            {
              dataSubmit = {
                _id: data._id,
                name: `Purchase Order ${data.orderNumber}`,
                xeroId: data.xeroId,
                organisationId: organisationId,
                message: mes,
                emails: emails.split(","),
                subject: subject,
                data: {
                  Invoice_Number: data.orderNumber,
                  Date: data.date,
                  Due_Date: data.deliveryDate,
                  Reference: data.reference,
                  Client: data?.contact?.name,
                  Client_Address: {
                    Street: data?.contact?.streetAddress,
                    Suburb: data?.contact?.streetSuburb,
                    State: data?.contact?.streetState,
                    Post_Code: data?.contact?.streetPostalCode,
                    Country: data?.contact?.streetCountry,
                  },
                  Line_Items: lineItemsArr,
                },
              };

              const res = await EMAIL_PURCHASE(dataSubmit);
              if (res) {
                dispatch(setDetailPurchase(res));
                setVisible(false);
              }
            }
            break;

          case LINE_ITEM_TABLE_TYPE.BILL:
            {
              dataSubmit = {
                _id: data._id,
                name: `Invoice ${data.reference}`,
                xeroId: data.xeroId,
                templateId: "404641",
                message: mes,
                emails: emails.split(","),
                subject: subject,
                data: {
                  Invoice_Number: data.reference,
                  Date: data.date,
                  Due_Date: data.dueDate,
                  Reference: data.reference,
                  Client: data?.contact?.name,
                  Client_Address: {
                    Street: data?.contact?.streetAddress,
                    Suburb: data?.contact?.streetSuburb,
                    State: data?.contact?.streetState,
                    Post_Code: data?.contact?.streetPostalCode,
                    Country: data?.contact?.streetCountry,
                  },
                  Line_Items: lineItemsArr,
                },
              };

              const res = await EMAIL_INVOICE(dataSubmit);
              if (res) {
                dispatch(setDetailPurchase(res));
                setVisible(false);
              }
            }
            break;

          case LINE_ITEM_TABLE_TYPE.INVOICE:
            {
              dataSubmit = {
                _id: data._id,
                name: `Invoice ${data.reference}`,
                xeroId: data.xeroId,
                organisationId: organisationId,
                templateId: "404641",
                message: mes,
                emails: emails.split(","),
                subject: subject,
                data: {
                  Invoice_Number: data.reference,
                  Date: data.date,
                  Due_Date: data.dueDate,
                  Reference: data.reference,
                  Client: data?.contact?.name,
                  Client_Address: {
                    Street: data?.contact?.streetAddress,
                    Suburb: data?.contact?.streetSuburb,
                    State: data?.contact?.streetState,
                    Post_Code: data?.contact?.streetPostalCode,
                    Country: data?.contact?.streetCountry,
                  },
                  Line_Items: lineItemsArr,
                },
              };
              const res = await EMAIL_INVOICE(dataSubmit);
              if (res) {
                dispatch(setDetailPurchase(res));
                setVisible(false);
              }
            }
            break;

          case LINE_ITEM_TABLE_TYPE.QUOTE:
            {
              dataSubmit = {
                _id: data._id,
                name: `Quote ${data.reference}`,
                organisationId: organisationId,
                contactId: data.contact.xeroId,
                date: data.date,
                xeroId: data.xeroId,
                templateId: "404641",
                message: mes,
                emails: emails.split(","),
                subject: subject,
                data: {
                  Invoice_Number: data.reference,
                  Date: data.date,
                  Due_Date: data.dueDate,
                  Reference: data.reference,
                  Client: data?.contact?.name,
                  Client_Address: {
                    Street: data?.contact?.streetAddress,
                    Suburb: data?.contact?.streetSuburb,
                    State: data?.contact?.streetState,
                    Post_Code: data?.contact?.streetPostalCode,
                    Country: data?.contact?.streetCountry,
                  },
                  Line_Items: lineItemsArr,
                },
              };

              const res = await EMAIL_QUOTE(dataSubmit);
              if (res) {
                dispatch(setDetailQuote(res));
                setVisible(false);
              }
            }
            break;
          default:
            break;
        }

        message.success("Email Sent");
      } else {
        message.error(
          "The email addresses are not valid. Please check and try again (Note: Each email is separated by comma)."
        );
      }
      setSaveLoading(false);
    } catch (err) {
      message.error(err);
    }
  };

  useEffect(() => {
   if(organisationId) {
    const org = selizationOrganisations.filter(o => o.organisationId === organisationId)[0]
    form.setFields([
      {
        name: "emails",
        value: data?.contact?.emailAddress,
      },
      {
        name: "subject",
        value: `${hierachy[0]?.label} ${data?.orderNumber} from Demo Company (AU) for ${data?.contact?.name}`,
      },
      {
        name: "message",
        value: `Hi,\nHere's ${hierachy[0]?.label} ${data?.orderNumber} for $AUD ${totalData?.total}.\n\nDelivery due date, address and intructions are included in the ${hierachy[0]?.label?.toLowerCase()}.\n\nIf you have any questions, please let us known\n\nThanks,\n\n${org.organisationName}`,
      },
    ]);
   }
  }, [data, organisationId]);

  return (
    <Modal
      title="Send Email"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={"60%"}
    >
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        labelAlign="left"
        scrollToFirstError
        autoComplete="on"
      >
        <Form.Item
          name="emails"
          label={<label className="custom-label">To</label>}
          rules={[{ required: true }]}
        >
          <Input placeholder="To" />
        </Form.Item>
        <Form.Item
          name="subject"
          label={<label className="custom-label">Subject</label>}
          rules={[{ required: true }]}
        >
          <Input placeholder="Subject" />
        </Form.Item>
        <Form.Item
          name="message"
          label={<label className="custom-label">Message</label>}
          rules={[{ required: true }]}
        >
          <Input.TextArea placeholder="Message" rows={10} />
        </Form.Item>
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Form.Item className="mg-0">
            <Button type="secondary" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item className="mg-0">
            <Button htmlType="submit" type="primary" loading={saveLoading}>
              Send
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default EmailModal;
