import FleetVehicle from "../../../components/FleetVehicle";

const TeamMember = () => {
  return (
    <>
      <FleetVehicle />
    </>
  );
};

export default TeamMember;
