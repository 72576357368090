import Document from "./Document/index";
import DocumentEdit from "./Document/detail";
import DocumentCategory from "./DocumentCategory";
import DocumentCategoryEdit from "./DocumentCategory/detail";
import CompanyPolicies from "./CompanyPolicies";
import DocumentRegister from "./DocumentRegister";
import SafeWorkMethodStatements from "./SafeWorkMethodStatements";
import SafeWorkProcedures from "./SafeWorkProcedures";
import SafetyDataSheets from "./SafetyDataSheets";
import QualificationsTraining from "./QualificationsTraining";
import { Navigate } from "react-router-dom";

const router = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "/documents/:id",
      component: DocumentEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents",
      component: Document,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/categories/:id",
      component: DocumentCategoryEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/categories",
      component: DocumentCategory,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/policies",
      component: CompanyPolicies,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/policies/:id",
      component: DocumentEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/register",
      component: DocumentRegister,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/register/:id",
      component: DocumentEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/safety-data-sheets",
      component: SafetyDataSheets,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/safety-data-sheets/:id",
      component: DocumentEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/safety-work-method-statements",
      component: SafeWorkMethodStatements,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/safety-work-method-statements/:id",
      component: DocumentEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/safety-work-procedures",
      component: SafeWorkProcedures,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/safety-work-procedures/:id",
      component: DocumentEdit,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/documents/qualification-and-training",
      component: QualificationsTraining,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
  ],
};

export default router;
