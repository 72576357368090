import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin, message } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  CREATING_ITEM_CATEGORY,
  FETCH_DETAIL_ITEM_CATEGORY,
  NEW_ITEM_CATEGORY,
  setDetailItemCategory,
  UPDATING_ITEM_CATEGORY,
} from "../../store/items-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditDocumentCategory = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailItemCategory } = useSelector(({ items }) => items.itemReducer);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const onUpperCase = (e) => {
    if(e) {
      form.setFields([
        {
          name: "code",
          value: e.target.value.toUpperCase()
        }
      ])
    }
  }

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailItemCategory?._id;
    values.code = values.code.toUpperCase();
    if (id === "new") {
      CREATING_ITEM_CATEGORY(values)
        .then((data) => {
          setLoading(false);
          navigate("/items/category");
        })
        .catch((err) => {
          const res = err.response.data.payload.message;
          message.error(res);
          setLoading(false);
        });
    } else {
      UPDATING_ITEM_CATEGORY(values)
        .then((data) => {
          setLoading(false);
          navigate("/items/category");
        })
        .catch((err) => {
          const res = err.response.data.payload.message;
          message.error(res);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const updateState = () => {
      if (id === "new") {
        dispatch(NEW_ITEM_CATEGORY());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_ITEM_CATEGORY(id).then((data) => {
          dispatch(setDetailItemCategory(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if (detailItemCategory) {
      form.setFields([
        {
          name: "code",
          value: detailItemCategory.code.toUpperCase(),
        },
        {
          name: "name",
          value: detailItemCategory.name,
        },
        {
          name: "status",
          value: detailItemCategory.status,
        },
      ]);
    }
  }, [detailItemCategory]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Item Categories", path: "/items/category" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/items/category/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            requiredMark={false}
            form={form}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "The Code must not exceed 3 characters.",
                },
              ]}
            >
              <Input maxLength={3} onChange={onUpperCase}/>
            </Form.Item>
            <Form.Item label="Active" name="status" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditDocumentCategory;
