import Payments from "./index";
import PaymentsEdit from "./detail";

const router = {
  settings: {
    layout: {},
  },

  routes: [
    {
      path: "/payroll/pay-profiles/:payId",
      component: PaymentsEdit,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true,
        },
      },
    },
    {
      path: "/payroll/pay-profiles",
      component: Payments,
      auth: ["admin", "user"],
      settings: {
        layout: {
          head: true,
          sider: true
        },
      },
    },
  ],
};

export default router;
