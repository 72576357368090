import { Modal, Button, Spin } from "antd";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "../../../styles/teamMember.scss";
import { useSelector } from "react-redux";
import {
  CREATING_ITEM_CATEGORY,
  CREATING_ACCOUNT_CODE,
} from "../../../store/items-store/reducer";
import FormCategory from "./formCategory";
import FormAccountCodeode from "./formAccountCode";

const ModalItems = ({
  form,
  fetchSubData,
  isModalVisible,
  setIsModalVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const { organisationId } = useSelector(({ auth }) => auth.user);

  const [category, setCategory] = useState({
    organisationId: organisationId,
    _id: "new",
    status: true,
  });
  const [code, setCode] = useState({
    organisationId: organisationId,
    _id: "new",
    status: true,
  });

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18, color: "#fff" }} spin />
  );

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    switch (isModalVisible.type) {
      case "Category":
        CREATING_ITEM_CATEGORY(category).then((data) => {
          fetchSubData();
          setTimeout(() => {
            form.setFields([
              {
                name: "category",
                value: data?._id,
              },
            ]);
            setLoading(false);
            setIsModalVisible(false);
          }, 1000);
        });
        break;
      case "Chart of Accounts":
        CREATING_ACCOUNT_CODE(code).then((data) => {
          fetchSubData();
          setTimeout(() => {
            form.setFields([
              {
                name: "accountCode",
                value: data?._id,
              },
            ]);
            setLoading(false);
            setIsModalVisible(false);
          }, 1500);
        });
        break;
    }
  };

  const renderType = () => {
    switch (isModalVisible.type) {
      case "Category":
        return <FormCategory category={category} setCategory={setCategory} />;
      case "Chart of Accounts":
        return <FormAccountCodeode code={code} setCode={setCode} />;
      default:
        setLoading(false);
        break;
    }
  };

  return (
    <Modal
      title={"Add " + isModalVisible.type}
      visible={isModalVisible}
      onCancel={handleCancel}
      className="modal-sub"
      okText="Add"
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="add"
          type="primary"
          onClick={handleOk}
          style={{ opacity: loading && 0.6 }}
        >
          {loading ? (
            <Spin
              indicator={antIcon}
              style={{ position: "relative !important" }}
            />
          ) : (
            "Add"
          )}
        </Button>,
      ]}
    >
      {renderType()}
    </Modal>
  );
};

export default ModalItems;
