import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Badge, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import {
  FETCH_ALL_DOCUMENT,
  FETCH_ALL_DOCUMENT_BY_CATEGORY,
  setDocuments,
  DELETING_DOCUMENT,
} from "../../store/document-store/reducer";
import moment from "moment";

const Documents = ({ description }) => {
  const dispatch = useDispatch();
  const { organisationId, role } = useSelector(({ auth }) => auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const { documents } = useSelector(
    ({ documents }) => documents.documentReducer
  );
  const [dataList, setDataList] = useState([]);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    xPos: 0,
    yPos: 0,
    record: null,
    isLoading: false
  });
  const [csv, setCsv] = useState({
    headers: [
      {
        key: "title",
        label: "Title",
      },
      {
        key: "category",
        label: "Category",
      },
      {
        key: "fileType",
        label: "File Type",
      },
      {
        key: "fileUrl",
        label: "File Url",
      },
      {
        key: "versionNumber",
        label: "Version Number",
      },
      {
        key: "versionDate",
        label: "Version Date",
      },
      {
        key: "teamMember",
        label: "Team Member",
      },
      {
        key: "fleet",
        label: "Fleet",
      },
      {
        key: "permission",
        label: "Permission",
      },
      {
        key: "active",
        label: "Active",
      },
    ],
    data: [],
    fileName: "documents.csv",
  });

  const handleColumnCsv = (array) => {
    let csvData = [];
    array?.forEach((e) => {
      csvData.push({
        title: e.title,
        category: e.category,
        fileType: e.fileType,
        fileUrl: e.fileUrl,
        versionNumber: e.versionNumber,
        versionDate: e.versionDate,
        teamMember: e.teamMember,
        fleet: e.fleet,
        permission: e.permission,
        active: e.active,
      });
    });
    return csvData;
  };

  const columns = [
    {
      title: "Number",
      render: (data) => (
        <>
          {data.fileUrl && JSON.parse(data.fileUrl)?.name?.split(" - ")[0]}
          {/* {documents?.map((doc, index) => (
            <span key={index}>
              {data._id === doc._id &&
                `${data?.category?.code}${1000 + index + 1}`}
            </span>
          ))} */}
        </>
      ),
      sorter: (a, b) => a?.title.localeCompare(b?.title),
      width: "8%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a?.title.localeCompare(b?.title),
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <>
          <Tooltip placement="topLeft" title={data}>
            {data}
          </Tooltip>
        </>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",

      sorter: (a, b) =>
        a?.category.description.localeCompare(b?.category.description),
      render: (data) => (
        <>
          <Tooltip
            placement="topLeft"
            title={data?.code + " - " + data?.description}
          >
            {`${data?.code ? data?.code : ""}`}
          </Tooltip>
        </>
      ),
      width: "8%",
    },
    {
      title: "Version",
      dataIndex: "versionNumber",
      key: "versionNumber",
      sorter: (a, b) => (moment(a?.versionNumber)?.unix() - moment(b?.versionNumber)?.unix()),
      render: (data) => <>{Number(data).toFixed(1)}</>,
      width: "8%",
    },
    {
      title: "Version Date",
      dataIndex: "versionDate",
      key: "versionDate",
      render: (versionDate) => <>{moment(versionDate).format("DD-MMM-YYYY")}</>,
      sorter: (a, b) => a?.versionDate.localeCompare(b?.versionDate),
      width: "8%",
    },
    {
      title: "Association",
      render: (record) => (
        <p style={{ display: "flex", gap: 5, flexWrap: "wrap", margin: 0 }}>
          {record.teamMember && <Tag color="gray">Team Member</Tag>}
          {record.fleet && <Tag color="gray">Fleet</Tag>}
        </p>
      ),
      sorter: (a, b) => a?._id.localeCompare(b?._id),
      width: "12%",
    },
    {
      title: "Permission",
      dataIndex: "permission",
      key: "permission",
      render: (permission) => (
        <>
          {permission === "admin" ? (
            <Tag color="#108ee9">{permission}</Tag>
          ) : permission === "manager" ? (
            <Tag color="warning" style={{ color: "#ffc53d" }}>
              {permission}
            </Tag>
          ) : (
            <Tag color="default">{permission}</Tag>
          )}
        </>
      ),
      sorter: (a, b) => a?.permission.localeCompare(b?.permission),
      width: "8%",
    },
    {
      title: "Expiry date",
      render: (record) => (
        <>
          {moment().isAfter(moment(record.expireDate)) ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
              {moment(record.expireDate).format("DD-MMM-YYYY")}
              <Tag color="#ff4d4f">Expired</Tag>
            </div>
          ) : (
            <>
              {record.expireDate &&
                record.expireDate !== "" &&
                moment(record.expireDate).format("DD-MMM-YYYY")}
            </>
          )}
        </>
      ),
      sorter: (a, b) => (moment(a?.expireDate)?.unix() - moment(b?.expireDate)?.unix()),
      width: "12%",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Active" : "InActive"} />;
      },
      sorter: (a, b) => b.active - a.active,
      width: "8%",
    },
  ];

  const FETCH_DATA = () => {
    if (organisationId) {
      if (description) {
        FETCH_ALL_DOCUMENT_BY_CATEGORY(organisationId, description)
          .then((data) => dispatch(setDocuments(data)))
          .finally(() => setIsLoading(false));
      } else {
        FETCH_ALL_DOCUMENT({ organisationId })
          .then((data) => {
            const roleMananger = data.filter(
              (ele) => ele.permission !== "admin"
            );
            if (role.includes("manager")) {
              dispatch(setDocuments(roleMananger));
            } else {
              dispatch(setDocuments(data));
            }
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (documents) {
      const columns = handleColumnCsv(documents);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [documents]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    documents?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.category?.code}${1000 + index} - ${ele?.title}`,
        description: `v${parseInt(ele.versionNumber).toFixed(1)} / ${moment(
          ele?.versionDate
        ).format("DD-MMM-YYYY")}`,
        fileUrl: ele?.fileUrl,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [documents]);

  const onToogleMenuOpen = () => {
    if (contextMenu?.record?.fileUrl && contextMenu?.record?.fileUrl !== "") {
      window.open(
        JSON.parse(contextMenu?.record?.fileUrl)?.webViewLink,
        "_blank"
      );
    }
    setContextMenu({ visible: false });
  };

  return (
    <div className="table-layouts">
      <div className="tables">
        <StandardTableLayout
          csvHeader={csv.headers || null}
          isPagination={{
            current: 1,
            pageSize: 100,
          }}
          csvData={csv.data || null}
          csvFileName={csv.fileName || null}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          hierachy={[{ key: 1, label: "Documents" }]}
          dataSource={documents}
          columns={columns}
          DELETE_FUNC={DELETING_DOCUMENT}
          hasNew={true}
          FETCH_DATA={FETCH_DATA}
          rightMenu={true}
          optionMenu={[{ title: "Open", onToogleMenu: onToogleMenuOpen }]}
          contextMenu={contextMenu}
          setContextMenu={setContextMenu}
          table={
            description === "Safety Data Sheet"
            ? "documents/safety-data-sheets" 
            : description === "Safe Work Method Statement"
              ? "documents/safety-work-method-statements"
              : description === "Safe Work Procedure"
                ? "documents/safety-work-procedures"
                : description === "Policy"
                  ? "documents/policies"
                  : description === "Qualification"
                    ? "documents/qualification-and-training"
                    : "documents"
          }
        />
      </div>
      <div className="table-lists">
        <StandardListLayout
          csvHeader={csv.headers || null}
          csvData={csv.data || null}
          csvFileName={csv.fileName || null}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          hierachy={[{ key: 1, label: "Documents" }]}
          dataList={dataList}
          columns={columns}
          DELETE_FUNC={DELETING_DOCUMENT}
          hasNew={true}
          FETCH_DATA={FETCH_DATA}
          rightMenu={true}
          optionMenu={[{ title: "Open", onToogleMenu: onToogleMenuOpen }]}
          contextMenu={contextMenu}
          setContextMenu={setContextMenu}
          table="documents"
        />
      </div>
    </div>
  );
};

export default Documents;
