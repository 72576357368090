import EditFleetCategory from "../../../components/FleetCategories/EditFleetCategories";

const FleetCategoriesEdit = () => {
  return (
    <>
      <EditFleetCategory />
    </>
  );
};

export default FleetCategoriesEdit;
