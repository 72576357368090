/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/StandardTableLayout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import {
  Input,
  Layout,
  Table,
  Breadcrumb,
  Space,
  Button,
  Modal,
  Tooltip,
  Dropdown,
  Menu,
  Drawer,
  Tabs,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  SearchOutlined,
  MenuOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { setCsvExportData } from "../../store/user-store/reducer";
import FuseUtils from "../../utils/Utils";
import { INVOICE_STATUS } from "../../constants";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Content } = Layout;
const { TabPane } = Tabs;

const StandardTableTabPaneLayout = ({
  csvHeader,
  csvData,
  csvFileName,
  isLoading,
  setIsLoading,
  hierachy,
  dataSource,
  tabSort,
  setTabSort,
  columns,
  FETCH_DATA,
  DELETE_FUNC,
  table,
}) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { role } = useSelector(({ auth }) => auth.user);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [rowsSelected, setRowsSelected] = useState(false);
  const [saveRow, setSaveRow] = useState([]);
  const [visible, setVisible] = useState(false);
  const [tempDate, setTempDate] = useState();
  const [configToolbar, setConfigToolbar] = useState({ isDelete: true });

  const onAddButtonClickHandler = () => {
    navigate(`/${table}/new`);
  };

  const showConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to delete these records?",
      icon: <ExclamationCircleOutlined />,
      content: "The operation cannot be undone.",
      onOk() {
        const newSource = saveRow?.map((s) => {
          return `ids=${s._id}`;
        });
        DELETE_FUNC(newSource.join("&")).then((data) => {
          FETCH_DATA();
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, record) => {
      setSaveRow(record);
      selectedRowKeys && selectedRowKeys?.length > 0
        ? setRowsSelected(true)
        : setRowsSelected(false);
    },
  };

  const moreOptionsMenu = (
    <Menu style={{ minWidth: "150px" }}>
      <Menu.Item key="0">Print</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={() => dispatch(setCsvExportData(tableData))}>
        <CSVLink
          data={csvData && csvData}
          headers={csvHeader && csvHeader}
          separator={","}
          filename={csvFileName && csvFileName}
        >
          Export
        </CSVLink>
      </Menu.Item>
    </Menu>
  );

  const onSearchDrawerClose = () => {
    setVisible(false);
  };

  const showSearchDrawer = () => {
    setVisible(true);
  };

  const handleClickRow = (record) => {
    setIsLoading(true);
    if (record) {
      setIsLoading(false);
      navigate(`/${table}/${record._id}`);
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setTempDate(
      searchText.length === 0
        ? tableData
        : FuseUtils.filterArrayByString(tableData, searchText)
    );
  };

  useEffect(() => {
    const newData = [];
    if (dataSource) {
      dataSource &&
        dataSource?.map((ele, index) => {
          newData.push({
            ...ele,
            key: ele._id,
          });
        });
      setTableData(newData);
      setTableColumns(columns);
    }
  }, [dataSource, columns, hierachy, table]);

  useEffect(() => {
    if (saveRow) {
      dispatch(setCsvExportData(saveRow));
    }
  }, [saveRow]);

  const switchKeys = (key) => {
    switch (key.toLowerCase()) {
      case "all":
        return "ALL";
      case "draft":
        return INVOICE_STATUS.DRAFT;
      case "awaiting approval":
        return INVOICE_STATUS.SUBMITTED;
      case "awaiting payment":
        return INVOICE_STATUS.AUTHORISED;
      case "approved":
        return INVOICE_STATUS.AUTHORISED;
      case "billed":
        return INVOICE_STATUS.BILLED;
      case "paid":
        return INVOICE_STATUS.PAID;
      case "repeating":
        return INVOICE_STATUS.VOIDED;
      case "Sent":
        return INVOICE_STATUS.SENT;
      case "Desclined":
        return INVOICE_STATUS.DECLINED;
      case "Accepted":
        return INVOICE_STATUS.ACCEPTED;
      case "Invoiced":
        return INVOICE_STATUS.INVOICED;
    }
  };

  const onChangeTabs = (key) => {
    let switchKey = switchKeys(key);
    setTabSort({
      ...tabSort,
      tabChoosed: switchKey,
    });
  };

  useEffect(() => {
    const tabChoosing = tabSort?.tabChoosed;
    // if tab is approve --> isDelete :false
    if (tabChoosing?.toUpperCase() === INVOICE_STATUS.SUBMITTED) {
      setConfigToolbar({ ...configToolbar, isDelete: false });
    } else {
      setConfigToolbar({ ...configToolbar, isDelete: true });
    }
  }, [tabSort]);

  return (
    <>
      <Content className={styles.content}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          {hierachy?.map((item) => {
            return (
              <Breadcrumb.Item key={item.key}>{item.label}</Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        <div className="site-layout-content">
          <div className={styles.actionBar}>
            {rowsSelected && (
              <Space>
                {configToolbar.isDelete && (
                  <Button icon={<DeleteOutlined />} onClick={showConfirm}>
                    Delete
                  </Button>
                )}
                <CSVLink
                  data={csvData ? csvData : []}
                  headers={csvHeader && csvHeader}
                  separator={","}
                  filename={"records"}
                >
                  <Button icon={<ExportOutlined />}>Export</Button>
                </CSVLink>
              </Space>
            )}

            <div className={styles.rightActions}>
              <Space>
                <Tooltip placement="topLeft" title="Search">
                  <Button
                    icon={<SearchOutlined />}
                    onClick={showSearchDrawer}
                  ></Button>
                </Tooltip>
                {role?.indexOf("admin") !== -1 && (
                  <Tooltip placement="top" title="Add">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={onAddButtonClickHandler}
                    ></Button>
                  </Tooltip>
                )}
                {role?.indexOf("admin") !== -1 && (
                  <Dropdown overlay={moreOptionsMenu} trigger={["click"]}>
                    <Tooltip placement="topRight" title="More Options">
                      <Button icon={<MenuOutlined />}></Button>
                    </Tooltip>
                  </Dropdown>
                )}
              </Space>
            </div>
          </div>

          {tabSort && (
            <Tabs
              defaultActiveKey="0"
              onChange={onChangeTabs}
              style={{ width: "100%" }}
            >
              {tabSort?.tabs?.map((ele) => (
                <TabPane
                  key={ele?.type}
                  tab={
                    <span>
                      {ele?.type} {ele?.length > 0 && `(${ele?.length})`}
                    </span>
                  }
                />
              ))}
            </Tabs>
          )}

          {/* Table  */}
          <Table
            size="small"
            rowClassName={"clickable"}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleClickRow(record);
                },
              };
            }}
            bordered
            rowSelection={rowSelection}
            pagination={{ pageSize: 50 }}
            columns={tableColumns}
            dataSource={tempDate ? tempDate : tableData}
            loading={{ indicator: antIcon, spinning: isLoading }}
            sticky={{ offsetHeader: 64 }}
            scroll={{ x: 1500 }}
          />
        </div>
      </Content>

      {/* Drawer  */}
      <Drawer
        title="Search"
        placement="right"
        onClose={onSearchDrawerClose}
        visible={visible}
        footer={
          <Space>
            <Button type="primary" onClick={onSearchDrawerClose}>
              Search
            </Button>
          </Space>
        }
      >
        <Input onChange={(e) => handleSearch(e)} autoFocus />
      </Drawer>
    </>
  );
};

export default StandardTableTabPaneLayout;
