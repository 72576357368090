import React from "react";
import EditEmploymentTypes from "../../../../components/EmploymentTypes/EditEmploymentTypes";

const TypesDetail = () => {
  return (
    <>
      <EditEmploymentTypes />
    </>
  );
};

export default TypesDetail;
