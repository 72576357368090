import React from 'react';
import { Modal, Input } from 'antd';

const FleetStartModal = ({
  setModalInput,
  modalInput,
  title,
  isVisible,
  handleOk,
  handleCancel
}) => {

  return (
    <Modal title={title} visible={isVisible} onOk={handleOk} onCancel={handleCancel}>
      <Input.TextArea autoSize value={modalInput} onChange={(e) => setModalInput(e.target.value)}/>
    </Modal>
  );
};

export default FleetStartModal