export const formatterInputNumber = (value, state) => {
    const str = value.toString().split(".");
    if(state) {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
        if(str.length === 1) {
            return `${value}.00`
        } else if(str[1].length === 1) {
            return value
        } else {
            return  Number(`${value}0`).toFixed(2)
        }
            
    }
}