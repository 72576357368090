import React from "react";
import Positions from "../../../../components/Positions";

const Position = () => {
  return (
    <>
      <Positions />
    </>
  );
};

export default Position;
