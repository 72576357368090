import Reports from "../../components/Reports";

const ReportPage = () => {
  return (
    <>
      <Reports />
    </>
  );
};

export default ReportPage;
