import TeamMembers from "../../../components/TeamMembers";

const TeamMember = () => {
  return (
    <>
      <TeamMembers />
    </>
  );
};

export default TeamMember;
