import EditQuote from "../../components/Quote/EditQuote";

const Quotes = () => {
  return (
    <>
      <EditQuote />
    </>
  );
};

export default Quotes;
