import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  List,
  Avatar,
  Tag,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import {
  MoreOutlined,
  CrownOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  DELETING_USER,
  FETCH_ALL_USERS_IN_ORGANISATION,
  setDetailUser,
  setUsers,
} from "../../store/user-store/reducer";
import { FORM_TYPE } from "../../constants";

const Users = ({ editUser, handleUpdateUser }) => {
  const dispatch = useDispatch();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const { data: users } = useSelector(({ user }) => user.userReducer);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    FETCH_ALL_USERS_IN_ORGANISATION(organisationId).then((data) => {
      dispatch(setUsers(data));
    }).finally(() => setLoading(false));
  }, [dispatch, organisationId]);

  const confirmDelete = (values) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      content: "This action can't be undone",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        await handleDelete(values);
      },
    });
  };

  const handleDelete = async (values) => {
    DELETING_USER({ email: values.email, organisationId })
      .then((res) => {
        handleUpdateUser(values._id, FORM_TYPE.DELETE);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const moreMenu = (user) => {
    return (
      <Menu>
        <Menu.Item key="1">
          <div
            onClick={() => {
              dispatch(setDetailUser(user));
              editUser();
            }}
          >
            Edit
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div style={{ color: "#f5222d" }} onClick={() => confirmDelete(user)}>
            Delete User
          </div>
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <List
      itemLayout="horizontal"
      dataSource={users}
      loading={loading}
      className="users"

      renderItem={(user) => (
        <List.Item
          actions={[
            <Tooltip title="More info and options" placement="right">
              <Dropdown
                overlay={moreMenu(user)}
                placement="bottomLeft"
                trigger={["click"]}
              >
                <Button type="text" icon={<MoreOutlined />} />
              </Dropdown>
            </Tooltip>,
          ]}
        >
          <List.Item.Meta
            avatar={
              <Avatar>
                {user.firstName.charAt(0).toUpperCase()}
                {user.lastName.charAt(0).toUpperCase()}
              </Avatar>
            }
            title={
              user.role === "Super Admin" ? (
                <a href="#">
                  {user.name} <CrownOutlined style={{ color: "#faad14" }} />
                </a>
              ) : (
                <a href="#">
                  {user.firstName} {user.lastName}
                </a>
              )
            }
            description={
              user.role.includes("admin") ? (
                <>
                  {user.role.map((r, index) => {
                    return (
                      <Tag key={index} color="#108ee9">
                        {r}
                      </Tag>
                    );
                  })}
                  {user.email}
                </>
              ) : (
                <>
                  {
                    user.role.includes("manager") ? (
                      <>
                        <Tag color="gold">{user.role[0]}</Tag> {user.email}
                      </>
                    ) : (
                      <>
                        <Tag color="default">{user.role[0]}</Tag> {user.email}
                      </>
                    )
                  }
                 
                </>
              )
            }
          />
        </List.Item>
      )}
    />
  );
};

export default Users;
