import FleetCompletingPreStartPage from "./index";
import DetailFleetCompletingStart from "./detail";

const router = {
  settings: {
    layout: {},
  },

  routes: [
    {
      path: "/fleet/prestarts/completing/:id",
      component: DetailFleetCompletingStart,
      auth: ["admin", "user", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
    {
      path: "/fleet/prestarts/completing",
      component: FleetCompletingPreStartPage,
      auth: ["admin", "manager"],
      settings: {
        layout: {
          head: true,
          sider: true,
        }
      }
    },
  ],
};

export default router;
