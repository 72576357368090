import DetailVehicleModel from "../../../components/VehicleModels/EditVehicleModels";

const VehicleModelsEditPage = () => {
  return (
    <>
      <DetailVehicleModel/>
    </>
  );
};

export default VehicleModelsEditPage;
