import DetailVehicleMake from "../../../components/VehicleMakes/EditVehicleMakse";

const VehicleMakeEditPage = () => {
  return (
    <>
      <DetailVehicleMake/>
    </>
  );
};

export default VehicleMakeEditPage;
