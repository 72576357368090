import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
//state
const orgSlice = createSlice({
    name: "userStore",
    initialState: {
        data: [],
        detailOrg: null,
        searchText: '',
        success: ""
    },
    reducers: {
        setOrgs: (state, action) => {
            state.data = action.payload
            state.detailOrg = null
        },
        setDetailOrg: (state, action) => {
            state.detailOrg = action.payload
        },

    }
})

export default orgSlice.reducer;

//action
export const { 
    setOrgs,
    setDetailOrg
} = orgSlice.actions;

export const FETCH_DETAIL_ORGANISATION = async ( organisationId ) => {
    try {
        return await axios.get(`${process.env.REACT_APP_BACK_URL}/api/organisations/organisation_get_detail`, { params: {
            organisationId
        }})
        .then(res => {
            return res.data.payload.organisation
        })
    } catch (err) {
        return console.error(err.message)
    }
}

export const UPDATING_ORG = async (organisationId, data, logo) => {
    const formData = new FormData()
    const body = {...data, _id: organisationId}
    formData.append("data", JSON.stringify(body))
    if(logo.length > 0) {
        formData.append("logo", logo[0].file)
    }
    try {
        return await axios.post(`${process.env.REACT_APP_BACK_URL}/api/organisations/organisation_update`, formData, {
            headers: {
                "Content-Type": `multipart/form-data;boundary=${formData._boundary}`
            }
        })
        .then(res => {
            return res.data.payload.updatedOrganisation
        })
    } catch (err) {
        return console.error(err.message)
    }
}


export const NEW_ORG = () => dispatch => {
    const newModel = {
        name: "",
        legalName: "",
        ABN: 0,
        email: "",
        phone: "",
        street: "",
        suburb: "",
        postcode: "",
        state: "",
        tenantId: "",
        country: "",
        website: "",
        facebookUrl: "",
        instagramUrl: "",
        linkedInUrl: "",
        youtubeUrl: "",
        status: true
    }
    return dispatch(setDetailOrg(newModel))
}