export const BACK_URL =
  "https://script.google.com/macros/s/AKfycby-Gc2mZLgxbEMbabyn8BXLeOp8IAjEaE6VY-JaEokwP_9b_s6X4AScw8t_v30REGI-/exec";

export const APP_NAME = "Insight";

export const FORM_TYPE = {
  UPDATE: "UPDATE",
  CREATE: "CREATE",
  DELETE: "DELETE",
};

export const MENU_ITEM_TYPE = {
  ITEM: "item",
  COLLAPSE: "collapse",
};

export const TAX_DATA = [
  { id: "1", value: "BAS Excluded" },
  // { id: '2', value: "GST Free Capital" },
  { id: "3", value: "GST Free Expenses" },
  // { id: '4', value: " GST Free Exports" },
  { id: "5", value: "GST Free Income" },
  // { id: '6', value: "GST on Capital" },
  // { id: '7', value: " GST on Capital Imports" },
  { id: "8", value: "GST on Imports" },
  { id: "9", value: "GST on Expenses" },
  { id: "10", value: "GST on Income" },
  // { id: '11', value: "Input Taxed" },
];

export const TERM_PAYMENT_DATA = [
  { id: "1", value: "DAYSAFTERBILLDATE", text: "day(s) after the bill date" },
  {
    id: "3",
    value: "DAYSAFTERBILLMONTH",
    text: "day(s) after the end of the bill month",
  },
  { id: "5", value: "OFCURRENTMONTH", text: "of the current month" },
  { id: "8", value: "OFFOLLOWINGMONTH", text: "of the following month" },
];

export const TERM_PAYMENT_IVOICE_DATA = [
  {
    id: "1",
    value: "DAYSAFTERINVOICEDATE",
    text: "day(s) after the invoice date",
  },
  {
    id: "3",
    value: "DAYSAFTERINVOICEMONTH",
    text: "day(s) after the end of the invoice month",
  },
  { id: "5", value: "OFCURRENTMONTH", text: "of the current month" },
  { id: "8", value: "OFFOLLOWINGMONTH", text: "of the following month" },
];

export const LINE_ITEM_TABLE_TYPE = {
  PURCHASE_ORDER: "PURCHASE_ORDER",
  BILL: "BILL",
  INVOICE: "INVOICE",
  QUOTE: "QUOTE"
};

export const INVOICE_STATUS = {
  AUTHORISED: "AUTHORISED",
  BILLED: "BILLED",
  DRAFT: "DRAFT",
  SUBMITTED: "SUBMITTED",
  DELETED: "DELETED",
  VOIDED: "VOIDED",
  PAID: "PAID",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
  INVOICED: "INVOICED",
  SENT: "SENT",
};

export const BILL_STATUS = {
  DRAFT: "DRAFT",
  AWAITING_APPROVAL: "AWAITING APPROVAL",
  APPROVED: "APPROVED",
};

export const TAX_RATE_DATA = [
  { name: "BASEXCLUDED", value: "BAS Excluded" },
  // { name: 'EXEMPTCAPITAL', value: "GST Free Capital" },
  { name: "EXEMPTEXPENSES", value: "GST Free Expenses" },
  // { name: 'EXEMPTEXPORT', value: " GST Free Exports" },
  { name: "EXEMPTOUTPUT", value: "GST Free Income" },
  // { name: 'CAPEXINPUT', value: "GST on Capital" },
  // { name: 'GSTONCAPIMPORTS', value: " GST on Capital Imports" },
  { name: "GST_ON_IMPORT", value: "GST on Imports" },
  { name: "INPUT", value: "GST on Expenses" },
  { name: "OUTPUT", value: "GST on Income" },
  // { name: 'INPUTTAXED', value: "Input Taxed" },
];

export const PAYMENT_TERM_BILL_TYPE = {
  DAYSAFTERBILLMONTH: "DAYSAFTERBILLMONTH",
  DAYSAFTERBILLDATE: "DAYSAFTERBILLDATE",
  OFCURRENTMONTH: "OFCURRENTMONTH",
  OFFOLLOWINGMONTH: "OFFOLLOWINGMONTH"
}

export const PAYMENT_TERM_INVOICE_TYPE = {
  DAYSAFTERINVOICEMONTH: "DAYSAFTERINVOICEMONTH",
  DAYSAFTERINVOICEDATE: "DAYSAFTERINVOICEDATE",
  OFCURRENTMONTH: "OFCURRENTMONTH",
  OFFOLLOWINGMONTH: "OFFOLLOWINGMONTH"
}

export const DATA_TYPE = {
  SITE_OPERATOR: "SITE_OPERATOR",
  MANUFACTURERS: "MANUFACTURERS",
  RISK_LEVEL: "RISK_LEVEL",
};
