import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useSelector } from "react-redux";
import {
  DELETE_TIME_SHEET,
  FETCH_ALL_TIME_SHEET_ADMIN,
} from "../../store/time-sheet-store/reducer";
import { Tag } from "antd";
import { useNavigate } from "react-router-dom";
import TimesheetCommentModalView from "../TimesheetComments/CommentModalView";

const TimeSheets = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [commentView, setOpenCommentView] = useState(false);
  const [comments, setComments] = useState([]);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [filterDate, setFilterDate] = useState([]);
  const { organisationId, role } = useSelector(({ auth }) => auth.user);
  const { searchDateTime } = useSelector(
    ({ timeSheet }) => timeSheet.TimeSheetReducer
  );
  const [dataList, setDataList] = useState([]);

  const convertReduce = (value) => {
    const initialValue = 0;
    const sum = value?.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
    return sum;
  };

  const columns = [
    {
      title: "Team Member",
      key: "teamMember",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        return (
          <p
            style={{
              color: "#0c5a7e",
              margin: 0,
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {record?.employee?.firstName} {record?.employee?.lastName}
          </p>
        );
      },
      sorter: (a, b) =>
        a?.employee?.firstName.localeCompare(b?.employee?.firstName),
      width: 150,
    },
    {
      title: "Completed",
      key: "completed",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        if (record.timeSheets.length >= 5) {
          return <Tag color="#87d068">{"Completed"}</Tag>;
        } else {
          return <Tag color="#e3a32e">{"< 5 days"}</Tag>;
        }
      },
    },
    {
      title: "Ordinary",
      key: "ordinary",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let payProfile = record.payProfile;
        let ordinaryHourBonus = 0.0;
        let overtime150 = 0.0;
        let bankedHours = 0.0;
        let overtime200 = 0.0;
        const value = record.timeSheets.map((r) => r?.totalHours);
        //handle over time hour
        let ordinaryHours = convertReduce(value);
        if (payProfile?.overtimeUsed) {
          if (payProfile.overtime.calculated === "weekly") {
            if (!payProfile.overtime.overtime200Used) {
              if (ordinaryHours > payProfile.overtime.overtime150after) {
                overtime150 =
                  ordinaryHours - payProfile.overtime.overtime150after;
                ordinaryHours = ordinaryHours - overtime150;
              }
            } else {
              if (
                ordinaryHours > payProfile.overtime.overtime150after &&
                ordinaryHours <= payProfile.overtime.overtime200after
              ) {
                overtime150 =
                  ordinaryHours - payProfile.overtime.overtime150after;
                ordinaryHours = ordinaryHours - overtime150;
              } else if (ordinaryHours > payProfile.overtime.overtime200after) {
                overtime150 =
                  payProfile.overtime.overtime200after -
                  payProfile.overtime.overtime150after;
                overtime200 =
                  ordinaryHours - payProfile.overtime.overtime200after;
                ordinaryHours = ordinaryHours - overtime150 - overtime200;
              }
            }
          } else {
            overtime150 = 0.0;
            record.timeSheets.forEach((timeRecord) => {
              if (
                timeRecord.totalHours > payProfile?.overtime?.overtime150after
              ) {
                overtime150 =
                  overtime150 +
                  (timeRecord.totalHours -
                    payProfile?.overtime?.overtime150after);
              }
            });
            ordinaryHours = ordinaryHours - overtime150;
          }
        }

        if (payProfile?.paidLunch) {
          const arr = record.timeSheets.map((r) =>
            r?.totalHours >= payProfile?.payLunchWhenHoursExceed ? 0.5 : 0
          );
          const paidLunch = convertReduce(arr);
          ordinaryHours = ordinaryHours - paidLunch;
        }

        if (payProfile?.bankedHoursUsed) {
          const value = record.timeSheets.map((r) => {
            if (r?.bankedHoursAction && r?.bankedHours > 0) {
              if (r.bankedHoursAction === "deposit") {
                return r?.bankedHours;
              } else {
                return r?.bankedHours * -1;
              }
            }
            return 0;
          });

          bankedHours = convertReduce(value);
          if (isNaN(bankedHours)) {
            bankedHours = 0.0;
          }
          ordinaryHours -= bankedHours;
        }

        if (payProfile?.includeOrdinaryHoursOverStandardWeek) {
          if (ordinaryHours > payProfile?.hours?.weekly) {
            ordinaryHourBonus = ordinaryHours - payProfile?.hours?.weekly;
            ordinaryHours = ordinaryHours - ordinaryHourBonus;
          }
        }

        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {ordinaryHours !== 0 && ordinaryHours.toFixed(2)}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.totalHours)) -
        convertReduce(a.timeSheets.map((r) => r?.totalHours)),
    },
    {
      title: "Paid Lunch",
      key: "paidLunch",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        const payProfile = record.payProfile;
        let value = [];
        if (payProfile?.paidLunch) {
          value = record.timeSheets.map((r) =>
            r?.totalHours >= payProfile?.payLunchWhenHoursExceed ? 0.5 : 0
          );
        }
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {convertReduce(value) !== 0 && convertReduce(value)?.toFixed(1)}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.workedAwayHours)) -
        convertReduce(a.timeSheets.map((r) => r?.workedAwayHours)),
    },
    {
      title: "Bonus",
      key: "bonus",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        const payProfile = record.payProfile;
        let value = [];
        if (payProfile?.bonusHoursUsed) {
          value = record.timeSheets.map((r) => {
            let eachOrdinaryHours = r.totalHours;
            let eachOvertime150 = 0.0;
            if (payProfile?.overtimeUsed) {
              if (payProfile.overtime.calculated === "daily") {
                eachOvertime150 = 0.0;
                if (eachOrdinaryHours > payProfile?.overtime?.overtime150after)
                  eachOvertime150 =
                    eachOvertime150 +
                    (eachOrdinaryHours -
                      payProfile?.overtime?.overtime150after);
                eachOrdinaryHours = eachOrdinaryHours - eachOvertime150;
              }
            }
            return eachOrdinaryHours;
          });
        }
        const bonus = value.map((v) => {
          if (v > payProfile?.payBonusWhenHoursExceed) {
            return Number(v) - Number(payProfile?.payBonusWhenHoursExceed);
          }
          return 0;
        });

        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {convertReduce(bonus) !== 0 && convertReduce(bonus)?.toFixed(2)}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.workedAwayHours)) -
        convertReduce(a.timeSheets.map((r) => r?.workedAwayHours)),
    },
    {
      title: "Ordinary Bonus",
      key: "ordinaryBonus",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let payProfile = record.payProfile;
        let ordinariHourBonus = 0;
        let overtime150 = 0.0;
        let bankedHours = 0.0;
        let overtime200 = 0.0;
        const value = record.timeSheets.map((r) => r?.totalHours);
        //handle over time hour
        let ordinaryHours = convertReduce(value);
        if (payProfile?.overtimeUsed) {
          if (payProfile.overtime.calculated === "weekly") {
            if (!payProfile.overtime.overtime200Used) {
              if (ordinaryHours > payProfile.overtime.overtime150after) {
                overtime150 =
                  ordinaryHours - payProfile.overtime.overtime150after;
                ordinaryHours = ordinaryHours - overtime150;
              }
            } else {
              if (
                ordinaryHours > payProfile.overtime.overtime150after &&
                ordinaryHours <= payProfile.overtime.overtime200after
              ) {
                overtime150 =
                  ordinaryHours - payProfile.overtime.overtime150after;
                ordinaryHours = ordinaryHours - overtime150;
              } else if (ordinaryHours > payProfile.overtime.overtime200after) {
                overtime150 =
                  payProfile.overtime.overtime200after -
                  payProfile.overtime.overtime150after;
                overtime200 =
                  ordinaryHours - payProfile.overtime.overtime200after;
                ordinaryHours = ordinaryHours - overtime150 - overtime200;
              }
            }
          } else {
            overtime150 = 0.0;
            record.timeSheets.forEach((timeRecord) => {
              if (
                timeRecord.totalHours > payProfile?.overtime?.overtime150after
              ) {
                overtime150 =
                  overtime150 +
                  (timeRecord.totalHours -
                    payProfile?.overtime?.overtime150after);
              }
            });
            ordinaryHours = ordinaryHours - overtime150;
          }
        }

        if (payProfile?.paidLunch) {
          const arr = record.timeSheets.map((r) =>
            r?.totalHours >= payProfile?.payLunchWhenHoursExceed ? 0.5 : 0
          );
          const paidLunch = convertReduce(arr);
          if (!isNaN(paidLunch)) {
            ordinaryHours = ordinaryHours - paidLunch;
          }
        }

        if (payProfile?.bankedHoursUsed) {
          const value = record.timeSheets.map((r) => {
            if (r?.bankedHoursAction && r?.bankedHours > 0) {
              if (r.bankedHoursAction === "deposit") {
                return r?.bankedHours;
              } else {
                return r?.bankedHours * -1;
              }
            }
            return 0;
          });
          bankedHours = convertReduce(value);
          if (!isNaN(bankedHours)) {
            ordinaryHours -= bankedHours;
          }
        }

        if (payProfile?.includeOrdinaryHoursOverStandardWeek) {
          if (ordinaryHours > payProfile?.hours?.weekly) {
            ordinariHourBonus = ordinaryHours - payProfile?.hours?.weekly;
          }
        }

        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {ordinariHourBonus > 0 && ordinariHourBonus?.toFixed(2)}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.workedAwayHours)) -
        convertReduce(a.timeSheets.map((r) => r?.workedAwayHours)),
    },
    {
      title: "150%",
      key: "150",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let payProfile = record.payProfile;
        let overtime150 = 0.0;
        const value = record.timeSheets.map((r) => r?.totalHours);
        //handle over time hour
        let ordinaryHours = convertReduce(value);
        if (payProfile?.overtimeUsed) {
          if (payProfile.overtime.calculated === "weekly") {
            if (!payProfile.overtime.overtime200Used) {
              if (ordinaryHours > payProfile.overtime.overtime150after) {
                overtime150 =
                  ordinaryHours - payProfile.overtime.overtime150after;
                ordinaryHours = ordinaryHours - overtime150;
              }
            } else {
              if (
                ordinaryHours > payProfile.overtime.overtime150after &&
                ordinaryHours <= payProfile.overtime.overtime200after
              ) {
                overtime150 =
                  ordinaryHours - payProfile.overtime.overtime150after;
              } else if (ordinaryHours > payProfile.overtime.overtime200after) {
                overtime150 =
                  payProfile.overtime.overtime200after -
                  payProfile.overtime.overtime150after;
              }
            }
          } else {
            overtime150 = 0.0;
            record.timeSheets.forEach((timeRecord) => {
              if (
                timeRecord.totalHours > payProfile?.overtime?.overtime150after
              )
                overtime150 =
                  overtime150 +
                  (timeRecord.totalHours -
                    payProfile?.overtime?.overtime150after);
            });
          }
        }

        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {overtime150 !== 0 && overtime150.toFixed(2)}
          </p>
        );
      },
    },
    {
      title: "Worked Away",
      key: "workedAway",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        const value = record.timeSheets.map((r) => r?.workedAwayHours);
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {/* {convertReduce(value) !== 0 && convertReduce(value)?.toFixed(2)} */}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.workedAwayHours)) -
        convertReduce(a.timeSheets.map((r) => r?.workedAwayHours)),
    },
    {
      title: "Annual",
      key: "annual",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let annualLeaveHours = 0.0;
        if (record.payProfile?.leave.annualLeave) {
          const value = record.timeSheets
            .filter((r) => r?.annualLeaveHours)
            .map((r) => r?.annualLeaveHours);
          annualLeaveHours = convertReduce(value);
        }
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {annualLeaveHours !== 0 && annualLeaveHours.toFixed(2)}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.annualLeaveHours)) -
        convertReduce(a.timeSheets.map((r) => r?.annualLeaveHours)),
    },
    {
      title: "Personal",
      key: "personal",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let personalLeaveHours = 0.0;
        if (record.payProfile?.leave.annualLeave) {
          const value = record.timeSheets
            .filter((r) => r?.personalLeaveHours)
            .map((r) => r?.personalLeaveHours);
          personalLeaveHours = convertReduce(value);
        }
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {personalLeaveHours !== 0 && personalLeaveHours.toFixed(2)}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.personalLeaveHours)) -
        convertReduce(
          a.timeSheets.map((r) => r?.leaveWithpersonalLeaveHoursoutPay)
        ),
    },
    {
      title: "Leave Without Pay",
      key: "leaveWithoutPay",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        const value = record.timeSheets
          .filter((r) => r?.leaveWithoutPay)
          .map((r) => r?.leaveWithoutPay);
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {convertReduce(value) !== 0 && convertReduce(value)?.toFixed(2)}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.leaveWithoutPay)) -
        convertReduce(a.timeSheets.map((r) => r?.leaveWithoutPay)),
    },
    {
      title: "Banked",
      key: "banked",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let bankedHours = 0.0;

        if (record.payProfile?.bankedHoursUsed) {
          const value = record.timeSheets.map((r) => {
            if (r?.bankedHoursAction && r?.bankedHours > 0) {
              if (r.bankedHoursAction === "deposit") {
                return r?.bankedHours;
              } else {
                return r?.bankedHours * -1;
              }
            }
            return 0;
          });

          bankedHours = convertReduce(value);

          if (isNaN(bankedHours)) {
            bankedHours = 0.0;
          }
        }

        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {bankedHours !== 0 && bankedHours.toFixed(2)}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.bankedHours)) -
        convertReduce(a.timeSheets.map((r) => r?.bankedHours)),
    },
    {
      title: "200%",
      key: "200",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let payProfile = record.payProfile;
        let overtime150 = 0.0;
        let overtime200 = 0.0;
        const value = record.timeSheets.map((r) => r?.totalHours);
        //handle over time hour
        let ordinaryHours = convertReduce(value);
        if (payProfile?.overtimeUsed) {
          if (payProfile.overtime.calculated === "weekly") {
            if (!payProfile.overtime.overtime200Used) {
              if (ordinaryHours > payProfile.overtime.overtime150after) {
                overtime150 =
                  ordinaryHours - payProfile.overtime.overtime150after;
              }
            } else {
              if (
                ordinaryHours > payProfile.overtime.overtime150after &&
                ordinaryHours <= payProfile.overtime.overtime200after
              ) {
                ordinaryHours =
                  ordinaryHours -
                  (ordinaryHours - payProfile.overtime.overtime150after);
              } else if (ordinaryHours > payProfile.overtime.overtime200after) {
                overtime150 =
                  payProfile.overtime.overtime200after -
                  payProfile.overtime.overtime150after;
                overtime200 =
                  ordinaryHours - payProfile.overtime.overtime200after;
              }
            }
          }
        }
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {overtime200 !== 0 && overtime200.toFixed(2)}
          </p>
        );
      },
    },
    {
      title: "Annual Loading",
      key: "annualLoading",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let annualLeaveHours = 0.0;
        if (record.payProfile?.leave.annualLeaveLoading) {
          const value = record.timeSheets.map((r) => r?.annualLeaveHours);
          annualLeaveHours = convertReduce(value);
        }
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {annualLeaveHours !== 0 && annualLeaveHours.toFixed(2)}
          </p>
        );
      },
      sorter: (a, b) =>
        convertReduce(b.timeSheets.map((r) => r?.annualLeaveHours)) -
        convertReduce(a.timeSheets.map((r) => r?.annualLeaveHours)),
    },

    {
      title: "Public Holiday",
      key: "publicHoliday",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let hourDaily = 0;
        const payProfile = record.payProfile;
        const timeSheets = record.timeSheets;
        if (payProfile && payProfile.hours.daily) {
          timeSheets.forEach((time) => {
            if (time.leave.includes("Public Holiday")) {
              hourDaily += payProfile.hours.daily;
            }
          });
        }
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {hourDaily > 0 && hourDaily}
          </p>
        );
      },
    },

    {
      title: "First-Aid",
      key: "aid",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let firstAidPayable = 0.0;
        if (record.payProfile?.allowances.firstAid) {
          firstAidPayable = 1.0;
        }
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {firstAidPayable !== 0 && firstAidPayable.toFixed(2)}
          </p>
        );
      },
    },

    {
      title: "Leading Hand",
      key: "leadingHand",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let leadingHandPayable = 0.0;
        if (record.payProfile?.allowances.leadingHand) {
          leadingHandPayable = 1.0;
        }

        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {leadingHandPayable !== 0 && leadingHandPayable.toFixed(2)}
          </p>
        );
      },
    },

    {
      title: "Plant Operator",
      key: "plantOperator",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let plantOperatorPayable = 0.0;
        if (record.payProfile?.allowances.plant) {
          plantOperatorPayable = record.timeSheets.length;
        }
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {plantOperatorPayable !== 0 && plantOperatorPayable.toFixed(2)}
          </p>
        );
      },
    },
    {
      title: "Meal",
      key: "meal",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/payroll/time-sheets/${record?.employee?._id}/${record?.employee?.firstName}_${record?.employee?.lastName}`
            );
          },
        };
      },
      render: (record) => {
        let mealPayable = 0.0;
        if (record.payProfile?.allowances.meal) {
          record.timeSheets.forEach((timeRecord) => {
            if (timeRecord.totalHours > 9.1) mealPayable += 1.0;
          });
        }
        return (
          <p style={{ color: "#0c5a7e", margin: 0 }}>
            {mealPayable !== 0 && mealPayable.toFixed(2)}
          </p>
        );
      },
    },
    {
      title: "Comments",
      key: "comments",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            const cms = [];
            record.timeSheets.forEach((t) => {
              if (t.comments) {
                cms.push(...t.comments);
              }
            });
            setOpenCommentView(true);
            setComments(cms);
          },
        };
      },
      render: (record) => {
        let countComments = 0;
        record.timeSheets.forEach((t) => {
          if (t.comments) {
            countComments += Number(t.comments.length);
          }
        });
        return countComments > 0 && <Tag color="#108ee9">{countComments}</Tag>;
      },
    },
  ];

  const [csv, setCsv] = useState({
    headers: [
      {
        key: "leave",
        label: "Leave",
      },
      {
        key: "signature",
        label: "Signature",
      },
      {
        key: "comment",
        label: "Comment",
      },
      {
        key: "payProfile",
        label: "Payroll Profile",
      },
      {
        key: "day",
        label: "Day",
      },
      {
        key: "overTimeHours",
        label: "Over Time Hours",
      },
      {
        key: "annualLeaveHours",
        label: "Annual Leave Hours",
      },
      {
        key: "personalLeaveHours",
        label: "Personal Leave Hours",
      },
      {
        key: "leaveWithoutPay",
        label: "Leave Without Pay",
      },
      {
        key: "bankedHours",
        label: "Banked Hours",
      },
      {
        key: "wasThisDayWorkedAway",
        label: "Was This Day Worked Away",
      },
      {
        key: "workedAwayHours",
        label: "Worked Away Hours",
      },
    ],
    data: [],
    fileName: "AdminTimeSheets.csv",
  });

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        dateTime: e.dateTime,
        startTime: e.startTime,
        startBreak: e.startBreak,
        finishBreak: e.finishBreak,
        finishTime: e.finishTime,
        totalHours: e.totalHours,
        leave: e.leave,
        signature: e.signature,
        comment: e.comment,
        payProfile: e.payProfile,
        day: e.day,
        overTimeHours: e.overTimeHours,
        annualLeaveHours: e.annualLeaveHours,
        personalLeaveHours: e.personalLeaveHours,
        leaveWithoutPay: e.leaveWithoutPay,
        bankedHours: e.bankedHours,
        wasThisDayWorkedAway: e.wasThisDayWorkedAway,
        workedAwayHours: e.workedAwayHours,
      });
    });
    return csvData;
  };

  useEffect(() => {
    if (filterDate) {
      const columns = handleColumnCsv(filterDate);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [filterDate]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  const FETCH_DATA = () => {
    setIsLoading(true);
    if (searchDateTime) {
      FETCH_ALL_TIME_SHEET_ADMIN(
        organisationId,
        searchDateTime.format("DD-MMM-YYYY")
      )
        .then((data) => {
          setFilterDate(data);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    } else {
      FETCH_ALL_TIME_SHEET_ADMIN(organisationId)
        .then((data) => {
          setFilterDate(data);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId, searchDateTime]);

  useEffect(() => {
    const newDoc = [];
    filterDate?.forEach((ele, index) => {
      let data = {
        _id: ele?.employee?._id,
        title: `${ele?.employee?.lastName} ${ele?.employee?.firstName}`,
        description: ``,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [filterDate]);

  return (
    <div className="team_member payroll_sumary">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            isPagination={false}
            className={"time-sheet-layout"}
            csvHeader={csv?.headers || null}
            csvData={csv?.data || null}
            csvFileName={csv?.fileName || null}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isHaveRowAction={false}
            hierachy={[{ key: 1, label: "Payroll Summary" }]}
            dataSource={filterDate}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_TIME_SHEET}
            hasNew={false}
            dateRanger={true}
            hasSelection={false}
            table="payroll/payroll-summary"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv?.headers || null}
            csvData={csv?.data || null}
            csvFileName={csv?.fileName || null}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Payroll Summary" }]}
            dataList={dataList}
            columns={columns}
            FETCH_DATA={FETCH_DATA}
            DELETE_FUNC={DELETE_TIME_SHEET}
            hasNew={false}
            dateRanger={false}
            hasSelection={false}
            table="payroll/time-sheets"
          />
        </div>
      </div>

      {comments.length > 0 && (
        <TimesheetCommentModalView
          role={role}
          visible={commentView}
          onClose={() => setOpenCommentView(false)}
          comments={comments}
        />
      )}
    </div>
  );
};

export default TimeSheets;
