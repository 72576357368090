import React from "react";
import EditEmploymentAwards from "../../../../components/EmploymentAwards/EditEmploymentAwards";

const AwardDetail = () => {
  return (
    <>
      <EditEmploymentAwards />
    </>
  );
};

export default AwardDetail;
