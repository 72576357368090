import {
  Form,
  Input,
  Button,
  Switch,
  Spin,
  Select,
  DatePicker,
  Typography,
  Space,
  Upload,
  message,
  InputNumber,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadOutlined,
  PlusOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import moment from "moment";
import {
  CHECK_FILE_DRIVER,
  FETCH_DOCUMENT_CATEGORY,
  UPDATING_DOCUMENT,
  CREATING_DOCUMENT,
  setDocumentCategory,
  NEW_DOCUMENT,
  setDetailDocuments,
  FETCH_DETAIL_DOCUMENT_IN_ORGANISATION,
  FETCH_ALL_DOCUMENT,
  setDocuments,
} from "../../store/document-store/reducer";
import {
  FETCH_ALL_TEAM_MEMBER_IN_ORGANISATION,
  setTeamMembers,
} from "../../store/team-member-store/reducer";
import {
  FETCH_ALL_VEHICLES,
  setVehicles,
} from "../../store/fleet-store/reducer";
import {
  FETCH_ALL_USERS_NOT_MATCH_IN_ORGANISATION,
  setUsers,
} from "../../store/user-store/reducer";
import GooglePicker from "react-google-picker";
import ModalCategory from "./components/ModalCategory";
import "../../styles/modal.scss";
import {
  FETCH_DETAIL_ORGANISATION,
  setDetailOrg,
} from "../../store/org-store/reducer";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Option } = Select;

const ButtonPicker = () => {
  return <Button>Open Picker</Button>;
};

const onChange = (data) => {
  console.log("on change:", data);
};

const onAuthFailed = (data) => {
  console.log("on auth failed:", data);
};

const EditTeamMember = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { organisationId, email } = useSelector(({ auth }) => auth.user);
  const { documents, detailDocument, documentCategory } = useSelector(
    ({ documents }) => documents.documentReducer
  );
  const { detailOrg } = useSelector(({ org }) => org.orgReducer);
  const { teamMembers } = useSelector(({ team }) => team.teamReducer);
  const { vehicles } = useSelector(({ fleets }) => fleets.fleetReducer);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [fileFromUrl, setFileFromUrl] = useState({
    oldUrlFileId: "",
    parentId: "",
    fileId: "",
    url: ""
  })
  const [canSubmit, setCanSubmit] = useState(true);
  const [currentData, setCurrentData] = useState(null);
  const [checkExpiry, setCheckExpiry] = useState(false);
  const [assoType, setAssoType] = useState();
  const [fileType, setFileType] = useState();
  const [saveValue, setSaveValue] = useState({});
  const [fileUrl, setFileUrl] = useState("");
  const [onChangInputNumber, setOnChangInputNumber] = useState(false);
  const [children, setChildren] = useState([
    { id: 1, value: "Team Member" },
    { id: 2, value: "Fleet" },
  ]);
  const [isModalVisible, setIsModalVisible] = useState({
    visible: false,
    type: "",
  });

  const onHandleRemoveImage = () => {
    setFileList([]);
  };

  const handlePath = () => {
      if(pathname.includes(`safety-work-procedures`)) {
        return `/documents/safety-work-procedures`
      } else if(pathname.includes(`safety-work-method-statements`)) {
        return `/documents/safety-work-method-statements`
      } else if(pathname.includes(`safety-data-sheets`)) {
        return `/documents/safety-data-sheets`
      } else if(pathname.includes(`policies`)) {
        return `/documents/policies`
      } else if(pathname.includes(`register`)) {
        return `/documents/register`
      } else return `/documents`
  }

  const onFinish = async (values) => {
    if (canSubmit) {
      setLoading(true);
      values.organisationId = organisationId;
      values._id = detailDocument._id;
      values.email = email;
      values.fileName = fileList && fileList[0]?.name;
      values.oldParentId = fileFromUrl.parentId
      values.fileId = fileFromUrl.fileId
      values.oldUrlFileId = fileFromUrl.oldUrlFileId

      const returnPath = handlePath()

      if (id === "new") {
        CREATING_DOCUMENT(values, fileList[0])
          .then((data) => {
            setLoading(false);
            navigate(returnPath, {
              replace: true
            });
          })
          .catch((err) => {
            setLoading(false);
            message.error(
              "You do not have the required Googe Drive access. Please contact your administrator to gain access"
            );
            const redirectLink = err.response?.data?.payload?.redirect;
            if (redirectLink) {
              window.open(redirectLink, "_blank");
            }
          });
      } else {
        UPDATING_DOCUMENT(values, fileList[0])
          .then((data) => {
            setLoading(false);
            navigate(returnPath, {
              replace: true
            });
          })
          .catch((err) => {
            setLoading(false);
            message.error(
              "You do not have the required Googe Drive access. Please contact your administrator to gain access"
            );
            const redirectLink = err.response?.data?.payload?.redirect;
            if (redirectLink) {
              window.open(redirectLink, "_blank");
            }
          });
      }
    } else {
      message.error("Cannot Submit. Please check fields.");
    }
  };

  const props = {
    name: "file",
    maxCount: 1,
    className: "upload-list-inline",
    listType: "picture",
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileList([
          {
            uid: "2",
            name:
              saveValue?.title && saveValue?.category
                ? `${saveValue?.category}${1000 + documents?.length + 1} - ${saveValue?.title
                }`
                : file?.name,
            status: "done",
            file,
            url,
          },
        ]);
      };
    },
  };

  const FETCH_DATA = () => {
    FETCH_ALL_DOCUMENT({ organisationId }).then((data) =>
      dispatch(setDocuments(data))
    );
  };

  useEffect(() => {
    FETCH_DATA();
  }, []);

  const fetchSubData = async () => {
    const organisation = await FETCH_DETAIL_ORGANISATION(organisationId);
    if (organisation) {
      dispatch(setDetailOrg(organisation));
    }
    const category = await FETCH_DOCUMENT_CATEGORY(organisationId);
    if (category) {
      dispatch(setDocumentCategory(category));
    }
    const allMember = await FETCH_ALL_TEAM_MEMBER_IN_ORGANISATION(
      { organisationId }
    );
    if (allMember) {
      dispatch(setTeamMembers(allMember));
    }

    const vehicles = await FETCH_ALL_VEHICLES(organisationId);
    if (vehicles) {
      dispatch(setVehicles(vehicles));
    }

    const loggedUser = await FETCH_ALL_USERS_NOT_MATCH_IN_ORGANISATION(
      organisationId,
      id
    );
    if (loggedUser) {
      dispatch(setUsers(loggedUser));
    }
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      if (id === "new") {
        dispatch(NEW_DOCUMENT());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_DOCUMENT_IN_ORGANISATION(organisationId, id).then(
          (data) => {
            dispatch(setDetailDocuments(data));
            setInitialLoading(false);
          }
        );
      }
    };

    updateState();
  }, [dispatch, id]);

  useEffect(() => {
    if ((detailDocument && !currentData) || (detailDocument && currentData && detailDocument._id !== currentData._id)) {
      setCurrentData(detailDocument)
      const associations = [];
      if (detailDocument.expireDate && detailDocument.expireDate !== "") {
        setCheckExpiry(true);
      } else {
        setCheckExpiry(false);
      }
      if (detailDocument.teamMember) {
        associations.push({ id: 1, value: "Team Member" });
      }
      if (detailDocument.fleet) {
        associations.push({ id: 2, value: "Fleet" });
      }

      setAssoType(associations.map((a) => a.value));
      setFileType(detailDocument.fileType);
      setSaveValue({
        category: detailDocument?.category?.code,
        title: detailDocument?.title,
      });
      if (id && id !== "new") {
        if (detailDocument?.fileUrl) {
          const doc = JSON.parse(detailDocument.fileUrl);
          setFileList([
            {
              uid: "2",
              name: doc.name,
              status: "done",
              url: doc.webViewLink,
            },
          ])
          setFileFromUrl({
            oldUrlFileId: JSON.parse(detailDocument.fileUrl)?.id,
            fileId: JSON.parse(detailDocument.fileUrl)?.id,
            parentId: JSON.parse(detailDocument.fileUrl)?.parents && JSON.parse(detailDocument.fileUrl)?.parents[0],
            url: JSON.parse(detailDocument.fileUrl)?.webContentLink,
          })
        }
      }

      if (detailDocument?.versionNumber !== "1") {
        setOnChangInputNumber(true)
      }
      form.setFields([
        {
          name: "title",
          value: detailDocument.title,
        },
        {
          name: "category",
          value: detailDocument.category?._id,
        },
        {
          name: "fileType",
          value: detailDocument.fileType,
        },
        {
          name: "fileUrl",
          value:
            id !== "new"
              ? detailDocument.fileUrl &&
              JSON.parse(detailDocument.fileUrl)?.webViewLink?.replace(
                "?usp=drivesdk",
                ""
              )
              : detailDocument.fileUrl,
        },
        {
          name: "versionNumber",
          value: detailDocument.versionNumber,
        },
        {
          name: "versionDate",
          value: moment(detailDocument.versionDate),
        },
        {
          name: "expireDate",
          value: moment(detailDocument.expireDate),
        },
        {
          name: "teamMember",
          value: detailDocument.teamMember,
        },
        {
          name: "fleet",
          value: detailDocument.fleet?._id,
        },
        {
          name: "association",
          value: associations,
        },
        {
          name: "permission",
          value: detailDocument?.permission,
        },
        {
          name: "expire",
          value: detailDocument.expireDate ? true : false,
        },
        {
          name: "active",
          value: detailDocument.active,
        },
      ]);
    }
  }, [detailDocument]);

  const onHandleSaveValue = async (e, name) => {
    if (name === "association") {
      setAssoType(e);
    } else if (name === "category") {
      let code = documentCategory?.find((d) => d._id === e);
      setSaveValue({
        ...saveValue,
        [name]: code.code,
      });
    } else if (name === "fileUrl") {
      let pattern =
        /^(https:\/\/drive\.google\.com\/)file\/d\/([^\/]+)\/(view)/g;
      if (pattern.test(e.target.value)) {
        const splitStrs = e.target.value.split("/");
        const id = splitStrs[splitStrs.length - 2];
        const data = await CHECK_FILE_DRIVER(id);
        if (!data) {
          message.error("The Driver URL is not valid. Please check!");
          setCanSubmit(false);
        } else {
          setCanSubmit(true);
        }
      } else {
        setCanSubmit(false);
      }
      setFileUrl(e.target.value);
    } else {
      setSaveValue({
        ...saveValue,
        [name]: e.target.value,
      });
    }
  };


  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Documents", path: handlePath() },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/documents/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            labelWrap={true}
            form={form}
            scrollToFirstError
            onFinish={onFinish}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            requiredMark={false}
            initialValues={{
              versionNumber: 1,
              versionDate: moment(new Date(), "DD-MMM-YYYY"),
            }}
          >
            {/*//////////// Title  */}
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Missing Title" }]}
            >
              <Input onChange={(value) => onHandleSaveValue(value, "title")} />
            </Form.Item>

            {/*///////////// Category */}
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true, message: "Missing Category" }]}
            >
              <Select
                onChange={(value) => onHandleSaveValue(value, "category")}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Space align="center" style={{ padding: "0 8px 4px" }}>
                      <Typography.Link
                        onClick={() =>
                          setIsModalVisible({
                            visible: true,
                            type: "Category",
                          })
                        }
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <PlusOutlined /> Add item
                      </Typography.Link>
                    </Space>
                  </>
                )}
              >
                {documentCategory &&
                  documentCategory.map((type) => {
                    return (
                      <Option key={type._id} value={type._id}>
                        {type.description}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            {/*///////////// fileType */}
            <Form.Item
              label="File Type"
              name="fileType"
              rules={[{ required: true, message: "Missing File Type " }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                style={{ width: "100%" }}
                onChange={(value) => setFileType(value)}
              >
                <Option value="URL">URL</Option>
                <Option value="File">File</Option>
              </Select>
            </Form.Item>

            {fileType && fileType === "URL" ? (
              <Form.Item label="URL">
                <div className="document__url">
                  <Form.Item
                    style={{
                      width: "100%",
                    }}
                    name="fileUrl"
                    rules={[
                      {
                        required: true,
                        message: "Missing URL",
                      },
                      // {
                      //   pattern:
                      //     /^(https:\/\/drive\.google\.com\/)file\/d\/([^\/]+)\/(view)/g,
                      //   message:
                      //     "Please format url as 'https://drive.google.com/file/d/[file_id]/view'",
                      // },
                    ]}
                  >
                    <Input
                      onChange={(value) => onHandleSaveValue(value, "fileUrl")}
                      value={fileUrl}
                    />
                  </Form.Item>
                  <GooglePicker
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    developerKey={process.env.REACT_APP_DEVELOPER_KEY}
                    scope={["https://www.googleapis.com/auth/drive.readonly"]}
                    onChange={onChange}
                    onAuthFailed={onAuthFailed}
                    multiselect={false}
                    viewId={"DOCS"}
                    createPicker={(google, oauthToken) => {
                      const googleViewId = google.picker.ViewId.DOCS;
                      const docsView = new google.picker.DocsView(googleViewId)
                        .setIncludeFolders(true)
                        .setEnableDrives(true)
                        .setParent(detailOrg?.parentFolderId);

                      const picker = new window.google.picker.PickerBuilder()
                        .addView(docsView)
                        .setOAuthToken(oauthToken)
                        .setDeveloperKey(process.env.REACT_APP_DEVELOPER_KEY)
                        .enableFeature(google.picker.Feature.NAV_HIDDEN)
                        .hideTitleBar()
                        .setCallback((data) => {
                          if (data.action === "cancel") {
                            console.log("User clicked cancel/close button");
                          }

                          const doc = data?.docs;
                          if (data.action === "picked" && doc && doc[0]) {
                            setFileList([
                              {
                                uid: "2",
                                name: saveValue?.title && saveValue?.category
                                  ? `${saveValue?.category}${1000 + documents?.length + 1} - ${saveValue?.title
                                  }`
                                  : doc[0]?.name,
                                status: "done",
                                url: doc[0].url,
                              },
                            ])
                            setFileFromUrl({
                              ...fileFromUrl,
                              parentId: doc[0].parentId,
                              fileId: doc[0].id,
                              url: doc[0].url,
                            })
                            form.setFields([
                              {
                                name: "fileUrl",
                                value: doc[0].url,
                              },
                            ]);
                          }
                        });

                      picker.build().setVisible(true);
                    }}
                  >
                    <ButtonPicker />
                  </GooglePicker>
                </div>

                {/* file Name  */}
                <div>
                  {fileList.length > 0 ? (
                    fileList.map((l, index) => (
                      <div
                        key={index}
                        style={{
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <a href={l?.url} target="_blank">{l?.name}</a>
                        <div
                          onClick={() => onHandleRemoveImage()}
                          style={{
                            margin: "-5px 0 0 5px",
                            cursor: "pointer",
                          }}
                        >
                          <CloseCircleOutlined />
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </Form.Item>
            ) : (
              fileType &&
              fileType === "File" && (
                <Form.Item label="File">
                  <Upload {...props} onRemove={() => setFileList([])}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <div>
                    {fileList.length > 0 ? (
                      fileList.map((l, index) => (
                        <div
                          key={index}
                          style={{
                            marginTop: "10px",
                            display: "flex",
                          }}
                        >
                          <a href={l?.url} target="_blank">{l?.name}</a>
                          <div
                            onClick={() => onHandleRemoveImage()}
                            style={{
                              margin: "-5px 0 0 5px",
                              cursor: "pointer",
                            }}
                          >
                            <CloseCircleOutlined />
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </Form.Item>
              )
            )}

            {/*///////////// Version Number */}
            <Form.Item
              label="Version Number"
              name="versionNumber"
              rules={[{ required: true, message: "Missing Version Number" }]}
            >
              <InputNumber
                min={1}
                formatter={(value) =>
                  !onChangInputNumber ? Number(value).toFixed(1) : value
                }
                onChange={() => setOnChangInputNumber(true)}
              />
            </Form.Item>

            {/*///////////// Version Date */}
            <Form.Item
              label="Version Date"
              name="versionDate"
              rules={[{ required: true, message: "Missing Version Date" }]}
            >
              <DatePicker format="DD-MMM-YYYY" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item label="Expire" name="expire" valuePropName="checked">
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(checked) => setCheckExpiry(checked)}
              />
            </Form.Item>

            {/*///////////// Expiry Date */}
            {checkExpiry && (
              <Form.Item
                label="Expiry Date"
                name="expireDate"
                rules={[{ required: true, message: "Missing Expiry Date" }]}
              >
                <DatePicker format="DD-MMM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            )}

            {/*///////////// Association  */}
            <Form.Item label="Association " name="association">
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select association"
                onChange={(value) => onHandleSaveValue(value, "association")}
                options={children}
              />
            </Form.Item>

            {/* Check association */}
            {assoType?.map((a) => (
              <div key={a}>
                {a === "Team Member" ? (
                  <Form.Item
                    label="Team Member"
                    name="teamMember"
                    rules={[{ required: true, message: "Missing Team Member" }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toString()
                          ?.replace(/,/gi, "")
                          .toLowerCase()
                          ?.includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA?.children
                          ?.toString()
                          ?.replace(/,/gi, "")
                          ?.toLowerCase()
                          ?.localeCompare(
                            optionB?.children
                              ?.toString()
                              ?.replace(/,/gi, "")
                              ?.toLowerCase()
                          )
                      }
                      style={{ width: "100%" }}
                    >
                      {teamMembers?.map((t) => (
                        <Option value={t?._id} key={t?._id}>
                          {t.firstName} {t.lastName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Fleet"
                    name="fleet"
                    rules={[{ required: true, message: "Missing Fleet" }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toString()
                          ?.replace(/,/gi, "")
                          .toLowerCase()
                          ?.includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA?.children
                          ?.toString()
                          ?.replace(/,/gi, "")
                          ?.toLowerCase()
                          ?.localeCompare(
                            optionB?.children
                              ?.toString()
                              ?.replace(/,/gi, "")
                              ?.toLowerCase()
                          )
                      }
                      style={{ width: "100%" }}
                    >
                      {vehicles?.map((v) => (
                        <Option value={v?._id} key={v?._id}>
                          {v?.registration}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </div>
            ))}

            {/*///////////// Permission  */}
            <Form.Item
              label="Permission"
              name="permission"
              rules={[{ required: true, message: "Missing Permission" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                defaultValue="admin"
                style={{ width: "100%" }}
              >
                <Option value="admin">admin</Option>
                <Option value="manager">manager</Option>
                <Option value="user">user</Option>
              </Select>
            </Form.Item>

            {/*///////////// Status  */}
            <Form.Item label="Status" name="active" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
            <div>
              {isModalVisible.visible && (
                <ModalCategory
                  form={form}
                  fetchSubData={fetchSubData}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                />
              )}
            </div>
          </Form>
        )
      }
    />
  );
};

export default EditTeamMember;
