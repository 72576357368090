import {
  Button,
  Form,
  Input,
  InputNumber,
  Table,
  Select,
  AutoComplete,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { TAX_RATE_DATA } from "../../constants";
import { formatterInputNumber } from "../../utils/core/functions";
import { EditableContext } from "../../shared-components/LineItemTableWithAction";
import FuseUtils from "../../utils/Utils";
const { TextArea } = Input;
const { Option, OptGroup } = Select;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  items,
  showAccounts,
  accounts,
  departments,
  lineItemColumns,
  onEditLineItem,
  setReadyLineItemState,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const [inputNumber, setInputNumer] = useState(false);
  const [showAutoComplete, setShowAutoComplete] = useState([]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [record]);

  useEffect(() => {
    if (dataIndex) {
      switch (dataIndex) {
        case "item":
          {
            setShowAutoComplete(items);
          }
          break;
        case "taxType":
          {
            setShowAutoComplete(TAX_RATE_DATA);
          }
          break;
        case "account":
          {
            setShowAutoComplete(showAccounts);
          }
          break;
        case "department":
          {
            setShowAutoComplete(departments);
          }
          break;
        default:
          break;
      }
    }
  }, []);

  const save = async () => {
    try {
      let values = await form.getFieldsValue();
      handleSave({ ...record, ...values });
      await form.validateFields();
      setReadyLineItemState(true);
    } catch (errInfo) {
      setReadyLineItemState(false);
    }
  };

  const onSaveItem = async (element) => {
    try {
      let values = await form.getFieldsValue();
      const lineItem = onEditLineItem(element);
      values = {
        ...values,
        ...lineItem,
      };
      handleSave({ ...record, ...values });
      await form.validateFields();
      setReadyLineItemState(true);
    } catch (errInfo) {
      setReadyLineItemState(false);
    }
  };

  const onSaveAccount = async (element) => {
    try {
      let values = await form.getFieldsValue();
      values = {
        ...values,
        accountCode: element.item.code,
        accountCodeId: element.item._id,
      };
      handleSave({ ...record, ...values });
      await form.validateFields();
      setReadyLineItemState(true);
    } catch (errInfo) {
      setReadyLineItemState(false);
    }
  };

  const onSaveTaxRate = async (element) => {
    let values = {
      taxType: element.value,
      taxTypeId: element.name,
    };

    handleSave({ ...record, ...values });
    setReadyLineItemState(true);
  };

  const onSaveDepartment = async (element) => {
    try {
      let values = await form.getFieldsValue();
      values = {
        ...values,
        department: element.item.name,
      };
      handleSave({ ...record, ...values });
      await form.validateFields();
      setReadyLineItemState(true);
    } catch (errInfo) {
      setReadyLineItemState(false);
    }
  };

  const onSearch = (e) => {
    if (e) {
      let arr = [];
      switch (dataIndex) {
        case "item":
          arr = FuseUtils.filterArrayByString(items, e);
          break;
        case "taxType":
          arr = FuseUtils.filterArrayByString(TAX_RATE_DATA, e);
          break;
        case "account":
          arr = FuseUtils.filterArrayByString(showAccounts, e);
          break;
        case "department":
          arr = FuseUtils.filterArrayByString(departments, e);
          break;
        default:
          break;
      }
      setShowAutoComplete(arr);
    }
  };

  const onChange = () => {
    setInputNumer(true);
  };

  let childNode = children;

  if (editable) {
    switch (dataIndex) {
      case "item":
        childNode = (
          <AutoComplete
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={500}
            options={showAutoComplete}
            onSearch={onSearch}
            onSelect={(e, record) => {
              onSaveItem(record);
            }}
          >
            <Input
              placeholder="Items"
              bordered={false}
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        );
        break;
      case "description":
        childNode = (
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            bordered={false}
          />
        );
        break;
      case "quantity":
        childNode = (
          <InputNumber
            className="rightAlign"
            formatter={(value) => formatterInputNumber(value, inputNumber)}
            onChange={onChange}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            bordered={false}
          />
        );
        break;
      case "unitAmount":
        childNode = (
          <InputNumber
            className="rightAlign"
            formatter={(value) => formatterInputNumber(value, inputNumber)}
            ref={inputRef}
            onChange={onChange}
            onPressEnter={save}
            onBlur={save}
            bordered={false}
          />
        );
        break;
      case "account":
        childNode = (
          <Select
            showSearch
            bordered={false}
            onSearch={onSearch}
            onSelect={(e, record) => onSaveAccount(record)}
            filterOption={false}
          >
            {showAutoComplete.map((ele, index) => (
              <OptGroup label={ele.nameGroup} key={index}>
                {ele.items.map((i) => (
                  <Option value={i._id} key={i._id} item={i}>
                    {i.code} - {i.name}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        );
        break;
      case "taxType":
        childNode = (
          <AutoComplete
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={500}
            options={showAutoComplete}
            onSearch={onSearch}
            onSelect={(e, record) => onSaveTaxRate(record)}
          >
            <Input
              placeholder="Tax Rate"
              bordered={false}
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        );
        break;
      case "department":
        childNode = (
          <AutoComplete
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={500}
            options={showAutoComplete}
            onSearch={onSearch}
            onSelect={(e, record) => onSaveDepartment(record)}
          >
            <Input
              placeholder="Department"
              bordered={false}
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        );
        break;
      case "amount":
        childNode = (
          <InputNumber
            className="rightAlign"
            ref={inputRef}
            disabled
            onChange={onChange}
            bordered={false}
            formatter={(value) => formatterInputNumber(value, inputNumber)}
          />
        );
        break;
      default:
        break;
    }
  }

  return (
    <td {...restProps}>
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={
          dataIndex !== "amount"
            ? [
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]
            : []
        }
      >
        {childNode}
      </Form.Item>
    </td>
  );
};

/* ********** Main funtion ****************/
const StandardLineItemTableLayout = ({
  showAccounts,
  items,
  accounts,
  departments,
  lineItems,
  setLineItems,
  lineItemColumns,
  totalData,
  setReadyLineItemState,
  onAddLineItem,
  onEditLineItem,
  DraggableBodyRow,
  DraggableContainer,
  instructions,
}) => {
  const handleSave = async (row) => {
    const newData = [...lineItems];
    const index = newData.findIndex((item) => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    newData.forEach((ele) => {
      ele.amount = ele.quantity * ele.unitAmount;
    });
    setLineItems(newData);
  };

  const components = {
    body: {
      wrapper: DraggableContainer,
      row: DraggableBodyRow,
      cell: EditableCell,
      // row: EditableRow,
      // cell: EditableCell,
    },
  };

  const columns = lineItemColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        showAccounts,
        items,
        accounts,
        departments,
        onEditLineItem,
        setReadyLineItemState,
        handleSave,
      }),
    };
  });

  // default total columns
  const totalColumns = [
    {
      key: "name",
      render: (data) => {
        return <>{data.name === "Total" ? <h2>{data.name}</h2> : data.name}</>;
      },
      align: "right",
    },
    {
      key: "value",
      render: (data) => {
        return (
          <>
            {data.name === "Total" ? (
              <h2>
                {data?.value &&
                  data?.value?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h2>
            ) : (
              data?.value && data?.value?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            )}
          </>
        );
      },
      align: "right",
    },
  ];

  let dataTotalSource = [
    {
      key: "1",
      name: "Subtotal",
      value: totalData.subTotal.toFixed(2),
    },
    {
      key: "2",
      name: "Includes GST 10.00%",
      value: totalData.GST.toFixed(2),
    },
    {
      key: "3",
      name: "Total",
      value: totalData.total ? totalData.total.toFixed(2) : 0.0,
    },
  ];

  return (
    <div>
      {/* Table Line Items  */}
      <Table
        style={{
          marginBottom: "10px",
        }}
        className="lineitems"
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        rowKey="position"
        dataSource={lineItems}
        columns={columns}
        pagination={false}
        sticky={{ offsetHeader: 64 }}
        scroll={{
          x: "calc(700px + 50%)",
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "40%" }}>
          <Button type="secondary" onClick={onAddLineItem}>
            Add Line Item
          </Button>
          {instructions && (
            <TextArea
              placeholder="Delivery Instructions"
              rows={4}
              maxLength={500}
              style={{ marginTop: 20 }}
            />
          )}
        </div>
        {/* Table total  */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-end",
          }}
        >
          <Table
            size="small"
            dataSource={dataTotalSource}
            columns={totalColumns}
            pagination={false}
            showHeader={false}
            bordered={false}
            sticky={{ offsetHeader: 64 }}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StandardLineItemTableLayout;
