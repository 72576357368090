import Receipt from "../../components/Receipt";

const Receipts = () => {
  return (
    <>
      <Receipt />
    </>
  );
};

export default Receipts;
