import "antd/dist/antd.css";
import { Form, Input, Button, Switch, Spin } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import { useState, useEffect } from "react";
import {
  CREATING_MEMBER_POSITION,
  FETCH_DETAIL_MEMBER_POSITION_IN_ORGANISATION,
  NEW_MEMBER_POSITION,
  setDetailMemberPosition,
  UPDATING_MEMBER_POSITION,
} from "../../store/team-member-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const EditPositions = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { idPosition } = useParams();
  const { detailMemeberPosition } = useSelector(({ team }) => team.teamReducer);
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailMemeberPosition._id;
    if (idPosition === "new") {
      CREATING_MEMBER_POSITION(values).then((data) => {
        setLoading(false);
        navigate("/team/positions");
      });
    } else {
      UPDATING_MEMBER_POSITION(values).then((data) => {
        setLoading(false);
        navigate("/team/positions");
      });
    }
  };

  useEffect(() => {
    const updateState = () => {
      if (idPosition === "new") {
        dispatch(NEW_MEMBER_POSITION());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_MEMBER_POSITION_IN_ORGANISATION(idPosition).then(
          (data) => {
            dispatch(setDetailMemberPosition(data));
            setInitialLoading(false);
          }
        );
      }
    };
    updateState();
  }, [dispatch, idPosition]);

  useEffect(() => {
    if (detailMemeberPosition) {
      form.setFields([
        {
          name: "description",
          value: detailMemeberPosition.description,
        },
        {
          name: "status",
          value: detailMemeberPosition.status,
        },
      ]);
    }
  }, [detailMemeberPosition]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Positions", path: "/team/positions" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/team/positions/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            form={form}
            requiredMark={false}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )
      }
    />
  );
};

export default EditPositions;
