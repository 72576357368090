import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Switch,
  Spin,
  Select,
  Upload,
  DatePicker,
  Typography,
  Image,
  Space,
  message,
} from "antd";
import { useEffect, useState } from "react";
import {
  UploadOutlined,
  PlusOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StandardFormLayout from "../../layouts/StandardFormLayout/StandardFormLayout";
import moment from "moment";
import {
  FETCH_ALL_EMPLOYMENT_TYPE_IN_ORGANISATION,
  FETCH_ALL_MEMBER_AWARD_IN_ORGANISATION,
  FETCH_ALL_MEMBER_POSITION_IN_ORGANISATION,
  FETCH_DETAIL_TEAM_MEMBER_IN_ORGANISATION,
  NEW_TEAM_MEMBER,
  setEmploymentTypes,
  setMemberAwards,
  setMemberPositions,
  setDetailTeamMember,
  CREATING_TEAM_MEMBER,
  UPDATING_TEAM_MEMBER,
} from "../../store/team-member-store/reducer";
// import "../../styles/modal.scss";
import {
  FETCH_ALL_PAYMENTS,
  setPayment,
} from "../../store/payment-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ALL_DEPARTMENTS_IN_ORGANISATION,
  setDepartments,
} from "../../store/department-store/reducer";
import {
  FETCH_ALL_USERS_NOT_MATCH_IN_ORGANISATION,
  setUsers,
} from "../../store/user-store/reducer";

import ModalTeamMember from "./components/ModalTeamMember";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const { Option } = Select;
const { Title } = Typography;

const EditTeamMember = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const { memberId } = useParams();
  const { organisationId, _id } = useSelector(({ auth }) => auth.user);
  const { detailTeamMember, employmentTypes, memberPositions, memberAwards } =
    useSelector(({ team }) => team.teamReducer);
  const { departments } = useSelector(
    ({ departments }) => departments.departmentReducer
  );
  const { payments } = useSelector(({ payments }) => payments.paymentReducer);
  const { data } = useSelector(({ user }) => user.userReducer);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState({
    visible: false,
    type: "",
  });

  const [deletePhoto, setDeletePhoto] = useState({
    photo: "",
    isDeletePhoto: false,
  });

  const onHandleRemoveImage = () => {
    setFileList([]);
    setDeletePhoto({
      photo: detailTeamMember?.photo,
      isDeletePhoto: true,
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.organisationId = organisationId;
    values._id = detailTeamMember._id;
    values.photo = detailTeamMember.photo;
    values.isDeletePhoto = deletePhoto.isDeletePhoto;
    if (memberId === "new") {
      CREATING_TEAM_MEMBER(values, fileList[0])
        .then((data) => {
          setLoading(false);
          navigate("/team/members");
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = err.data?.payload?.message;
          if (errorMessage) {
            message.error(errorMessage);
          }
        });
    } else {
      UPDATING_TEAM_MEMBER(values, fileList[0])
        .then((data) => {
          setLoading(false);
          navigate("/team/members");
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = err.data?.payload?.message;
          if (errorMessage) {
            message.error(errorMessage);
          }
        });
    }
  };

  const props = {
    name: "file",
    maxCount: 1,
    className: "upload-list-inline",
    listType: "picture",
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        setDeletePhoto({
          photo: detailTeamMember?.photo ? detailTeamMember?.photo : "",
          isDeletePhoto: detailTeamMember?.photo ? true : false,
        });
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url,
          },
        ]);
      };
    },
  };

  const fetchSubData = async () => {
    const employmentTypeData = await FETCH_ALL_EMPLOYMENT_TYPE_IN_ORGANISATION(
      organisationId
    );
    if (employmentTypeData) {
      dispatch(setEmploymentTypes(employmentTypeData));
    }
    const memberAwardData = await FETCH_ALL_MEMBER_AWARD_IN_ORGANISATION(
      organisationId
    );
    if (memberAwardData) {
      dispatch(setMemberAwards(memberAwardData));
    }
    const memberPositionData = await FETCH_ALL_MEMBER_POSITION_IN_ORGANISATION(
      organisationId
    );
    if (memberPositionData) {
      dispatch(setMemberPositions(memberPositionData));
    }
    const departmentData = await FETCH_ALL_DEPARTMENTS_IN_ORGANISATION(
      organisationId
    );
    if (departmentData) {
      dispatch(setDepartments(departmentData));
    }
    const payrollProfileData = await FETCH_ALL_PAYMENTS(organisationId);
    if (payrollProfileData) {
      dispatch(setPayment(payrollProfileData));
    }
    const loggedUser = await FETCH_ALL_USERS_NOT_MATCH_IN_ORGANISATION(
      organisationId,
      memberId
    );
    if (loggedUser) {
      dispatch(setUsers(loggedUser));
    }
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      if (memberId === "new") {
        dispatch(NEW_TEAM_MEMBER());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_TEAM_MEMBER_IN_ORGANISATION(organisationId, memberId).then(
          (data) => {
            dispatch(setDetailTeamMember(data));
            setInitialLoading(false);
          }
        );
      }
    };
    updateState();
  }, [dispatch, memberId]);

  useEffect(() => {
    if (detailTeamMember) {
      setFileList([
        {
          uid: "2",
          status: "done",
          file: null,
          url: detailTeamMember.photo,
        },
      ]);
      form.setFields([
        {
          name: "firstName",
          value: detailTeamMember.firstName,
        },
        {
          name: "lastName",
          value: detailTeamMember.lastName,
        },
        {
          name: "nickName",
          value: detailTeamMember.nickName,
        },
        {
          name: "userName",
          value: detailTeamMember.userName,
        },
        {
          name: "dateOfBirth",
          value: moment(detailTeamMember.dateOfBirth),
        },
        {
          name: "USI",
          value: detailTeamMember.USI,
        },
        {
          name: "employmentTypeId",
          value: detailTeamMember?.employmentType?._id,
        },
        {
          name: "positionId",
          value: detailTeamMember?.position?._id,
        },
        {
          name: "employeeAwardId",
          value: detailTeamMember?.employeeAward?._id,
        },
        {
          name: "awardLevel",
          value: detailTeamMember.awardLevel,
        },
        {
          name: "dateOfJoining",
          value: moment(detailTeamMember.dateOfJoining),
        },
        {
          name: "departmentId",
          value: detailTeamMember?.departmentId?._id,
        },
        {
          name: "labourCost",
          value: detailTeamMember.labourCost,
        },
        {
          name: "bankedHoursBalance",
          value: detailTeamMember.bankedHoursBalance,
        },
        {
          name: "emailPersonal",
          value: detailTeamMember.emailPersonal,
        },
        {
          name: "mobilePersonal",
          value: detailTeamMember.mobilePersonal,
        },
        {
          name: "addressPersonal",
          value: detailTeamMember.addressPersonal,
        },
        {
          name: "payrollProfile",
          value: detailTeamMember.payrollProfile,
        },
        {
          name: "emailWork",
          value: detailTeamMember.emailWork,
        },
        {
          name: "mobileWork",
          value: detailTeamMember.mobileWork,
        },
        {
          name: "includeInPayroll",
          value: detailTeamMember.includeInPayroll,
        },
        {
          name: "timesheetReminder",
          value: detailTeamMember.timesheetReminder,
        },
        {
          name: "active",
          value: detailTeamMember.active,
        },
      ]);
    }
  }, [detailTeamMember]);

  return (
    <StandardFormLayout
      hierachy={[
        { key: 1, label: "Team Members", path: "/team/members" },
        { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
      ]}
      editPath="/team/members/new"
      form={
        initialLoading ? (
          <Spin spinning={initialLoading} indicator={antIcon}></Spin>
        ) : (
          <Form
            labelAlign="left"
            labelWrap={true}
            form={form}
            scrollToFirstError
            onFinish={onFinish}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            requiredMark={false}
          >
            <Row gutter={32}>
              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <Title level={4}>Personal</Title>

                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{ required: true, message: "Missing First Name" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: true, message: "Missing Last Name" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Nick Name" name="nickName">
                  <Input />
                </Form.Item>

                <Form.Item label="Photo">
                  <Upload {...props} onRemove={() => setFileList([])}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <div>
                    {fileList.length > 0 ? (
                      fileList.map((l, index) => (
                        <div
                          key={index}
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            position: "relative",
                            width: "max-content",
                          }}
                        >
                          {memberId !== "new" && fileList[0]?.url !== "" && (
                            <div
                              onClick={() => onHandleRemoveImage()}
                              style={{
                                right: "0px",
                                position: "absolute",
                                zIndex: 9,
                                cursor: "pointer",
                              }}
                            >
                              <CloseCircleOutlined />
                            </div>
                          )}
                          <Image
                            src={l.url}
                            style={{
                              maxWidth: "100px",
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </Form.Item>

                <Form.Item label="User" name="userName">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        .toLowerCase()
                        ?.includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        ?.toLowerCase()
                        ?.localeCompare(
                          optionB?.children
                            ?.toString()
                            ?.replace(/,/gi, "")
                            ?.toLowerCase()
                        )
                    }
                    allowClear
                  >
                    {data &&
                      data.map((u) => {
                        return (
                          <Option key={u._id} value={u._id}>
                            {u.firstName} {u.lastName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item label="Date of Birth" name="dateOfBirth">
                  <DatePicker format="DD-MMM-YYYY" style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item label="USI" name="USI">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <Title level={4}>Work</Title>

                <Form.Item
                  label="Employment Type"
                  name="employmentTypeId"
                  rules={[
                    { required: true, message: "Missing Employment Type" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        .toLowerCase()
                        ?.includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        ?.toLowerCase()
                        ?.localeCompare(
                          optionB?.children
                            ?.toString()
                            ?.replace(/,/gi, "")
                            ?.toLowerCase()
                        )
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Space align="center" style={{ padding: "0 8px 4px" }}>
                          <Typography.Link
                            onClick={() =>
                              setIsModalVisible({
                                visible: true,
                                type: "Employment Type",
                              })
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <PlusOutlined /> Add item
                          </Typography.Link>
                        </Space>
                      </>
                    )}
                  >
                    {employmentTypes &&
                      employmentTypes.map((type) => {
                        return (
                          <Option key={type._id} value={type._id}>
                            {type.description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item label="Position" name="positionId">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        .toLowerCase()
                        ?.includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        ?.toLowerCase()
                        ?.localeCompare(
                          optionB?.children
                            ?.toString()
                            ?.replace(/,/gi, "")
                            ?.toLowerCase()
                        )
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Space align="center" style={{ padding: "0 8px 4px" }}>
                          <Typography.Link
                            onClick={() =>
                              setIsModalVisible({
                                visible: true,
                                type: "Position",
                              })
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <PlusOutlined /> Add item
                          </Typography.Link>
                        </Space>
                      </>
                    )}
                  >
                    {memberPositions &&
                      memberPositions.map((type) => {
                        return (
                          <Option key={type._id} value={type._id}>
                            {type.description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item label="Award" name="employeeAwardId">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        .toLowerCase()
                        ?.includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA?.children
                        ?.toString()
                        ?.replace(/,/gi, "")
                        ?.toLowerCase()
                        ?.localeCompare(
                          optionB?.children
                            ?.toString()
                            ?.replace(/,/gi, "")
                            ?.toLowerCase()
                        )
                    }
                    className="abc"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Space align="center" style={{ padding: "0 8px 4px" }}>
                          <Typography.Link
                            onClick={() =>
                              setIsModalVisible({
                                visible: true,
                                type: "Award",
                              })
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <PlusOutlined /> Add item
                          </Typography.Link>
                        </Space>
                      </>
                    )}
                  >
                    {memberAwards &&
                      memberAwards.map((type) => {
                        return (
                          <Option key={type._id} value={type._id}>
                            {type.code} - {type.title}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <div>
                  {isModalVisible.visible && (
                    <ModalTeamMember
                      form={form}
                      fetchSubData={fetchSubData}
                      isModalVisible={isModalVisible}
                      setIsModalVisible={setIsModalVisible}
                    />
                  )}
                </div>

                <Form.Item label="Award Level" name="awardLevel">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    <Select.Option value="1">1</Select.Option>
                    <Select.Option value="2">2</Select.Option>
                    <Select.Option value="3">3</Select.Option>
                    <Select.Option value="4">4</Select.Option>
                    <Select.Option value="5">5</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Date of Joining"
                  name="dateOfJoining"
                  rules={[
                    { required: true, message: "Missing Date Of Joining" },
                  ]}
                >
                  <DatePicker format="DD-MMM-YYYY" style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  label="Department"
                  name="departmentId"
                  rules={[{ required: true, message: "Missing Department" }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {departments &&
                      departments.map((type) => {
                        return (
                          <Option key={type._id} value={type._id}>
                            {type.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Labour Cost"
                  name="labourCost"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Banked Hours Balance"
                  name="bankedHoursBalance"
                >
                  <Input 
                    disabled={ detailTeamMember._id !== "new" }
                  />
                </Form.Item>

                <Form.Item
                  label="Payroll Profile"
                  name="payrollProfile"
                  rules={[
                    { required: true, message: "Missing Payroll Profile" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {payments &&
                      payments.map((payment) => {
                        return (
                          <Option key={payment._id} value={payment._id}>
                            {payment.description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Title level={4}>Contact Info</Title>
            <Row gutter={32}>
              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <Form.Item label="Email" name="emailPersonal">
                  <Input />
                </Form.Item>

                <Form.Item label="Mobile" name="mobilePersonal">
                  <Input />
                </Form.Item>

                <Form.Item label="Address" name="addressPersonal">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <Form.Item label="Work Email" name="emailWork">
                  <Input />
                </Form.Item>

                <Form.Item label="Work Mobile" name="mobileWork">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <Title level={4}>Settings</Title>
                <Form.Item
                  label="Include In Payroll"
                  name="includeInPayroll"
                  valuePropName="checked"
                >
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>

                <Form.Item
                  label="Timesheet Reminder"
                  name="timesheetReminder"
                  valuePropName="checked"
                >
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>

                <Form.Item label="Status" name="active" valuePropName="checked">
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )
      }
    />
  );
};

export default EditTeamMember;
