import EditDocumentCategory from "../../../components/DocumentCategory/EditDocumentCategory";

const DocumentEdit = () => {
  return (
    <>
      <EditDocumentCategory />
    </>
  );
};

export default DocumentEdit;
