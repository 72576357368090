import {
  Form,
  Input,
  Button,
  Upload,
  Space,
  DatePicker,
  Select,
  message,
  Spin,
  Alert,
} from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  CREATING_INVOICE,
  FETCH_DETAIL_INVOICE,
  NEW_INVOICE,
  setDetailInvoice,
  UPDATING_INVOICE,
} from "../../store/invoice-store/reducer";
import {
  FETCH_ALL_CONTACT,
  setContacts,
} from "../../store/contact-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  PaperClipOutlined,
  DeleteOutlined,
  FileAddOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import StandardCardLayout from "../../layouts/StandardCardLayout/StandardCardLayout";
import LineItemTableWithAction from "../../shared-components/LineItemTableWithAction";
import {
  INVOICE_STATUS,
  LINE_ITEM_TABLE_TYPE,
  PAYMENT_TERM_BILL_TYPE,
  PAYMENT_TERM_INVOICE_TYPE,
  TAX_RATE_DATA,
} from "../../constants";
import moment from "moment";
import InvoiceCardWithAction from "../../shared-components/InvoiceCardWithAction";
import { UPDATING_INVOICE_STATUS } from "../../store/bill-store/reducer";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const EditInvoice = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);
  const { contacts } = useSelector(({ contact }) => contact.contactReducer);
  const { detailInvoice } = useSelector(
    ({ invoice }) => invoice.invoiceReducer
  );
  const { pathname } = useLocation();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState({
    draft: false,
    submitted: false,
    approved: false,
    voided: false,
    deleted: false,
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [status, setStatus] = useState(INVOICE_STATUS.DRAFT);
  const [readyLineItemState, setReadyLineItemState] = useState(false);
  const [total, setTotal] = useState({
    subTotal: 0.0,
    GST: 0.0,
    total: 0.0,
  });
  const [lineItems, setLineItems] = useState([]);
  const lineItemColumns = [
    {
      title: "Item",
      dataIndex: "item",
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
    },
    {
      title: "Unit Price",
      dataIndex: "unitAmount",
      editable: true,
    },
    {
      title: "Account",
      dataIndex: "account",
      editable: true,
    },
    {
      title: "Tax Rate",
      dataIndex: "taxType",
      editable: true,
    },
    {
      title: "Department",
      dataIndex: "department",
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: true,
    },
  ];

  const props = {
    name: "file",
    maxCount: 1,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url,
          },
        ]);
      };
    },
  };

  const onSelectContact = (record) => {
    const term = record.item.saleTerm
    const day = record.item.saleTermDay
    switch (term) {
      case PAYMENT_TERM_INVOICE_TYPE.DAYSAFTERINVOICEDATE:
        form.setFields([
          {
            name: "dueDate",
            value: moment().add(Number(day), "days")
          }
        ]); break;
      case PAYMENT_TERM_INVOICE_TYPE.DAYSAFTERINVOICEMONTH:
        form.setFields([
          {
            name: "dueDate",
            value: moment().endOf('month').add(Number(day), "days")
          }
        ]); break;
      case PAYMENT_TERM_INVOICE_TYPE.OFCURRENTMONTH:
        form.setFields([
          {
            name: "dueDate",
            value: moment().add(Number(day) - moment().day() + 1, "days")
          }
        ]); break;
      case PAYMENT_TERM_INVOICE_TYPE.OFFOLLOWINGMONTH:
        form.setFields([
          {
            name: "dueDate",
            value: moment().endOf("month").add(Number(day), "days")
          }
        ]); break;
      default: break;
    }
  }
  
  const onFinish = async (values) => {
    if (readyLineItemState && checkValidLineItem()) {
      values.organisationId = organisationId;
      if (detailInvoice?.image) {
        values.image = detailInvoice.image;
      }
      values._id = detailInvoice._id;
      values.sentToContact = false;
      values.date = values.date.format("YYYY-MM-DD");
      if (values.dueDate) {
        values.dueDate = values.dueDate.format("YYYY-MM-DD");
      }
      values.status = status;

      //take contact xeroCode
      const contactXeroCode = contacts.filter(
        (c) => c._id === values.contact
      )[0].xeroId;
      values.contactXeroCode = contactXeroCode;

      //handle line items
      const handleLineItems = lineItems?.map((l) => {
        return {
          itemId: l.itemId,
          position: l.position,
          itemCode: l.itemCode,
          description: l.description,
          quantity: Number(l.quantity),
          unitAmount: Number(l.unitAmount),
          accountCodeId: l.accountCodeId,
          accountCode: l.accountCode,
          amount: l.amount,
          taxType: l.taxTypeId,
          account: l.account,
          item: l.item,
          tracking: [
            {
              name: "Department",
              option: l.department,
            },
          ],
        };
      });

      values.lineItems = handleLineItems;

      if (id === "new") {
        CREATING_INVOICE(values, fileList[0]).then((data) => {
          setLoading({
            draft: false,
            submitted: false,
            approved: false,
            voided: false,
            deleted: false,
          });
          navigate("/invoice");
        });
      } else {
        UPDATING_INVOICE(values, fileList[0]).then((data) => {
          setLoading({
            draft: false,
            submitted: false,
            approved: false,
            voided: false,
            deleted: false,
          });
          navigate("/invoice");
        });
      }
    } else {
      message.error("Validate");
    }
  };

  const checkValidLineItem = () => {
    let valid = true;
    if (lineItems.length) {
      for (let i = 0; i < lineItems.length; i++) {
        if (!lineItems[i].itemId) {
          valid = false;
          break;
        }
      }
    } else {
      valid = false;
    }
    return valid;
  };

  const fetchSubData = async () => {
    const contacts = await FETCH_ALL_CONTACT(organisationId);
    if (contacts) {
      dispatch(setContacts(contacts));
    }
  };

  const handleTotalPriceLineItems = (array) => {
    let totalPrice = 0;
    array.forEach((ele) => {
      totalPrice = Number(
        totalPrice + Number(ele.quantity) * Number(ele.unitAmount)
      );
    });
    form.setFields([
      {
        name: "Total",
        value: Number(totalPrice).toFixed(2),
      },
    ]);
    const subTotal = totalPrice - totalPrice / 11;
    setTotal({
      subTotal: subTotal,
      GST: subTotal * 0.1,
      total: subTotal + subTotal * 0.1,
    });
  };

  useEffect(() => {
    const updateState = async () => {
      await fetchSubData();
      if (id === "new") {
        dispatch(NEW_INVOICE());
        setInitialLoading(false);
      } else {
        FETCH_DETAIL_INVOICE(id).then((data) => {
          dispatch(setDetailInvoice(data));
          setInitialLoading(false);
        });
      }
    };
    updateState();
  }, [id]);

  useEffect(() => {
    if (id !== "new") {
      setReadyLineItemState(true);
    }
    if (detailInvoice) {
      if (detailInvoice.image) {
        const file = JSON.parse(detailInvoice.image);
        setFileList([
          {
            uid: "2",
            name: file.name,
            status: "done",
            file,
            url: file.url,
          },
        ]);
      }
      const handleLineItems = detailInvoice?.lineItems?.map((l, i) => {
        const taxType = TAX_RATE_DATA.filter((t) => t.name === l.taxType)[0];
        return {
          _id: l._id,
          position: i,
          item: l.itemId?.name,
          itemId: l.itemId?._id,
          itemCode: l.itemId?.sku,
          description: l.description,
          taxType: taxType?.value,
          taxTypeId: l.taxType,
          quantity: l.quantity,
          unitAmount: l.unitAmount,
          amount: Number(l.unitAmount) * Number(l.quantity),
          account: `${l.accountCodeId?.code} - ${l.accountCodeId?.name}`,
          accountCodeId: l.accountCodeId?._id,
          accountCode: l.accountCodeId?.code,
          department: l.tracking[0]?.option,
        };
      });
      if (handleLineItems) {
        setLineItems(handleLineItems);
      }

      form.setFields([
        {
          name: "contact",
          value: detailInvoice.contact?._id,
        },
        {
          name: "date",
          value: detailInvoice.date ? moment(detailInvoice.date) : null,
        },
        {
          name: "dueDate",
          value: detailInvoice.dueDate ? moment(detailInvoice.dueDate) : null
        },
        {
          name: "orderNumber",
          value: detailInvoice.orderNumber,
        },
        {
          name: "reference",
          value: detailInvoice.reference,
        },
        {
          name: "status",
          value: detailInvoice.status,
        },
      ]);
    }
  }, [detailInvoice]);

  useEffect(() => {
    if (lineItems) {
      handleTotalPriceLineItems(lineItems);
    }
  }, [lineItems]);

  return (
    <Form
      labelAlign="left"
      layout="vertical"
      form={form}
      requiredMark={false}
      onFinish={onFinish}
    >
      <InvoiceCardWithAction
        type={LINE_ITEM_TABLE_TYPE.INVOICE}
        currentData={detailInvoice}
        disabled={detailInvoice && detailInvoice.status === INVOICE_STATUS.VOIDED}
        xeroPath={`https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${detailInvoice?.xeroId}`}
        hierachy={[
          { key: 1, label: "Invoice", path: "/invoice" },
          { key: 2, label: pathname.indexOf("new") === -1 ? "Edit" : "New" },
        ]}
        setStatus={setStatus}
        setLoading={setLoading}
        UPDATING_FUNC={UPDATING_INVOICE_STATUS}
        loading={loading}
        title={detailInvoice && detailInvoice.status}
        editPath="/invoice/new"
        form = {
          initialLoading ? (
            <Spin spinning={initialLoading} indicator={antIcon}></Spin>
          ) : 
          <>
            <Alert
                style={{
                  marginBottom: 10,
                }}
                message="GST Note"
                description="All prices are to be exclusive of GST."
                type="info"
            />
            <div
              style={{
                display: "flex",
                marginBottom: 2,
              }}
            >
              <Space wrap>
                <Form.Item
                  label="Contact"
                  name="contact"
                  style={{ width: 150 }}
                  rules={[{ required: true }]}
                >
                 
                    <Select
                        showSearch
                        placeholder="Contact"
                        optionFilterProp="children"
                        onSelect={(e, record) => onSelectContact(record)}
                        filterOption={(input, option) =>
                          option?.children
                            ?.toString()
                            ?.replace(/,/gi, "")
                            .toLowerCase()
                            ?.includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA?.children
                            ?.toString()
                            ?.replace(/,/gi, "")
                            ?.toLowerCase()
                            ?.localeCompare(
                              optionB?.children
                                ?.toString()
                                ?.replace(/,/gi, "")
                                ?.toLowerCase()
                            )
                        }
                      >
                        {contacts &&
                          contacts?.map((contact) => {
                            return (
                              <Option key={contact._id} value={contact._id} item={contact}>
                                {contact.name}
                              </Option>
                            );
                          })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="date"
                    label="Date"
                    rules={[
                      {
                        type: "object",
                        required: true,
                        message: "Please select date!",
                      },
                    ]}
                    wrapperCol={{ sm: 24 }}
                  >
                    <DatePicker format="DD-MMM-YYYY" />
                  </Form.Item>

                  <Form.Item name="dueDate" label="Due Date">
                    <DatePicker format="DD-MMM-YYYY" />
                  </Form.Item>

                  <Form.Item name="reference" label="Reference">
                    <Input placeholder="Reference" />
                  </Form.Item>

                  <Form.Item name="File" label=" ">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Upload {...props} showUploadList={false}>
                        <Button icon={<FileAddOutlined />}></Button>
                      </Upload>
                      {fileList.length > 0
                        ? fileList?.map((l, index) => {
                            return (
                            <div>
                              <div
                                key={index}
                                style={{
                                  margin: "0 0 0 10px",
                                  display: "flex",
                                }}
                              >
                                <PaperClipOutlined />
                                <div
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: "400px",
                                  }}
                                >
                                  <a
                                    href={l.url}
                                    target="_blank"
                                    rel={l.name}
                                    style={{
                                      margin: "0 10px",
                                    }}
                                  >
                                    {l.name}
                                  </a>
                                </div>
                                <DeleteOutlined
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const index = fileList.indexOf(l.file);
                                    const list = [...fileList];
                                    list.splice(index, 1);
                                    setFileList([...list]);
                                  }}
                                />
                              </div>
                              <DeleteOutlined
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const index = fileList.indexOf(l.file);
                                  const list = [...fileList];
                                  list.splice(index, 1);
                                  setFileList([...list]);
                                }}
                              />
                            </div>
                          );
                        })
                        : ""
                      }
                  </div>
                
                </Form.Item>
              </Space>
            </div>
            <LineItemTableWithAction
              totalData={total}
              lineItems={lineItems}
              readLineItemState={readyLineItemState}
              setReadyLineItemState={setReadyLineItemState}
              setLineItems={setLineItems}
              lineItemColumns={lineItemColumns}
              type={LINE_ITEM_TABLE_TYPE.INVOICE}
            />
          </>
        }
      />
    </Form>
  );
};

export default EditInvoice;
