import React, { useState, useEffect } from "react";
import {
  Layout,
  Breadcrumb,
  List,
  Space,
  DatePicker,
  Card,
  Tooltip,
  Button,
  Dropdown,
  Menu,
  Drawer,
  Input,
  Modal,
  Tabs,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  MenuOutlined,
  ExportOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import styles from "../../styles/StandardTableLayout.module.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CSVLink } from "react-csv";
import { setCsvExportData } from "../../store/user-store/reducer";
import { useDispatch, useSelector } from "react-redux";
import FuseUtils from "../../utils/Utils";
import {
  ContextMenu,
  handleOpenContextMenu,
} from "../../shared-components/ContextMenu";
import { INVOICE_STATUS } from "../../constants";

const { Content } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
moment().locale("vi_VN");
const { TabPane } = Tabs;

const StandardListLayout = ({
  csvHeader,
  csvData,
  csvFileName,
  isLoading,
  setIsLoading,
  isHaveRowAction,
  hierachy,
  dataList,
  tabSort,
  setTabSort,
  columns,
  FETCH_DATA,
  DELETE_FUNC,
  table,
  hasSelection,
  hasToolbar,
  hasNew,
  dateRanger,
  rightMenu,
  contextMenu,
  setContextMenu,
  optionMenu,
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { role, organisationId } = useSelector(({ auth }) => auth.user);

  const [tempDate, setTempDate] = useState();
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowsSelected, setRowsSelected] = useState(false);
  const [saveRow, setSaveRow] = useState([]);

  const [configLayout, setConfigLayout] = useState({
    hasSelection: true,
    hasToolbar: true,
    hasRowAction: true,
    hasNew: true,
  });

  const onAddButtonClickHandler = (event) => {
    navigate(`/${table}/new`);
  };

  const handleClickRow = (record) => {
    if (table === "documents" && role?.indexOf("admin") === -1) {
      window.open(
        record?.fileUrl &&
          record?.fileUrl !== "" &&
          JSON.parse(record?.fileUrl)?.webViewLink,
        "_blank"
      );
    } else {
      navigate(`/${table}/${record._id}`);
    }
  };

  const showSearchDrawer = () => {
    setVisible(true);
  };

  const onSearchDrawerClose = () => {
    setVisible(false);
  };

  const onChangeBetweenDate = (date, dateString) => {
    const filterDate = [];

    dataList &&
      dataList?.filter((date, dateIdx) => {
        date?.timeSheets.filter((d) => {
          if (
            moment(moment(dateString).format("YYYY/MM/DD")).isSame(
              moment(d.dateTime).format("YYYY/MM/DD")
            )
          ) {
            filterDate.push(dataList && dataList[dateIdx]);
          }
        });
      });

    setTempDate(filterDate);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setTempDate(
      searchText.length === 0
        ? dataList
        : FuseUtils.filterArrayByString(dataList, searchText)
    );
  };

  const moreOptionsMenu = (
    <Menu style={{ minWidth: "150px" }}>
      <Menu.Item key="0">Print</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={() => dispatch(setCsvExportData(tableData))}>
        <CSVLink
          data={csvData && csvData}
          headers={csvHeader && csvHeader}
          separator={","}
          filename={csvFileName && csvFileName}
        >
          Export
        </CSVLink>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    let config = {
      hasSelection: true,
      hasToolbar: true,
      hasRowAction: true,
      hasNew: true,
    };
    if (isHaveRowAction !== undefined && !isHaveRowAction) {
      config.hasRowAction = isHaveRowAction;
    }
    if (hasSelection !== undefined && !hasSelection) {
      config.hasSelection = hasSelection;
    }
    if (hasToolbar !== undefined && !hasToolbar) {
      config.hasToolbar = hasToolbar;
    }
    if (hasNew !== undefined && !hasNew) {
      config.hasNew = hasNew;
    }
    setConfigLayout(config);
  }, [
    dataList,
    columns,
    hierachy,
    table,
    isHaveRowAction,
    hasSelection,
    hasToolbar,
    hasNew,
    rightMenu,
  ]);

  const showConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to delete these records?",
      icon: <ExclamationCircleOutlined />,
      content: "The operation cannot be undone.",
      onOk() {
        const newSource = saveRow?.map((s) => {
          return `ids=${s._id}`;
        });
        if (table === "team/members" || table === "departments") {
          DELETE_FUNC(
            `${newSource.join("&")}&organisationId=${organisationId}`
          ).then((data) => {
            FETCH_DATA();
          });
        }
        DELETE_FUNC(newSource.join("&")).then((data) => {
          FETCH_DATA();
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const switchKeys = (key) => {
    switch (key.toLowerCase()) {
      case "all":
        return "ALL";
      case "draft":
        return INVOICE_STATUS.DRAFT;
      case "awaiting approval":
        return INVOICE_STATUS.SUBMITTED;
      case "awaiting payment":
        return INVOICE_STATUS.DELETED;
      case "approved":
        return INVOICE_STATUS.AUTHORISED;
      case "billed":
        return INVOICE_STATUS.BILLED;
      case "paid":
        return INVOICE_STATUS.PAID;
      case "repeating":
        return INVOICE_STATUS.VOIDED;
    }
  };

  const onChangeTabs = (key) => {
    let switchKey = switchKeys(key);
    setTabSort({
      ...tabSort,
      tabChoosed: switchKey,
    });
  };

  return (
    <>
      <ContextMenu optionMenu={optionMenu} contextMenu={contextMenu} />
      <Content
        className={styles.content}
        onClick={() =>
          role?.indexOf("admin") !== -1 && setContextMenu({ visible: false })
        }
      >
        {/* breadcumb  */}
        <Breadcrumb style={{ margin: "16px 0" }}>
          {hierachy?.map((item) => {
            return (
              <Breadcrumb.Item key={item.key}>{item.label}</Breadcrumb.Item>
            );
          })}
        </Breadcrumb>

        {/* handle toolbar  */}
        <div className="site-layout-content">
          <div className={styles.actionBar}>
            {rowsSelected && (
              <Space>
                <Button icon={<DeleteOutlined />} onClick={showConfirm}>
                  Delete
                </Button>
                <CSVLink
                  data={csvData}
                  headers={csvHeader}
                  separator={","}
                  filename={"records"}
                >
                  <Button icon={<ExportOutlined />}>Export</Button>
                </CSVLink>
              </Space>
            )}

            <div className={styles.rightActions}>
              <Space>
                {dateRanger && (
                  <div className="date">
                    <DatePicker
                      onChange={onChangeBetweenDate}
                      picker="week"
                      locale={moment.locale("vi_VN", {
                        week: { dow: 1, doy: 2 },
                      })}
                    />
                  </div>
                )}
                {configLayout.hasToolbar && (
                  <>
                    <Tooltip placement="topLeft" title="Search">
                      <Button
                        icon={<SearchOutlined />}
                        onClick={showSearchDrawer}
                      ></Button>
                    </Tooltip>
                    {role?.indexOf("admin") !== -1 && configLayout.hasNew && (
                      <Tooltip placement="top" title="Add">
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={onAddButtonClickHandler}
                        ></Button>
                      </Tooltip>
                    )}
                    {role?.indexOf("admin") !== -1 && (
                      <Dropdown overlay={moreOptionsMenu} trigger={["click"]}>
                        <Tooltip placement="topRight" title="More Options">
                          <Button icon={<MenuOutlined />}></Button>
                        </Tooltip>
                      </Dropdown>
                    )}
                  </>
                )}
              </Space>
            </div>
          </div>
          {tabSort && (
            <Tabs
              defaultActiveKey="0"
              onChange={onChangeTabs}
              style={{ width: "100%" }}
            >
              {tabSort?.tabs?.map((ele) => (
                <TabPane
                  key={ele?.type}
                  tab={
                    <span>
                      {ele?.type} {ele?.length > 0 && `(${ele?.length})`}
                    </span>
                  }
                />
              ))}
            </Tabs>
          )}
          {/* table list data  */}
          <Card bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={tempDate ? tempDate : dataList}
              loading={{ indicator: antIcon, spinning: isLoading }}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    onClick={() => handleClickRow(item)}
                    onContextMenu={(e) => {
                      handleOpenContextMenu(e, item, setContextMenu);
                    }}
                    title={<a>{item?.title}</a>}
                    description={item?.description}
                  />
                </List.Item>
              )}
            />
          </Card>
        </div>
      </Content>

      {/* search drawer  */}
      <Drawer
        title="Search"
        placement="right"
        onClose={onSearchDrawerClose}
        visible={visible}
        footer={
          <Space>
            <Button type="primary" onClick={onSearchDrawerClose}>
              Search
            </Button>
          </Space>
        }
      >
        <Input onChange={(e) => handleSearch(e)} />
      </Drawer>
    </>
  );
};

export default StandardListLayout;
