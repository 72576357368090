import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Form, Input, Button, Select, Modal } from "antd";
import { useSelector } from "react-redux";
import { CREATING_USER, UPDATING_USER } from "../../store/user-store/reducer";
import { FORM_TYPE } from "../../constants";

const EditUserModal = ({
  title,
  type,
  visible,
  onCancel,
  handleUpdateUser,
}) => {
  const [form] = Form.useForm();
  const { detailMember } = useSelector(({ user }) => user.userReducer);
  const { user } = useSelector(({ auth }) => auth);
  const [buttonLoading, setButtonLoading] = useState(false);

  const onFinish = async (values) => {
    values.role = [values.role];
    values.organisationId = user.organisationId;
    values._id = detailMember._id;
    values.email = values.email.toLowerCase().trim()
    setButtonLoading(true);
    if (type === FORM_TYPE.CREATE) {
      CREATING_USER(values)
        .then((res) => {
          handleUpdateUser({ ...values, ...res }, FORM_TYPE.CREATE);
          setButtonLoading(false);
          onCancel();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      UPDATING_USER(values)
        .then((res) => {
          handleUpdateUser({ ...values, ...res }, FORM_TYPE.UPDATE);
          setButtonLoading(false);
          onCancel();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (detailMember) {
      form.setFields([
        {
          name: "firstName",
          value: detailMember.firstName,
        },
        {
          name: "lastName",
          value: detailMember.lastName,
        },
        {
          name: "email",
          value: detailMember.email,
        },
        {
          name: "password",
          value: detailMember.password,
        },
        {
          name: "role",
          value: detailMember?.role[0],
        },
      ]);
    }
  }, [detailMember]);

  return (
    <Modal title={title} visible={visible?.isOpen} onCancel={onCancel} footer={null}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label="Name" style={{ marginBottom: 0 }}>
          <Form.Item
            name="firstName"
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              marginRight: "4px",
            }}
          >
            <Input placeholder="First name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              marginLeft: "4px",
            }}
          >
            <Input placeholder="Last name" />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Email" name="email">
          <Input placeholder="Email address" />
        </Form.Item>

        {
          detailMember?._id === "new" &&
          <Form.Item label="Password" name="password">
            <Input.Password placeholder="Password" />
          </Form.Item>
        }

        <Form.Item label="Role" name="role" id="select-drop">
          <Select>
            <Select.Option key="admin" value="admin">
              Admin
            </Select.Option>
            <Select.Option key="user" value="user">
              User
            </Select.Option>
            <Select.Option key="manager" value="manager">
              Manager
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={buttonLoading}>
            {visible?.type === FORM_TYPE.CREATE ? "Add" : "Update"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
