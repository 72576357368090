import React from "react";
import EditPositions from "../../../../components/Positions/EditPositions";

const PositionDetail = () => {
  return (
    <>
      <EditPositions />
    </>
  );
};

export default PositionDetail;
