import EditOrganisations from "../../components/Organisations/EditOrganisations";

const OrganisationsEdit = () => {
  return (
    <>
      <EditOrganisations />
    </>
  );
};

export default OrganisationsEdit;
