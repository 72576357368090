import EditFleetVehicle from "../../../components/FleetVehicle/EditFleetVehicle";

const FleetVehicleEdit = () => {
  return (
    <>
      <EditFleetVehicle />
    </>
  );
};

export default FleetVehicleEdit;
