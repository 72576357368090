import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
//state
const postWebsiteSlice = createSlice({
  name: "quoteStore",
  initialState: {
    quote: [],
    detailQuote: null,
  },
  reducers: {
    setQuote: (state, action) => {
      state.quote = action.payload;
      state.detailQuote = null;
    },
    setDetailQuote: (state, action) => {
      state.detailQuote = action.payload;
    },
  },
});

export default postWebsiteSlice.reducer;

//action
export const { setQuote, setDetailQuote } = postWebsiteSlice.actions;

//Quote
export const FETCH_ALL_QUOTE = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/quote/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const FETCH_DETAIL_QUOTE = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/quote/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const CREATING_QUOTE = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/quote/create`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_QUOTE = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/quote/update`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    console.log(JSON.parse(err.response.data.payload.message))
    throw err;
  }
};

export const DELETING_QUOTE = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/quote/delete?${query}`)
      .then((res) => {
        return res.data.payload.removedUser;
      });
  } catch (err) {
    throw err;
  }
};

export const DOWNLOAD_GOOGLE_CLOUD_FILE = async (name) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/google/download`, {
        params: {
          name: name,
        },
        responseType: "blob",
      })
      .then((data) => {
        const url = URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      });
  } catch (err) {
    throw err;
  }
};

export const UPDATING_QUOTE_STATUS = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/quote/status`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    console.log(JSON.parse(err.response.data.payload.message))
    throw err;
  }
};

export const EMAIL_QUOTE = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/quote/email`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
    throw err;
  }
};

export const NEW_QUOTE = () => (dispatch) => {
  const newModel = {
    _id: "new",
    contact: "",
    date: moment(),
    expiryDate: "",
    quoteNumber: "",
    reference: "",
    title: "",
    summary: "",
    sentToContact: false,
    status: "DRAFT",
  };
  return dispatch(setDetailQuote(newModel));
};
