import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import styles from "../../styles/Head.module.scss";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import UserPopover from "./UserPopover";
import OrgPopover from "./OrgPopover";
import navigationConfig from "../StandardConfigLayout/navigationConfig";
import _ from "lodash";
import { MENU_ITEM_TYPE } from "../../constants";

const { Header } = Layout;
const { SubMenu } = Menu;

const Head = () => {
  const { user } = useSelector(({ auth }) => auth);
  const { pathname } = useLocation();
  const [currentKey, setCurentKey] = useState("mail");
  const [navigationItems, setNavigationItem] = useState([]);
  const handleClick = (e) => {
    setCurentKey(e.key);
  };

  useEffect(() => {
    let items = [];
    let arrZone = []
    if (!user || (user && (!user.role || user.role.length === 0))) {
      items = navigationConfig.filter((item) => item.auth.length === 0);
    } else {
      navigationConfig.forEach((nav) => {
        const roles = nav.auth;
        let intersection = _.intersection(roles, user.role);
        if (intersection.length > 0) {
          const childrens = [];
          if (nav.title === "My Zone") {
            arrZone.push(nav)
          }
          nav.children?.forEach((n) => {
            let intersection = _.intersection(user.role, n.auth);
            if (intersection.length > 0) {
              childrens.push(n);
            }
          });
          if (!user?.teamMemberId) {
            nav.title !== "My Zone" && items.push({ ...nav, children: childrens });
          } else {
            items.push({ ...nav, children: childrens });
          }
        }
      });
    }
    setNavigationItem(items);
  }, [user]);

  return (
    <Header className={styles.header}>
      <OrgPopover user={user} />
      <UserPopover user={user} />
      <Menu
        theme="dark"
        mode="horizontal"
        className="navigation__items"
        onClick={handleClick}
        selectedKeys={[currentKey]}
      >
        {navigationItems?.map((item) =>
          item.type === MENU_ITEM_TYPE.ITEM ? (
            <Menu.Item
              key={item.title}
              style={{ background: pathname === item.url && "#1890ff" }}
            >
              <Link to={item.url}>{item.title}</Link>
            </Menu.Item>
          ) : (
            <SubMenu
              key={item.title}
              title={item.title}
              style={{
                background:
                  pathname ===
                  item?.children?.find((e) => e.url === pathname)?.url &&
                  "#1890ff",
              }}
            >
              {item?.children?.map((child) => (
                <Menu.Item
                  key={child.title}
                  style={{
                    background: pathname === child.url && "#1890ff",
                  }}
                >
                  <Link to={child.url}>{child.title}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          )
        )}
      </Menu>
    </Header>
  );
};

export default Head;
