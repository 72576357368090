import EditInvoice from "../../components/Invoice/EditInvoice";

const Invoices = () => {
  return (
    <>
      <EditInvoice />
    </>
  );
};

export default Invoices;
