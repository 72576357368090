import React from "react";
import {
    Comment,
    List,
    Avatar,
    Space,
    Tag,
    Modal,
} from "antd";
import moment from "moment";

const splitName = (name) => {
    if (name) {
        const nameSplit = name.split(" ");
        const firstCharacters = nameSplit.reduce((last, current) => {
            return last.substring(1, 0) + current.substring(1, 0);
        });

        return firstCharacters;
    }
    return "";
};
const TimesheetCommentModalView = ({
    visible,
    onClose,
    comments,
    role
}) => {
    return (
        <Modal
            title="Comments"
            width={700}
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <List
                className="comment-list"
                itemLayout="horizontal"
                dataSource={comments}
                renderItem={(item, index) => (
                    <li key={index}>
                        <Comment
                            author={
                                item.addedBy?.name ||
                                `${item?.addedBy?.firstName} ${item?.addedBy?.lastName}`
                            }
                            avatar={
                                item?.addedBy?.photo ? (
                                    <Avatar
                                        src={item?.addedBy?.photo}
                                        alt={
                                            item.addedBy?.name
                                        }
                                    />
                                ) : (
                                    <Avatar style={{ backgroundColor: "#87d068" }}>
                                        {splitName(
                                            item.addedBy?.name
                                        )}
                                    </Avatar>
                                )
                            }
                            content={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item && item.description,
                                    }}
                                ></div>
                            }
                            datetime={
                                <Space>
                                    {
                                        moment(
                                            item.addedTime,
                                            "DD-MMM-YYYY"
                                        ).fromNow()
                                    }
                                    {
                                        (item.adminOnly && role?.includes("admin")) &&
                                        (
                                            <>
                                                <Tag color="#108ee9">{item.addedTime}</Tag>
                                                <Tag color="default">Admin Only</Tag>
                                            </>
                                        )
                                    }
                                </Space>
                            }
                        />
                    </li>
                )}
            />
        </Modal>
    )
}

export default TimesheetCommentModalView