import React, { useState, useEffect } from "react";
import { Badge } from "antd";
import { useDispatch, useSelector } from "react-redux";
import StandardTableLayout from "../../layouts/StandardTableLayout/StandardTableLayout";
import StandardListLayout from "../../layouts/StandardTableLayout/StandardListLayout";
import { useNavigate } from "react-router-dom";
import {
  FETCH_ALL_RECEIPT,
  setReceipt,
  DELETING_RECEIPT,
} from "../../store/receipt-store/reducer";

const Receipt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organisationId } = useSelector(({ auth }) => auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const { receipt } = useSelector(({ receipt }) => receipt.receiptReducer);
  const { csvExportData } = useSelector(({ user }) => user.userReducer);
  const [csv, setCsv] = useState({
    headers: [
      {
        key: "firstName",
        label: "First Name",
      },
      {
        key: "image",
        label: "Image",
      },
      {
        key: "name",
        label: "Name",
      },
      {
        key: "saleDescription",
        label: "Sale Description",
      },
      {
        key: "category",
        label: "Category",
      },
      {
        key: "unit",
        label: "Unit",
      },
      {
        key: "accountCode",
        label: "Chart of Accounts",
      },
      {
        key: "department",
        label: "Department",
      },
      {
        key: "purchasePrice",
        label: "Purchase Price",
      },
      {
        key: "salePrice",
        label: "Sale Price",
      },
      {
        key: "sku",
        label: "Sku",
      },
    ],
    data: [],
    fileName: "allItems.csv",
  });
  const [dataList, setDataList] = useState([]);

  const handleColumnCsv = (array) => {
    let csvData = [];
    array.forEach((e) => {
      csvData.push({
        jobNumber: e.jobNumber,
        description: e.description,
      });
    });
    return csvData;
  };

  const columns = [
    {
      title: "Job Number",
      sorter: (a, b) => a?.jobNumber.localeCompare(b?.jobNumber),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/receipt/${record._id}`);
          },
        };
      },
      render: (data) => <>{data?.jobNumber}</>,
    },
    {
      title: "Description",
      sorter: (a, b) => a?.description.localeCompare(b?.description),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/receipt/${record._id}`);
          },
        };
      },
      render: (data) => <>{data?.description}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/items/${record._id}`);
          },
        };
      },
      render: (status) => {
        let type = status === true ? "success" : "error";
        return <Badge status={type} text={status ? "Converted" : "Not Converted"} />;
      },
      sorter: (a, b) => b.status - a.status,
    },
  ];

  const FETCH_DATA = () => {
    if (organisationId) {
      FETCH_ALL_RECEIPT(organisationId)
        .then((data) => {
          dispatch(setReceipt(data));
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    FETCH_DATA();
  }, [organisationId]);

  useEffect(() => {
    if (receipt) {
      const columns = handleColumnCsv(receipt);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [receipt]);

  useEffect(() => {
    if (csvExportData.length > 0) {
      const columns = handleColumnCsv(csvExportData);
      setCsv({
        ...csv,
        data: columns,
      });
    }
  }, [csvExportData]);

  useEffect(() => {
    const newDoc = [];
    receipt?.forEach((ele, index) => {
      let data = {
        _id: ele?._id,
        title: `${ele?.description}`,
        description: `${ele?.description}`,
      };
      newDoc.push(data);
    });
    setDataList(newDoc);
  }, [receipt]);

  return (
    <div className="team_member">
      <div className="table-layouts">
        <div className="tables">
          <StandardTableLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            isHaveRowAction={false}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Receipt" }]}
            dataSource={receipt}
            columns={columns}
            DELETE_FUNC={DELETING_RECEIPT}
            FETCH_DATA={FETCH_DATA}
            table="receipt"
          />
        </div>
        <div className="table-lists">
          <StandardListLayout
            csvHeader={csv.headers || null}
            csvData={csv.data || null}
            csvFileName={csv.fileName || null}
            isHaveRowAction={false}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hierachy={[{ key: 1, label: "Receipt" }]}
            dataList={dataList}
            columns={columns}
            DELETE_FUNC={DELETING_RECEIPT}
            FETCH_DATA={FETCH_DATA}
            table="receipt"
          />
        </div>
      </div>
    </div>
  );
};

export default Receipt;
