import { Form, Input } from "antd";
import "../../../styles/teamMember.scss";

const FormEmployType = ({ mbEmploy, semMbEmploy }) => {
  const [form] = Form.useForm();

  const handleChange = (e) => {
    const value = e.target.value;
    semMbEmploy({
      ...mbEmploy,
      description: value,
    });
  };


  return (
    <Form
      labelAlign="left"
      form={form}
      requiredMark={false}
      labelCol={{ span: 5 }}
    >
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true }]}
      >
        <Input onChange={handleChange} />
      </Form.Item>
    </Form>
  );
};

export default FormEmployType;
