import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
//state
const orgSlice = createSlice({
  name: "userStore",
  initialState: {
    categories: [],
    makes: [],
    vehicles: [],
    models: [],
    issues: [],
    detailIssue: null,
    detailCategory: null,
    detailMake: null,
    detailModel: null,
    detailVehicle: null,
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
      state.detailCategory = null;
    },
    setDetailCategory: (state, action) => {
      state.detailCategory = action.payload;
    },
    setVehicleMakes: (state, action) => {
      state.makes = action.payload;
      state.detailMake = null;
    },
    setDetailVehicleMake: (state, action) => {
      state.detailMake = action.payload;
    },
    setVehicleModel: (state, action) => {
      state.models = action.payload;
      state.detailModel = null;
    },
    setDetailVehicleModel: (state, action) => {
      state.detailModel = action.payload;
    },
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
      state.detailVehicle = null;
    },
    setDetailVehicle: (state, action) => {
      state.detailVehicle = action.payload;
    },
    setFleetIssues: (state, action) => {
      state.issues = action.payload;
      state.detailIssue = null;
    },
    setDetailFleetIssue: (state, action) => {
      state.detailIssue = action.payload;
    },
  },
});

export default orgSlice.reducer;

//action
export const {
  setCategories,
  setDetailCategory,
  setDetailVehicle,
  setDetailVehicleMake,
  setDetailVehicleModel,
  setVehicleMakes,
  setVehicleModel,
  setVehicles,
  setFleetIssues,
  setDetailFleetIssue
} = orgSlice.actions;

//category
export const FETCH_ALL_FLEET_CATEGORIES = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/categories/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_DETAIL_FLEET_CATEGORIES = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/categories/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const CREATE_FLEET_CATEGORY = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/categories/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const UPDATE_FLEET_CATEGORY = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/categories/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const DELETE_FLEET_CATEGORY = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/categories/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_CATEGORY = () => (dispatch) => {
  const newModel = {
    _id: "new",
    description: "",
    organisationId: "",
    active: true,
  };
  return dispatch(setDetailCategory(newModel));
};

//Vehicle Makes
export const FETCH_ALL_VEHICLE_MAKE = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-make/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_DETAIL_VEHICLE_MAKE = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-make/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const CREATE_VEHICLE_MAKE = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-make/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const UPDATE_VEHICLE_MAKE = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-make/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const DELETE_VEHICLE_MAKE = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-make/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_VEHICLE_MAKE = () => (dispatch) => {
  const newModel = {
    _id: "new",
    model: "",
    description: "",
    organisationId: "",
    active: true,
  };
  return dispatch(setDetailVehicleMake(newModel));
};

//Vehicle Models
export const FETCH_ALL_VEHICLE_MODEL = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-model/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_DETAIL_VEHICLE_MODEL = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-model/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const CREATE_VEHICLE_MODEL = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-model/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      })
      .catch((err) => {
        throw err.response
      })
  } catch (err) {
   throw err
  }
};

export const UPDATE_VEHICLE_MODEL = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-model/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      })
      .catch((err) => {
        throw err.response
      })
  } catch (err) {
   throw err
  }
};

export const DELETE_VEHICLE_MODEL = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/vehicle-model/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_VEHICLE_MODEL = () => (dispatch) => {
  const newModel = {
    _id: "new",
    description: "",
    organisationId: "",
    active: true,
  };
  return dispatch(setDetailVehicleModel(newModel));
};

//vehicle
export const FETCH_ALL_VEHICLES = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/all?organisationId=${organisationId}`)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_DETAIL_VEHICLE = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const CREATE_VEHICLE = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/fleet/create`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const UPDATE_VEHICLE = async (data) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/fleet/update`, data)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const DELETE_VEHICLE = async (query) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/delete?${query}`)
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_VEHICLE = () => (dispatch) => {
  const newModel = {
    _id: "new",
    organisationId: "",
    nickName: "",
    registration: "",
    make: "",
    category: "",
    fuelType: "",
    vinNumber: "",
    enginNumber: "",
    year: moment().year(),
    seats: 0,
    TARE: 0,
    GVM: 0,
    color: "",
    tollId: "",
    lastOdometer: 0,
    lastOdometerDate: moment(),
    nextServiceOdometer: 0,
    notes: "",
    driveUrl: "",
    hourlyCost: 0,
    status: true,
  };
  return dispatch(setDetailVehicle(newModel));
};

//issue
export const FETCH_ALL_FLEET_ISSUES = async (organisationId) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/issues/all`, {
        params: {
          organisationId,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const FETCH_DETAIL_FLEET_ISSUE = async (id) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/fleet/issues/detail`, {
        params: {
          id,
        },
      })
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const CREATE_FLEET_ISSUE = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/issues/create`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const UPDATE_FLEET_ISSUE = async (data) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/issues/update`,
        data
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const DELETE_FLEET_ISSUE = async (query) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/fleet/issues/delete?${query}`
      )
      .then((res) => {
        return res.data.payload.data;
      });
  } catch (err) {
   throw err
  }
};

export const NEW_ISSUE = () => (dispatch) => {
  const newModel = {
    _id: "new",
    dateTime: moment(),
    organisationId: "",
    fleetPreStartId:"",
    fleetId: "",
    issueQuestion: "",
    issueDescription: "",
    active: true,
  };
  return dispatch(setDetailFleetIssue(newModel));
};